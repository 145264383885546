import React, { useContext, useState, useRef, useEffect } from 'react';
import Dropdown from './Dropdown';

import './index.css';
import { StoreContext } from '../../store';

import { findColor } from '../../services';

import { ReactComponent as Search } from '../../assets/search.svg';
import Bar from '../../assets/categories/bar.svg';
import Beauty from '../../assets/categories/beauty.svg';
import Bigbox from '../../assets/categories/bigbox.svg';
import Business from '../../assets/categories/business.svg';
import Cafe from '../../assets/categories/cafe.svg';
import FastFood from '../../assets/categories/fast-food.svg';
import Finance from '../../assets/categories/finance.svg';
import Shopping from '../../assets/categories/general-shopping.svg';
import Grocery from '../../assets/categories/grocery.svg';
import Health from '../../assets/categories/health.svg';
import HouseHold from '../../assets/categories/household.svg';
import Restaurant from '../../assets/categories/restaurant.svg';
import Safety from '../../assets/categories/safety.svg';
import Entertainment from '../../assets/entertainment.svg';
import Favorite from '../../assets/favorites.svg';
import Access from '../../assets/access.svg';
import { ReactComponent as CloseSqaure } from '../../assets/close-square.svg';
import { ReactComponent as FilterIcon } from '../../assets/filter-2.svg';
import { ReactComponent as Star } from '../../assets/star.svg';
import { ReactComponent as Close } from '../../assets/close-icon.svg';

import Show from '../../utility/Show';

const FilterContainer = () => {
  const { state, dispatch, actions } = useContext(StoreContext);
  const inputRef = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [showSearchBox, setShowSearchBox] = useState(true);
  const {
    mapTab,
    showFilter,
    isLoading,
    currentView,
    isMobile,
    currentLifestyleType,
    currentRates,
  } = state;

  const isModern = currentView === 'Modern';

  const others = [
    {
      name: 'Others',
      list: [
        { name: 'Unclassified', value: 'others' },
        { name: 'Baggage', value: 'Baggage' },
        { name: 'Charity', value: 'Charity' },
        { name: 'Craft', value: 'Craft' },
        { name: 'Gift', value: 'Gift' },
        { name: 'Public Toilet', value: 'Public Toilet' },
        { name: 'Shipping', value: 'Shipping' },
        { name: 'Storage Rental', value: 'Storage Rental' },
        { name: 'Variety Store', value: 'Variety Store' },
      ],
    },
  ];

  const groceries = [
    {
      name: 'Chain',
      list: [
        { name: "Trader Joe's", value: "Trader Joe's" },
        { name: 'Whole Foods', value: 'Whole Foods' },
        { name: 'Specialty Foods', value: 'Specialty Foods' },
      ],
    },
    {
      name: 'Others',
      list: [
        { name: 'Convenience', value: 'Convenience' },
        { name: 'Supermarket', value: 'Supermarket' },
        { name: 'Bakery', value: 'Bakery' },
        { name: 'Liquor', value: 'Alcohol' },
      ],
    },
  ];

  const restaurant = [
    {
      name: 'American',
      list: [
        { name: 'Steak', value: 'Steak' },
        { name: 'Burger', value: 'Burger' },
        { name: 'Bbq', value: 'barbecue' },
        { name: 'Diner', value: 'Diner' },
        { name: 'Salad', value: 'salad' },
      ],
    },
    {
      name: 'European',
      list: [
        { name: 'Italian', value: 'Italian' },
        { name: 'German', value: 'German' },
        { name: 'Spanish', value: 'Spanish' },
        { name: 'Swiss', value: 'Swiss' },
      ],
    },
    {
      name: 'Asian',
      list: [
        { name: 'Japanese', value: 'Japanese' },
        { name: 'Chinese', value: 'Chinese' },
        { name: 'Korean', value: 'Korean' },
        { name: 'Filipino', value: 'Filipino' },
        { name: 'Thailand', value: 'Thai' },
      ],
    },
    {
      name: 'Latin',
      list: [
        { name: 'Dominican', value: 'Dominican' },
        { name: 'Colombian', value: 'Colombian' },
        { name: 'Brazilian', value: 'Brazilian' },
        { name: 'Argentinian', value: 'Argentinian' },
        { name: 'Cuban', value: 'Cuban' },
        { name: 'Mexican', value: 'Mexican' },
      ],
    },
  ];

  const fastfoods = [
    // {
    //   name: 'Chain',
    //   list: [
    //     { name: "McDonald's", value: "McDonald's" },
    //     { name: 'Shake Shack', value: 'Shake Shack' },
    //     { name: 'Burger King', value: 'Burger King' },
    //     { name: 'Jolibee', value: 'Jolibee' },
    //     { name: 'Sweetgreen', value: 'Sweetgreen' },
    //   ],
    // },

    {
      name: 'Food Type',
      list: [
        { name: 'Fast Food', value: 'Fast Food' },
        { name: 'Ice Cream', value: 'Ice Cream' },
        { name: 'Chocolate', value: 'Chocolate' },
        { name: 'Frozen Yogurt', value: 'frozen yogurt' },
        { name: 'Pizza', value: 'Pizza' },
        { name: 'Delis', value: 'Delis' },
        { name: 'Bagel', value: 'Bagel' },
      ],
    },
  ];

  const cafe = [
    // {
    //   name: 'Chain',
    //   list: [
    //     { name: "Dunkin' Donut", value: "Dunkin' Donuts" },
    //     { name: 'Starbucks', value: 'Starbucks' },
    //     { name: 'Naman', value: 'Naman' },
    //     { name: 'Think Coffee', value: 'Think Coffee' },
    //     { name: "Joe's Coffee", value: "Joe's Coffee" },
    //   ],
    // },
    {
      name: 'Others',
      list: [
        { name: 'Cafe', value: 'Cafe' },
        { name: 'Coffee Shop', value: 'Coffee Shop' },
        { name: 'Boutique', value: 'Boutique' },
        { name: "Donut", value: "Donut" },
      ],
    },
  ];

  const bars = [
    {
      name: 'Bars',
      list: [
        { name: 'Sports', value: 'Sportsbar' },
        { name: 'Wine', value: 'Wine Bar' },
        { name: 'Champagne', value: 'Champagne Bar' },
        { name: 'Irish', value: 'Irish Bar' },
        { name: 'Pub', value: 'Pub' },
      ],
    },
  ];

  // SHOPPING CATEGORIES

  const bigbox = [
    {
      name: 'Bigbox',
      list: [
        { name: 'Target', value: 'Target' },
        { name: 'Walmart', value: 'Walmart' },
        { name: 'Marshalls', value: 'Marshalls' },
      ],
    },
  ];

  // const chains = [
  //   {
  //     name: 'Chains',
  //     list: [
  //       { name: 'Zara', value: 'Zara' },
  //       { name: 'Ann Taylor', value: 'Ann Taylor' },
  //       { name: 'NorthFace', value: 'NorthFace' },
  //       { name: 'Patagonia', value: 'Patagonia' },
  //       { name: 'Warby Parker', value: 'Warby Parker' },
  //       { name: 'H&M', value: 'H&M' },
  //     ],
  //   },
  // ];

  const beauty = [
    {
      name: 'Beauty / Wellness',
      list: [
        { name: 'Hair Salon', value: 'hairdresser' },
        { name: 'Barber Shop', value: 'barber' },
        { name: 'Nail', value: 'Nail' },
        { name: 'Lashes', value: 'Lashes' },
        { name: 'Cosmetics', value: 'cosmetics' },
      ],
    },
  ];

  const household = [
    {
      name: 'Household',
      list: [
        { name: 'Laundry', value: 'Laundry' },
        { name: 'Hardware', value: 'Hardware' },
        { name: 'Home Furnishings', value: 'Home Furnishings' },
        { name: 'Lumber', value: 'Lumber' },
        { name: 'Pet Store', value: 'Pet Store' },
      ],
    },
  ];

  const othersShopping = [
    {
      name: 'General Categories',
      list: [
        { name: 'Cannabis', value: 'Cannabis' },
        { name: 'Clothes', value: 'Clothes' },
        { name: 'Shoes', value: 'Shoe' },
        { name: 'Jewelry', value: 'Jewelry' },
        { name: 'Electronics', value: 'Phone' },
        { name: 'Boutique', value: 'Boutique' },
        { name: 'Antique', value: 'Antique' },
        { name: 'Toys', value: 'Toys' },
        { name: 'Watches', value: 'Watches' },
        { name: 'Housing Works', value: 'Housing Works' },
        { name: 'Convenience', value: 'Convenience' },
        { name: 'Optician', value: 'optician' },
        { name: 'Massage', value: 'Massage' },
        { name: 'Supplements', value: 'nutrition supplements' },
        { name: 'Bike', value: 'bicycle' },
        { name: 'Garden', value: 'Garden' },
        { name: 'Department Store', value: 'department store' },
      ],
    },
  ];

  // SERVICES

  const education = [
    {
      name: 'Education',
      list: [
        { name: 'College', value: 'College' },
        { name: 'Coworking Space', value: 'Coworking Space' },
        { name: 'Library', value: 'Library' },
        { name: 'School', value: 'School' },
      ],
    },
  ];

  const health = [
    {
      name: 'Health',
      list: [
        { name: 'Medical Clinic', value: 'Clinic' },
        { name: 'Hospital', value: 'hospital' },
        { name: 'Urgent Care', value: 'Urgent Care' },
        { name: 'Dental', value: 'Dentist' },
        { name: 'Pharmacy', value: 'Pharmacy' },
      ],
    },
  ];

  const business = [
    {
      name: 'Business',
      list: [
        { name: 'Office Supplies', value: 'Office Supplies' },
        { name: 'Post Office', value: 'Post Office' },
        { name: 'Printing', value: 'Printing' },
        { name: 'Legal', value: 'Legal' },
      ],
    },
  ];

  const finance = [
    {
      name: 'Finance',
      list: [
        { name: 'Bank', value: 'Bank' },
        { name: 'ATM', value: 'ATM' },
      ],
    },
  ];

  const safety = [
    {
      name: 'Safety',
      list: [
        { name: 'Fire Station', value: 'Fire Station' },
        { name: 'Police Station', value: 'Police' },
      ],
    },
  ];

  const gasStation = [
    {
      name: 'Gas Station',
      list: [
        { name: 'Charging Station', value: 'Charging' },
        { name: 'Gas', value: 'Gas' },
      ],
    },
  ];

  // TRANSIT

  const publicTransit = [
    {
      name: 'Public Transit',
      list: [
        { name: 'Bus', value: 'bus' },
        { name: 'Subway', value: 'subway' },
        { name: 'Train', value: 'train' },
        { name: 'Ferry', value: 'ferry terminal' },
        { name: 'Terminal', value: 'terminal' },
      ],
    },
  ];

  // ENTERTAINMENT
  const entertainment = [
    {
      name: 'Entertainment Fitness',
      list: [
        { name: 'Cinema', value: 'Cinema' },
        { name: 'Dog Park', value: 'Dog Park' },
        { name: 'Theater', value: 'Theater' },
        { name: 'Concert', value: 'Concert' },
        { name: 'Park', value: 'Park' },
        { name: 'Fitness', value: 'Fitness' },
      ],
    },
  ];

  const handleFilterChange = (value) => {
    setSearchText(value);

    const timeoutId = setTimeout(() => {
      dispatch({ type: 'SEARCH_CATEGORY', payload: value });
    }, 500); // 300 milliseconds delay

    // Cleanup function to clear the timeout when the component unmounts or when searchText changes
    return () => clearTimeout(timeoutId);
  };

  const resetCategories = () => {
    dispatch({ type: 'RESET_RATE', payload: [] });
  };

  useEffect(() => {
    setSearchText('');
  }, [mapTab]);

  const handleOnMouseLeave = (value) => {
    if (value !== '') {
      actions.sendDataToGoogleAnalytics('filter_search', {
        filter_search_value: value,
      });
    }
  };

  const handleShowSearchBox = () => {
    setShowSearchBox(true);
  };

  const handleClearAll = () => {
    handleFilterChange('');
    resetCategories();
    dispatch({ type: 'RESET_CATEGORY', payload: [''] });
  };

  const handleEstablishmentFilter = () => {
    dispatch({
      type: 'SET_SHOW_FILTERS',
      payload: !showFilter,
    });
  };

  const hasRate = (index) => {
    return index + 1 <= Math.max(...currentRates);
  };

  const getStarColor = (index) => {
    const feature = mapTab;
    const starStyle = {
      '--star-color':
        hasRate(index) && feature && feature !== 'All'
          ? findColor('lifestyle', feature)
          : '#919191',
    };
    return starStyle;
  };

  return (
    <div
      className={`filter-container ${isModern && 'modern-filter-container'}`}
    >
      <Show.When isTrue={isModern && !isMobile}>
        <div className='modern-filter-header'>
        <Show>
          <Show.When isTrue={isMobile}>
            <div className='modern-filter-header-buttons'>
              <div onClick={handleClearAll}>
                clear all
              </div>
            </div>
            Filter
            <CloseSqaure onClick={() => handleEstablishmentFilter()} />
          </Show.When>
          <Show.Else>
            <div>
              <FilterIcon />
              Filter
            </div>
            <div className='modern-filter-header-buttons'>
              {/* <Search onClick={() => handleShowSearchBox()} /> */}
              <div onClick={handleClearAll}>
                clear all
                {/* <CloseSqaure /> */}
              </div>
              <Search onClick={() => handleShowSearchBox()} />
            </div>
          </Show.Else>
        </Show>
        </div>
      </Show.When>

      <Show.When isTrue={isModern && showSearchBox && !isMobile}>
        <div
          className={`search-input-container ${
            isLoading && 'disabled-search'
          } ${isModern && 'modern-search-input-container'}`}
        >
          <Search />
          <input
            ref={inputRef}
            type='text'
            value={searchText}
            onChange={(e) => handleFilterChange(e.target.value)}
            onMouseLeave={(e) => handleOnMouseLeave(e.target.value)}
            placeholder='Search...'
            disabled={isLoading}
          />
        </div>
      </Show.When>

      <Show.When isTrue={!isMobile}>
       <div className='map-rating-filter'>
          <div>Rating</div>
          {[...Array(5)].map((_, index) => (
            <Star
              className={`${hasRate(index) && 'active-star'}`}
              onClick={() => actions.setRatings(index + 1)}
              style={getStarColor(index)}
            />
          ))}
          <Close className='close-icon' onClick={resetCategories} />
       </div>
      </Show.When>

      {/* {categoryFilters.length - 1 > 0 && (
        <div className='category-total-wrapper'>
          <div className='filter-selected' onClick={resetCategories}>
            {categoryFilters.length - 1} Categories Selected
            <img className='filter-clear' src={Cancel} alt='Clear Text' />
          </div>
          <div className='filter-selected' onClick={resetCategories}>
            Clear All
          </div>
        </div>
      )} */}

      {/* <Dropdown title='General' image={Food} categories={categories} /> */}
      <div>
        <Show.When
          isTrue={
            mapTab === 'Food' ||
            mapTab === 'All' ||
            currentLifestyleType.includes('Food')
          }
        >
          <Dropdown title='Grocery' image={Grocery} categories={groceries} />
          <Dropdown
            title='Restaurants'
            image={Restaurant}
            categories={restaurant}
          />
          <Dropdown title='Fast Foods' image={FastFood} categories={fastfoods} />
          <Dropdown title='Cafe' image={Cafe} categories={cafe} />
          <Dropdown title='Bars' image={Bar} categories={bars} />
        </Show.When>

        <Show.When
          isTrue={
            mapTab === 'Shops' ||
            mapTab === 'All' ||
            currentLifestyleType.includes('Shops')
          }
        >
          <Dropdown title='Big Box' image={Bigbox} categories={bigbox} />
          {/* <Dropdown title='Chains' image={Chain} categories={chains} /> */}
          <Dropdown title='Beauty' image={Beauty} categories={beauty} />
          <Dropdown title='Household' image={HouseHold} categories={household} />
          <Dropdown title='Other Shops' image={Shopping} categories={othersShopping} />
        </Show.When>

        <Show.When
          isTrue={
            mapTab === 'Services' ||
            mapTab === 'All' ||
            currentLifestyleType.includes('Services')
          }
        >
          <Dropdown title='Education' image={Health} categories={education} />
          <Dropdown title='Health' image={Health} categories={health} />
          <Dropdown title='Business' image={Business} categories={business} />
          <Dropdown title='Finance' image={Finance} categories={finance} />
          <Dropdown title='Safety' image={Safety} categories={safety} />
          <Dropdown title='Gas Station' image={Access} categories={gasStation} />
        </Show.When>

        <Show.When
          isTrue={
            mapTab === 'Transit' ||
            mapTab === 'All' ||
            currentLifestyleType.includes('Transit')
          }
        >
          <Dropdown title='Transit' image={Access} categories={publicTransit} />
        </Show.When>

        <Show.When
          isTrue={
            mapTab === 'Leisure' ||
            mapTab === 'All' ||
            currentLifestyleType.includes('Leisure')
          }
        >
          <Dropdown
            title='Leisure'
            image={Entertainment}
            categories={entertainment}
          />
        </Show.When>

        <Show.When isTrue={mapTab === 'All'}>
          <Dropdown title='Others' image={Favorite} categories={others} />
        </Show.When>
      </div>
    </div>
  );
};

export default FilterContainer;
