import React, { useState, useContext, useEffect, useRef } from 'react';
import { StoreContext } from '../../store';
import MapSingle from './single-map';
import Slider from 'react-slick';
import Show from '../../utility/Show';

const CompareMaps = ({ properties }) => {
  const { state, dispatch, actions } = useContext(StoreContext);
  const [innerWidth, setInnerWidth] = useState(false);
  let sliderRef = useRef(null);

  const {
    compareLifeStyleAddress,
    isMobile,
    isSmallerScreen,
    compareMapsSliderIndex,
    currentMarker,
    selectedMap,
  } = state;

  const settings = {
    dots: currentMarker[selectedMap] && isMobile ? false : true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    className: `slider-map-container modern-slider-map-container`,
    afterChange: (index) => handleSliderChange(index),
  };

  const handleSliderChange = (index) => {
    dispatch({ type: 'SET_COMPARE_MAPS_SLIDER_INDEX', payload: index });
    dispatch({ type: 'SET_SELECTED_MAP', payload: index });
  };

  useEffect(() => {
    if (isSmallerScreen || isMobile) {
      const index = compareMapsSliderIndex;
      let gotoIndex = null;
      if (isSmallerScreen) {
        if (index > 1) {
        gotoIndex = 1
        } else {
        gotoIndex = 0;
        }
      }

      if (isMobile) {
        gotoIndex = index;
      }

      sliderRef.slickGoTo(gotoIndex);
    }
  }, [compareMapsSliderIndex]);

  const getIndex = (index, chunkIndex) => {
    if (chunkIndex === 1) {
      return chunkIndex + (index + 1);
    } else {
      return index;
    }
  };

  const renderSliderRow = (
    <Slider
      ref={slider => {
        sliderRef = slider;
      }}
      {...settings}
    >
      {compareLifeStyleAddress
        .reduce((chunks, item, index) => {
          if (index % 2 === 0)
            chunks.push(compareLifeStyleAddress.slice(index, index + 2));
          return chunks;
        }, [])
        .map((chunk, chunkIndex) => (
          <div key={chunkIndex} className='compare-map-slider-row'>
            {chunk.map((item, index) => (
              <MapSingle
                key={index}
                className='map-item'
                isSearchable
                index={getIndex(index, chunkIndex)}
                property={item?.property}
                longitude={item?.longitude}
                latitude={item?.latitude}
                neighborhood={item?.neighborhood}
                properties={properties}
                state={state}
                dispatch={dispatch}
                actions={actions}
              />
            ))}
          </div>
        ))}
    </Slider>
  );

  const renderSlider = (
    <Slider
      ref={slider => {
        sliderRef = slider;
      }}
      {...settings}
    >
      {compareLifeStyleAddress.map((item, index) => {
        return (
          <MapSingle
            className='map-item'
            isSearchable
            index={index}
            key={index}
            property={item?.property}
            longitude={item?.longitude}
            latitude={item?.latitude}
            neighborhood={item?.neighborhood}
            properties={properties}
            state={state}
            dispatch={dispatch}
            actions={actions}
          />
        );
      })}
    </Slider>
  );

  return (
    <Show>
      <Show.When isTrue={isSmallerScreen}>
        <div className='compare-map-slider'> {renderSliderRow}</div>
      </Show.When>
      <Show.When isTrue={isMobile}>
        <div className='compare-map-slider'> {renderSlider}</div>
      </Show.When>

      <Show.Else>
        <div className='compare-map-location'>
          {compareLifeStyleAddress.map((item, index) => {
            return (
              <MapSingle
                className='map-item'
                isSearchable
                index={index}
                property={item?.property}
                longitude={item?.longitude}
                latitude={item?.latitude}
                neighborhood={item?.neighborhood}
                properties={properties}
                state={state}
                dispatch={dispatch}
                actions={actions}
              />
            );
          })}
        </div>
      </Show.Else>
    </Show>
  );
};

export default CompareMaps;
