import { useContext } from 'react';
import { StoreContext } from '../../store';

import './index.css';

import { ReactComponent as Info } from '../../assets/alert-icon.svg';

const PrivacyPolicy = () => {
  const { dispatch } = useContext(StoreContext);

  const closePrivacyPolicy = () => {
    dispatch({ type: 'SET_SHOW_PRIVACY_POLICY', payload: false });
  };

  return (
    <div className='privacy-policy-container'>
      <div className='privacy-policy-card max-w-screen-lg'>
        <div className='title'><Info /> Privacy Policy</div>
        <div className='content'>
          <p>
            We respect your privacy and are committed to protecting your personal information. 
            This privacy policy outlines how we collect, use, and safeguard your data when you interact with our services. 
            Your information is treated with the utmost confidentiality and is not shared with third parties unless required 
            by law or with your explicit consent. We utilize industry-standard security measures to protect your data. 
            By using our services, you agree to the terms of this privacy policy.
          </p>
        </div>
        <button onClick={() => closePrivacyPolicy()}>Done</button>
      </div>
    </div>
  )
};

export default PrivacyPolicy;