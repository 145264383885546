import React, { useEffect, useRef } from 'react';

function ClickOutsideHandler({
  children,
  className,
  onOutsideClick,
  onBlur = null
}) {
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onOutsideClick();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onOutsideClick]);

  return (
    <div
      className={className}
      ref={wrapperRef}
      onBlur={onBlur ? onBlur : null}
    >
      {children}
    </div>
  );
}

export default ClickOutsideHandler;