import React, { useState, useContext, useEffect, useRef } from 'react';
import { StoreContext } from '../../store.js';
import './index.css';

import { ReactComponent as WalkIndexIcon } from '../../assets/walkindex-icon.svg';
import { ReactComponent as GemIcon } from '../../assets/gem-icon.svg';
import { ReactComponent as ModernGem } from '../../assets/modern-gem.svg';

import { ReactComponent as InfoIcon } from '../../assets/info-icon.svg';
import { ReactComponent as HomeIcon } from '../../assets/home-icon.svg';

import { ReactComponent as Search } from '../../assets/search.svg';
import { ReactComponent as Back } from '../../assets/landing-page/back-button.svg';

import { getLifeStyleScore, getLimit, scoreCircleStyle, getOverallFeelScore } from '../../services.js';
import { walkIndexFeatures, lifeStyleFeatures } from '../../constants.js';
import Skeleton from '../Skeleton/index.jsx';
import { WalkingDistanceButtons } from '../WalkingDistanceButtons/WalkingDistanceButtons.jsx';
import { ToggleSwitch } from '../ToggleSwitch/ToggleSwitch.jsx';
import { EstablishmentSearch } from '../EstablishmentSearch/index.jsx';
import Show from '../../utility/Show.jsx';
import { Tooltip } from 'react-tooltip';

import MiniMap from '../Map/minimap.jsx';
import { NeighborhoodScore } from '../NeighborhoodScore/index.jsx';
import { WalkingLoader } from '../Loaders/index.jsx';
import Plugin from '../Plugin/index.jsx';

const WalkSummary = () => {
  const { state, dispatch, actions } = useContext(StoreContext);

  const {
    currentTab,
    lifeStyleScores,
    walkindexScores,
    currentDistance,
    isLoading,
    isMobile,
    mapTab,
    showCompareLifeStyle,
    showDashboard,
    isStandalone,
    currentAddress,
    showSearchAddress,
    isPlugin,
    isEstablishmentSearchOpen,
    currentView,
    enableChangeAddress,
    showTutorial,
    quickSearchMarkers,
    clientName,
    isSmallerScreen,
  } = state;
  
  const [featureList, setFeatureList] = useState([]);
  const [overallScore, setOverallScore] = useState(0);
  const [showFilterSearch, setShowFilterSearch] = useState(false);
  const [allMarkers, setAllMarkers] = useState([]);
  const [isAddress, setAddressValue] = useState(false);

  const isWalkIndex = currentTab === 'walkindex';
  const isModern = currentView === 'Modern';

  useEffect(() => {
    if (walkindexScores[0] && lifeStyleEssentialScores()) {
      setOverallScore(getOverallScore());
    }
  }, [walkindexScores, lifeStyleScores]);

  useEffect(() => {
    const filteredGemFeatures = lifeStyleFeatures.slice(0, -1);
    const getFeatures = isWalkIndex
      ? walkIndexFeatures
      : filteredGemFeatures;

    setFeatureList(getFeatures);
  }, [currentTab]);

  // useEffect(() => {
  //   if (!isWalkIndex) {
  //     fetchLifestyleEssentials();
  //   }
  // }, [mapTab]);

  useEffect(() => {
    dispatch({ type: 'SET_SHOW_FEEDBACK_FORM', payload: false });
    dispatch({ type: 'SET_COMPARE_LIFESTYLE', payload: false });
    isAddressExists();

    // actions.fetchQuickSearchMarkers(currentAddress);
  }, []);

  const openTutorial = () => {
    actions.sendDataToGoogleAnalytics('used_tutorial', {});
    dispatch({ type: 'SET_SHOW_TUTORIAL', payload: true });
  };

  const openEstablishmentSearch = () => {
    setShowFilterSearch(true);
    dispatch({ type: 'SET_IS_ESTABLISHMENT_SEARCH_OPEN', payload: true });
  };

  useEffect(() => {
    if (quickSearchMarkers?.markers?.length > 0) {
      setAllMarkers(quickSearchMarkers.markers);
    }
  }, [quickSearchMarkers]);

  const getOverallScore = () => {
    if (isWalkIndex) {
      return getOverallFeelScore(walkindexScores[0])
    } else {
      const scores = Object.values(lifeStyleEssentialScores());

      const counts = [0, 0, 0];
      scores.forEach((score) => {
        const roundedScore = Math.round(score);
        counts[roundedScore - 1]++;
      });

      if (counts[2] >= 1) return 3;
      if (counts[1] >= 1) return 2;
      return 1;
    }
  };

  const isAddressExists = () => {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.get('address')) {
      setAddressValue(true);
    }
  };

  const getWalkableDescription = (score) => {
    const limit = getLimit(currentDistance);

    if (score === 1) {
      return isWalkIndex
        ? 'Low presence of features <25%'
        : `< ${limit[0]} living essentials`;
    } else if (score === 2) {
      return isWalkIndex
        ? 'Moderate presence of features >25% <50%'
        : `${limit[0]} -  ${limit[1]} living essentials`;
    } else {
      return isWalkIndex
        ? 'High presence of features >50%'
        : `> ${limit[1]} living essentials`;
    }
  };

  const walkindxRadius = (score) => {
    if (score === 1) {
      return '<25%';
    } else if (score === 2) {
      return '>25% <50%';
    } else {
      return '>50%';
    }
  };

  const lifeStyleEssentialScores = () => {
    if (!lifeStyleScores[0]) return {};

    const categories = ['Food', 'Services', 'Shops', 'Transit', 'Leisure'];
    const essentialScores = {};

    categories.forEach((category) => {
      const type = category === 'Leisure' ? 'leisure' : category.toLowerCase();
      essentialScores[category] = getLifeStyleScore(
        lifeStyleScores[0][type],
        currentDistance,
        isStandalone
      );
    });

    return essentialScores;
  };

  const getButtonList = isWalkIndex
    ? walkIndexFeatures
    : lifeStyleFeatures.slice(0, -1);

  const getTotalGems = () => {
    return Object.values(lifeStyleScores[0]).reduce(
      (acc, count) => acc + count,
      0
    );
  };

  const renderCustomButtons = () => {
    const listBtns = featureList;

    const scores = isWalkIndex
      ? walkindexScores[0]
      : lifeStyleEssentialScores();

    if (!scores) return;

    if (listBtns.length) {
      return listBtns.map((item) => {
        const btnColor = {
          '--type-color': item.color,
        };

        const selectedScore = item.id === 'All'
          ? (getTotalGems() === 0 ? 1 : getTotalGems())
          : Math.round(scores[item.id]);

        return (
          <button
            data-tooltip-id={`custom-button-${item.name}`}
            key={`custom-button-${item.name}`}
            className={`dashboard-type-btn is-btn-active ${
                isMobile ? 'is-mobile-btn' : ''
              } ${
                isLoading ? 'btn-loading' : ''
              } ${
                showDashboard ? 'default-cursor' : ''
              } ${
                isModern ? 'modern-dashboard-type-btn' : ''
              } ${
                isSmallerScreen ? 'smaller-screen-dashboard-btn' : ''
              }
            `}
            style={btnColor}
          >
            {/* {currentView === 'Classic' ?
              item.svg :
              <div className='type-btn-dot'></div>
            } */}
            <div
              className={`custom-button-icon ${
                isModern && 'modern-custom-button-icon'
              } ${
                isModern && !isWalkIndex && 'modern-lifestyle-custom-button'
              }`}
            >
              {item.svg}
              <Show.When isTrue={isModern}>
                <Show>
                  <Show.When isTrue={isWalkIndex}>
                    <div
                      className='icon-score-cirlce'
                      data-score-value={selectedScore.toString()}
                      style={scoreCircleStyle(selectedScore)}
                    ></div>
                  </Show.When>
                  <Show.Else>
                    <div
                      className='score-diamond'
                      data-score-value={selectedScore.toString()}
                    >
                      <ModernGem />
                    </div>
                  </Show.Else>
                </Show>
              </Show.When>
            </div>
            <div className='btn-name'>
              {item.name}
              <Show.When isTrue={!isWalkIndex}>
                <span className='gem-count'>
                  <Show>
                    <Show.When isTrue={item.id === 'All'}>
                      {` ${getTotalGems() || '0'}`}
                    </Show.When>
                    <Show.Else>
                      {` ${
                        lifeStyleScores[0][item.name.toLowerCase()] || '0'
                      }`}
                    </Show.Else>
                  </Show>
                 </span>
              </Show.When>
            </div>

            <Show.When isTrue={!isModern}>
              <span> {selectedScore}</span>
            </Show.When>

            {/* <Tooltip
              className='custom-btn-popup'
              id={`custom-button-${item.name}`}
              place='bottom'
            >
              {getAttributeDescription(item.name)}
            </Tooltip> */}
          </button>
        );
      });
    }
  };

  const getDescription = getButtonList.find((item) => item.name === mapTab);

  const redirectToMap = () => {
    dispatch({ type: 'SET_PREVIOUS_PAGE', payload: 1 });
    if (isMobile) {
      actions.toggleFullScreenMap();
    }
    // dispatch({ type: 'SET_DESKTOP_SIDEBAR', payload: true });
    dispatch({ type: 'SET_SHOW_DASHBOARD', payload: false });
  };

  const toggleCompareVisibility = () => {
    dispatch({ type: 'SET_PREVIOUS_PAGE', payload: 1 });
    if (isMobile) {
      actions.toggleFullScreenMap();
    }
    if (currentTab === 'walkindex') {
      if (isModern) {
        dispatch({ type: 'SET_COMPARE_LIFESTYLE', payload: true });
      } else {
        dispatch({ type: 'SET_COMPARE_VISIBLE', payload: true });
      }
    } else {
      dispatch({
        type: 'SET_COMPARE_LIFESTYLE',
        payload: !showCompareLifeStyle,
      });
      dispatch({ type: 'SET_DESKTOP_SIDEBAR', payload: true });
    }
    dispatch({ type: 'SET_SHOW_DASHBOARD', payload: false });

    if (isModern) {
      dispatch({ type: 'SET_SHOW_COMBINE_SEARCH', payload: true });
    }
    const mapTabPayload = isWalkIndex ? 'Nature' : 'All';
    dispatch({ type: 'SET_MAP_TAB', payload: mapTabPayload });
  };

  const getFeaturesTitle = () => {
    return currentTab === 'walkindex' ? 'KEY FEATURES' : 'LIVING ESSENTIALS';
  };

  const legend = [
    { label: 'Meh', value: 1 },
    { label: 'Yeah', value: 2 },
    { label: 'Wow', value: 3 },
  ];

  const gotoSearchAddress = () => {
    dispatch({ type: 'SET_SHOW_SEARCH_ADDRESS', payload: true });
  };

  const getDashboardBtnStyle = () => {
    const style = {
      '--mt': actions.isDeviceiPhone() ? '2rem' : '1rem',
      '--pb': actions.isDeviceiPhone() ? '0.5rem' : '3.5rem',
    };
    return style;
  };

  const renderLegends = () => {
    return (
      <div
        className={`dashboard-score-legend-container ${
          isModern && 'modern-dashboard-score-legend-container'
        }`}
      >
        {legend.map((item, index) => {
          return (
            <div
              className='dashboard-legend-label-container'
              key={`dashboard-legend-${index}`}
            >
              <div
                data-tooltip-id={`${item.label}-label`}
                className={`small-score-icon ${
                  !isWalkIndex && !isModern && 'small-score-lifestyle'
                } ${isModern && 'modern-small-score-icon'} ${
                  isModern && !isWalkIndex && 'modern-small-score-lifestyle'
                }`}
              >
                {isModern &&
                  (isWalkIndex ? (
                    <div
                      className='icon-score-cirlce'
                      data-score-value={item.value.toString()}
                      style={scoreCircleStyle(item.value)}
                    ></div>
                  ) : (
                    <div
                      className='score-diamond'
                      data-score-value={item.value.toString()}
                    >
                      <ModernGem />
                    </div>
                  ))}
                {!isModern && (isWalkIndex ? <WalkIndexIcon /> : <GemIcon />)}
                <h1>{item.value}</h1>
              </div>
              <p>{item.label}</p>
              <Tooltip
                className='legend-popup'
                id={`${item.label}-label`}
                place='right'
              >
                {getWalkableDescription(item.value)}{' '}
                {walkindxRadius(getOverallScore())}
              </Tooltip>
            </div>
          );
        })}
      </div>
    );
  };

  const renderHeaderButtons = () => {
    return (
      <div
        className={`dasboard-action-buttons ${
          isModern && 'modern-dasboard-action-buttons'
        }`}
      >
        {enableChangeAddress && (
          <div
            className='change-address-container'
            onClick={() => gotoSearchAddress()}
          >
            <HomeIcon />
            <p>Change Address</p>
          </div>
        )}

        <Show>
          <Show.When isTrue={quickSearchMarkers.isLoading}>
            <Skeleton count={1} size={50} width={50} baseColor='#a9a9a9' />
          </Show.When>
          <Show.Else>
            <div
              className='filter-search-container'
              onClick={() => openEstablishmentSearch()}
            >
              <Search />
              <p>Quick Search</p>
            </div>
          </Show.Else>
        </Show>

        <div className='tutorial-container' onClick={() => openTutorial()}>
          <InfoIcon />
          <p>Tutorial</p>
        </div>
      </div>
    );
  };

  return (
    <div className='dashboard-wrapper' style={actions.getBrandingColor()}>
      <Show>
        <Show.When isTrue={showFilterSearch}>
          <EstablishmentSearch
            state={state}
            dispatch={dispatch}
            actions={actions}
            setShowFilterSearch={setShowFilterSearch}
            allMarkers={allMarkers}
            isMobile={isMobile}
            currentView={currentView}
            showDashboard={showDashboard}
          />
        </Show.When>
       
        <Show.When
          isTrue={
            isModern &&
            !showSearchAddress &&
            !isPlugin &&
            !clientName
          }
        >
          {/* <div className='modern-blurred-bg'></div> */}
        </Show.When>
      </Show>
      <div className='city-bg'></div>
      <div
        className={`dashboard-summary-container max-w-screen-lg  ${
            isMobile ? 'dashboard-summary-container-mobile' : ''
          } ${
            ((showTutorial && isMobile) || showSearchAddress)
            ? 'hide-dashboard' : ''
          } ${
            isPlugin ? 'dashboard-summary-container-plugin' : ''
          } ${
            isSmallerScreen ? 'dashboard-summary-smaller' : ''
          }`}
      >
        <div
          className={`dashboard-header ${
            isMobile ? 'radar-header-mobile' : ''
          } ${isModern && 'modern-dashboard-header'}
          `}
        >
          {/* <h2>
            {isStandalone ? 'Neighborhood Gems' : 'Neighborhood Intelligence'}
          </h2> */}
          <Show>
            <Show.When isTrue={isLoading && !isModern}>
              <div className='header-buttons-skeleton'>
                <Show.When isTrue={enableChangeAddress}>
                  <Skeleton
                    count={1}
                    size={40}
                    width={100}
                    baseColor='#a9a9a9'
                  />
                </Show.When>
                <Skeleton count={1} size={40} width={100} baseColor='#a9a9a9' />
                <Skeleton count={1} size={40} width={100} baseColor='#a9a9a9' />
              </div>
            </Show.When>
            <Show.Else>
              <Show.When isTrue={!isModern}>{renderHeaderButtons()}</Show.When>
            </Show.Else>
          </Show>
        </div>
        <Show.When isTrue={isMobile}>
          <ToggleSwitch fullWidth={false} className='toggle-switch-mobile' />
        </Show.When>

        <Plugin overallScore={overallScore} openEstablishments={openEstablishmentSearch}/>
        {/* <div
          className={`dashboard-container ${
            isMobile ? 'dashboard-container-mobile' : ''
          } ${
            isModern ? 'modern-dashboard-container' : ''
          } ${
            isPlugin ? 'dashboard-container-plugin' : ''
          } ${
            isSmallerScreen ? 'dashboard-container-plugin' : ''
          }`}
          style={getDashboardBtnStyle()}
        >
          <div
            className={`dashboard-score-container ${
              isModern && 'modern-dashboard-score-container'
            }`}
          >
            <Show.When
              isTrue={
                (!isStandalone ||
                  actions.isInLocalData(currentAddress?.address)) &&
                !isAddress &&
                !clientName
              }
            >
              <Back
                className='modern-back-btn'
                onClick={() => gotoSearchAddress()}
              />
            </Show.When>
            <NeighborhoodScore overallScore={overallScore} />
            <div
              className={`dashboard-walking-distance ${
                isModern && 'modern-dashboard-walking-distance'
              }`}
            >
              <WalkingDistanceButtons
                isTitle={false}
                key='dashboard-walking-distance-btn'
              />
            </div>
            {!isModern && renderLegends()}
          </div>
          
         
          <div className='dashboard-summary-details'>
            <Show.When isTrue={!isLoading}>
              <div
                className={`dashboard-summary-details-header ${
                  isModern && 'modern-dashboard-summary-details-header'
                }`}
              >
                <Show.When isTrue={!isMobile && !isStandalone}>
                  <ToggleSwitch />
                </Show.When>
                <Show.When isTrue={isModern}>
                  <div className='modern-summary-header-buttons'>
                    <Show>
                      <Show.When isTrue={isLoading}>
                        <Skeleton count={1} size={55} baseColor='#a9a9a9' />
                      </Show.When>
                      <Show.Else>
                        <div className='category-button-list'>
                          {renderCustomButtons()}
                        </div>
                        {renderHeaderButtons()}
                      </Show.Else>
                    </Show>
                  </div>
                </Show.When>
              </div>

              <Show>
                <Show.When isTrue={isLoading}>
                  <Skeleton
                    count={1}
                    size={isModern ? 255 : 220}
                    baseColor='#a9a9a9'
                  />
                </Show.When>
                <Show.Else>
                  <MiniMap
                    property={currentAddress}
                    state={state}
                    dispatch={dispatch}
                    actions={actions}
                  />
                </Show.Else>
              </Show>

              <Show>
                <Show.When isTrue={isModern}>
                  <Show>
                    <Show.When isTrue={isLoading}>
                      <Skeleton count={1} size={45} baseColor='#a9a9a9' />
                    </Show.When>
                    <Show.Else>
                      <div className='dashboard-address-name'>
                        {currentAddress?.property}
                      </div>
                    </Show.Else>
                  </Show>
                </Show.When>

                <Show.Else>
                  <h4 className={`${isModern && 'modern-address'}`}>
                    {currentAddress?.property}
                  </h4>
                </Show.Else>
              </Show>


              <Show>
                <Show.When isTrue={isLoading}>
                  <Skeleton count={1} size={45} baseColor='#a9a9a9' />
                </Show.When>
                <Show.Else>
                  <Show.When isTrue={!isMobile || !isEstablishmentSearchOpen}>
                    <div
                      className={`dashboard-button-container ${
                        isModern && 'modern-dashboard-button-container'
                      }`}
                    >
                      <button type='submit' onClick={redirectToMap}>
                        View Map
                      </button>

                      <button type='submit' onClick={toggleCompareVisibility}>
                        Compare Addresses
                      </button>
                    </div>
                  </Show.When>
                </Show.Else>
              </Show>
            </Show.When>
            <Show.When isTrue={isLoading}>
              <WalkingLoader />
            </Show.When>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default WalkSummary;
