import React from 'react';
import './index.css';

import NeighborhoodFeel from '../../assets/screens/neighborhood-feel.png';
import NeighborhoodGems from '../../assets/screens/neighborhood-gems.png';
import CompareNeighborhood from '../../assets/screens/compare-neighborhood.png';
import PlatformFeatures from '../../assets/landing-page/platform-feature.png';

import { ReactComponent as Rating } from '../../assets/other-features/rating.svg';
import { ReactComponent as WalkScore } from '../../assets/other-features/walkability-score.svg';
import { ReactComponent as ViewMaps } from '../../assets/other-features/view-maps.svg';
import { ReactComponent as WalkDistance } from '../../assets/other-features/walking-distance.svg';
import { ReactComponent as CarbonFootprint } from '../../assets/other-features/carbon-footprint.svg';
import { ReactComponent as Health } from '../../assets/other-features/health.svg';
import { ReactComponent as Vibe } from '../../assets/other-features/neighborhood-vibe.svg';
import { ReactComponent as Navigation } from '../../assets/other-features/navigation-black.svg';
import { ReactComponent as Filter } from '../../assets/other-features/filter-black.svg';
import { ReactComponent as WhiteLabel } from '../../assets/other-features/white-label.svg';
import { ReactComponent as Customization } from '../../assets/other-features/customize.svg';
import { ReactComponent as Analytics } from '../../assets/other-features/analytics-black.svg';

const dataSections = [
  {
    title: 'Neighborhood Feel',
    img: NeighborhoodFeel,
    points: [
      'Displays the feel score, a sensation while walking along a sidewalk walk with the presence of nature, architecture, Vibrance, comfort and quiet.',
      'Maps and scores presence of trees, architectural values, comfort, Vibrance, and quiet.',
      'Up to four neighborhoods can be compared for walkability',
      'Includes based on extensive research on what people seek in walkable neighborhoods',
      'Highlights the sense of walk along pedestrian corridors',
    ],
  },
  {
    title: 'Neighborhood Gems',
    img: NeighborhoodGems,
    points: [
      'Displays the gems score, availability of living essentials like food, shops, services, transit and leisure',
      'Data scraped, labeled, and scored on a global scale',
      'Displays lifestyle establishments within 5-10-20 minute walk from property',
      'Integrates other APIs on rating Gem Index/Priority search, detailed breakdown of living essentials',
      'Maps favorites for efficient decision-making',
      'Up to four neighborhoods can be compared for lifestyle essentials availability and quality',
    ],
  },
  {
    title: 'Compare Neighborhoods',
    img: CompareNeighborhood,
    points: [
      'Up to four addresses can be compared for Neighborhood Feel or Neighborhood Gems. It takes the capacity to analyze alternatives or location scenarios formerly exclusive to urban planners and into the hands of buyers, renters and travelers.',
      'The ability to compare neighborhoods is a powerful decision-making tool to help clients find their happy place.',
    ],
  },
];

const features = [
  {
    name: 'Rating',
    img: <Rating className='rating-icon' />,
    description: '1 to 5 stars',
  },
  {
    name: 'Walkability Score',
    img: <WalkScore className='walk-score-icon' />,
    description: 'meh, yeah and wow walkable',
  },
  {
    name: 'View Maps',
    img: <ViewMaps />,
    description: 'lines and satellite view',
  },
  {
    name: 'Walking Distance',
    img: <WalkDistance />,
    description: '.25, .5 and 1 mile from listing',
  },
  {
    name: 'Carbon Footprint',
    img: <CarbonFootprint className='carbon-footprint-icon' />,
    description: 'CO2 saved',
  },
  {
    name: 'Health',
    img: <Health className='health-icon' />,
    description: 'calories, steps, distance',
  },
  {
    name: 'Neighborhood Vibe',
    img: <Vibe />,
    description: 'dominant community and Vibrance',
  },
  {
    name: 'Navigation',
    img: <Navigation className='navigation-icon' />,
    description: 'short distance',
  },
  {
    name: 'Filters',
    img: <Filter />,
    description: 'lifestyle essentials',
  },
  {
    name: 'White Label',
    img: <WhiteLabel className='white-label-icon' />,
    description: 'white label format',
  },
  {
    name: 'Customization',
    img: <Customization className='customization-icon' />,
    description: 'bespoke custom design',
  },
  {
    name: 'Analytics',
    img: <Analytics className='analytics-icon' />,
    description: 'data collection and service providing',
  },
];

const Platform = () => {
  return (
    <div className='Platform'>
      <section className='platform-section'>
        <h1 className='landing-title'>A Neighborhood Intelligence Platform</h1>
        <img
          src={PlatformFeatures}
          alt='Laptop showing platform features'
          className='laptop-image'
        />
      </section>
      <section className='what-americans-want'>
        <h1 className='landing-title'>What Americans Want</h1>
        <p>
          <span className='quote'>“</span>
          78% are willing to pay more to move to walkable neighborhoods (NAR,
          2023)
          <br />
          90% of Gen Z's and millennials prioritize walkable communities as most
          important
          <span className='quote'>”</span>
        </p>
      </section>
      <section className='data-collection'>
        <h1 className='landing-title'>Data Collection</h1>
        <div className='data-metrics'>
          <div className='metric'>
            <h2>200</h2>
            <p>Micro-scale Metrics</p>
          </div>
          <div className='metric'>
            <h2>80</h2>
            <p>Macro-scale Metrics</p>
          </div>
          <div className='metric'>
            <h2>450,000 +</h2>
            <p>Number of sidewalks</p>
          </div>
          <div className='metric'>
            <h2>45 +</h2>
            <p>US Cities and Growing</p>
          </div>
        </div>
      </section>
      <section className='neighborhood-wrapper'>
        {dataSections.map((section, index) => (
          <section key={index} className='data-section'>
            <h1 className='landing-title'>{section.title}</h1>
            <div className='data-section-content'>
              <img src={section.img} alt={section.title} />
              <ul>
                {section.points.map((point, idx) => (
                  <li key={idx}>{point}</li>
                ))}
              </ul>
            </div>
          </section>
        ))}
      </section>
      <section className='more-features max-w-screen-lg'>
        <h1 className='landing-title'>More Features</h1>
        <div className='features-grid'>
          {features.map((feature, index) => (
            <div key={index} className='feature-item'>
              {feature.img}
              <h3>{feature.name}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Platform;
