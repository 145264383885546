import React, { useState, useContext, useEffect } from 'react';
import { StoreContext } from '../../store';

import StreetViewMap from '../Map/streetmap';
import AddressInput from '../Base/input';
import LoadingCircle from '../Loading';
import Skeleton from '../Skeleton';

import MapIcon from '../../assets/map-icon.svg';

import { ReactComponent as Quiet } from '../../assets/quiet.svg';
import { ReactComponent as Access } from '../../assets/access.svg';
import { ReactComponent as Architecture } from '../../assets/architecture.svg';
import { ReactComponent as Comfort } from '../../assets/comfort.svg';
import { ReactComponent as Activities } from '../../assets/activities.svg';
import { ReactComponent as Nature } from '../../assets/nature.svg';

import { Line } from 'rc-progress';

import { WalkingDistanceButtons } from '../WalkingDistanceButtons/WalkingDistanceButtons';

const AddressItem = ({ item, index, properties, getTypeColor }) => {
  const { dispatch, actions, state } = useContext(StoreContext);

  const {
    currentDistance,
    compareAddress,
    isMobile,
    currentAddress,
    currentView,
  } = state;
  const [walkIndex, setWalkIndex] = useState({});
  const [isRendered, setIsRendered] = useState(true);

  const isModern = currentView === 'Modern';

  useEffect(() => {
    if (item.property) {
      setIsRendered(false);
      fetchWalkIndex();
    }
  }, [currentDistance, item]);

  const fetchWalkIndex = async () => {
    const result = await actions.fetchSingleWalkIndex(
      item.latitude,
      item.longitude
    );

    setIsRendered(true);
    setWalkIndex(result);
  };

  const handleAddressClick = (index, object) => {
    actions.sendDataToGoogleAnalytics(
      'walkindx_compare_address',
      { walkindx_address_name: object.property }
    );
    dispatch({ type: 'UPDATE_COMPARE_ADDRESS', payload: { index, object } });
    if (isModern) {
      dispatch({
        type: 'UPDATE_LIFESTYLE_COMPARE_ADDRESS',
        payload: { item: index, address: object },
      });
    }
  };

  const getAttributeScore = (number, type) => {
    const score = Math.round(number);
    if (!isRendered) {
      return (
        <Skeleton count={1} size={18} />
      );
    } else {
      if (score >= 3) {
        return (
          <>
          <Line
            percent={100}
            style={{ width: '100%' }}
            strokeWidth={4}
            trailWidth={4}
            strokeColor={getTypeColor(type)}
            strokeLinecap={isModern ? 'square' : 'round' }
          />
          <div className='score-type-value'>
            {score}
          </div>
          </>
        );
      } else if (score < 3 && score >= 2) {
        return (
          <>
            <Line
              percent={50}
              style={{ width: '100%' }}
              strokeWidth={4}
              trailWidth={4}
              strokeColor={getTypeColor(type)}
              strokeLinecap={isModern ? 'square' : 'round' }
            />
            <div className='score-type-value'>
              {score}
            </div>
          </>
        );
      } else if (score < 2 && score >= 1) {
        return (
          <>
            <Line
              percent={10}
              style={{ width: '100%' }}
              strokeWidth={4}
              trailWidth={4}
              strokeColor={getTypeColor(type)}
              strokeLinecap={isModern ? 'square' : 'round' }
            />
            <div className='score-type-value'>
              {score}
            </div>
          </>
        );
      } else {
        const branding = actions.getBrandingColor();
        return (
          <Line
            percent={0}
            style={{ width: '100%' }}
            strokeWidth={4}
            trailWidth={4}
            strokeColor={branding['--color']}
            strokeLinecap={isModern ? 'square' : 'round' }
          />
        );
      }
    }
  };

  const viewProperty = async (item) => {
    await actions.fetchAddressData(item.property);
    dispatch({ type: 'SET_DESKTOP_SIDEBAR', payload: true });
    if (!isModern) {
      dispatch({ type: 'RESET_COMPARE_ADDRESS', payload: item });
    }
    dispatch({ type: 'SET_COMPARE_VISIBLE', payload: false });
  };

  const filteredProperties = () => {
    return properties.filter(unselected => {
      return !compareAddress.some(selected => selected?.property?.toLowerCase().includes(unselected.label?.toLowerCase()))
    })
  }

  const currentProperty = item.property
    ? {
        label: item.property,
        value: item,
      }
    : null;

  const getTypeKey = (value) => {
    let targetKey = null
    for (const key in walkIndex) {
      if (walkIndex[key] === value) {
        targetKey = key;
        break;
      }
    }
    return targetKey;
  };

  return (
    <div className='location-item'>
      <div className='walking-distance-location'>
        <StreetViewMap
          coordinates={[parseFloat(item.latitude), parseFloat(item.longitude)]}
        />

        <div className='location-input'>
          <AddressInput
            value={item.property}
            currentAddress={currentAddress}
            properties={filteredProperties()}
            index={index}
            handleClick={handleAddressClick}
            isDisabled={!isRendered}
          />
        </div>
      </div>

      <h5 style={{ opacity: 0 }}> Ignore this element</h5>
      <div className={isMobile && 'score-type-container'}>
        <div className='score-type'>
          <div className='score-placeholder'>
            <Nature />
          </div>
          {getAttributeScore(walkIndex?.BeautyN, 'BeautyN')}
        </div>
        <div className='score-type'>
          <div className='score-placeholder'>
            <Architecture />
          </div>
          {getAttributeScore(walkIndex?.BeautyM, 'BeautyM')}
        </div>
        <div className='score-type'>
          <div className='score-placeholder'>
            <Activities />
          </div>
          {getAttributeScore(walkIndex?.Activities, 'Activities')}
        </div>
        <div className='score-type'>
          <div className='score-placeholder'>
            <Comfort />
          </div>
          {getAttributeScore(walkIndex?.Comfort, 'Comfort')}
        </div>

        <div className='score-type'>
          <div className='score-placeholder'>
            <Quiet />
          </div>
          {getAttributeScore(walkIndex?.Noise, 'Noise')}
        </div>
      </div>
      <button
        className={`btn-view ${
          isModern && 'modern-btn-view'
        }`}
        style={actions.getBrandingColor()}
        onClick={() => viewProperty(item)}
        disabled={!item.property}
      >
        {/* <img src={MapIcon} alt='Low' />  */}
        VIEW MAP
      </button>
    </div>
  );
};

export default AddressItem;
