import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import Close from '../../assets/cancel-white.svg';
import './index.css'

export const FeedbackForm = ({
  state,
  dispatch,
  actions,
  showFBForm,
  setShowFBForm,
  currentView,
}) => {
  const { showFeedbackForm, showCompareLifeStyle, isMobile } = state;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formValues, setFormValues] = useState({
    from_name: '',
    message: '',
  });
  const form = useRef();

  const isModern = currentView === 'Modern';

  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  }

  const closeForm = () => {
    if(showCompareLifeStyle){
      setShowFBForm(false);
    } else {
      dispatch({ type: 'SET_SHOW_FEEDBACK_FORM', payload: false });
    }
    setIsSubmitted(false);
  }

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitted(true);

    const fromName = form?.current[0]?.value;
    const message = form?.current[1]?.value;

    setFormValues({
      from_name: fromName,
      message: message,
    });

    if (fromName === '' || message === '') {
      return;
    }
    emailjs.sendForm('service_tdyc8vd', 'template_u5kehov', form.current, 'NqktzMSE3ylSjOrfR')
      .then((result) => {
        actions.sendDataToGoogleAnalytics(
          'sent_feedback',
          {}
        );
      }, (error) => {
        setIsSubmitted(false);
      });
  };

  const renderSubmissionReceived = () => {
    return (
     <div className='submission-container'>
      <h3>Thank You!</h3>
      <div>
        <p>Your submission has been received.</p>
        <p>We will be in touch and contact you soon.</p>
      </div>
      <button className='feedback-button' onClick={() => closeForm()}>Close</button>
     </div>
    )
  }

  const renderForm = () => {
    return (
      <form ref={form}>
        <div className='feedback-header'>
          <span className='feedback-header-title'>Feedback Form</span>
          <img src={Close} className='feedback-form-close' onClick={closeForm} />
        </div>
        <div className='feedback-body'>
          <div className='form-field'>
            <span className='feedback-header-title'>
              Enter Your Name <span className='required-field'>*</span>
            </span>
            {formValues.from_name === '' && isSubmitted && (
              <p className='field-error'>This field is required</p>
            )}
            <input
              type='text'
              name="from_name"
              defaultValue={formValues.from_name}
            />
          </div>
          <div className='form-field'>
            <span className='feedback-header-title'>
              Comments <span className='required-field'>*</span>
            </span>
            {formValues.message === '' && isSubmitted && (
              <p className='field-error'>This field is required</p>
            )}
            <textarea
              style={{ resize: 'none' }}
              rows='4'
              name="message"
              defaultValue={formValues.message}
            >
            </textarea>
          </div>
        </div>
        <div className='feedback-bottom'>
          <input type='file' id='fileInput' style={{ display: 'none' }} />
          <div
            className={`feedback-button ${isModern && 'sharp-border'}`}
            onClick={sendEmail}
          >
            Submit
          </div>
        </div>
      </form>
    )
  }

  return (
    <div className='feedback-form-container'>
      <div className={`feedback-form
          ${(showCompareLifeStyle && !isMobile)  && 'small-form'}
          ${(showCompareLifeStyle && isSubmitted) && 'submit-wrapper'}
          ${isModern && 'sharp-border'}
        `}
      >
        {isSubmitted && (formValues.from_name !== '' && formValues.message !== '') ?
          renderSubmissionReceived() :
          renderForm()
        }
      </div>
    </div>
  );
}