import React, { useState, useContext, useRef, useEffect } from 'react';
import { StoreContext } from '../../store';
import { ReactComponent as Property } from '../../assets/architecture.svg';
import Clear from '../../assets/cancel-icon.svg';
import ClickOutside from 'react-click-outside';

const SearchInput = ({
  currentProperty,
  currentView,
  properties,
  handleClick,
  index,
  showListProperties,
  isOpen = false,
  isFocus = false,
}) => {
  const [inputValue, setInputValue] = useState(currentProperty.property);
  const [isFocused, setFocus] = useState(false);
  const inputRef = useRef(null);
  const autoCompleteRef = useRef();
  const options = {
    fields: ['ALL'],
  };
  const { actions } = useContext(StoreContext);

  const isModern = currentView === 'Modern';

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
  };

  const handlePropertyClick = (property) => {
    if (index !== undefined) {
      handleClick(index, property);
      closeList();
    } else {
      handleClick(property);
    }
    actions.sendDataToGoogleAnalytics(
      'changed_address',
      { selected_address: property.property }
    );

    setFocus(false);
  };

  const closeList = () => {
    showListProperties(false);
  };

  useEffect(() => {
    // Focus the input when the component mounts
    if (isFocus) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
     inputRef.current,
     options
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      const payload = {
        property: place?.formatted_address,
        latitude: place?.geometry?.location?.lat(),
        longitude: place?.geometry?.location?.lng(),
      };
      handleClick(index, payload);
      closeList();
    });
   }, [index]);

  // useEffect(() => {
  //   // Focus the input when the component mounts
  //   setInputValue(currentProperty.property);
  // }, [currentProperty]);

  const filteredItems =
    inputValue && inputValue.length
      ? properties.filter((item) => {
          return item.label.toLowerCase().includes(inputValue.toLowerCase());
        })
      : properties;

  return (
    <div className='search-property-container'>
      <div className={`search-input-container ${
          isModern && 'sharp-border'
        }`}
      >
        <Property />
        <input
          ref={inputRef}
          type='text'
          value={inputValue}
          onChange={handleInputChange}
          placeholder='Search for Property or Address...'
          // onClick={() => setFocus(true)}
          autoComplete='false'
          // onBlur={() => setFocus(false)}
        />

        {inputValue && isFocused && (
          <img
            className='search-clear'
            src={Clear}
            alt='Clear Text'
            onClick={() => setInputValue('')}
          />
        )}
      </div>

      {isFocused && (
        <div className='properties-container'>
          {filteredItems.length ? (
            filteredItems.map((property, index) => {
              return (
                <div
                  className='property-item'
                  key={`${property.label}-${index}`}
                  onClick={() => handlePropertyClick(property.value)}
                >
                  <span>{property.label}</span>
                  {/* <span className='badge'> {property.value.type} </span> */}
                </div>
              );
            })
          ) : (
            <div className='empty-property'> Sorry, No Properties Found!</div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchInput;
