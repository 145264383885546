import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { renderToString } from 'react-dom/server';
import { Rating } from 'react-simple-star-rating';
import AddressInput from '../Base/input';
import PropertyList from '../List';
import RatingsBar from '../RatingsBar';
import { WalkindxSpeedDial } from '../WalkindxSpeedDial';
import { MarkerDetails } from './marker-details.jsx';
import { WalkingLoader } from '../Loaders/index.jsx';
import Show from '../../utility/Show.jsx';
import './index.css';

import Food from '../../assets/food.svg';
import Services from '../../assets/services.svg';
import Shop from '../../assets/shop.svg';
import Entertainment from '../../assets/entertainment.svg';
import Access from '../../assets/access.svg';
import Star from '../../assets/favorites.svg';
import Favorite from '../../assets/favorite.png';
import Unfavorite from '../../assets/unfavorite.png';
import Walk from '../../assets/walking-distance.svg';
import { ReactComponent as WalkIndxIcon } from '../../assets/walkindx-icon.svg';

import Location from '../../assets/location.png';
import Cancel from '../../assets/cancel-white.svg';
import Expand from '../../assets/expand.png';
import { ReactComponent as Fullscreen } from '../../assets/maximize.svg';
import { ReactComponent as Minimize } from '../../assets/minimize.svg';
import { ReactComponent as AddressMarker } from '../../assets/active-marker.svg';
import { ReactComponent as ComparePlaceholder } from '../../assets/compare-placeholder.svg';

// CLUSTERS
import Cluster from '../../assets/cluster/cluster1.svg';
import FoodMarker from '../../assets/cluster/food.svg';
import ShopMarker from '../../assets/cluster/shops.svg';
import ServiceMarker from '../../assets/cluster/services.svg';
import TransitMarker from '../../assets/cluster/transit.svg';
import EntertainmentMarker from '../../assets/cluster/entertainment.svg';

import FoodCluster from '../../assets/cluster/food-cluster.svg';
import ShopCluster from '../../assets/cluster/shops-cluster.svg';
import ServicesCluster from '../../assets/cluster/services-cluster.svg';
import TransitCluster from '../../assets/cluster/transit-cluster.svg';
import EntertainmentCluster from '../../assets/cluster/entertainment-cluster.svg';

import FoodThumbnail from '../../assets/cluster/food-thumbnail.png';
import TransitThumbnail from '../../assets/cluster/transit-thumbnail.png';
import ShopThumbnail from '../../assets/cluster/shops-thumbnail.png';
import EntertainmentThumbnail from '../../assets/cluster/entertainment-thumbnail.png';
import ServicesThumbnail from '../../assets/cluster/services-thumbnail.png';

import { ReactComponent as FootstepsSVG } from '../../assets/footsteps.svg';
import { ReactComponent as QuickSearch } from '../../assets/quick-search.svg';

import { fetchPlaceDetails } from '../../api.js';
import { walkIndexFeatures, lifeStyleFeatures, walkindxID } from '../../constants.js';
import {
  getLifeStyleScore,
  getWalkIndexWording,
  getLifeStyleWording,
  getTabColor,
  getLimit,
  scoreCircleStyle,
  getFeatureIcon,
  getTypeDescription,
  areObjectPropertiesNull,
  findColor,
  getZoom,
  getZoomMobile,
  getZoomLifeStyle,
  lightMapTheme,
  setLSData,
  getArea,
  convertMarkers,
} from '../../services';

import { Tooltip } from 'react-tooltip';
import { useSnackbar } from 'notistack';
import { center, distance } from '@turf/turf';

import { ReactComponent as PlusIcon } from '../../assets/zoom-in.svg';
import { ReactComponent as MinusIcon } from '../../assets/zoom-out.svg';
import { ReactComponent as Target } from '../../assets/target.svg';
import { ReactComponent as Filter } from '../../assets/filter-2.svg';
import { ReactComponent as Satellite } from '../../assets/satellite.svg';
import { ReactComponent as Map } from '../../assets/map.svg';
import { ReactComponent as Home } from '../../assets/home-icon-2.svg';
import { ReactComponent as Location2 } from '../../assets/location-2.svg';
import { ReactComponent as ModernGem } from '../../assets/modern-gem.svg';
import Pin from '../../assets/pin.svg';
import Feedback from '../../assets/feedback.png';

import {
  GoogleMap,
  OverlayView,
  Circle,
  Marker,
  StreetViewPanorama,
  DirectionsRenderer,
} from '@react-google-maps/api';

import { FeedbackForm } from '../FeedbackForm';

import useGemMarkers from '../../hooks/useGemMarkers.js';
import OpenStreetMapAttribution from './openstreetmap-attribution.jsx';

const MapContainer = ({
  property,
  index = 0,
  isSearchable = true,
  latitude = 0,
  longitude = 0,
  neighborhood = '',
  properties,
  state,
  dispatch,
  setProperties,
  actions,
  navigate,
  mapHeight='100%',
}) => {
  const {
    mapTab,
    token,
    currentRates,
    currentDistance,
    showEstablishments,
    showFilter,
    currentTab,
    showCompareLifeStyle,
    favorites,
    currentPresence,
    categoryFilters,
    isMobile,
    isMobileSidebarOpen,
    map,
    currentMarker,
    isLoading,
    showFeedbackForm,
    compareLifeStyleAddress,
    keyPropertyName,
    currentWalkindxType,
    currentLifestyleType,
    isIphone,
    isStandalone,
    currentAddress,
    isFullScreenMap,
    isDesktopSidebarOpen,
    isEstablishmentSearchOpen,
    listOfMarkers,
    currentView,
    showProperties,
    walkindexScores,
    lifeStyleScores,
    scoreProperties,
    showCombineSearch,
    selectedMap,
    isSmallerScreen,
    isMapLoading,
    isGoogleMapsLoaded,
    apiKey,
    showSearchAddress,
    isGoogleMode,
    showMapQS,
    mapType,
    zoom: zoom1,
    userDetails,
    lifeStyleCompareMaps,
    showMarkerList,
    setCenter,
  } = state;

  const { enqueueSnackbar } = useSnackbar();
  const [filteredMarkers, setFilteredMarkers] = useState([]);
  const [originalMarkers, setOriginalMarkers] = useState([]);
  const [currentMapInstance, setCurrentMap] = useState(null);
  const [clusterImage, setClusterImage] = useState(Cluster);
  const [showPin, togglePin] = useState(false);
  const [mapCenter, setMapCenter] = useState({
    lat: parseFloat(latitude),
    lng: parseFloat(longitude),
  });
  const [showFBForm, setShowFBForm] = useState(false);
  const [showSpeedDial, setShowSpeedDial] = useState(false);
  const [showStreetView, setShowStreetView] = useState(false);
  const [markerDetails, setMarkerDetails] = useState(null);
  const [direction, setDirection] = useState(null);
  const [directionDetails, setDirectionDetails] = useState(null);
  const [popupPosition, setPopupPosition] = useState(-100);
  const [isMounted, setIsMounted] = useState(false);
  const [footstepIcon, setFootstepIcon] = useState(null);
  const [forceRender, setForceRender] = useState(false);
  const [zoom, setZoom] = useState(zoom1);
  // const [trafficLayer, setTrafficLayer] = useState(null);

  const isWalkIndex = currentTab === 'walkindex';
  const isModern = currentView === 'Modern';

  const toggleShowEstablishments = () => {
    dispatch({
      type: 'SET_SHOW_ESTABLISHMENTS',
      payload: !showEstablishments,
    });
    dispatch({ type: 'SET_SELECTED_MAP', payload: index });
  };

  const toggleShowFilters = () => {
    if (showFilter) {
      dispatch({ type: 'RESET_CATEGORY', payload: [''] });
    }
    dispatch({
      type: 'SET_SHOW_FILTERS',
      payload: !showFilter,
    });

    if (isMobile) {
      dispatch({ type: 'SET_MOBILE_SIDEBAR', payload: !isMobileSidebarOpen });
    }

    if (!isDesktopSidebarOpen) {
      dispatch({ type: 'SET_DESKTOP_SIDEBAR', payload: true });
    }
  };

  const handleAddressClick = async (index, object) => {
    if (showCompareLifeStyle) {
      actions.sendDataToGoogleAnalytics('lifestyle_compare_address', {
        lifestyle_address_name: object.property,
      });
      dispatch({
        type: 'UPDATE_LIFESTYLE_COMPARE_ADDRESS',
        payload: { item: index, address: object },
      });
      if (isModern) {
        dispatch({
          type: 'UPDATE_COMPARE_ADDRESS',
          payload: { index, object },
        });
      }
    } else {
      await actions.fetchAddressData(object.property);
      dispatch({
        type: 'GET_PROPERTY_BY_NAME',
        payload: object.property,
      });

      dispatch({
        type: 'UPDATE_LIFESTYLE_COMPARE_ADDRESS',
        payload: { item: 0, address: object },
      });
    }
  };

  const setMarkers = (markers) => {
    const getMarkers = typeof markers !== 'string' ? markers : [];
    setOriginalMarkers(getMarkers);
    let result = [];
    if (getMarkers && showFilter && categoryFilters[0] !== '') {
      result = actions.getQuickSearchResult(markers, categoryFilters[0]);
      setFilteredMarkers(result);
      setFilterMarkers(result);
    } else {
      setFilterMarkers(getMarkers);
      if (!isFilterActive) {
        setFilteredMarkers(getMarkers);
      }
    }
    if (!isEstablishmentSearchOpen) {
      if (!showFilter) {
        dispatch({
          type: 'SET_LIST_OF_MARKERS',
          payload: { markersIndex: index, markersList: getMarkers },
        });
      }
    } else {
      setFilteredMarkers(listOfMarkers[index]);
    }

    if (currentLifestyleType.length > 0) {
      setCombineSearchMarkers(getMarkers);
    } else {
      if (isFilterActive) {
        setFilterMarkers(filterMarkersByTab(getMarkers, true));
      } else {
        filterMarkersByTab(getMarkers);
      }
    }
  };

  const controller = new AbortController();
  const signal = controller.signal;
  const serviceRef = useRef();

  const {
    data: gemMarkers,
    error: gemMarkersError,
    isLoading: gemMarkersLoading,
  } = useGemMarkers(
    latitude,
    longitude,
    'all',
    token,
    apiKey,
    getArea(currentDistance),
    signal,
    mapTab,
    serviceRef, 
    mapCenter,
  );

  useEffect(() => {
    if (!gemMarkers) return;

    if (!isEstablishmentSearchOpen) {
      handleGemMarkers();
    }
    dispatch({
      type: 'SET_LIST_OF_ORIGINAL_MARKERS',
      payload: gemMarkers,
    });
  }, [gemMarkers]);
  
  useEffect(() => {
    if (currentMapInstance) {
      goToCenter();
    }
  }, [setCenter]);

  const handleGemMarkers = () => {
    dispatch({
      type: 'SET_IS_MAP_LOADING',
      payload: { loadingIndex: index, loadingValue: gemMarkersLoading },
    });

    if (gemMarkersError) {
      console.log(gemMarkersError);
      return;
    }

    if (gemMarkers) {
      if (gemMarkers?.error === "50x") {
        dispatch({ type: 'SET_IS_SERVER_ERROR', payload: true });
        return;
      }

      if (!gemMarkers.Success && !Array.isArray(gemMarkers) && typeof gemMarkers === 'object') {
        if (gemMarkers.Error === 'Exceeded access limit') {
          dispatch({ type: 'SET_REACHED_MAX_LIMIT', payload: true });
        } else if (gemMarkers.Error === 'Unauthorized') {
          dispatch({ type: 'SET_TOKEN', payload: '' });
          dispatch({ type: 'SET_USER_DETAILS', payload: {} });
          if (isGoogleMode) {
            navigate('/login');
          }
        }
      } else {
        const markers = convertMarkers(gemMarkers);
        const getLength = markers ? markers.length : 0;
        if (currentAddress !== null) {
          dispatch({
            type: 'UPDATE_COMPARE',
            payload: { number: index, length: getLength },
          });
        }
        setMarkers(markers);
        setClusterImage(getClusterImage());
      }
    }

    dispatch({
      type: 'SET_IS_MAP_LOADING',
      payload: { loadingIndex: index, loadingValue: false },
    });
  };

  const clearMapTypes = () => {
    if (map) map.overlayMapTypes.clear();
  };

  const clearDirections = () => {
    setDirection(null);
    setDirectionDetails(null);
  };

  useEffect(() => {
    setIsMounted(true);

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (isGoogleMapsLoaded && !isWalkIndex) {
      const icon = {
        url:
          'data:image/svg+xml;base64,' +
          modifyFootstepsIcon(
            { color: mapType === 'satellite' ? 'white' : findColor(currentTab, mapTab) }
          ),
        scaledSize: new window.google.maps.Size(24, 24),
        anchor: { x: 12, y: 8 },
      };
      setFootstepIcon(icon);
    }
  }, [isGoogleMapsLoaded, mapTab, mapType]);

  useEffect(() => {
    if (isEstablishmentSearchOpen && isFilterActive) {
      dispatch({ type: 'SET_CURRENT_ZOOM', payload: 14 });
      setFilteredMarkers(listOfMarkers[0]);
    }
  }, [isEstablishmentSearchOpen, currentDistance]);

  useEffect(() => {
    if (property && isGoogleMapsLoaded) {
      clearDirections();
      clearMapTypes();
      if (isGoogleMapsLoaded) {
        if (!isEstablishmentSearchOpen) {
          handleGemMarkers();
        }
      }

      if (isModern && currentLifestyleType?.length > 0) {
        // fetchData(latitude, longitude, currentLifestyleType.toLowerCase());
        setCombineSearchMarkers();
      }

      generateMapWalkIndex();
      setMapCenter({
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      });

      return () => {
        controller.abort();
      };
    }

    if (index) {
      dispatch({ type: 'SET_CURRENT_ZOOM', payload: zoomValue });
    }
  }, [longitude, latitude, isGoogleMapsLoaded]);

  useEffect(() => {
    if (currentMapInstance) {
      if (currentWalkindxType) {
        changeWalkindexType();
      } else {
        generateMapWalkIndex();
      }
    }
  }, [currentMapInstance]);

  const getRatingMarkers = (markers) => {
    const getMarkers = markers || originalMarkers;
    if (!currentRates || !currentRates?.length) return getMarkers;
    return getMarkers.filter((place) => {
      const number = place.rating ? Math.floor(place.rating) : 0;
      return currentRates.includes(number);
    });
  };

  useEffect(() => {
    if (property && originalMarkers?.length) {
      const hasFilterCategory =
        categoryFilters.length > 1 || categoryFilters[0] !== '';

      const hasRating = currentRates.length > 0 && currentRates[0] !== '';

      let selectedMarkers = [];
      if (hasFilterCategory) {
        selectedMarkers = listOfMarkers[index];
      } else {
        selectedMarkers =  currentLifestyleType.length > 0
          ? originalMarkers
          : filterMarkersByTab(originalMarkers, true);
      }

      let newMarkers = [];
      if (hasRating && hasFilterCategory) {
        newMarkers = getRatingMarkers(selectedMarkers);
        if (currentLifestyleType?.length > 0) {
          setCombineSearchMarkers(newMarkers);
        } else {
          setFilteredMarkers(newMarkers);
        }
      } else if (!hasRating && hasFilterCategory) {
        if (currentLifestyleType?.length > 0) {
          setCombineSearchMarkers();
        } else {
          setFilterMarkers();
        }
      } else {
        if (hasRating) {
          newMarkers = getRatingMarkers(selectedMarkers);
          if (currentLifestyleType?.length > 0) {
            setCombineSearchMarkers(newMarkers, false);
          } else {
            setFilteredMarkers(newMarkers);
          }
        } else {
          if (currentLifestyleType?.length > 0) {
            setCombineSearchMarkers(selectedMarkers);
          } else {
            setFilteredMarkers(selectedMarkers);
          }
        }
      }
      dispatch({
        type: 'SET_LIST_OF_MARKERS',
        payload: { markersIndex: index, markersList: newMarkers },
      });
    }
    clearDirections();
    setMarkerDetails(null);
    // dispatch({ type: 'SET_LIST_OF_MARKERS', payload: filteredMarkers });
  }, [currentRates]);

  const getZoomType = isMobile
    ? getZoomMobile(currentDistance)
    : getZoom(currentDistance);

  const zoomValue = showCompareLifeStyle
    ? getZoomLifeStyle(currentDistance)
    : getZoomType;

  useEffect(() => {
    dispatch({ type: 'SET_CURRENT_ZOOM', payload: zoomValue });

    if (!isMounted || !property) return;

    clearDirections();
    if (property) {
      setForceRender(!forceRender);
      const payload = { latitude: latitude, longitude: longitude };
      actions.handleFetchScores(payload, index);
      if (!isWalkIndex) {
        handleGemMarkers();
        if (currentWalkindxType) {
          changeWalkindexType();
        }
      } else {
        if (showCompareLifeStyle) {
          handleGemMarkers();
        }
        generateMapWalkIndex();
      }

      return () => {
        controller.abort();
      };
    }
    setShowStreetView(false);
    setMarkerDetails(null);    
  }, [currentDistance]);

  useEffect(() => {
    if (!isMounted) return;

    clearDirections();
    setCurrentMarker(null);
    if (property) {
      if (!isWalkIndex) {
        // if (mapTab === 'Favorites') {
        //   setMarkers(favorites[getKeyName()]);
        // } else {
        //   fetchData(latitude, longitude, mapTab.toLowerCase());
        // }
        if (isFilterActive) {
          setFilterMarkers(filterMarkersByTab(null, true));
        } else {
          if (currentLifestyleType.length > 0) {
            setCombineSearchMarkers();
          } else {
            filterMarkersByTab();
          }
        }
        if (currentWalkindxType) {
          changeWalkindexType();
        }
      } else {
        generateMapWalkIndex();
      }

      // if (isModern && showCompareLifeStyle && currentLifestyleType?.length > 0) {
      //   fetchData(latitude, longitude, currentLifestyleType.toLowerCase());
      // }

      setForceRender(!forceRender);
      dispatch({ type: 'SET_CURRENT_ZOOM', payload: zoomValue });
    }
  }, [mapTab]);

  useEffect(() => {
    if (!isMounted || !property) return;

    const payload = { latitude: latitude, longitude: longitude };
    actions.handleFetchScores(payload, index);

    if (isWalkIndex) {
      setShowSpeedDial(false);

      if (isModern && showCompareLifeStyle) {
        if (currentWalkindxType) {
          changeWalkindexType();
        } else {
          generateMapWalkIndex();
        }
        const mapTabPayload = !isWalkIndex ? 'All' :  'Nature';
        dispatch({ type: 'SET_MAP_TAB', payload: mapTabPayload });
      }
    } else {
      changeWalkindexType();
      if (isModern && showCompareLifeStyle) {
        if (currentWalkindxType) {
          changeWalkindexType();
        } else {
          generateMapWalkIndex();
        }
      }

      if (property) {
        handleGemMarkers();
      }
    }

    setShowStreetView(false);
    setMarkerDetails(null);
    clearDirections();
  }, [currentTab]);

  useEffect(() => {
    generateMapWalkIndex();
  }, [currentPresence]);

  useEffect(() => {
    if (!isMounted) return;

    if (currentLifestyleType?.length > 0) {
      setCombineSearchMarkers();
    } else {
      if (!isEstablishmentSearchOpen || isMobile) {
        setFilterMarkers(filterMarkersByTab(null, true));
      }
    }
    clearDirections();
    setMarkerDetails(null);
  }, [categoryFilters]);

  useEffect(() => {
    if (!showFilter && !isMobile) {
      dispatch({
        type: 'RESET_CATEGORY',
        payload: [''],
      });
    }
    setMarkerDetails(null);
    clearDirections();
    if (isMobile && showFilter) {
      setCurrentMarker(null);
    }
  }, [showFilter]);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.target.className == 'feedback-form-container') {
            setShowFBForm(false);
          } else {
            if (!showCompareLifeStyle && !isModern) {
              setCurrentMarker(null);
              setMarkerDetails(null);
              clearDirections();
            }
          }
        }
      };
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const popuprRef = useRef(null);
  useOutsideAlerter(popuprRef);

  useEffect(() => {
    dispatch({
      type: 'SET_KEY_PROPERTY_NAME',
      payload: String(currentAddress?.property),
    });
    if (!isWalkIndex) {
      if (currentWalkindxType) {
        changeWalkindexType();
      }
    }
    if (latitude && longitude) {
      const payload = { latitude: latitude, longitude: longitude };
      actions.handleFetchScores(payload, index);
    }
    clearDirections();
  }, [currentAddress]);

  const setFilterMarkers = (markers) => {
    let getMarkers = markers || originalMarkers;

    if (currentRates.length > 0 && currentRates[0] !== '') {
      getMarkers = getRatingMarkers(getMarkers);
    }

    if (property && getMarkers?.length) {
      const filteredMarkers =
        categoryFilters.length > 1 || categoryFilters[0] !== ''
          ? getMarkers.filter((place) => {
              const placeCategory = place.category;
              const placeName = place.name?.toLowerCase();
              const category = placeCategory
                ? placeCategory.toLowerCase()
                : 'others';
              const isSearchTextFound =
                categoryFilters[0] !== '' &&
                (placeName.includes(categoryFilters[0].toLowerCase()) ||
                category.includes(categoryFilters[0].toLowerCase()));

              const filters = categoryFilters.slice(1);

              return (
                isSearchTextFound ||
                filters.some(
                  (item) =>
                    actions.isWholeWordMatch(
                      category.toLowerCase(),
                      item.toLowerCase()
                    )
                )
              );
            })
          : currentLifestyleType.length > 0
          ? getMarkers
          : filterMarkersByTab(getMarkers, true);

      setFilteredMarkers(filteredMarkers);
      if (showFilter || isMobile) {
        dispatch({
          type: 'SET_LIST_OF_MARKERS',
          payload: { markersIndex: index, markersList: filteredMarkers },
        });
      }
    } else {
      setFilteredMarkers(getMarkers);
    }
  };

  useEffect(() => {
    if (!isMounted) return;

    if (!showFilter && !isMobile) {
      dispatch({
        type: 'SET_LIST_OF_MARKERS',
        payload: { markersIndex: index, markersList: filterMarkersByTab(null, true) },
      });
    }
  }, [showFilter]);

  const saveToFavorites = (item) => {
    const favoritesCopy = { ...favorites };
    const propertyFavorites = favorites[getKeyName()];
    if (propertyFavorites?.length > 0) {
      propertyFavorites.push(item);
      favoritesCopy[getKeyName()] = [...propertyFavorites];
    } else {
      favoritesCopy[getKeyName()] = [item];
    }
    setLSData('favorites', favoritesCopy);
    dispatch({
      type: 'SET_FAVORITES',
      payload: favoritesCopy,
    });
  };

  const removeFromFavorites = (item) => {
    const favoritesCopy = { ...favorites };
    const updatedFavorites = favoritesCopy[getKeyName()].filter(
      (favorite) => favorite.id !== item.id
    );
    favoritesCopy[getKeyName()] = updatedFavorites;
    setLSData('favorites', favoritesCopy);
    dispatch({
      type: 'SET_FAVORITES',
      payload: favoritesCopy,
    });
  };

  const handleFavorite = (item) => {
    if (favorites[getKeyName()]?.some((fav) => fav.id === item.id)) {
      removeFromFavorites(item);
    } else {
      saveToFavorites(item);
      enqueueSnackbar('Added to your Favorites', {
        variant: 'info',
        preventDuplicate: true,
        autoHideDuration: 2000,
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      });
    }
  };

  const isFavoriteChecked = (item) => {
    return (
      favorites[getKeyName()]?.length &&
      favorites[getKeyName()]?.some((fav) => fav.id === item?.id)
    );
  };

  const getKeyName = () => {
    return property ? String(property) : keyPropertyName;
  };

  const handleHighlightProperty = async () => {
    clearDirections();
    setCurrentMarker(null);
    setMarkerDetails(null);
    dispatch({ type: 'SET_PREVIOUS_PAGE', payload: 3 });
    if (isWalkIndex && currentWalkindxType) {
      dispatch({ type: 'SET_MAP_TAB', payload: currentWalkindxType });
      dispatch({ type: 'SET_CURRENT_WALKINDX_TYPE', payload: null });
    } else {
      const mapTabPayload = isWalkIndex ? 'Nature' : 'All';
      dispatch({ type: 'SET_MAP_TAB', payload: mapTabPayload });
    }
    if (property !== currentAddress.property) {
      await actions.fetchAddressData(property);
      actions.sendDataToGoogleAnalytics('used_expand_map', {});
      if (!isModern) {
        dispatch({
          type: 'RESET_LIFESTYLE_COMPARE_ADDRESS',
          payload: currentAddress,
        });
      }
    }
    dispatch({
      type: 'SET_COMPARE_LIFESTYLE',
      payload: false,
    });
    dispatch({
      type: 'SET_SHOW_COMBINE_SEARCH',
      payload: true,
    });
  };

  const toggleFeedback = (e) => {
    if (showCompareLifeStyle) {
      setShowFBForm(!showFBForm);
      dispatch({
        type: 'SET_CURRENT_COMPARE_MAP',
        payload: { index: index, visibleForm: !showFBForm },
      });
    } else {
      dispatch({ type: 'SET_SHOW_FEEDBACK_FORM', payload: !showFeedbackForm });
    }
  };

  const calculateDistance = (startPoint, endPoint) => {
    return distance(startPoint, endPoint, { units: 'miles' });
  };

  const renderDefaultPopup = (categories) => {
    return (
      <div className='popup-description-container'>
        {categories && (
          <div className='popup-category-container'>
            {categories.map((item) => (
              <div className='popup-category-item'> {item}</div>
            ))}
          </div>
        )}
        <img
          src={isFavoriteChecked(currentMarker[index]) ? Favorite : Unfavorite}
          className='popup-favorite'
          width={25}
          onClick={() => handleFavorite(currentMarker[index])}
        />

        <div className='popup-header'>
          <img src={getImage(currentMarker[index].type)} alt='Icon' />
          <h4 className='popup-type'>{currentMarker[index].type}</h4>

          <img
            src={Cancel}
            className='popup-close'
            onClick={() => setCurrentMarker(null)}
            alt='Popup Close'
          />
        </div>
        <span className='popup-name'>{currentMarker[index].name}</span>
        <div className='distance-rating-container'>
          <div className='establishment-distance'>
            <img src={Walk} alt='Distance' />
            {calculateDistance(
              [currentMarker[index].lng, currentMarker[index].lat],
              [currentAddress?.longitude, currentAddress?.latitude]
            ).toFixed(2)}{' '}
            mi
          </div>
          <Rating
            initialValue={currentMarker[index].rating}
            allowFraction
            size={25}
            readonly
            fillColor='#0f5671'
          />
        </div>

        <span>{currentMarker[index].Address}</span>

        {/* <div className='favorite-icon'>
          <Rating
            iconsCount={1}
            emptyIcon={<Unfavorite />}
            fillIcon={<Favorite />}
            size={5}
            transition
            initialValue={isFavoriteChecked(currentMarker) ? 1 : 0}
            showTooltip={false}
            onClick={() => handleFavorite(currentMarker)}
          />
        </div> */}
      </div>
    );
  };

  const markerCategories = currentMarker[index]?.category
      ? currentMarker[index].category.split(';')
      : '';

  const renderPopup = () => {
    if (currentMarker[index]) {
      return (

          <div
            className={`popup ${isModern && 'modern-popup'} ${
              isModern &&
              !isMobile &&
              showCompareLifeStyle &&
              'modern-lifestyle-compare-popup'
            }`}
            ref={popuprRef}
          >
            <Show>
              <Show.When
                isTrue={
                  !isModern &&
                  currentMarker[index] &&
                  markerDetails === null &&
                  direction !== null
                }
              >
                {renderDefaultPopup()}
              </Show.When>
              <Show.When
                isTrue={
                  isModern && currentMarker[index] && markerDetails !== null
                }
              >
                <MarkerDetails
                  index={index}
                  marker={markerDetails}
                  categories={markerCategories}
                  currentMarker={currentMarker[index]}
                  currentAddress={currentAddress}
                  currentView={currentView}
                  isLoading={isLoading}
                  isMobile={isMobile}
                  directionDetails={directionDetails}
                  actions={actions}
                  dispatch={dispatch}
                  getThumbnail={getThumbnail}
                  setCurrentMarker={setCurrentMarker}
                  setMarkerDetails={setMarkerDetails}
                  handleFavorite={handleFavorite}
                  isFavoriteChecked={isFavoriteChecked}
                  calculateDistance={calculateDistance}
                />
              </Show.When>
              <Show.Else>
                <></>
              </Show.Else>
            </Show>
          </div>
      );
    }
    return null;
  };

  const setCurrentMarker = (marker) => {
    dispatch({
      type: 'SET_CURRENT_MARKER',
      payload: { markerIndex: index, markerData: marker },
    });
  };

  const handleMarkerClick = (marker) => {
    actions.sendDataToGoogleAnalytics('marker_click', {
      marker_name: marker.name,
    });
    clearDirections();
    fetchMarkerDetails(marker);
    if (showFilter && isMobile) {
      dispatch({ type: 'SET_SHOW_FILTERS', payload: false });
    }
    const newPosition = new window.google.maps.LatLng(
      parseFloat(marker.lat),
      parseFloat(marker.lng)
    );
    currentMapInstance?.panTo(newPosition);
  };

  const checkMarkerPosition = (marker) => {
    // Get the pixel position of map center
    const centerPixelPosition = map
      ?.getProjection()
      ?.fromLatLngToPoint(mapCenter);

    // Get the pixel position of the clicked marker
    const markerPixelPosition = map?.getProjection()?.fromLatLngToPoint(marker);
    if (showCompareLifeStyle || isSmallerScreen) {
      setPopupPosition(250);
    } else {
      setPopupPosition(50);
    }
  };

  const fetchMarkerDetails = (marker) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    const markerPosition = {
      lat: parseFloat(marker.lat),
      lng: parseFloat(marker.lng),
    };

    checkMarkerPosition(markerPosition);

    const originPosition = {
      lat: latitude,
      lng: longitude,
    };

    const google = window.google;

    // Create a Geocoder instance
    const geocoder = new google.maps.Geocoder();

    // Use Geocoder to get the address components for the clicked marker
    geocoder.geocode({ location: markerPosition }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          // fetch place details using the place_id
          fetchPlaceDetails(results[0].place_id)
            .then((details) => {
              // setMarkerDetails(details);
              // calculateDirections(originPosition, markerPosition);
              const payload = [{
                name: details?.name,
                rating: details?.rating,
                place_id: details?.place_id,
              },];

              actions.handleGoogleMarkerRating(index, payload).then((msg) => {
                const markerCopy = {...marker, rating: details?.rating};
                setCurrentMarker(markerCopy);
                setMarkerDetails(details);
                calculateDirections(originPosition, markerPosition);
              });
            })
            .catch((error) => {
              setMarkerDetails({});
              console.error('Error:', error.message);
            });
        } else {
          setMarkerDetails({});
          console.error('No results found for geocoding.');
        }
      } else {
        setMarkerDetails({});
        console.error('Error fetching place details:', status);
      }
    });
    setCurrentMarker(marker);
  };

  useEffect(() => {
    if (markerDetails !== null) {
      dispatch({ type: 'SET_LOADING', payload: false });
    } else {
      clearDirections();
    }
  }, [markerDetails]);

  useEffect(() => {
    clearDirections()
    setCurrentMarker(null)
    // dispatch({ type: 'SET_CURRENT_ZOOM', payload: zoomValue });
    // setForceRender(!forceRender);
  }, [showMapQS, showMarkerList]);

  useEffect(() => {
    if (
      !showEstablishments &&
      !showFilter &&
      currentMarker[index] &&
      property &&
      index === selectedMap
    ) {
      handleMarkerClick(currentMarker[index]);
    }
  }, [showEstablishments, showFilter]);

  useEffect(() => {
    clearDirections();
    setMarkerDetails(null);
    setCurrentMarker(null);
  }, [showCompareLifeStyle]);

  const calculateDirections = (origin, destination) => {
    if (showCompareLifeStyle && !currentMapInstance && !currentMarker[index]) return;

    const directionsService = new window.google.maps.DirectionsService();

    const directionsServiceOptions = {
      destination: destination,
      origin: origin,
      travelMode: 'WALKING',
      provideRouteAlternatives: false,
      optimizeWaypoints: true,
    };

    directionsService.route(directionsServiceOptions, (result, status) => {
      setDirectionDetails(result);
      if (result !== null && status === 'OK') {
        setDirection(result);
      } else {
        console.error(`Error fetching directions: ${status}`);
      }
    });
  };

  // useEffect(() => {
  //   // Start updating traffic data every 5 minutes
  //   const updateTrafficData = () => {
  //     if (trafficLayer) {
  //       trafficLayer.setMap(null); // Remove existing traffic layer
  //       trafficLayer.setMap(currentMapInstance); // Add updated traffic layer
  //     }
  //   };
  //   const intervalId = setInterval(updateTrafficData, 5 * 60 * 1000);
  //   return () => clearInterval(intervalId); // Clean up the interval on component unmount
  // }, [trafficLayer]);

  const directionsCallback = useCallback((result, status) => {
    if (status === 'OK') {
      setDirection(result);
    }
  });

  const goToCenter = () => {
    currentMapInstance.panTo(mapCenter);
    actions.sendDataToGoogleAnalytics('recenter_map', {});
  };

  const getGeoserverLink = (layer, bbox, type, viewParams) => {
    let geoserver = 'geoserver/walkspan/wms?';
    geoserver += '&REQUEST=GetMap';
    geoserver += '&SERVICE=WMS';
    geoserver += '&VERSION=1.1.1';
    geoserver += '&LAYERS=walkspan:' + layer; //walkspan_circle
    geoserver += '&FORMAT=image/png';
    geoserver += '&BGCOLOR=0xFFFFFF';
    geoserver += '&TRANSPARENT=TRUE';
    geoserver += '&SRS=EPSG:' + '4326';
    geoserver += '&BBOX=' + bbox;
    geoserver += '&WIDTH=256';
    geoserver += '&HEIGHT=256';
    geoserver += '&STYLES=' + type;
    geoserver += '&VIEWPARAMS=' + viewParams;
    return geoserver;
  };

  const getGeoserverBackdropLink = (bbox, viewParams) => {
    let geoserver = 'geoserver/walkspan/wms?';
    geoserver += '&REQUEST=GetMap';
    geoserver += '&SERVICE=WMS';
    geoserver += '&VERSION=1.1.1';
    geoserver += '&LAYERS=walkspan:walkspan_backdrop';
    geoserver += '&FORMAT=image/png';
    geoserver += '&BGCOLOR=0xFFFFFF';
    geoserver += '&TRANSPARENT=TRUE';
    geoserver += '&SRS=EPSG:' + '4326';
    geoserver += '&BBOX=' + bbox;
    geoserver += '&WIDTH=256';
    geoserver += '&HEIGHT=256';
    geoserver += '&VIEWPARAMS=' + viewParams;
    return geoserver;
  };

  const toggleMapType = () => {
    const type = mapType === 'roadmap' ? 'satellite' : 'roadmap';
    actions.sendDataToGoogleAnalytics('changed_map_type', { map_type: type });
    dispatch({ type: 'SET_CURRENT_MAPTYPE', payload: type });
  };

  const toggleWalkindx = () => {
    if (showSpeedDial) {
      dispatch({ type: 'SET_CURRENT_WALKINDX_TYPE', payload: null });
    }

    if (!showSpeedDial && isMobile) {
      togglePin(false);
    }
    setShowSpeedDial(!showSpeedDial);
  };

  const handleSpeedDialClick = (type) => {
    if (type === currentWalkindxType) {
      dispatch({ type: 'SET_CURRENT_WALKINDX_TYPE', payload: null });
    } else {
      actions.sendDataToGoogleAnalytics('activate_walkindx', {
        walkindx_type: type,
      });
      dispatch({ type: 'SET_CURRENT_WALKINDX_TYPE', payload: type });
    }
  };

  const handleZoomClick = (value) => {
    actions.sendDataToGoogleAnalytics('used_zoom_btn', {});
    if (value >= zoomValue) {
      dispatch({ type: 'SET_CURRENT_ZOOM', payload: value });
    }
  };

  const changeWalkindexType = () => {
    const currentMap = currentMapInstance;
    if (currentWalkindxType) {
      const radiusMap = {
        'quarter-mile': 25,
        'half-mile': 50,
        'one-mile': 100,
      };

      let viewParams = '_radius:' + (radiusMap[getArea(currentDistance)] || 10000);

      viewParams += `;_lat:${(latitude * 10000).toFixed()};_lng:${Math.abs(
        longitude * 10000
      ).toFixed()}`;

      const getFilter = (viewParams += currentPresence
        ? `;_walk_indx_metric:${
            currentWalkindxType === 'Quiet'
              ? 'noise'
              : currentWalkindxType === 'Vibrance'
              ? 'activities'
              : currentWalkindxType?.toLowerCase()
          };_score_filter:${currentPresence}`
        : '');

      const layer = new window.google.maps.ImageMapType({
        getTileUrl: (coord, zoom) => {
          const zfactor = Math.pow(2, zoom);
          const proj = currentMap.getProjection();

          // Get Long Lat coordinates
          const bot = proj.fromPointToLatLng(
            new window.google.maps.Point(
              ((coord.x + 1) * 256) / zfactor,
              ((coord.y + 1) * 256) / zfactor
            )
          );
          const top = proj.fromPointToLatLng(
            new window.google.maps.Point(
              (coord.x * 256) / zfactor,
              (coord.y * 256) / zfactor
            )
          );
          // Create the Bounding box string
          const bbox = `${top.lng()},${bot.lat()},${bot.lng()},${top.lat()}`;
          // Build WMS URL
          const geoserver = getGeoserverLink(
            'walkspan_circle',
            bbox,
            `${
              currentWalkindxType === 'Quiet'
                ? 'noise'
                : currentWalkindxType === 'Vibrance'
                ? 'activities'
                : currentWalkindxType?.toLowerCase()
            }WalkspanV3`,
            getFilter
          );
          const url = `${process.env.REACT_APP_API_URL}/geoserver/?geoserver=${encodeURIComponent(
            geoserver
          )}
          &email=${userDetails?.email}&api_key=${userDetails?.api_key}`;

          return url;
        },
        tileSize: new window.google.maps.Size(256, 256),
        maxZoom: 9,
        minZoom: 0,

        isPng: true,
        name: 'WalkspanLayer',
      });

      if (layer) {
        if (currentMap?.overlayMapTypes.length === 1) {
          currentMap?.overlayMapTypes.push(layer);
        } else {
          currentMap?.overlayMapTypes.setAt(1, layer);
        }
      }
    } else {
      currentMap?.overlayMapTypes.removeAt(1);
    }
  };

  useEffect(() => {
    changeWalkindexType();
    if (isWalkIndex) {
      generateMapWalkIndex();
    }
    clearDirections();
  }, [currentWalkindxType]);

  useEffect(() => {
    if (!isMounted || isEstablishmentSearchOpen) return;

    clearDirections();
    setCurrentMarker(null);
    if (currentLifestyleType?.length > 0) {
      setCombineSearchMarkers();
    } else {
      setFilterMarkers();
      // setFilteredMarkers(originalMarkers);
      dispatch({
        type: 'SET_LIST_OF_MARKERS',
        payload: { markersIndex: index, markersList: originalMarkers },
      });
    }
  }, [currentLifestyleType]);

  const setCombineSearchMarkers = (markers, shouldFilter=true) => {
    const selectedMarkers = markers || originalMarkers;
    const combineSearchMarkers = selectedMarkers.filter((marker) => {
      return (
        (mapTab !== 'All' && marker.type.toLowerCase() === mapTab.toLowerCase()) ||
        currentLifestyleType.some((type) => type.toLowerCase() === marker.type.toLowerCase())
      );
    });

    if (isFilterActive && shouldFilter) {
      setFilterMarkers(combineSearchMarkers);
    } else {
      setFilteredMarkers(combineSearchMarkers);
    }
    dispatch({
      type: 'SET_LIST_OF_MARKERS',
      payload: { markersIndex: index, markersList: combineSearchMarkers },
    });
  };

  const filterMarkersByTab = (markers, shouldReturn = false) => {
    const selectedMarkers = markers || originalMarkers;
    let mapTabMarkers = [];
    if (mapTab === 'Favorites') {
      mapTabMarkers = favorites[getKeyName()];
    } else if (mapTab === 'All') {
      mapTabMarkers = selectedMarkers;
    } else {
      mapTabMarkers = selectedMarkers.filter((marker) => {
        return mapTab.toLowerCase() === marker?.type?.toLowerCase();
      });
    }

    if (shouldReturn) {
      return mapTabMarkers;
    } else {
      setFilteredMarkers(mapTabMarkers);
      dispatch({
        type: 'SET_LIST_OF_MARKERS',
        payload: { markersIndex: index, markersList: mapTabMarkers },
      });
      dispatch({
        type: 'UPDATE_COMPARE',
        payload: { number: index, length: mapTabMarkers?.length || 0 },
      });
    }
  };

  const generateMapWalkIndex = (map1) => {
    // dispatch({ type: 'SET_LOADING', payload: true });
    const currentMap = map1 || currentMapInstance;

    if (currentMap) {
      const tab = currentWalkindxType || mapTab;
      currentMap.overlayMapTypes.clear();
      const radiusMap = {
        'quarter-mile': 25,
        'half-mile': 50,
        'one-mile': 100,
      };
      let viewParams = '_radius:' + (radiusMap[getArea(currentDistance)] || 10000);

      viewParams += `;_lat:${(latitude * 10000).toFixed()};_lng:${Math.abs(
        longitude * 10000
      ).toFixed()}`;

      const getFilter = (viewParams += currentPresence
        ? `;_walk_indx_metric:${
            (tab === 'Quiet' || tab === 'quiet')
              ? 'noise'
              : (tab === 'Vibrance' || tab === 'Vibrance')
              ? 'activities'
              : tab.toLowerCase()
          };_score_filter:${currentPresence}`
        : '');

      const backdrop = new window.google.maps.ImageMapType({
        getTileUrl: (coord, zoom) => {
          const proj = currentMap.getProjection();
          const zfactor = Math.pow(2, zoom);
          // Get Long Lat coordinates
          const top = proj.fromPointToLatLng(
            new window.google.maps.Point(
              (coord.x * 256) / zfactor,
              (coord.y * 256) / zfactor
            )
          );
          const bot = proj.fromPointToLatLng(
            new window.google.maps.Point(
              ((coord.x + 1) * 256) / zfactor,
              ((coord.y + 1) * 256) / zfactor
            )
          );
          // Create the Bounding box string
          const bbox = `${top.lng()},${bot.lat()},${bot.lng()},${top.lat()}`;
          // Build WMS URL
          const geoserver = getGeoserverBackdropLink(bbox, viewParams);

          return `${process.env.REACT_APP_API_URL}/geoserver/?action=Geoserver&geoserver=${encodeURIComponent(
            geoserver
          )}
          &email=${userDetails?.email}&api_key=${userDetails?.api_key}`;
        },
        tileSize: new window.google.maps.Size(256, 256),
        isPng: true,
        name: 'WalkspanBackdropLayer',
      });

      currentMap.overlayMapTypes.push(backdrop);

      if (isWalkIndex) {
        const layer = new window.google.maps.ImageMapType({
          getTileUrl: (coord, zoom) => {
            const zfactor = Math.pow(2, zoom);
            const proj = currentMap.getProjection();

            // Get Long Lat coordinates
            const bot = proj.fromPointToLatLng(
              new window.google.maps.Point(
                ((coord.x + 1) * 256) / zfactor,
                ((coord.y + 1) * 256) / zfactor
              )
            );
            const top = proj.fromPointToLatLng(
              new window.google.maps.Point(
                (coord.x * 256) / zfactor,
                (coord.y * 256) / zfactor
              )
            );
            // Create the Bounding box string
            const bbox = `${top.lng()},${bot.lat()},${bot.lng()},${top.lat()}`;
            // Build WMS URL
            const geoserver = getGeoserverLink(
              'walkspan_circle',
              bbox,
              `${
                (tab === 'Quiet' || tab === 'quiet')
                  ? 'noise'
                  : (tab === 'Vibrance' || tab === 'Vibrance')
                  ? 'activities'
                  : tab.toLowerCase()
              }WalkspanV3`,
              getFilter
            );
            const url =`${process.env.REACT_APP_API_URL}/geoserver/?geoserver=${encodeURIComponent(
              geoserver
            )}
            &email=${userDetails?.email}&api_key=${userDetails?.api_key}`;

            return url;
          },
          tileSize: new window.google.maps.Size(256, 256),
          maxZoom: 9,
          minZoom: 0,

          isPng: true,
          name: 'WalkspanLayer',
        });

        if (layer) {
          currentMap.overlayMapTypes.push(layer);
        }
      }
      // dispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  const getClusterZoomLevel = () => {
    if (currentDistance >= 3) {
      return zoom > 16;
    } else if (currentDistance >= 2) {
      return zoom >= 17;
    } else if (currentDistance >= 1) {
      return zoom >= 18;
    }
  };

  const getClusterImage = () => {
    switch (mapTab) {
      case 'Food':
        return FoodCluster;
      case 'Shops':
        return ShopCluster;
      case 'Services':
        return ServicesCluster;
      case 'Transit':
        return TransitCluster;
      case 'Leisure':
        return EntertainmentCluster;
      default:
        return Cluster;
    }
  };

  const clustererOptions = {
    imagePath:
      'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
    gridSize: 50,
    className: 'cluster',
    styles: [
      {
        url: clusterImage,
        width: 50,
        height: 50,
        textSize: 13,
        className: 'cluster',
        textColor: 'white',
      },
      {
        url: clusterImage,
        width: 50,
        height: 50,
        textSize: 13,
        className: 'cluster',
        textColor: 'white',
      },
      {
        url: clusterImage,
        width: 50,
        height: 50,
        textSize: 13,
        className: 'cluster',
        textColor: 'white',
      },
      {
        url: clusterImage,
        width: 50,
        height: 50,
        textSize: 13,
        className: 'cluster',
        textColor: 'white',
      },
    ],
  };

  const getMarkerIcon = (type) => {
    switch (type) {
      case 'food':
        return FoodMarker;
      case 'shops':
        return ShopMarker;
      case 'services':
        return ServiceMarker;
      case 'transit':
        return TransitMarker;
      case 'leisure':
        return EntertainmentMarker;
      default:
        return Cluster;
    }
  };

  const handleTogglePin = (e) => {
    e.stopPropagation();
    if (!showPin && isMobile) {
      setShowSpeedDial(false);
      dispatch({ type: 'SET_CURRENT_WALKINDX_TYPE', payload: null });
    } else {
      dispatch({ type: 'SET_RATES', payload: [] });
    }
    togglePin(!showPin);
  };

  const isFilterActive =
    categoryFilters.length > 1 ||
    categoryFilters[0] !== '' ||
    (currentRates.length !== 0 && currentRates[0] !== '');

  const customMarkerIcon = (marker) => {
    if (!isGoogleMapsLoaded) return;

    const isMarkerSelected =
      currentMarker[index] !== null &&
      marker.lat === currentMarker[index]?.lat &&
      marker.lng === currentMarker[index]?.lng

    const getMarkerSize = isMarkerSelected
      ? [35, 45] :
      isMobile
      ? [15, 20] :
      filteredMarkers?.length > 20
      ? [20, 25] : [20, 25];
    return {
      url: getMarkerIcon(marker.type),
      scaledSize: new window.google.maps.Size(
        getMarkerSize[0],
        getMarkerSize[1]
      ),
    };
  };

  const filteredProperties = () => {
    return properties.filter((unselected) => {
      return !compareLifeStyleAddress.some((selected) =>
        selected?.property
          ?.toLowerCase()
          .includes(unselected.label?.toLowerCase())
      );
    });
  };

  const handleFBForm = (value) => {
    setShowFBForm(value);
    dispatch({
      type: 'SET_CURRENT_COMPARE_MAP',
      payload: { index: index, visibleForm: value },
    });
  };

  const onLoad = useCallback(function callback(map1) {
    clearDirections();
    map1.setMapTypeId('satellite');
    
    map1.fetchMarkerDetails = fetchMarkerDetails;
  
    dispatch({ type: 'SET_CURRENT_MAPTYPE', payload: 'satellite' });
  
    if (!showCompareLifeStyle) {
      dispatch({ type: 'SET_MAP_INSTANCE', payload: map1 });
    } else {
      dispatch({ type: 'SET_COMPARE_MAPS', payload: { index, object: map1 } });
    }
    
    serviceRef.current = new window.google.maps.places.PlacesService(map1);
    setCurrentMap(map1);
  
    generateMapWalkIndex(map1);
  }, []);

  const streetViewOnLoad = (streetViewPanorama) => {
    streetViewPanorama.setVisible(showStreetView);
    streetViewPanorama.addListener('visible_changed', () => {
      const isStreetViewVisible = streetViewPanorama.getVisible();
      setShowStreetView(isStreetViewVisible);
      if (!isStreetViewVisible) {
        setShowStreetView(false);
        dispatch({
          type: 'SET_SHOW_MAP_STREET_VIEW',
          payload: { svIndex: index, svValue: false }
        });
      }
    });
  };


  const toggleStreetView = () => {
    clearDirections();
    setShowStreetView(!showStreetView);
    dispatch({
      type: 'SET_SHOW_MAP_STREET_VIEW',
      payload: { svIndex: index, svValue:!showStreetView }
    });
  };

  const toggleFullScreenMap = () => {
    actions.toggleFullScreenMap();
    if (!isMobile) {
      dispatch({ type: 'SET_DESKTOP_SIDEBAR', payload: true });
    }
  };

  const modifyFootstepsIcon = ({ color }) => {
    const svgString = renderToString(
      <FootstepsSVG style={{ fill: color || '#000000' }} />
    );
    const svgBase64 = btoa(svgString);
    return svgBase64;
  };

  const setShowProperties = (value) => {
    dispatch({ type: 'SET_SHOW_PROPERTIES', payload: value });
  };


  const filteredLifestyleFeatures = () => {
    const features = lifeStyleFeatures.slice(1, lifeStyleFeatures.length - 1);

    if (
      currentLifestyleType?.length > 1 ||
      (isWalkIndex && currentLifestyleType?.length > 0)
    ) {
      return features.filter((feature) => {
        return currentLifestyleType.includes(feature.name);
      });
    } else {
      return features;
    }
  };

  const renderCustomButtons = (selected) => {
    if (!walkindexScores[index] || !lifeStyleScores[index]) return;
    let listBtns = [];
    if (currentLifestyleType.length > 1) {
      listBtns = filteredLifestyleFeatures();
    } else {
      listBtns = selected
        ? isWalkIndex
          ? walkIndexFeatures
          : filteredLifestyleFeatures()
        : isWalkIndex
        ? filteredLifestyleFeatures()
        : walkIndexFeatures;
    }

    let scores = [];
    if (currentLifestyleType.length > 1) {
      scores = lifeStyleEssentialScores();
    } else {
      scores = selected
        ? isWalkIndex
          ? walkindexScores[index]
          : lifeStyleEssentialScores()
        : isWalkIndex
        ? lifeStyleEssentialScores()
        : walkindexScores[index];
    }

    if (listBtns.length) {
      return listBtns.map((item) => {
        const btnColor = {
          '--type-color': item.color,
        };

        const getName =
          item.name === 'Leisure' ? 'leisure' : item.name.toLowerCase();

        return (
          <button
            data-tooltip-id={`custom-button-${item.name}`}
            key={`custom-button-${item.name}`}
            className={`dashboard-type-btn is-btn-active
              ${isMobile && 'is-mobile-btn'}
              ${isMapLoading[index] ? 'btn-loading' : ''}
              ${isModern && 'modern-dashboard-type-btn'}
            `}
            style={btnColor}
          >
            <div
              className={`custom-button-icon modern-custom-button-icon
              ${
                (!isWalkIndex ||
                  !selected ||
                  currentLifestyleType.length > 1) &&
                'modern-lifestyle-custom-button'
              }`}
            >
              {item.svg}
              <Show>
                <Show.When
                  isTrue={
                    isWalkIndex && selected && currentLifestyleType.length < 2
                  }
                >
                  <div
                    className='icon-score-cirlce'
                    data-score-value={Math.round(scores[item.id] || 1).toString()}
                    style={scoreCircleStyle(Math.round(scores[item.id] || 1))}
                  ></div>
                </Show.When>
                <Show.When
                  isTrue={
                    !isWalkIndex || !selected || currentLifestyleType.length > 1
                  }
                >
                  <div
                    className='score-diamond'
                    data-score-value={Math.round(scores[item.id]).toString()}
                  >
                    <ModernGem />
                  </div>
                </Show.When>
                <Show.Else>
                  <></>
                </Show.Else>
              </Show>
            </div>
            <div className='btn-name'>
              {item.name}
              <Show.When
                isTrue={!isWalkIndex || currentLifestyleType.length > 0}
              >
                <span className='gem-count'>{` ${
                  lifeStyleScores[index][getName] || '0'
                }`}</span>
              </Show.When>
            </div>

            <Show.When isTrue={!isModern}>
              <span> {Math.round(scores[item.id])}</span>
            </Show.When>
          </button>
        );
      });
    }
  };

  const lifeStyleEssentialScores = () => {
    if (!lifeStyleScores[index]) return {};

    const categories = ['Food', 'Services', 'Shops', 'Transit', 'Leisure'];
    const essentialScores = {};

    categories.forEach((category) => {
      const type = category === 'Leisure' ? 'leisure' : category.toLowerCase();
      essentialScores[category] = getLifeStyleScore(
        lifeStyleScores[index][type],
        currentDistance,
        isStandalone
      );
    });

    return essentialScores;
  };

  const getOverallScore = () => {
    if (!walkindexScores[index] ||!lifeStyleScores[index]) return 1;
    if (isWalkIndex) {
      const { activities, beautyM, beautyN, comfort, noise } =
        walkindexScores[index];

      const scoresChecker = {
          Activities: activities,
          BeautyM: beautyM,
          BeautyN: beautyN,
          Comfort: comfort,
          Noise: noise,
      };

      if (areObjectPropertiesNull(scoresChecker)) {
        return 1;
      }

      const scores = [
        parseFloat(activities),
        parseFloat(beautyM),
        parseFloat(beautyN),
        parseFloat(comfort),
        parseFloat(noise),
      ];

      const counts = [0, 0, 0];
      scores.forEach((score) => {
        const roundedScore = Math.round(score);
        counts[roundedScore - 1]++;
      });

      if (counts[2] >= 1) return 3;
      if (counts[1] >= 1) return 2;
      return 1;
    } else {
      const scores = Object.values(lifeStyleEssentialScores());

      const counts = [0, 0, 0];
      scores.forEach((score) => {
        const roundedScore = Math.round(score);
        counts[roundedScore - 1]++;
      });

      if (counts[2] >= 1) return 3;
      if (counts[1] >= 1) return 2;
      return 1;
    }
  };

  const getFeatureScore = () => {
    if (isWalkIndex) {
      const feature = currentWalkindxType === 'Vibrance'
        ? 'Activities'
        : currentWalkindxType;
      return Math.round(
        walkindexScores[index][walkindxID[feature]] || 1
      );
    } else {
      const feature = currentLifestyleType[0];
      // const type = mapTab === 'Leisure' ? 'leisure' : mapTab.toLowerCase();
      return getLifeStyleScore(
        lifeStyleScores[index][feature.toLowerCase()],
        currentDistance,
        isStandalone
      );
    }
  };

  const getFinalNeigborhoodScore = () => {
    return hasType(true) ? getFeatureScore() : getOverallScore();
  };

  const getOverallScoreLabel = () => {
    const type = isWalkIndex
      ? currentWalkindxType
      : currentLifestyleType.length == 1
      ? currentLifestyleType[0]
      : 'GEM';
    if (type) {
      return `${type.toUpperCase()} SCORE`;
    } else {
      return `${isWalkIndex ? 'FEEL' : 'GEM'} SCORE`;
    }
  };

  const getOverallScoreWording = () => {
    const gemFeature =
      currentLifestyleType[0] === 'Leisure'
        ? 'leisure'
        : currentLifestyleType[0]?.toLowerCase();
    if (mapTab !== 'All') {
      if (isWalkIndex) {
        return getWalkIndexWording(getFinalNeigborhoodScore());
      } else {
        return (
          <span
            className='gem-feature-score'
            // style={getTabColor(false, mapTab)}
          >
            {getLifeStyleWording(scoreProperties[index], currentDistance)}
          </span>
        );
      }
    } else {
      if (isWalkIndex) {
        return getWalkIndexWording(getFinalNeigborhoodScore());
      } else {
        return getLifeStyleWording(
          lifeStyleScores[index][gemFeature],
          currentDistance
        );
      }
    }
  };

  const renderModernWalkindxScore = () => {
    const feature = currentWalkindxType === 'Vibrance'
      ? 'Activities'
      : currentWalkindxType;
    return (
      <div className='lifestyle-search-score-container'>
        <div className='custom-button-icon modern-custom-button-icon'>
          {getFeatureIcon(true, feature)}
          <div
            className='icon-score-cirlce'
            data-score-value={Math.round(
              walkindexScores?.[index]?.[walkindxID?.[feature]] || 1
            )}
            style={scoreCircleStyle(
              Math.round(walkindexScores?.[index]?.[walkindxID?.[feature]] || 1)
            )}
          ></div>
        </div>
        <div
          className='tab-score-desciption'
          data-border-style={Math.round(
            walkindexScores[index][walkindxID[feature]] || 1
          ).toString()}
          style={getTabColor(true, feature)}
        >
          {getTypeDescription(
            'walkindex',
            feature?.toLowerCase(),
            Math.round(
              parseFloat(walkindexScores[index][walkindxID[feature]] || 1)
            ),
            currentDistance,
            isStandalone
          )}
        </div>
      </div>
    );
  };

  const renderModernLifestyleScore = () => {
    const feature = currentLifestyleType[0];

    return (
      <div className='lifestyle-search-score-container gem'>
        <div className='custom-button-icon modern-custom-button-icon'>
          {getFeatureIcon(false, feature)}
          <Show.When isTrue={currentLifestyleType.length > 0}>
            <div
              className='score-diamond'
              data-score-value={
                getLifeStyleScore(
                  lifeStyleScores[index][feature.toLowerCase()],
                  currentDistance,
                  isStandalone
                )
              }
            >
              <ModernGem />
            </div>
          </Show.When>
        </div>
        <div
          className='tab-score-desciption'
          data-border-style={Math.round(
            lifeStyleEssentialScores()[feature]
          ).toString()}
          style={getTabColor(false, feature)}
        >
          <b>{filteredMarkers.length}</b>{' '}
          {!feature?.endsWith('s') && scoreProperties[index] > 1
            ? feature + 's'
            : feature}
        </div>
      </div>
    );
  };

  const hasType = (selected) => {
    return selected
      ? isWalkIndex
        ? currentWalkindxType !== null
        : currentLifestyleType?.length > 0 && currentLifestyleType?.length < 2
      : isWalkIndex
      ? currentLifestyleType?.length > 0 && currentLifestyleType?.length < 2
      : currentWalkindxType !== null;
  };

  const renderFinalScore = (selected) => {
    if (showCompareLifeStyle && selected && hasType(true)) {
      return isWalkIndex
        ? renderModernWalkindxScore()
        : renderModernLifestyleScore();
    } else if (showCompareLifeStyle && !selected && hasType(false)) {
      return !isWalkIndex
        ? renderModernWalkindxScore()
        : renderModernLifestyleScore();
    } else {
      return null;
    }
  };

  const hasData = () => {
    if (isWalkIndex) {
      return true;
    } else {
      return filteredMarkers?.length !== 0;
    }
  };

  const renderPropertyScore = (length) => {
    if (!length) return '1';

    const limit = getLimit(currentDistance);
    if (length >= 0 && length <= limit[0]) {
      return '1';
    } else if (length <= limit[1]) {
      return '2';
    } else {
      return '3';
    }
  };

  const getCircleTab = () => {
    if (isWalkIndex) {
      return currentWalkindxType || mapTab;
    } else {
      if (hasType(true)) {
        return currentLifestyleType[0];
      } else {
        return mapTab;
      }
    }
  };

  useEffect(() => {
    clearDirections();
    setCurrentMarker(null);
  }, [showCombineSearch]);

  useEffect(() => {
    if (isMobile) {
      dispatch({
        type: 'SET_LIST_OF_MARKERS',
        payload: { markersIndex: index, markersList: filteredMarkers },
      });
    }
  }, [filteredMarkers]);

  useEffect(() => {
    setZoom(zoom1)
  }, [zoom1]);

  useEffect(() => {
    if (index === 0 && showMapQS && !showCompareLifeStyle) {
      if (!currentMarker[0]) {
        clearDirections();
      }
    }
  }, [currentMarker]);

  const handleMapClick = () => {
    if (isMobile) {
      if (showFilter) {
        dispatch({ type: 'SET_SHOW_FILTERS', payload: false });
      }

      if (currentMarker[index]) {
        setCurrentMarker(null);
      }
    }
  };

  const openFilter = () => {
    if (isEstablishmentSearchOpen) {
      dispatch({ type: 'SET_IS_ESTABLISHMENT_SEARCH_OPEN', payload: false });
    }

    dispatch({ type: 'SET_SHOW_FILTERS', payload: !showFilter });
  }

  const openQuickSearch = () => {
    if (isWalkIndex) {
      dispatch({ type: 'SET_CURRENT_TAB', payload: 'lifestyle' });
    }

    if (mapTab !== 'All') {
      dispatch({ type: 'SET_MAP_TAB', payload: 'All' });
    }

    if (showFilter) {
      dispatch({ type: 'SET_SHOW_FILTERS', payload: false });
    }

    if (categoryFilters?.length > 1 || categoryFilters[0] !== '') {
      dispatch({
        type: 'RESET_CATEGORY',
        payload: [''],
      });
    }

    if (currentMarker[selectedMap]) {
      dispatch({
        type: 'SET_CURRENT_MARKER',
        payload: { markerIndex: selectedMap, markerData: null },
      });
    }

    if (currentRates?.length > 0) {
      dispatch({ type: 'SET_RATES', payload: [] });
    }

    if (showMapQS) {
      dispatch({ type: 'SEARCH_CATEGORY', payload: '' });
    }

    dispatch({ type: 'SET_SHOW_MAP_QS', payload: !showMapQS });
  };

  const getAddressCompareIndex = () => {
    return compareLifeStyleAddress.findIndex((obj) => {
      return obj.property === property && property;
    });
  };

  const onUnmount = useCallback(function callback(map) {
    dispatch({ type: 'SET_MAP_INSTANCE', payload: null });
  }, []);

  return (
    <div
      className='maps-container'
      style={{ height: mapHeight, width: '100%', position: 'relative' }}
    >
      <Show.When isTrue={!showCompareLifeStyle && showFeedbackForm}>
        <FeedbackForm
          state={state}
          dispatch={dispatch}
          actions={actions}
          currentView={currentView}
        />
      </Show.When>

      <Show.When isTrue={showCompareLifeStyle && showFBForm}>
        <FeedbackForm
          showFBForm={showFBForm}
          setShowFBForm={handleFBForm}
          state={state}
          dispatch={dispatch}
          currentView={currentView}
        />
      </Show.When>

      <Show.When isTrue={property && isGoogleMapsLoaded}>
        <GoogleMap
          mapContainerStyle={{ height: '100%', width: '100%' }}
          defaultCenter={mapCenter}
          zoom={zoom}
          center={mapCenter}
          mapTypeId={mapType}
          onLoad={onLoad}
          // onZoomChanged={() => isMobile ? handleZoomChanged() : null}
          onUnmount={onUnmount}
          options={{
            minZoom: zoomValue,
            zoomControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            styles: lightMapTheme,
            keyboardShortcuts: false,
          }}
          key={forceRender}
          onClick={() => handleMapClick()}
        >
          <StreetViewPanorama
            position={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
            visible={showStreetView}
            onLoad={streetViewOnLoad}
            options={{
              enableCloseButton: true,
              fullscreenControl: false,
              disableDefaultUI: false,
              addressControl: false,
              zoomControl: false,
              panControl: false,
              keyboardShortcuts: false,
            }}
            // onVisibleChanged={() => toggleStreetView}
          />

          <Show.When
            isTrue={
              direction !== null &&
              directionDetails !== null &&
              markerDetails !== null &&
              footstepIcon
            }
          >
            <DirectionsRenderer
              options={{
                directions: direction,
                markerOptions: {
                  icon: footstepIcon,
                },
                polylineOptions: {
                  strokeColor: 'transparent',
                  strokeWeight: 4,
                  icons: [
                    {
                      icon: {
                        path: 'M 0,0 m -1, 0 a 1,1 0 1,0 2,0 a 1,1 0 1,0 -2,0',
                        scale: 2,
                        strokeOpacity: 1,
                        strokeColor: mapType === 'satellite' ? 'white' : findColor(currentTab, mapTab),
                        strokeWeight: 4,
                      },
                      offset: '0',
                      repeat: '20px', // Defines the length of each dash and the gap
                    },
                  ],
                },
                preserveViewport: true,
              }}
            ></DirectionsRenderer>
          </Show.When>

          {/* {showStreetView && (
            <div
              className={`close-street-view ${showCompareLifeStyle && 'compare-close-street-view'}`}
              onClick={toggleStreetView}
            >
              <img src={MapIcon} alt='Go Back' />
            </div>
          )} */}

          <MarkerContainer
            position={{
              lat: parseFloat(latitude),
              lng: parseFloat(longitude),
            }}
            currentActive={true}
            mapType={mapType}
            index={index}
            showCompareLifeStyle={showCompareLifeStyle}
            toggleStreetView={toggleStreetView}
          />

          <Show.When isTrue={currentDistance >= 3}>
            <Circle
              center={{
                lat: parseFloat(latitude),
                lng: parseFloat(longitude),
              }}
              radius={1609.34}
              options={{
                strokeColor: findColor(currentTab, getCircleTab()),
                strokeOpacity: 1,
                strokeWeight: 3,
                fillColor: 'transparent',
                fillOpacity: 0,
                zIndex: 100,
                visible: true,
              }}
              onClick={() => handleMapClick()}
            />
          </Show.When>

          <Show.When isTrue={currentDistance >= 2}>
            <Circle
              center={{
                lat: parseFloat(latitude),
                lng: parseFloat(longitude),
              }}
              radius={804.68}
              options={{
                strokeColor: findColor(currentTab, getCircleTab()),
                strokeOpacity: 1,
                strokeWeight: 3,
                fillColor: 'transparent',
                fillOpacity: 0,
                zIndex: 100,
                visible: true,
              }}
              onClick={() => handleMapClick()}
            />
          </Show.When>

          <Show.When isTrue={currentDistance >= 1}>
            <Circle
              center={{
                lat: parseFloat(latitude),
                lng: parseFloat(longitude),
              }}
              radius={402.34}
              options={{
                strokeColor: findColor(currentTab, getCircleTab()),
                strokeOpacity: 1,
                strokeWeight: 3,
                fillColor: 'transparent',
                fillOpacity: 0,
                zIndex: 100,
                visible: true,
              }}
              onClick={() => handleMapClick()}
            />
          </Show.When>

          <Show.When
            isTrue={
              (!isMapLoading[index] &&
                !isFilterActive &&
                !isWalkIndex &&
                filteredMarkers !== null &&
                typeof filteredMarkers !== 'string' &&
                mapTab !== 'Favorites' &&
                !showStreetView) ||
              currentLifestyleType?.length > 0
            }
          >
            {filteredMarkers?.map((marker, index) => (
              <Marker
                id={`cluster-marker-${index}`}
                key={`cluster-marker-${index}`}
                position={{
                  lat: parseFloat(marker.lat),
                  lng: parseFloat(marker.lng),
                }}
                icon={customMarkerIcon(marker)}
                onClick={() => handleMarkerClick(marker)}
              ></Marker>
            ))}
          </Show.When>

          <Show>
            <Show.When
              isTrue={
                !isMapLoading[index] &&
                isFilterActive &&
                !isWalkIndex &&
                filteredMarkers !== null &&
                typeof filteredMarkers !== 'string' &&
                mapTab !== 'Favorites'
              }
            >
              {filteredMarkers?.map((marker, index) => (
                <Marker
                  key={`cluster-marker-${index}`}
                  position={{
                    lat: parseFloat(marker.lat),
                    lng: parseFloat(marker.lng),
                  }}
                  icon={customMarkerIcon(marker)}
                  onClick={() => handleMarkerClick(marker)}
                ></Marker>
              ))}
            </Show.When>

            <Show.Else>
              <></>
            </Show.Else>
          </Show>

          <Show.When isTrue={!isWalkIndex && mapTab === 'Favorites'}>
            {favorites[getKeyName()]?.map((marker, index) => (
              <Marker
                key={`cluster-marker-${index}`}
                position={{
                  lat: parseFloat(marker.lat),
                  lng: parseFloat(marker.lng),
                }}
                icon={customMarkerIcon(marker)}
                onClick={() => handleMarkerClick(marker)}
              ></Marker>
            ))}
          </Show.When>
          <Show.When isTrue={!isMobile}>
            {renderPopup()}
          </Show.When>
        </GoogleMap>
        {/* <Show.When isTrue={currentMapInstance && isGoogleMapsLoaded}>
          <OpenStreetMapAttribution />
        </Show.When> */}
      </Show.When>

      <Show.When isTrue={!isMobile && showCompareLifeStyle}>
        <div className='lifestyle-compare-map-header'>
          <div className='house-icon-cotainer'>
            <Home />
            <span>A{getAddressCompareIndex() + 1}</span>
          </div>
          <Show.When isTrue={property && !isMapLoading[index]}>
            <div className='modern-address-sublocality'>{neighborhood}</div>

            <div className="view-map-right-container">

      {/* <Show.When
        isTrue={
          isFilterActive &&
          property &&
          !isEstablishmentSearchOpen &&
          !isMobile &&
          !isLoading &&
          !isMapLoading[index]
        }
      >
        <div className={`filter-result-container ${
            showCompareLifeStyle ? 'compare-filter-result-container' : ''
          }`}
        >
          <b>{filteredMarkers?.length} </b>
          gem{filteredMarkers?.length > 1 ? 's ' : ' '} found
        </div>
      </Show.When> */}

              <Show.When 
                isTrue={
                  !isWalkIndex &&
                  property &&
                  !isEstablishmentSearchOpen &&
                  !isMobile &&
                  !isLoading &&
                  !isMapLoading[index]}
              >
                <div className='view-map-container'
                >
                  <b>{filteredMarkers?.length} </b>
                  gem{filteredMarkers?.length > 1 ? 's ' : ' '} found
                </div>
              </Show.When>
              <div
                className='view-map-container'
                onClick={() => handleHighlightProperty()}
              >
                <Location2 />
                VIEW MAP
              </div>
            </div>
          </Show.When>
        </div>
        <Show.When isTrue={!isMapLoading[index] && !isMobile && !compareLifeStyleAddress}>
          <div
            className={`lifestly-compare-map-footer ${
              showCombineSearch &&
              hasType(true) &&
              hasType(false) &&
              hasData() &&
              'lifestly-compare-combine-search'
            }`}
          >
            <Show>
              <Show.When
                isTrue={
                  showCombineSearch &&
                  hasType(true) &&
                  hasType(false) &&
                  hasData()
                }
              >
                {renderFinalScore(false)}
              </Show.When>
              <Show.Else>
                <Show>
                  <Show.When isTrue={hasData()}>
                    <div className='property-score-container'>
                      <div
                        className={`custom-button-icon modern-custom-button-icon
                        ${!isWalkIndex && 'modern-lifestyle-custom-button'}`}
                      >
                        <Show>
                          <Show.When isTrue={isWalkIndex}>
                            {getFinalNeigborhoodScore()}
                          </Show.When>
                          <Show.Else>
                            <span>{getFinalNeigborhoodScore()}</span>
                          </Show.Else>
                        </Show>
                        <Show>
                          <Show.When isTrue={isWalkIndex}>
                            <div
                              className='icon-score-cirlce'
                              data-score-value={getFinalNeigborhoodScore().toString()}
                              style={scoreCircleStyle(
                                getFinalNeigborhoodScore()
                              )}
                            ></div>
                          </Show.When>
                          <Show.Else>
                            <div
                              className='score-diamond'
                              data-score-value={getFinalNeigborhoodScore().toString()}
                            >
                              <ModernGem />
                            </div>
                          </Show.Else>
                        </Show>
                      </div>
                      <Show.When isTrue={hasData()}>
                        <div className='gem-score-container'>
                          <b>{getOverallScoreLabel()}</b>
                          <hr />
                          <div>{getOverallScoreWording()}</div>
                          <hr />
                        </div>
                      </Show.When>
                    </div>
                  </Show.When>
                  <Show.Else>
                    <Show>
                      <Show.When isTrue={isMapLoading[index]}>
                        Loading...
                      </Show.When>
                      <Show.Else>
                        <div>No Data Available for this Neighborhood</div>
                      </Show.Else>
                    </Show>
                  </Show.Else>
                </Show>
              </Show.Else>
            </Show>
            <Show.When isTrue={hasData()}>
              <Show>
                <Show.When isTrue={!hasType(true) && !hasType(false)}>
                  <div
                    className={`type-scores ${
                      !isWalkIndex && 'gem-type-scores'
                    }`}
                  >
                    {renderCustomButtons(true)}
                  </div>
                </Show.When>
                <Show.Else>
                  <Show>
                    <Show.When isTrue={hasType(false) && !hasType(true)}>
                      {/* render the score of the selected feature of combine with feel/gem */}
                      {renderFinalScore(false)}
                    </Show.When>
                    <Show.Else>
                      <Show>
                        <Show.When isTrue={currentLifestyleType.length > 1}>
                          <Show.When isTrue={isWalkIndex}>
                            <div
                              className={`type-scores ${
                                !isWalkIndex && 'gem-type-scores'
                              }`}
                            >
                              {renderCustomButtons(false)}
                            </div>
                          </Show.When>
                          <Show.When isTrue={!isWalkIndex}>
                            <div
                              className={`type-scores ${
                                !isWalkIndex && 'gem-type-scores'
                              }`}
                            >
                              {renderCustomButtons(true)}
                            </div>
                          </Show.When>
                        </Show.When>
                        <Show.Else>
                          <Show.When isTrue={hasType(true)}>
                            {/* render the score of selected feature of the currentTab */}
                            {renderFinalScore(true)}
                          </Show.When>
                        </Show.Else>
                      </Show>
                    </Show.Else>
                  </Show>
                </Show.Else>
              </Show>
            </Show.When>
          </div>
        </Show.When>
      </Show.When>

      <Show>
        <Show.When isTrue={isMobile}>
          <div className={`mobile-navigation-controls ${
            isMobile &&
            showCompareLifeStyle &&
            'mobile-compare-navigation-controls'
          }`}>
            <div onClick={() => toggleMapType()}>
              {mapType === 'roadmap' ? <Map /> : <Satellite />}
            </div>
            <div onClick={() => goToCenter()}>
              <Target />
            </div>
          </div>
        </Show.When>
        <Show.Else>
          <Show.When isTrue={!showCompareLifeStyle && !isMapLoading[index] && !isEstablishmentSearchOpen}>
            <div
              className={`map-tools ${
                isEstablishmentSearchOpen && isMobile && 'quick-search-map-controls'
              } ${
                isModern && showCompareLifeStyle && 'modern-lifestyle-map-controls'
              }`}
            >
              {/* {(isWalkIndex || currentWalkindxType !== null) && <FilterWalkindex />} */}
              <div
                className={`map-right-controls ${
                  showCompareLifeStyle || isMobile ? 'map-controls-small' : ''
                }`}
              >
                {/* <div
                  data-tooltip-id={`street-view${index ? ('-' + index) : '' }`}
                  className='street-view-toggle'
                  onClick={() => toggleStreetView()}
                >
                  <img src={StreetViewIcon} alt='Toggle Street View' width='18px' />
                </div> */}

                <div
                  className={`map-control-wrapper
                    ${
                      mapTab === 'Favorites' && showCompareLifeStyle
                        ? 'compare-fixed-height-buttons-container'
                        : !isModern && 'fixed-height-buttons-container'
                    } ${isModern && 'modern-map-control-wrapper'} ${
                    isModern &&
                    showCompareLifeStyle &&
                    'modern-lifestyle-map-control-wrapper'
                  }`}
                >
                  <Show.When isTrue={mapTab !== 'Favorites' && !isWalkIndex && !isMobile}>
                    <div
                      className={`map-control-btn quick-search-btn ${
                        showFilter ? 'open' : ''
                      }`}
                      data-tooltip-id="filter"
                      onClick={() => openFilter()}
                    >
                      <Filter />
                    </div>
                  </Show.When>
                  <Show.When isTrue={!isMobile}>
                    <div
                      className={`map-control-btn quick-search-btn ${
                        showMapQS ? 'open' : ''
                      } ${
                        isLoading ? 'btn-loading' : ''
                      }`}
                      data-tooltip-id="qs-filter"
                      onClick={() => openQuickSearch()}
                    >
                      <QuickSearch />
                    </div>
                  </Show.When>
                  <Show.When isTrue={!isMobile}>
                    <Tooltip
                      className='map-control-btn-popup'
                      id={`street-view${index ? '-' + index : ''}`}
                      place='left'
                    >
                      Street View
                    </Tooltip>
                    <Show.When isTrue={!showPin}>
                      <Tooltip
                        className='map-control-btn-popup'
                        id={`rating${index ? '-' + index : ''}`}
                        place='top'
                      >
                        Rating
                      </Tooltip>
                    </Show.When>
                    <Tooltip
                      id='qs-filter'
                      place='top'
                    >
                      Quick Search
                    </Tooltip>
                    <Tooltip
                      id='filter'
                      place='top'
                    >
                      Filter
                    </Tooltip>
                    <Show.When isTrue={!isModern && !showSpeedDial}>
                      <Tooltip
                        className='map-control-btn-popup'
                        id='activate-walkindx'
                        place='top'
                      >
                        Activate Neighborhood Feel
                      </Tooltip>
                    </Show.When>
                    <Tooltip
                      className='map-control-btn-popup'
                      id='feedback-form'
                      place='top'
                    >
                      Feedback Form
                    </Tooltip>
                    <Tooltip
                      className='map-control-btn-popup'
                      id='expand-map'
                      place='top'
                    >
                      Expand Map
                    </Tooltip>
                    <Tooltip
                      className='map-control-btn-popup'
                      id='establishments'
                      place={showCompareLifeStyle ? 'left' : 'top'}
                    >
                      Establishments
                    </Tooltip>
                    <Tooltip
                      className='map-control-btn-popup'
                      id='change-location'
                      place='top'
                    >
                      Change Location
                    </Tooltip>
                    <Tooltip
                      id='center-map'
                      place='top'
                    >
                      Center Map
                    </Tooltip>
                    <Tooltip
                      id={`change-view${index ? '-' + index : ''}`}
                      place={showCompareLifeStyle ? 'left' : 'top'}
                    >
                      {mapType === 'roadmap' ? 'Map' : 'Satellite'} View
                    </Tooltip>
                    <Tooltip
                      className='map-control-btn-popup'
                      id='fullscreen-map'
                      place='top'
                    >
                      {isFullScreenMap ? 'Minimize' : 'Fullscreen'} Map
                    </Tooltip>
                    <Tooltip id='zoom-in-map' place='top'>Zoom In</Tooltip>
                    <Tooltip id='zoom-out-map' place='top'>Zoom Out</Tooltip>
                  </Show.When>

                  <Show.When isTrue={isModern && !isMobile}>
                    <div className='map-control-btn' data-tooltip-id="center-map" onClick={goToCenter}>
                      <Target />
                    </div>
                    <div className='zoom-control-wrapper'>
                      <div
                        className='map-control-btn'
                        data-tooltip-id="zoom-in-map"
                        onClick={() => handleZoomClick(zoom + 0.5)}
                      >
                        <PlusIcon />
                      </div>

                      <div
                        className='map-control-btn'
                        data-tooltip-id="zoom-out-map"
                        onClick={() => handleZoomClick(zoom - 0.5)}
                      >
                        <MinusIcon />
                      </div>
                    </div>
                    <div
                      data-tooltip-id={`change-view${index ? '-' + index : ''}`}
                      className='map-control-btn change-view-btn'
                      onClick={() => toggleMapType()}
                    >
                      {mapType === 'roadmap' ? <Map /> : <Satellite />}
                    </div>
                  </Show.When>

                  <Show.When isTrue={!isWalkIndex}>
                    {/* <Show.When
                      isTrue={filteredMarkers?.length && mapTab !== 'Favorites'}
                    >
                      <div
                        data-tooltip-id='establishments'
                        onClick={toggleShowEstablishments}
                      >
                        <div className='establishment-number'>
                          {filteredMarkers?.length}
                        </div>
                      </div>
                    </Show.When> */}
                  </Show.When>

                  {/* <Show.When isTrue={!isMobile}>
                    <div
                      data-tooltip-id='fullscreen-map'
                      className='map-control-btn fullscreen-map'
                      onClick={() => toggleFullScreenMap()}
                    >
                      {isFullScreenMap ? <Minimize /> : <Fullscreen />}
                    </div>
                  </Show.When> */}
                </div>
              </div>
            </div>
          </Show.When>
        </Show.Else>
      </Show>

      <Show.When isTrue={!property && !showSearchAddress}>
        <div
          className={`empty-map-container ${
            isModern && 'modern-empty-map-container'
          }`}
        >
          <Show>
            <Show.When isTrue={isModern}>
              <ComparePlaceholder />
              <div>
                {isMobile
                  ? 'enter the address at the top for comparison'
                  : 'add an address on the left panel for comparison'
                }
              </div>
            </Show.When>
            <Show.Else>
              <div className='compare-address-number'>A{index + 1}</div>
              <div className='empty-input'>
                <AddressInput
                  properties={filteredProperties()}
                  index={index}
                  handleClick={handleAddressClick}
                />
              </div>
            </Show.Else>
          </Show>
        </div>
      </Show.When>

      <Show.When isTrue={isSearchable && showProperties}>
        <PropertyList
          currentProperty={property}
          currentView={currentView}
          properties={properties}
          handleClick={handleAddressClick}
          index={index}
          showListProperties={setShowProperties}
          showCompareLifeStyle={showCompareLifeStyle}
        />
      </Show.When>

      <Show.When isTrue={isMobile}>
        <MarkerDetails
          index={index}
          marker={markerDetails}
          categories={markerCategories}
          currentMarker={currentMarker[index]}
          currentAddress={currentAddress}
          currentView={currentView}
          isLoading={isLoading}
          isMobile={isMobile}
          directionDetails={directionDetails}
          actions={actions}
          dispatch={dispatch}
          getThumbnail={getThumbnail}
          setCurrentMarker={setCurrentMarker}
          setMarkerDetails={setMarkerDetails}
          handleFavorite={handleFavorite}
          isFavoriteChecked={isFavoriteChecked}
          calculateDistance={calculateDistance}
        />
      </Show.When>

      <Show.When isTrue={property && (gemMarkersLoading || isMapLoading[index])}>
        <WalkingLoader />
      </Show.When>
    </div>
  );
};

export default MapContainer;

const getImage = (type) => {
  switch (type) {
    case 'food':
      return Food;
    case 'shops':
      return Shop;
    case 'services':
      return Services;
    case 'transit':
      return Access;
    case 'leisure':
      return Entertainment;
    default:
      return Food;
  }
};

const getThumbnail = (type) => {
  switch (type) {
    case 'food':
      return FoodThumbnail;
    case 'shops':
      return ShopThumbnail;
    case 'services':
      return ServicesThumbnail;
    case 'transit':
      return TransitThumbnail;
    case 'leisure':
      return EntertainmentThumbnail;
    default:
      return Food;
  }
};

const getBgColor = (type) => {
  switch (type) {
    case 'food':
      return '#F26F01';
    case 'shops':
      return '#F5C500';
    case 'services':
      return '#96C9DF';
    case 'transit':
      return '#3AA7F0';
    case 'entertainment-fitness':
      return '#9A77CF';
    default:
      return '#F26F01';
  }
};

const MarkerContainer = ({
  key,
  text,
  type,
  rating,
  onMouseEnter,
  onMouseLeave,
  onClick,
  position,
  currentActive = false,
  mapType,
  toggleStreetView,
  index,
  showCompareLifeStyle,
}) => {
  return (
    <OverlayView
      key={key}
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      shouldRedraw={false}
      getPixelPositionOffset={(width, height) => ({
        x: -width - 44 / 2,
        y: -height - 100 / 2,
      })}
    >
      {!currentActive ? (
        <div
          className='marker'
          data-tooltip-id={`tooltip-marker-item-${key}`}
          style={{ backgroundColor: getBgColor(type) }}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onClick}
        >
          <img src={getImage(type)} alt={text} />
          <div className='marker-description'>
            <img src={Star} alt={text} />
            <span>{rating || '0'}</span>
          </div>

          {/* <Tooltip className='example' id={`tooltip-marker-item-${key}`} place='top'>
      {text}
    </Tooltip> */}
        </div>
      ) : (
        <div
          className={`active-marker ${
            mapType === 'satellite' ? 'white-active-marker' : ''
          }`}
          onClick={() => toggleStreetView()}
        >
          <AddressMarker />
          {showCompareLifeStyle && <span>{index + 1}</span>}
        </div>
      )}
    </OverlayView>
  );
};
