import React, { useState, useEffect, useRef, useContext } from 'react';
import { StoreContext } from '../../store';
import './index.css';

import { Rating } from 'react-simple-star-rating';

import { ReactComponent as HomeIcon } from '../../assets/home-page/home.svg';
import { ReactComponent as Luggage } from '../../assets/home-page/travel-luggage.svg';
import { ReactComponent as City } from '../../assets/home-page/city.svg';
import { ReactComponent as Search } from '../../assets/search.svg';
import { ReactComponent as Target } from '../../assets/target.svg';

import BannerPH from '../../assets/home-page/banner-ph.jpg';
import Banner from '../../assets/home-page/banner.png';

import {
  walkIndexFeatures,
  lifeStyleFeatures,
  partnersData,
  otherFeatures,
  impactData,
  neighborhoodIntelTabs,
  neighborhoodSearchFeatures,
} from '../../constants';

import { fetchPlaceDetails } from '../../api';
import { saveAddressToRecentlyViewed } from '../../services';

import Slider from 'react-slick';
import Show from '../../utility/Show';

const Home = () => {
  const { state, actions, dispatch, navigate } = useContext(StoreContext);
  const { isGoogleMapsLoaded, currentView, recentlyViewedAddresses, currentCity } = state;
  const [currentTab, setCurrentTab] = useState(0);
  const [predictions, setPredictions] = useState([]);
  const [showIframe, setShowIframe] = useState(false);
  const inputRef = useRef();
  let sliderRef = useRef(null);
  const iframeRef = useRef(null);
  const placeholderRef = useRef(null);

  const NeighborhoodIntelTabs = () => {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      swipe: false,
    };

    const handleTabClick = (index) => {
      setCurrentTab(index);
    };

    useEffect(() => {
      if (sliderRef) {
        sliderRef.slickGoTo(currentTab);
      }
    }, [currentTab]);

    return (
      <div className='neighborhood-intel-tabs'>
        <h1 className='landing-title'>Neighborhood Intelligence</h1>
        <div className='tabs'>
          {neighborhoodIntelTabs.map((tab, index) => (
            <div
              key={index}
              className={`tab ${currentTab === index ? 'active' : ''}`}
              onClick={() => handleTabClick(index)}
            >
              {tab.name}
            </div>
          ))}
        </div>
        <Slider
          ref={(slider) => {
            sliderRef = slider;
          }}
          {...settings}
        >
          {neighborhoodIntelTabs.map((tab, index) => (
            <div
              key={index}
              className={`tab-content ${tab.name === 'Scoring' && 'scoring'}`}
            >
              <Show>
                <Show.When isTrue={tab.name === 'Scoring'}>
                  <img src={tab.img1} alt={tab.name} />
                  <img src={tab.img2} alt={tab.name} />
                </Show.When>
                <Show.Else>
                  <img src={tab.img} alt={tab.name} />
                </Show.Else>
              </Show>
            </div>
          ))}
        </Slider>
      </div>
    );
  };

  const searchAddress = () => {
    if (isGoogleMapsLoaded) {
      const autocompleteService =
        new window.google.maps.places.AutocompleteService();
      if (autocompleteService && inputRef.current) {
        const inputText = inputRef.current.value;
        const city = currentCity ? `${currentCity}, ` : '';

        autocompleteService.getPlacePredictions(
          {
            input: city + inputText,
            types: ['geocode'], // You can specify the types of predictions you want
          },
          async (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
              try {
                // Fetch details for each prediction
                const detailedPredictions = await Promise.all(predictions.map(async (prediction) => {
                  try {
                    const details = await fetchPlaceDetails(prediction.place_id);
                    return {
                      ...prediction,
                      latitude: details.geometry.location.lat(),
                      longitude: details.geometry.location.lng(),
                    };
                  } catch (error) {
                    console.error(`Failed to fetch details for place_id ${prediction.place_id}:`, error);
                    return null;
                  }
                }));

                // Filter out any null results (in case some place details couldn't be fetched)
                const validPredictions = detailedPredictions.filter(prediction => prediction !== null);
                setPredictions(validPredictions);
              } catch (error) {
                console.error('Error fetching place details:', error);
                setPredictions([]);
              }
            } else {
              setPredictions([]);
            }
          }
        );
      }
    }
  };

  const replaceWithDashes = (str) => {
    // Replace non-alphanumeric characters with dashes
    let result = str.replace(/[^a-zA-Z0-9]/g, '-');
    // Replace multiple consecutive dashes with a single dash
    result = result.replace(/-+/g, '-');
    // Remove leading and trailing dashes if any
    result = result.replace(/^-|-$/g, '');
    return result;
  };

  const addressClick = async (address) => {
    actions.handleLogin();
    // actions.sendDataToGoogleAnalytics('used_address_search', {});
    // dispatch({ type: 'SET_SHOW_SEARCH_ADDRESS', payload: false });
    const data = await actions.fetchAddressData(address.description, false, true)

    saveAddressToRecentlyViewed(data, recentlyViewedAddresses, dispatch);

    dispatch({ type: 'SET_LOADING', payload: true });
    // await actions.fetchAddressData(address);
    // navigate(`/address/${replaceWithDashes(address.description)}`);
    const newAddress = `address/${replaceWithDashes(address.description)}`;
    const baseUrl = window.location.origin;
    window.location.replace(`${baseUrl}/${newAddress}`);
  };

  const handleSearchChange = (event) => {
    if (!event.target.value) {
      setPredictions([]);
    } else {
      searchAddress();
    }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          actions.handleLogin();
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          actions.fetchAddressByLatLng(lat, lng);
          navigate(`/plugin`);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setShowIframe(false);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 } // Adjust this value as needed
    );

    if (placeholderRef.current) {
      observer.observe(placeholderRef.current);
    }

    return () => {
      if (placeholderRef.current) {
        observer.unobserve(placeholderRef.current);
      }
    };
  }, []);

  const handleLoadFrame = () => {
    setTimeout(() => {
      setShowIframe(true);
    }, 1000);
  };

  const HomeBanner = () => {
    return (
      <div className='home-banner'>
        <Show.When isTrue={true}>
          <img
            ref={placeholderRef}
            className='banner-placeholder'
            src={Banner}
            alt='Loading video...'
          />
        </Show.When>
        {/* <iframe
          data-vimeo-defer
          ref={iframeRef}
          src="https://player.vimeo.com/video/949417324?autoplay=1&muted=1&loop=1&background=1"
          frameborder="0"
          allow="accelerometer; autoplay; fullscreen"
          onLoad={() => handleLoadFrame()}
        ></iframe> */}
        <div className='max-w-screen-lg'>
          <div className='banner-content '>
            <div className='slogan'>Discover your ideal neighborhood</div>
            <div className='search-address-input'>
              <div
                onClick={() => getLocation()}
                className='current-location-icon'
              >
                <Target />
              </div>
              <input
                ref={inputRef}
                onChange={(e) => handleSearchChange(e)}
                placeholder='Search for an address, location , cities ...'
              />
              <div className='modern-search-address-btn'>
                <Search />
              </div>
              <Show>
                <Show.When isTrue={predictions.length !== 0}>
                  <div
                    className={`prediction-container ${
                      currentView === 'Modern' && 'modern-prediction-container'
                    }`}
                  >
                    {predictions.map((prediction) => (
                      <div
                        className='prediction'
                        key={prediction.place_id}
                        onClick={() => addressClick(prediction)}
                      >
                        {prediction.description}
                      </div>
                    ))}
                  </div>
                </Show.When>
              </Show>
            </div>
            {/* <div className='features'>
              <a href=''>
                <HomeIcon />
                Real Estate
              </a>
              <a href=''>
                <Luggage />
                Travel
              </a>
              <a href=''>
                <City />
                Smart City
              </a>
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  const NeighborhoodSearch = () => {
    return (
      <div className='home-neighborhood-search'>
        <div className='max-w-screen-lg'>
          <div className='title'>neighborhood search</div>
          <div className='ns-features'>
            {neighborhoodSearchFeatures.map((feature, index) => (
              <div>
                <div className='ns-feature-title'>{feature.name}</div>
                <div className='ns-feature-desc'>{feature.description}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const Measurements = () => {
    return (
      <div className='measurements-container'>
        <div className='max-w-screen-lg'>
          <h1 className='measurement-title'>What We Measure</h1>
          <section className='measurements'>
            <h2>Neighborhood Feel</h2>
            <span>features important to the experience of walking</span>
            <div className='measure-list'>
              {walkIndexFeatures.map((measure, index) => (
                <div key={index} className='measure-item'>
                  {measure.svg}
                  <p>{measure.name}</p>
                </div>
              ))}
            </div>
          </section>
          <section className='gems'>
            <h2>Neighborhood Gems</h2>
            <span>lifestyle essentials to increase quality of life</span>
            <div className='gems-list'>
              {lifeStyleFeatures
                .slice(1, lifeStyleFeatures.length - 1)
                .map((gem, index) => (
                  <div key={index} className='gem-item'>
                    {gem.svg}
                    <p>{gem.name}</p>
                  </div>
                ))}
            </div>
          </section>
        </div>
      </div>
    );
  };

  const OtherFeatures = () => {
    return (
      <section className='other-features'>
        <div className='max-w-screen-lg'>
          <h1 className='landing-title'>Other Features</h1>
          <div className='features-list'>
            {otherFeatures.map((feature, index) => (
              <div key={index} className='other-feature-item'>
                <Show>
                  <Show.When isTrue={feature.name === 'Ratings'}>
                    <Rating
                      className='ratings-icon'
                      initialValue={4}
                      iconsCount={5}
                      fillColor='#FFFFFF'
                      readonly
                    />
                  </Show.When>
                  <Show.Else>{feature.img}</Show.Else>
                </Show>
                <p>{feature.name}</p>
              </div>
            ))}
          </div>

          <button className='landing-view-btn'>Explore all features</button>
        </div>
      </section>
    );
  };

  const ImpactStartup = () => {
    return (
      <section className='impact-startup max-w-screen-lg'>
        <h1 className='landing-title'>WALKSPAN IS AN IMPACT STARTUP</h1>
        <div className='description'>
          Our business is designed to solve environmental and societal problems.
          Buying homes where lifestyle essentials are within walking reach will
          generate positive sustainable impacts.
        </div>
        <div className='impact-list'>
          {impactData.map((impact, index) => (
            <div key={index} className='impact-item'>
              <img src={impact.img} alt='Impact' />
              <p>{impact.description}</p>
            </div>
          ))}
        </div>
      </section>
    );
  };

  const Partners = () => {
    return (
      <section className='partners'>
        <div className='max-w-screen-lg'>
          <h1 className='landing-title'>Our Partners</h1>
          <div className='partners-list'>
            {partnersData.map((partner, index) => (
              <div key={index} className='partner-item'>
                <img src={partner.img} alt={partner.name} />
                <h3>{partner.name}</h3>
                <p>{partner.description}</p>
                <a
                  href={partner.link}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='landing-view-btn'
                >
                  Visit Website
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  };

  return (
    <div className='landing-home-container'>
      {HomeBanner()}
      {NeighborhoodSearch()}
      {NeighborhoodIntelTabs()}
      {Measurements()}
      {OtherFeatures()}
      <main>
        {ImpactStartup()}
        {/* {Partners()} */}
      </main>
    </div>
  );
};

export default Home;
