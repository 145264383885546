export const architectureList = [
    {
      name: 'Grand Central Station to Carnegie Hall',
      coordinates: [
        [40.752328, -73.977543],
        [40.752232878, -73.977700549],
        [40.75245857, -73.978245609],
        [40.752524932, -73.978378197],
        [40.753186119, -73.977897752],
        [40.753683411, -73.97753967],
        [40.753794271, -73.977482644],
        [40.754326782, -73.977084492],
        [40.754432203, -73.977001128],
        [40.754952121, -73.976619408],
        [40.755272327, -73.977357602],
        [40.755359915, -73.977291577],
        [40.755359915, -73.977291577],
        [40.755984361, -73.9768362],
        [40.755984361, -73.9768362],
        [40.756579925, -73.976635465],
        [40.756700911, -73.976550342],
        [40.75723888, -73.976190944],
        [40.757322744, -73.976094662],
        [40.75783507, -73.975727714],
        [40.758451824, -73.977126154],
        [40.75854939, -73.977074173],
        [40.75854939, -73.977074173],
        [40.759160802, -73.976613566],
        [40.759160802, -73.976613566],
        [40.759796025, -73.976161825],
        [40.759796025, -73.976161825],
        [40.760335615, -73.97576846],
        [40.760497525, -73.975897643],
        [40.760497525, -73.975897643],
        [40.761124522, -73.97545158],
        [40.761754467, -73.974982999],
        [40.761754467, -73.974982999],
        [40.762383973, -73.974544544],
        [40.762942913, -73.974126741],
        [40.764196043, -73.977126034],
        [40.764278273, -73.977366256],
        [40.765396789, -73.979974657],
        [40.765368, -73.980085],
        [40.765412461, -73.980013801]
      ],
      directions: [
        { description: 'Start at Grand Central Branch New York, NY 10178', miles: '', time: '' },
        { description: 'Turn left and continue on E 42 St', miles: '0.01 miles', time: '0.18 mins' },
        { description: 'Turn right and continue on Vanderbilt Av', miles: '0.19 miles', time: '3.66 mins' },
        { description: 'Turn left and continue on E 46 St', miles: '0.05 miles', time: '0.98 mins' },
        { description: 'Turn right and continue on Madison Av', miles: '0.06 miles', time: '1.07 mins' },
        { description: 'Continue on E 47 St', miles: '0.01 miles', time: '0.25 mins' },
        { description: 'Turn right and continue on Madison Av', miles: '0.14 miles', time: '2.68 mins' },
        { description: 'Turn left and continue on E 50 St', miles: '0.10 miles', time: '1.87 mins' },
        { description: 'Continue on 5 Av', miles: '0.15 miles', time: '2.84 mins' },
        { description: 'Turn right and continue on W 53 St', miles: '0.01 miles', time: '0.25 mins' },
        { description: 'Turn right and continue on 5 Av', miles: '0.19 miles', time: '3.70 mins' },
        { description: 'Turn left and continue on W 57 St', miles: '0.40 miles', time: '7.74 mins' },
        { description: 'Finish at 200 West 57th Street New York, NY 10019', miles: '', time: '' }
      ]
    },
    {
      name: 'Grand Central to Time Warner Building',
      coordinates: [
        [40.752328, -73.977543],
        [40.752232878, -73.977700549],
        [40.75245857, -73.978245609],
        [40.752524932, -73.978378197],
        [40.753186119, -73.977897752],
        [40.753683411, -73.97753967],
        [40.753794271, -73.977482644],
        [40.754326782, -73.977084492],
        [40.754432203, -73.977001128],
        [40.754952121, -73.976619408],
        [40.755272327, -73.977357602],
        [40.755359915, -73.977291577],
        [40.755359915, -73.977291577],
        [40.755984361, -73.9768362],
        [40.755984361, -73.9768362],
        [40.756579925, -73.976635465],
        [40.756700911, -73.976550342],
        [40.75723888, -73.976190944],
        [40.757322744, -73.976094662],
        [40.75783507, -73.975727714],
        [40.758451824, -73.977126154],
        [40.75854939, -73.977074173],
        [40.75854939, -73.977074173],
        [40.759160802, -73.976613566],
        [40.759253125, -73.976807548],
        [40.759747, -73.978013],
        [40.759764863, -73.977981863]
      ],
      directions: [
        { description: 'Start at Grand Central Branch New York, NY 10178', miles: '', time: '' },
        { description: 'Turn left and continue on E 42 St', miles: '0.01 miles', time: '0.18 mins' },
        { description: 'Turn right and continue on Vanderbilt Av', miles: '0.19 miles', time: '3.66 mins' },
        { description: 'Turn left and continue on E 46 St', miles: '0.05 miles', time: '0.98 mins' },
        { description: 'Turn right and continue on Madison Av', miles: '0.06 miles', time: '1.07 mins' },
        { description: 'Continue on E 47 St', miles: '0.01 miles', time: '0.25 mins' },
        { description: 'Turn right and continue on Madison Av', miles: '0.14 miles', time: '2.68 mins' },
        { description: 'Turn left and continue on E 50 St', miles: '0.10 miles', time: '1.87 mins' },
        { description: 'Turn right and continue on 5 Av', miles: '0.06 miles', time: '1.07 mins' },
        { description: 'Turn left and continue on E 51 St', miles: '0.01 miles', time: '0.26 mins' },
        { description: 'Finish at West 51st Street New York, NY 10019', miles: '', time: '' }
      ]
    },
    {
        name: 'Grand Central to Morgan Library',
        coordinates: [
          [40.752328, -73.977543],
          [40.752232878, -73.977700549],
          [40.75245857, -73.978245609],
          [40.75285766, -73.979124917],
          [40.75285766, -73.979124917],
          [40.752150145, -73.979656481],
          [40.752150145, -73.979656481],
          [40.751522722, -73.980092078],
          [40.751522722, -73.980092078],
          [40.750911431, -73.980562664],
          [40.750287202, -73.981004579],
          [40.750287202, -73.981004579],
          [40.749673157, -73.981455221],
          [40.749673157, -73.981455221],
          [40.749331, -73.981662],
          [40.749340025, -73.981710232]
        ],
        directions: [
          { description: 'Start at Grand Central Branch New York, NY 10178', miles: '', time: '' },
          { description: 'Turn left and continue on E 42 St', miles: '0.06 miles', time: '1.18 mins' },
          { description: 'Continue on Madison Av', miles: '0.25 miles', time: '4.84 mins' },
          { description: 'Finish at 225 Madison Avenue New York, NY 10016', miles: '', time: '' }
        ]
      },
      {
        name: 'Union Square to Washington Square Park',
        coordinates: [
          [40.735281, -73.990794],
          [40.735236324, -73.991066812],
          [40.73509841, -73.991671981],
          [40.734558752, -73.992161468],
          [40.734558752, -73.992161468],
          [40.734602355, -73.992297576],
          [40.733995257, -73.992834536],
          [40.733919149, -73.992918416],
          [40.733375935, -73.993374425],
          [40.733375935, -73.993374425],
          [40.732799754, -73.993853155],
          [40.732721382, -73.993909371],
          [40.732241129, -73.994339906],
          [40.73214228, -73.994408391],
          [40.731677545, -73.994815227],
          [40.731588979, -73.994922621],
          [40.731280574, -73.995204749],
          [40.730816075, -73.995593128],
          [40.730945, -73.997358],
          [40.731248654, -73.996760026]
        ],
        directions: [
          { description: 'Start at George Washington New York, NY 10003', miles: '', time: '' },
          { description: 'Turn right and continue on Union Sq W', miles: '0.04 miles', time: '0.71 mins' },
          { description: 'Turn right and continue on University Pl', miles: '0.05 miles', time: '0.89 mins' },
          { description: 'Continue on E 13 St', miles: '0.01 miles', time: '0.17 mins' },
          { description: 'Turn right and continue on University Pl', miles: '0.32 miles', time: '6.18 mins' },
          { description: 'Finish at Washington Square Fountain New York, NY 10012', miles: '', time: '' }
        ]
      },
      {
        name: 'Washington Square Park to Chelsea Market',
        coordinates: [
          [40.730945, -73.997358],
          [40.731248654, -73.996760026],
          [40.732146893, -73.99858455],
          [40.732286939, -73.998614741],
          [40.732931801, -73.999928333],
          [40.733026886, -74.00013448],
          [40.733465023, -74.001052183],
          [40.733813843, -74.001136263],
          [40.733896628, -74.001166322],
          [40.734533058, -74.001448934],
          [40.735083235, -74.00161778],
          [40.735314344, -74.001713792],
          [40.735455022, -74.001618091],
          [40.735455022, -74.001618091],
          [40.735813026, -74.001702378],
          [40.736456308, -74.001320309],
          [40.737118026, -74.001525721],
          [40.737199937, -74.001570225],
          [40.737738226, -74.001898485],
          [40.737727445, -74.001712969],
          [40.738664708, -74.002229866],
          [40.738698843, -74.00245138],
          [40.738830669, -74.002533787],
          [40.739204939, -74.002795137],
          [40.739204939, -74.002795137],
          [40.739629769, -74.002450917],
          [40.739629769, -74.002450917],
          [40.739778535, -74.002352313],
          [40.740455828, -74.002180927],
          [40.740455828, -74.002180927],
          [40.741543904, -74.004778997],
          [40.741643581, -74.005017593],
          [40.742264, -74.006283],
          [40.742285585, -74.00624685]
        ],
        directions: [
          { description: 'Start at Washington Square Fountain New York, NY 10012', miles: '', time: '' },
          { description: 'Turn left and continue on Mac Dougal St', miles: '0.01 miles', time: '0.13 mins' },
          { description: 'Turn left and continue on Waverly Pl', miles: '0.27 miles', time: '5.19 mins' },
          { description: 'Turn right and continue on 7 Av S', miles: '0.02 miles', time: '0.42 mins' },
          { description: 'Continue on Waverly Pl', miles: '0.02 miles', time: '0.45 mins' },
          { description: 'Turn right and continue on 7 Av S', miles: '0.06 miles', time: '1.10 mins' },
          { description: 'Turn left and continue on Greenwich Av', miles: '0.08 miles', time: '1.54 mins' },
          { description: 'Continue on W 12 St', miles: '0.01 miles', time: '0.23 mins' },
          { description: 'Turn left and continue on Greenwich Av', miles: '0.07 miles', time: '1.31 mins' },
          { description: 'Turn left and continue on W 13 St', miles: '0.01 miles', time: '0.27 mins' },
          { description: 'Turn left and continue on Greenwich Av', miles: '0.04 miles', time: '0.75 mins' },
          { description: 'Turn right and continue on 8 Av', miles: '0.09 miles', time: '1.74 mins' },
          { description: 'Turn left and continue on W 15 St', miles: '0.21 miles', time: '4.08 mins' },
          { description: 'Turn left and continue on 9 Ave', miles: '0.01 miles', time: '0.15 mins' },
          { description: 'Finish at 425 West 15th Street New York, NY 10011', miles: '', time: '' }
        ]
      },
      {
        name: 'Washington Square Park to Katz Deli',
        coordinates: [
          [40.730945, -73.997358],
          [40.731248654, -73.996760026],
          [40.691225, -73.982309],
          [40.690624016, -73.982699818]
        ],
        directions: [
          { description: 'Start at Washington Square Fountain New York, NY 10012', miles: '', time: '' },
          { description: 'Finish at 10 Flatbush Avenue Extension New York, NY 11201', miles: '', time: '' }
        ]
      },
      {
        name: 'Central Park South to Metropolitan Museum of Art',
        coordinates: [
          [40.765877, -73.97626],
          [40.765750764, -73.976114442],
          [40.764746318, -73.973727446],
          [40.764617877, -73.973500558],
          [40.764259064, -73.972882196],
          [40.764924826, -73.972429697],
          [40.765056347, -73.972542093],
          [40.765625057, -73.972147047],
          [40.76626256, -73.971688958],
          [40.766897161, -73.971240115],
          [40.767519654, -73.970799836],
          [40.768041119, -73.970431013],
          [40.7686497, -73.969951189],
          [40.769273762, -73.969465861],
          [40.769902262, -73.96900627],
          [40.770508112, -73.968566277],
          [40.771145259, -73.968103554],
          [40.771778098, -73.967643959],
          [40.772409293, -73.967185559],
          [40.773231546, -73.966582751],
          [40.773863695, -73.966125767],
          [40.774502905, -73.965663679],
          [40.775130277, -73.965210149],
          [40.775779115, -73.964741101],
          [40.776417264, -73.96427978],
          [40.77711534, -73.963779117],
          [40.777652394, -73.963347029],
          [40.778294476, -73.962896975],
          [40.779053, -73.96246],
          [40.779037296, -73.962363881]
        ],
        directions: [
          { description: 'Start at Central Park Outer Loop New York, NY 10105', miles: '', time: '' },
          { description: 'Turn left and continue on Central Park S', miles: '0.21 miles', time: '4.10 mins' },
          { description: 'Turn left and continue on E 59 St', miles: '0.01 miles', time: '0.27 mins' },
          { description: 'Turn right and continue on 5 Av', miles: '0.05 miles', time: '0.98 mins' },
          { description: 'Turn left and continue on C P E 60 Appr', miles: '0.01 miles', time: '0.21 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.69 mins' },
          { description: 'Turn right and continue on E 61 St', miles: '0.01 miles', time: '0.16 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.79 mins' },
          { description: 'Turn right and continue on E 62 St', miles: '0.01 miles', time: '0.17 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.76 mins' },
          { description: 'Turn right and continue on E 63 St', miles: '0.01 miles', time: '0.19 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.75 mins' },
          { description: 'Turn right and continue on E 64 St', miles: '0.01 miles', time: '0.18 mins' },
          { description: 'Continue on 5 Av', miles: '0.14 miles', time: '2.69 mins' },
          { description: 'Turn right and continue on E 67 St', miles: '0.01 miles', time: '0.15 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.80 mins' },
          { description: 'Turn right and continue on E 68 St', miles: '0.01 miles', time: '0.14 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.78 mins' },
          { description: 'Turn right and continue on E 69 St', miles: '0.01 miles', time: '0.17 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.79 mins' },
          { description: 'Turn right and continue on E 70 St', miles: '0.01 miles', time: '0.13 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.83 mins' },
          { description: 'Turn right and continue on E 71 St', miles: '0.01 miles', time: '0.19 mins' },
          { description: 'Continue on 5 Av', miles: '0.14 miles', time: '2.72 mins' },
          { description: 'Turn right and continue on E 74 St', miles: '0.01 miles', time: '0.13 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.80 mins' },
          { description: 'Turn right and continue on E 75 St', miles: '0.01 miles', time: '0.16 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.80 mins' },
          { description: 'Turn right and continue on E 76 St', miles: '0.01 miles', time: '0.15 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.83 mins' },
          { description: 'Turn right and continue on E 77 St', miles: '0.01 miles', time: '0.15 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.81 mins' },
          { description: 'Turn right and continue on E 78 St', miles: '0.01 miles', time: '0.15 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.80 mins' },
          { description: 'Turn right and continue on 79 St Transverse', miles: '0.01 miles', time: '0.25 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.84 mins' },
          { description: 'Turn right and continue on E 80 St', miles: '0.01 miles', time: '0.19 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.77 mins' },
          { description: 'Turn right and continue on E 81 St', miles: '0.01 miles', time: '0.19 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.77 mins' },
          { description: 'Finish at Central Park Outer Loop New York, NY 10075', miles: '', time: '' }
        ]
      },
      {
        name: 'Central Park South to Lincoln Center',
        coordinates: [
          [40.765877, -73.97626],
          [40.765838648, -73.976297687],
          [40.766939578, -73.978963052],
          [40.767889542, -73.981202132],
          [40.768503441, -73.981442515],
          [40.76923642, -73.980922517],
          [40.769844996, -73.980478976],
          [40.770009423, -73.980617676],
          [40.7707411, -73.982334463],
          [40.771102779, -73.983258607],
          [40.772438, -73.983484],
          [40.772292629, -73.98267392]
        ],
        directions: [
          { description: 'Start at Central Park Outer Loop New York, NY 10105', miles: '', time: '' },
          { description: 'Turn left and continue on Central Park S', miles: '0.34 miles', time: '6.49 mins' },
          { description: 'Turn left and continue on C P 8 Av Appr', miles: '0.04 miles', time: '0.80 mins' },
          { description: 'Turn right and continue on Central Park W', miles: '0.13 miles', time: '2.49 mins' },
          { description: 'Continue on W 62 St', miles: '0.09 miles', time: '1.75 mins' },
          { description: 'Turn left and continue on Broadway', miles: '0.03 miles', time: '0.53 mins' },
          { description: 'Turn left and continue on W 62 St', miles: '0.08 miles', time: '1.51 mins' },
          { description: 'Turn left and continue on Columbus Av', miles: '0.01 miles', time: '0.28 mins' },
          { description: 'Finish at Josie Robertson Plaza New York, NY 10023', miles: '', time: '' }
        ]
      }
  ];
  
export const comfortCategory = [
    {
      name: 'Grand Central Station to Carnegie Hall',
      coordinates: [
        [40.752328, -73.977543],
        [40.752232878, -73.977700549],
        [40.75245857, -73.978245609],
        [40.75285766, -73.979124917],
        [40.752916442, -73.979326517],
        [40.753364456, -73.980846669],
        [40.753457347, -73.981042768],
        [40.754721239, -73.984041718],
        [40.754871971, -73.983950085],
        [40.755422176, -73.983537447],
        [40.755505427, -73.983470448],
        [40.756144251, -73.983016262],
        [40.756765781, -73.982811563],
        [40.75686524, -73.982786943],
        [40.757383623, -73.982416133],
        [40.757485745, -73.982338089],
        [40.758598711, -73.984945905],
        [40.759196648, -73.984750854],
        [40.759301996, -73.984702092],
        [40.759420947, -73.984947311],
        [40.760129498, -73.984678432],
        [40.760793465, -73.984330617],
        [40.761448533, -73.983911999],
        [40.762088302, -73.983503373],
        [40.762717071, -73.98307154],
        [40.763389238, -73.982672471],
        [40.764038233, -73.982332298],
        [40.764731794, -73.982067299],
        [40.765468774, -73.981865983],
        [40.766145014, -73.981734166],
        [40.765368, -73.980085],
        [40.765412461, -73.980013801]
      ],
      directions: [
        { description: 'Start at Grand Central Branch New York, NY 10178', miles: '', time: '' },
        { description: 'Continue on E 42 St', miles: '0.07 miles', time: '1.44 mins' },
        { description: 'Turn left and continue on Madison Av', miles: '0.01 miles', time: '0.22 mins' },
        { description: 'Turn left and continue on W 42 St', miles: '0.10 miles', time: '1.89 mins' },
        { description: 'Turn left and continue on W 42 St', miles: '0.22 miles', time: '4.23 mins' },
        { description: 'Turn right and continue on Ave of the Americas', miles: '0.01 miles', time: '0.22 mins' },
        { description: 'Turn right and continue on Av of the Americas', miles: '0.04 miles', time: '0.84 mins' },
        { description: 'Turn right and continue on W 45 St', miles: '0.01 miles', time: '0.24 mins' },
        { description: 'Turn right and continue on W 47 St', miles: '0.02 miles', time: '0.33 mins' },
        { description: 'Continue on Broadway', miles: '0.23 miles', time: '4.51 mins' },
        { description: 'Finish at 200 West 57th Street New York, NY 10019', miles: '', time: '' }
      ]
    },
    {
      name: 'Grand Central to Time Warner Building',
      coordinates: [
        [40.752328, -73.977543],
        [40.752232878, -73.977700549],
        [40.75245857, -73.978245609],
        [40.75285766, -73.979124917],
        [40.753478037, -73.978918903],
        [40.754083936, -73.978457725],
        [40.754710246, -73.978003862],
        [40.755307962, -73.979432674],
        [40.75604663, -73.978888821],
        [40.756676601, -73.978429119],
        [40.757385889, -73.978157102],
        [40.758003479, -73.977740702],
        [40.759253125, -73.976807548],
        [40.759747, -73.978013],
        [40.759764863, -73.977981863]
      ],
      directions: [
        { description: 'Start at Grand Central Branch New York, NY 10178', miles: '', time: '' },
        { description: 'Continue on E 42 St', miles: '0.07 miles', time: '1.44 mins' },
        { description: 'Turn right and continue on Madison Av', miles: '0.14 miles', time: '2.72 mins' },
        { description: 'Turn left and continue on E 45 St', miles: '0.10 miles', time: '1.89 mins' },
        { description: 'Continue on 5 Av', miles: '0.15 miles', time: '2.83 mins' },
        { description: 'Turn right and continue on W 48 St', miles: '0.01 miles', time: '0.24 mins' },
        { description: 'Finish at West 51st Street New York, NY 10019', miles: '', time: '' }
      ]
    },
    {
        name: 'Grand Central to Morgan Library',
        coordinates: [
          [40.752328, -73.977543],
          [40.752232878, -73.977700549],
          [40.75245857, -73.978245609],
          [40.75285766, -73.979124917],
          [40.752150145, -73.979656481],
          [40.751522722, -73.980092078],
          [40.750911431, -73.980562664],
          [40.750287202, -73.981004579],
          [40.749673157, -73.981455221],
          [40.749331, -73.981662],
          [40.749340025, -73.981710232]
        ],
        directions: [
          { description: 'Start at Grand Central Branch New York, NY 10178', miles: '', time: '' },
          { description: 'Turn left and continue on E 42 St', miles: '0.06 miles', time: '1.18 mins' },
          { description: 'Continue on Madison Av', miles: '0.25 miles', time: '4.84 mins' },
          { description: 'Finish at 225 Madison Avenue New York, NY 10016', miles: '', time: '' }
        ]
      },
      {
        name: 'Union Square to Washington Square Park',
        coordinates: [
          [40.735281, -73.990794],
          [40.735236324, -73.991066812],
          [40.73509841, -73.991671981],
          [40.734558752, -73.992161468],
          [40.733938391, -73.992682232],
          [40.733328158, -73.993222246],
          [40.733375935, -73.993374425],
          [40.732799754, -73.993853155],
          [40.732721382, -73.993909371],
          [40.732241129, -73.994339906],
          [40.731677545, -73.994815227],
          [40.731280574, -73.995204749],
          [40.730816075, -73.995593128],
          [40.730945, -73.997358],
          [40.731248654, -73.996760026]
        ],
        directions: [
          { description: 'Start at George Washington New York, NY 10003', miles: '', time: '' },
          { description: 'Turn right and continue on Union Sq W', miles: '0.04 miles', time: '0.71 mins' },
          { description: 'Turn right and continue on University Pl', miles: '0.15 miles', time: '2.87 mins' },
          { description: 'Continue on E 11 St', miles: '0.01 miles', time: '0.19 mins' },
          { description: 'Turn right and continue on University Pl', miles: '0.22 miles', time: '4.18 mins' },
          { description: 'Finish at Washington Square Fountain New York, NY 10012', miles: '', time: '' }
        ]
      },
      {
        name: 'Washington Square Park to Chelsea Market',
        coordinates: [
          [40.730945, -73.997358],
          [40.731248654, -73.996760026],
          [40.732146893, -73.99858455],
          [40.732286939, -73.998614741],
          [40.732931801, -73.999928333],
          [40.733026886, -74.00013448],
          [40.733465023, -74.001052183],
          [40.733538697, -74.001180814],
          [40.733685841, -74.001495407],
          [40.733686852, -74.001797623],
          [40.733710765, -74.002625241],
          [40.733884075, -74.002798061],
          [40.734305255, -74.002933296],
          [40.734941234, -74.003109289],
          [40.735502002, -74.003273705],
          [40.736115564, -74.003551456],
          [40.736792398, -74.003743658],
          [40.737494464, -74.003909169],
          [40.737490079, -74.005045982],
          [40.737605608, -74.005578938],
          [40.738094497, -74.005502002],
          [40.739359216, -74.005285604],
          [40.739470789, -74.005272436],
          [40.739916878, -74.00518286],
          [40.740040355, -74.005187037],
          [40.740726115, -74.005068838],
          [40.741543904, -74.004778997],
          [40.742264, -74.006283],
          [40.742285585, -74.00624685]
        ],
        directions: [
          { description: 'Start at Washington Square Fountain New York, NY 10012', miles: '', time: '' },
          { description: 'Turn left and continue on Mac Dougal St', miles: '0.01 miles', time: '0.13 mins' },
          { description: 'Continue on Waverly Pl', miles: '0.20 miles', time: '3.83 mins' },
          { description: 'Turn left and continue on Grove St', miles: '0.02 miles', time: '0.38 mins' },
          { description: 'Continue on Christopher St', miles: '0.06 miles', time: '1.08 mins' },
          { description: 'Turn left and continue on 7 Av S', miles: '0.01 miles', time: '0.15 mins' },
          { description: 'Turn left and continue on W 4 St', miles: '0.24 miles', time: '4.55 mins' },
          { description: 'Continue on W 12 St', miles: '0.11 miles', time: '2.05 mins' },
          { description: 'Turn right and continue on Hudson St', miles: '0.21 miles', time: '4.01 mins' },
          { description: 'Turn right and continue on 9 Av', miles: '0.04 miles', time: '0.83 mins' },
          { description: 'Continue on W 15 St', miles: '0.02 miles', time: '0.32 mins' },
          { description: 'Turn left and continue on 9 Ave', miles: '0.01 miles', time: '0.15 mins' },
          { description: 'Finish at 425 West 15th Street New York, NY 10011', miles: '', time: '' }
        ]
      },
      {
        name: 'Washington Square Park to Katz Deli',
        coordinates: [
          [40.730945, -73.997358],
          [40.731248654, -73.996760026],
          [40.691225, -73.982309],
          [40.690624016, -73.982699818]
        ],
        directions: [
          { description: 'Start at Washington Square Fountain New York, NY 10012', miles: '', time: '' },
          { description: 'Finish at 10 Flatbush Avenue Extension New York, NY 11201', miles: '', time: '' }
        ]
      },
      {
        name: 'Central Park South to Metropolitan Museum of Art',
        coordinates: [
          [40.765877, -73.97626],
          [40.765750764, -73.976114442],
          [40.764746318, -73.973727446],
          [40.76502011, -73.97316006],
          [40.764847491, -73.972768423],
          [40.765056347, -73.972542093],
          [40.765625057, -73.972147047],
          [40.76626256, -73.971688958],
          [40.766897161, -73.971240115],
          [40.767519654, -73.970799836],
          [40.768041119, -73.970431013],
          [40.7686497, -73.969951189],
          [40.769273762, -73.969465861],
          [40.769902262, -73.96900627],
          [40.770508112, -73.968566277],
          [40.771145259, -73.968103554],
          [40.771778098, -73.967643959],
          [40.772409293, -73.967185559],
          [40.773231546, -73.966582751],
          [40.773863695, -73.966125767],
          [40.774502905, -73.965663679],
          [40.775130277, -73.965210149],
          [40.775779115, -73.964741101],
          [40.776417264, -73.96427978],
          [40.77711534, -73.963779117],
          [40.777652394, -73.963347029],
          [40.778294476, -73.962896975],
          [40.779053, -73.96246]
        ],
        directions: [
          { description: 'Start at Central Park Outer Loop New York, NY 10105', miles: '', time: '' },
          { description: 'Turn left and continue on Central Park S', miles: '0.18 miles', time: '3.48 mins' },
          { description: 'Turn left and continue on Grand Army Plz', miles: '0.06 miles', time: '1.16 mins' },
          { description: 'Turn right and continue on 5 Av', miles: '0.05 miles', time: '1.07 mins' },
          { description: 'Turn right and continue on E 61 St', miles: '0.01 miles', time: '0.16 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.79 mins' },
          { description: 'Turn right and continue on E 62 St', miles: '0.01 miles', time: '0.17 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.76 mins' },
          { description: 'Turn right and continue on E 63 St', miles: '0.01 miles', time: '0.19 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.75 mins' },
          { description: 'Turn right and continue on E 64 St', miles: '0.01 miles', time: '0.18 mins' },
          { description: 'Continue on 5 Av', miles: '0.14 miles', time: '2.69 mins' },
          { description: 'Turn right and continue on E 67 St', miles: '0.01 miles', time: '0.15 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.80 mins' },
          { description: 'Turn right and continue on E 68 St', miles: '0.01 miles', time: '0.14 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.78 mins' },
          { description: 'Turn right and continue on E 69 St', miles: '0.01 miles', time: '0.17 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.79 mins' },
          { description: 'Turn right and continue on E 70 St', miles: '0.01 miles', time: '0.13 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.83 mins' },
          { description: 'Turn right and continue on E 71 St', miles: '0.01 miles', time: '0.19 mins' },
          { description: 'Continue on 5 Av', miles: '0.14 miles', time: '2.72 mins' },
          { description: 'Turn right and continue on E 74 St', miles: '0.01 miles', time: '0.13 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.80 mins' },
          { description: 'Turn right and continue on E 75 St', miles: '0.01 miles', time: '0.16 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.80 mins' },
          { description: 'Turn right and continue on E 76 St', miles: '0.01 miles', time: '0.15 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.83 mins' },
          { description: 'Turn right and continue on E 77 St', miles: '0.01 miles', time: '0.15 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.81 mins' },
          { description: 'Turn right and continue on E 78 St', miles: '0.01 miles', time: '0.15 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.80 mins' },
          { description: 'Turn right and continue on 79 St Transverse', miles: '0.01 miles', time: '0.25 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.84 mins' },
          { description: 'Turn right and continue on E 80 St', miles: '0.01 miles', time: '0.19 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.77 mins' },
          { description: 'Turn right and continue on E 81 St', miles: '0.01 miles', time: '0.19 mins' },
          { description: 'Finish at Central Park Outer Loop New York, NY 10075', miles: '', time: '' }
        ]
      },
      {
        name: 'Central Park South to Lincoln Center',
        coordinates: [
          [40.765877, -73.97626],
          [40.765838648, -73.976297687],
          [40.766939578, -73.978963052],
          [40.767889542, -73.981202132],
          [40.768503441, -73.981442515],
          [40.76923642, -73.980922517],
          [40.769586045, -73.981877923],
          [40.770404689, -73.981939569],
          [40.7707411, -73.982334463],
          [40.771102779, -73.983258607],
          [40.772438, -73.983484]
        ],
        directions: [
          { description: 'Start at Central Park Outer Loop New York, NY 10105', miles: '', time: '' },
          { description: 'Turn left and continue on Central Park S', miles: '0.34 miles', time: '6.49 mins' },
          { description: 'Turn left and continue on C P 8 Av Appr', miles: '0.04 miles', time: '0.80 mins' },
          { description: 'Turn right and continue on Central Park W', miles: '0.06 miles', time: '1.10 mins' },
          { description: 'Turn left and continue on W 61 St', miles: '0.06 miles', time: '1.23 mins' },
          { description: 'Turn left and continue on Broadway', miles: '0.09 miles', time: '1.71 mins' },
          { description: 'Turn left and continue on W 62 St', miles: '0.08 miles', time: '1.51 mins' },
          { description: 'Turn left and continue on Columbus Av', miles: '0.01 miles', time: '0.28 mins' },
          { description: 'Finish at Josie Robertson Plaza New York, NY 10023', miles: '', time: '' }
        ]
      }
  ];

export const natureCategory = [
    {
      name: 'Grand Central Station to Carnegie Hall',
      coordinates: [
        [40.752328, -73.977543],
        [40.752232878, -73.977700549],
        [40.75245857, -73.978245609],
        [40.75285766, -73.979124917],
        [40.753478037, -73.978918903],
        [40.753565979, -73.978864412],
        [40.75417466, -73.980264172],
        [40.754775571, -73.980063935],
        [40.756046114, -73.983078079],
        [40.756791061, -73.982558193],
        [40.757383623, -73.982416133],
        [40.758493902, -73.985022006],
        [40.759196648, -73.984750854],
        [40.759301996, -73.984702092],
        [40.759420947, -73.984947311],
        [40.760129498, -73.984678432],
        [40.760793465, -73.984330617],
        [40.761448533, -73.983911999],
        [40.762088302, -73.983503373],
        [40.76271707, -73.98307154],
        [40.763389238, -73.982672471],
        [40.764038233, -73.982332298],
        [40.764731794, -73.982067299],
        [40.765468774, -73.981865983],
        [40.766145014, -73.981734166],
        [40.765368, -73.980085],
        [40.765412461, -73.980013801]
      ],
      directions: [
        { description: 'Start at Grand Central Branch New York, NY 10178', miles: '', time: '' },
        { description: 'Continue on E 42 St', miles: '0.07 miles', time: '1.44 mins' },
        { description: 'Turn right and continue on Madison Av', miles: '0.05 miles', time: '0.98 mins' },
        { description: 'Continue on E 43 St', miles: '0.10 miles', time: '1.86 mins' },
        { description: 'Turn left and continue on 5 Av', miles: '0.04 miles', time: '0.79 mins' },
        { description: 'Turn right and continue on E 44 St', miles: '0.01 miles', time: '0.26 mins' },
        { description: 'Turn left and continue on W 44 St', miles: '0.21 miles', time: '3.99 mins' },
        { description: 'Turn right and continue on Ave of the Americas', miles: '0.01 miles', time: '0.14 mins' },
        { description: 'Continue on Av of the Americas', miles: '0.04 miles', time: '0.79 mins' },
        { description: 'Turn right and continue on Ave of the Americas', miles: '0.01 miles', time: '0.17 mins' },
        { description: 'Continue on Av of the Americas', miles: '0.04 miles', time: '0.77 mins' },
        { description: 'Turn right and continue on Ave of the Americas', miles: '0.02 miles', time: '0.32 mins' },
        { description: 'Continue on W 46 St', miles: '0.19 miles', time: '3.61 mins' },
        { description: 'Turn right and continue on 7 Av', miles: '0.05 miles', time: '0.90 mins' },
        { description: 'Continue on W 47 Street Bike Path', miles: '0.01 miles', time: '0.14 mins' },
        { description: 'Turn right and continue on W 47 St', miles: '0.02 miles', time: '0.33 mins' },
        { description: 'Continue on Broadway', miles: '0.23 miles', time: '4.51 mins' },
        { description: 'Turn right and continue on W 52 St', miles: '0.01 miles', time: '0.14 mins' },
        { description: 'Turn left and continue on Broadway', miles: '0.04 miles', time: '0.79 mins' },
        { description: 'Turn right and continue on W 53 St', miles: '0.01 miles', time: '0.17 mins' },
        { description: 'Continue on Broadway', miles: '0.19 miles', time: '3.60 mins' },
        { description: 'Turn right and continue on W 57 St', miles: '0.11 miles', time: '2.04 mins' },
        { description: 'Finish at 200 West 57th Street New York, NY 10019', miles: '', time: '' }
      ]
    },
    {
      name: 'Grand Central to Time Warner Building',
      coordinates: [
        [40.752328, -73.977543],
        [40.752232878, -73.977700549],
        [40.75245857, -73.978245609],
        [40.75285766, -73.979124917],
        [40.753478037, -73.978918903],
        [40.754083936, -73.978457725],
        [40.754710246, -73.978003862],
        [40.755307962, -73.979432674],
        [40.75604663, -73.978888821],
        [40.756676601, -73.978429119],
        [40.757385889, -73.978157102],
        [40.758003479, -73.977740702],
        [40.758535628, -73.977321332],
        [40.759253125, -73.976807548],
        [40.759747, -73.978013],
        [40.759764863, -73.977981863]
      ],
      directions: [
        { description: 'Start at Grand Central Branch New York, NY 10178', miles: '', time: '' },
        { description: 'Continue on E 42 St', miles: '0.07 miles', time: '1.44 mins' },
        { description: 'Turn right and continue on Madison Av', miles: '0.14 miles', time: '2.72 mins' },
        { description: 'Turn left and continue on E 45 St', miles: '0.10 miles', time: '1.89 mins' },
        { description: 'Continue on 5 Av', miles: '0.15 miles', time: '2.83 mins' },
        { description: 'Turn right and continue on W 48 St', miles: '0.01 miles', time: '0.24 mins' },
        { description: 'Turn right and continue on 5 Av', miles: '0.15 miles', time: '2.99 mins' },
        { description: 'Finish at West 51st Street New York, NY 10019', miles: '', time: '' }
      ]
    },
    {
        name: 'Grand Central to Morgan Library',
        coordinates: [
          [40.752328, -73.977543],
          [40.752232878, -73.977700549],
          [40.75245857, -73.978245609],
          [40.75285766, -73.979124917],
          [40.752150145, -73.979656481],
          [40.751522722, -73.980092078],
          [40.750911431, -73.980562664],
          [40.750287202, -73.981004579],
          [40.749673157, -73.981455221],
          [40.749331, -73.981662],
          [40.749340025, -73.981710232]
        ],
        directions: [
          { description: 'Start at Grand Central Branch New York, NY 10178', miles: '', time: '' },
          { description: 'Turn left and continue on E 42 St', miles: '0.06 miles', time: '1.18 mins' },
          { description: 'Continue on Madison Av', miles: '0.25 miles', time: '4.84 mins' },
          { description: 'Finish at 225 Madison Avenue New York, NY 10016', miles: '', time: '' }
        ]
      },
      {
        name: 'Union Square to Washington Square Park',
        coordinates: [
          [40.735281, -73.990794],
          [40.735236324, -73.991066812],
          [40.73509841, -73.991671981],
          [40.734558752, -73.992161468],
          [40.734602355, -73.992297576],
          [40.733995257, -73.992834536],
          [40.733375935, -73.993374425],
          [40.732799754, -73.993853155],
          [40.732721382, -73.993909371],
          [40.732207682, -73.994181868],
          [40.731647112, -73.994664177],
          [40.730712435, -73.995443818],
          [40.730710542, -73.995667],
          [40.730945, -73.997358],
          [40.731248654, -73.996760026]
        ],
        directions: [
          { description: 'Start at George Washington New York, NY 10003', miles: '', time: '' },
          { description: 'Turn right and continue on Union Sq W', miles: '0.04 miles', time: '0.71 mins' },
          { description: 'Turn right and continue on University Pl', miles: '0.05 miles', time: '0.89 mins' },
          { description: 'Continue on E 13 St', miles: '0.01 miles', time: '0.17 mins' },
          { description: 'Turn right and continue on University Pl', miles: '0.15 miles', time: '2.91 mins' },
          { description: 'Turn right and continue on E 10 St', miles: '0.01 miles', time: '0.17 mins' },
          { description: 'Turn right and continue on University Pl', miles: '0.16 miles', time: '3.13 mins' },
          { description: 'Continue on Washington Sq E', miles: '0.01 miles', time: '0.17 mins' },
          { description: 'Turn right and continue on Washington Sq N', miles: '0.01 miles', time: '0.14 mins' },
          { description: 'Finish at Washington Square Fountain New York, NY 10012', miles: '', time: '' }
        ]
      },
      {
        name: 'Washington Square Park to Chelsea Market',
        coordinates: [
          [40.730945, -73.997358],
          [40.731248654, -73.996760026],
          [40.732146893, -73.99858455],
          [40.732828891, -73.99999655],
          [40.733686852, -74.001797623],
          [40.733710765, -74.002625241],
          [40.733884075, -74.002798061],
          [40.734305255, -74.002933296],
          [40.734941234, -74.003109289],
          [40.735502002, -74.003273705],
          [40.736115564, -74.003551456],
          [40.736807214, -74.003644936],
          [40.737478699, -74.003809958],
          [40.73807293, -74.004374195],
          [40.738185873, -74.005481883],
          [40.739359216, -74.005285604],
          [40.739470789, -74.005272436],
          [40.739916878, -74.00518286],
          [40.740040355, -74.005187037],
          [40.740726115, -74.005068838],
          [40.741543904, -74.004778997],
          [40.742264, -74.006283],
          [40.742285585, -74.00624685]
        ],
        directions: [
          { description: 'Start at Washington Square Fountain New York, NY 10012', miles: '', time: '' },
          { description: 'Continue on Waverly Pl', miles: '0.22 miles', time: '4.18 mins' },
          { description: 'Turn left and continue on Grove St', miles: '0.01 miles', time: '0.18 mins' },
          { description: 'Continue on Christopher St', miles: '0.06 miles', time: '1.08 mins' },
          { description: 'Turn left and continue on 7 Av S', miles: '0.01 miles', time: '0.15 mins' },
          { description: 'Turn left and continue on W 4 St', miles: '0.15 miles', time: '2.93 mins' },
          { description: 'Turn right and continue on W 11 St', miles: '0.01 miles', time: '0.10 mins' },
          { description: 'Continue on W 4 St', miles: '0.11 miles', time: '2.19 mins' },
          { description: 'Turn left and continue on 8 Av', miles: '0.01 miles', time: '0.18 mins' },
          { description: 'Continue on Jane St', miles: '0.09 miles', time: '1.76 mins' },
          { description: 'Turn right and continue on Hudson St', miles: '0.17 miles', time: '3.27 mins' },
          { description: 'Turn right and continue on 9 Av', miles: '0.04 miles', time: '0.83 mins' },
          { description: 'Continue on W 15 St', miles: '0.02 miles', time: '0.32 mins' },
          { description: 'Turn left and continue on 9 Ave', miles: '0.01 miles', time: '0.15 mins' },
          { description: 'Finish at 425 West 15th Street New York, NY 10011', miles: '', time: '' }
        ]
      },
       {
    name: 'Washington Square Park to Katz Deli',
    coordinates: [
      [40.730945, -73.997358],
      [40.731248654, -73.996760026],
      [40.691225, -73.982309],
      [40.690624016, -73.982699818]
    ],
    directions: [
      { description: 'Start at Washington Square Fountain New York, NY 10012', miles: '', time: '' },
      { description: 'Finish at 10 Flatbush Avenue Extension New York, NY 11201', miles: '', time: '' }
    ]
  },
  {
    name: 'Central Park South to Metropolitan Museum of Art',
    coordinates: [
      [40.765877, -73.97626],
      [40.765750764, -73.976114442],
      [40.764746318, -73.973727446],
      [40.76502011, -73.97316006],
      [40.764847491, -73.972768423],
      [40.765056347, -73.972542093],
      [40.765625057, -73.972147047],
      [40.76626256, -73.971688958],
      [40.766897161, -73.971240115],
      [40.767519654, -73.970799836],
      [40.768041119, -73.970431013],
      [40.768103898, -73.970346691],
      [40.7686497, -73.969951189],
      [40.769273762, -73.969465861],
      [40.769902262, -73.96900627],
      [40.770508112, -73.968566277],
      [40.771145259, -73.968103554],
      [40.771778098, -73.967643959],
      [40.772409293, -73.967185559],
      [40.773231546, -73.966582751],
      [40.773863695, -73.966125767],
      [40.774502905, -73.965663679],
      [40.775130277, -73.965210149],
      [40.775779115, -73.964741101],
      [40.776417264, -73.96427978],
      [40.77711534, -73.963779117],
      [40.777652394, -73.963347029],
      [40.778294476, -73.962896975],
      [40.779053, -73.96246],
      [40.779037296, -73.962363881]
    ],
    directions: [
      { description: 'Start at Central Park Outer Loop New York, NY 10105', miles: '', time: '' },
      { description: 'Turn left and continue on Central Park S', miles: '0.18 miles', time: '3.48 mins' },
      { description: 'Turn left and continue on Grand Army Plz', miles: '0.06 miles', time: '1.16 mins' },
      { description: 'Turn right and continue on 5 Av', miles: '0.05 miles', time: '1.07 mins' },
      { description: 'Turn right and continue on E 61 St', miles: '0.01 miles', time: '0.16 mins' },
      { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.79 mins' },
      { description: 'Turn right and continue on E 62 St', miles: '0.01 miles', time: '0.17 mins' },
      { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.76 mins' },
      { description: 'Turn right and continue on E 63 St', miles: '0.01 miles', time: '0.19 mins' },
      { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.75 mins' },
      { description: 'Turn right and continue on E 64 St', miles: '0.01 miles', time: '0.18 mins' },
      { description: 'Turn right and continue on 5 Av', miles: '0.14 miles', time: '2.69 mins' },
      { description: 'Turn right and continue on E 67 St', miles: '0.01 miles', time: '0.15 mins' },
      { description: 'Turn right and continue on 5 Av', miles: '0.04 miles', time: '0.80 mins' },
      { description: 'Turn right and continue on E 68 St', miles: '0.01 miles', time: '0.14 mins' },
      { description: 'Turn right and continue on 5 Av', miles: '0.04 miles', time: '0.78 mins' },
      { description: 'Turn right and continue on E 69 St', miles: '0.01 miles', time: '0.17 mins' },
      { description: 'Turn right and continue on 5 Av', miles: '0.04 miles', time: '0.79 mins' },
      { description: 'Turn right and continue on E 70 St', miles: '0.01 miles', time: '0.13 mins' },
      { description: 'Turn right and continue on 5 Av', miles: '0.04 miles', time: '0.83 mins' },
      { description: 'Turn right and continue on E 71 St', miles: '0.01 miles', time: '0.19 mins' },
      { description: 'Turn right and continue on 5 Av', miles: '0.14 miles', time: '2.72 mins' },
      { description: 'Turn right and continue on E 74 St', miles: '0.01 miles', time: '0.13 mins' },
      { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.80 mins' },
      { description: 'Turn right and continue on E 75 St', miles: '0.01 miles', time: '0.16 mins' },
      { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.80 mins' },
      { description: 'Turn right and continue on E 76 St', miles: '0.01 miles', time: '0.15 mins' },
      { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.83 mins' },
      { description: 'Turn right and continue on E 77 St', miles: '0.01 miles', time: '0.15 mins' },
      { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.81 mins' },
      { description: 'Turn right and continue on E 78 St', miles: '0.01 miles', time: '0.15 mins' },
      { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.80 mins' },
      { description: 'Turn right and continue on 79 St Transverse', miles: '0.01 miles', time: '0.25 mins' },
      { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.84 mins' },
      { description: 'Turn right and continue on E 80 St', miles: '0.01 miles', time: '0.19 mins' },
      { description: 'Turn right and continue on 5 Av', miles: '0.04 miles', time: '0.77 mins' },
      { description: 'Turn right and continue on E 81 St', miles: '0.01 miles', time: '0.19 mins' },
      { description: 'Turn right and continue on 5 Av', miles: '0.04 miles', time: '0.77 mins' },
      { description: 'Finish at Central Park Outer Loop New York, NY 10075', miles: '', time: '' }
    ]
  },
  {
    name: 'Central Park South to Lincoln Center',
    coordinates: [
      [40.765877, -73.97626],
      [40.765838648, -73.976297687],
      [40.766939578, -73.978963052],
      [40.767889542, -73.981202132],
      [40.768503441, -73.981442515],
      [40.76923642, -73.980922517],
      [40.769381591, -73.981081298],
      [40.769737297, -73.981933926],
      [40.770404689, -73.981939569],
      [40.7707411, -73.982334463],
      [40.771102779, -73.983258607],
      [40.771382034, -73.983327555],
      [40.772438, -73.983484],
      [40.772292629, -73.98267392]
    ],
    directions: [
      { description: 'Start at Central Park Outer Loop New York, NY 10105', miles: '', time: '' },
      { description: 'Turn left and continue on Central Park S', miles: '0.34 miles', time: '6.49 mins' },
      { description: 'Turn left and continue on C P 8 Av Appr', miles: '0.04 miles', time: '0.80 mins' },
      { description: 'Turn right and continue on Central Park W', miles: '0.06 miles', time: '1.10 mins' },
      { description: 'Continue on W 61 St', miles: '0.07 miles', time: '1.40 mins' },
      { description: 'Turn left and continue on Broadway', miles: '0.08 miles', time: '1.51 mins' },
      { description: 'Turn left and continue on W 62 St', miles: '0.08 miles', time: '1.51 mins' },
      { description: 'Turn left and continue on Columbus Av', miles: '0.01 miles', time: '0.28 mins' },
      { description: 'Finish at Josie Robertson Plaza New York, NY 10023', miles: '', time: '' }
    ]
  }

  ];
  
export const quietCategory = [
    {
      name: 'Grand Central Station to Carnegie Hall',
      coordinates: [
        [40.752328, -73.977543],
        [40.752232878, -73.977700549],
        [40.75245857, -73.978245609],
        [40.752524932, -73.978378197],
        [40.753186119, -73.977897752],
        [40.753683411, -73.97753967],
        [40.753794271, -73.977482644],
        [40.75412015, -73.978207096],
        [40.75474902, -73.977745495],
        [40.755359915, -73.977291577],
        [40.755984361, -73.9768362],
        [40.756621646, -73.976379165],
        [40.75723888, -73.976190944],
        [40.757322744, -73.976094662],
        [40.75783507, -73.975727714],
        [40.758451824, -73.977126154],
        [40.759038387, -73.978503916],
        [40.759103775, -73.978430913],
        [40.759654674, -73.978102915],
        [40.76056307, -73.979813555],
        [40.761161412, -73.979363894],
        [40.761779544, -73.978912123],
        [40.762310681, -73.97852583],
        [40.762394847, -73.978456721],
        [40.763030879, -73.978291894],
        [40.763118579, -73.978224215],
        [40.763659563, -73.977853165],
        [40.763757929, -73.977732823],
        [40.764278273, -73.977366256],
        [40.765396789, -73.979974657],
        [40.765368, -73.980085],
        [40.765412461, -73.980013801]
      ],
      directions: [
        { description: 'Start at Grand Central Branch New York, NY 10178', miles: '', time: '' },
        { description: 'Turn left and continue on E 42 St', miles: '0.01 miles', time: '0.18 mins' },
        { description: 'Turn right and continue on Vanderbilt Av', miles: '0.10 miles', time: '1.90 mins' },
        { description: 'Continue on E 44 St', miles: '0.05 miles', time: '0.97 mins' },
        { description: 'Turn right and continue on Madison Av', miles: '0.20 miles', time: '3.79 mins' },
        { description: 'Continue on E 48 St', miles: '0.01 miles', time: '0.23 mins' },
        { description: 'Turn right and continue on Madison Av', miles: '0.09 miles', time: '1.72 mins' },
        { description: 'Continue on E 50 St', miles: '0.11 miles', time: '2.13 mins' },
        { description: 'Turn left and continue on W 50 St', miles: '0.08 miles', time: '1.57 mins' },
        { description: 'Turn right and continue on Rockefeller Plz', miles: '0.04 miles', time: '0.78 mins' },
        { description: 'Turn right and continue on W 51 St', miles: '0.12 miles', time: '2.36 mins' },
        { description: 'Turn left and continue on Ave of the Americas', miles: '0.01 miles', time: '0.19 mins' },
        { description: 'Continue on Av of the Americas', miles: '0.04 miles', time: '0.74 mins' },
        { description: 'Turn right and continue on Ave of the Americas', miles: '0.01 miles', time: '0.18 mins' },
        { description: 'Turn right and continue on Av of the Americas', miles: '0.09 miles', time: '1.74 mins' },
        { description: 'Turn right and continue on Ave of the Americas', miles: '0.01 miles', time: '0.13 mins' },
        { description: 'Continue on W 54 St', miles: '0.01 miles', time: '0.26 mins' },
        { description: 'Turn right and continue on Av of the Americas', miles: '0.04 miles', time: '0.77 mins' },
        { description: 'Turn right and continue on Ave of the Americas', miles: '0.01 miles', time: '0.14 mins' },
        { description: 'Turn right and continue on Ave of the Americas', miles: '0.04 miles', time: '0.80 mins' },
        { description: 'Turn right and continue on Ave of the Americas', miles: '0.01 miles', time: '0.19 mins' },
        { description: 'Turn right and continue on Ave of the Americas', miles: '0.04 miles', time: '0.78 mins' },
        { description: 'Turn left and continue on W 57 St', miles: '0.18 miles', time: '3.46 mins' },
        { description: 'Finish at 200 West 57th Street New York, NY 10019', miles: '', time: '' }
      ]
    },
    {
      name: 'Grand Central to Time Warner Building',
      coordinates: [
        [40.752328, -73.977543],
        [40.752232878, -73.977700549],
        [40.75245857, -73.978245609],
        [40.752524932, -73.978378197],
        [40.753186119, -73.977897752],
        [40.753683411, -73.97753967],
        [40.753794271, -73.977482644],
        [40.75412015, -73.978207096],
        [40.75474902, -73.977745495],
        [40.755359915, -73.977291577],
        [40.755984361, -73.9768362],
        [40.756621646, -73.976379165],
        [40.75723888, -73.976190944],
        [40.757322744, -73.976094662],
        [40.75783507, -73.975727714],
        [40.758451824, -73.977126154],
        [40.75854939, -73.977074173],
        [40.759160802, -73.976613566],
        [40.759253125, -73.976807548],
        [40.759747, -73.978013],
        [40.759764863, -73.977981863]
      ],
      directions: [
        { description: 'Start at Grand Central Branch New York, NY 10178', miles: '', time: '' },
        { description: 'Turn left and continue on E 42 St', miles: '0.01 miles', time: '0.18 mins' },
        { description: 'Turn right and continue on Vanderbilt Av', miles: '0.10 miles', time: '1.90 mins' },
        { description: 'Continue on E 44 St', miles: '0.05 miles', time: '0.97 mins' },
        { description: 'Turn right and continue on Madison Av', miles: '0.20 miles', time: '3.79 mins' },
        { description: 'Continue on E 48 St', miles: '0.01 miles', time: '0.23 mins' },
        { description: 'Turn right and continue on Madison Av', miles: '0.09 miles', time: '1.72 mins' },
        { description: 'Turn left and continue on E 50 St', miles: '0.10 miles', time: '1.87 mins' },
        { description: 'Turn right and continue on 5 Av', miles: '0.06 miles', time: '1.07 mins' },
        { description: 'Turn left and continue on E 51 St', miles: '0.01 miles', time: '0.26 mins' },
        { description: 'Finish at West 51st Street New York, NY 10019', miles: '', time: '' }
      ]
    },
    {
        name: 'Grand Central to Morgan Library',
        coordinates: [
          [40.752328, -73.977543],
          [40.752232878, -73.977700549],
          [40.75245857, -73.978245609],
          [40.75285766, -73.979124917],
          [40.752150145, -73.979656481],
          [40.751522722, -73.980092078],
          [40.750911431, -73.980562664],
          [40.750287202, -73.981004579],
          [40.749673157, -73.981455221],
          [40.749331, -73.981662],
          [40.749340025, -73.981710232]
        ],
        directions: [
          { description: 'Start at Grand Central Branch New York, NY 10178', miles: '', time: '' },
          { description: 'Turn left and continue on E 42 St', miles: '0.06 miles', time: '1.18 mins' },
          { description: 'Continue on Madison Av', miles: '0.25 miles', time: '4.84 mins' },
          { description: 'Finish at 225 Madison Avenue New York, NY 10016', miles: '', time: '' }
        ]
      },
      {
        name: 'Union Square to Washington Square Park',
        coordinates: [
          [40.735281, -73.990794],
          [40.735236324, -73.991066812],
          [40.73509841, -73.991671981],
          [40.734558752, -73.992161468],
          [40.733938391, -73.992682232],
          [40.733328158, -73.993222246],
          [40.732750204, -73.993708242],
          [40.732207682, -73.994181868],
          [40.731647112, -73.994664177],
          [40.730712435, -73.995443818],
          [40.730710542, -73.995667],
          [40.730945, -73.997358],
          [40.731248654, -73.996760026]
        ],
        directions: [
          { description: 'Start at George Washington New York, NY 10003', miles: '', time: '' },
          { description: 'Turn right and continue on Union Sq W', miles: '0.04 miles', time: '0.71 mins' },
          { description: 'Turn right and continue on University Pl', miles: '0.37 miles', time: '7.06 mins' },
          { description: 'Continue on Washington Sq E', miles: '0.01 miles', time: '0.17 mins' },
          { description: 'Turn right and continue on Washington Sq N', miles: '0.01 miles', time: '0.14 mins' },
          { description: 'Finish at Washington Square Fountain New York, NY 10012', miles: '', time: '' }
        ]
      },
      {
        name: 'Washington Square Park to Chelsea Market',
        coordinates: [
          [40.730945, -73.997358],
          [40.731248654, -73.996760026],
          [40.732146893, -73.99858455],
          [40.732286939, -73.998614741],
          [40.732931801, -73.999928333],
          [40.733509503, -73.999497649],
          [40.734020867, -73.999781653],
          [40.734638761, -74.000056476],
          [40.735259904, -74.000422323],
          [40.735881753, -74.000791675],
          [40.736402344, -74.001100211],
          [40.736456308, -74.001320309],
          [40.737118026, -74.001525721],
          [40.737199937, -74.001570225],
          [40.737738226, -74.001898485],
          [40.738100358, -74.002121399],
          [40.738698843, -74.00245138],
          [40.738720297, -74.00334878],
          [40.738709296, -74.00363083],
          [40.738791721, -74.003794726],
          [40.739377403, -74.003940029],
          [40.739916878, -74.00518286],
          [40.740040355, -74.005187037],
          [40.740726115, -74.005068838],
          [40.741543904, -74.004778997],
          [40.741643581, -74.005017593],
          [40.742264, -74.006283],
          [40.742285585, -74.00624685]
        ],
        directions: [
          { description: 'Start at Washington Square Fountain New York, NY 10012', miles: '', time: '' },
          { description: 'Turn left and continue on Mac Dougal St', miles: '0.01 miles', time: '0.13 mins' },
          { description: 'Turn left and continue on Waverly Pl', miles: '0.09 miles', time: '1.79 mins' },
          { description: 'Turn left and continue on Av of the Americas', miles: '0.05 miles', time: '0.88 mins' },
          { description: 'Turn right and continue on Ave of the Americas', miles: '0.01 miles', time: '0.27 mins' },
          { description: 'Turn left and continue on Greenwich Av', miles: '0.18 miles', time: '3.41 mins' },
          { description: 'Continue on W 11 St', miles: '0.01 miles', time: '0.28 mins' },
          { description: 'Turn left and continue on 7 Av S', miles: '0.01 miles', time: '0.19 mins' },
          { description: 'Turn left and continue on Greenwich Av', miles: '0.14 miles', time: '2.79 mins' },
          { description: 'Turn right and continue on Horatio St', miles: '0.09 miles', time: '1.65 mins' },
          { description: 'Turn left and continue on W 4 St', miles: '0.03 miles', time: '0.61 mins' },
          { description: 'Turn left and continue on W 13 St', miles: '0.10 miles', time: '1.86 mins' },
          { description: 'Turn right and continue on Hudson St', miles: '0.06 miles', time: '1.24 mins' },
          { description: 'Turn right and continue on 9 Av', miles: '0.04 miles', time: '0.83 mins' },
          { description: 'Continue on W 15 St', miles: '0.02 miles', time: '0.32 mins' },
          { description: 'Turn left and continue on 9 Ave', miles: '0.01 miles', time: '0.15 mins' },
          { description: 'Finish at 425 West 15th Street New York, NY 10011', miles: '', time: '' }
        ]
      },
      {
        name: 'Washington Square Park to Katz Deli',
        coordinates: [
          [40.730945, -73.997358],
          [40.731248654, -73.996760026],
          [40.691225, -73.982309],
          [40.690624016, -73.982699818]
        ],
        directions: [
          { description: 'Start at Washington Square Fountain New York, NY 10012', miles: '', time: '' },
          { description: 'Finish at 10 Flatbush Avenue Extension New York, NY 11201', miles: '', time: '' }
        ]
      },
      {
        name: 'Central Park South to Metropolitan Museum of Art',
        coordinates: [
          [40.765877, -73.97626],
          [40.765750764, -73.976114442],
          [40.764746318, -73.973727446],
          [40.76502011, -73.97316006],
          [40.764847491, -73.972768423],
          [40.765056347, -73.972542093],
          [40.765625057, -73.972147047],
          [40.76626256, -73.971688958],
          [40.766897161, -73.971240115],
          [40.767519654, -73.970799836],
          [40.768041119, -73.970431013],
          [40.768103898, -73.970346691],
          [40.7686497, -73.969951189],
          [40.769273762, -73.969465861],
          [40.769902262, -73.96900627],
          [40.770508112, -73.968566277],
          [40.771145259, -73.968103554],
          [40.771778098, -73.967643959],
          [40.772409293, -73.967185559],
          [40.773231546, -73.966582751],
          [40.773863695, -73.966125767],
          [40.774502905, -73.965663679],
          [40.775130277, -73.965210149],
          [40.775779115, -73.964741101],
          [40.776417264, -73.96427978],
          [40.77711534, -73.963779117],
          [40.777652394, -73.963347029],
          [40.778294476, -73.962896975],
          [40.779053, -73.96246],
          [40.779037296, -73.962363881]
        ],
        directions: [
          { description: 'Start at Central Park Outer Loop New York, NY 10105', miles: '', time: '' },
          { description: 'Turn left and continue on Central Park S', miles: '0.18 miles', time: '3.48 mins' },
          { description: 'Turn left and continue on Grand Army Plz', miles: '0.06 miles', time: '1.16 mins' },
          { description: 'Turn right and continue on 5 Av', miles: '0.05 miles', time: '1.07 mins' },
          { description: 'Turn right and continue on E 61 St', miles: '0.01 miles', time: '0.16 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.79 mins' },
          { description: 'Turn right and continue on E 62 St', miles: '0.01 miles', time: '0.17 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.76 mins' },
          { description: 'Turn right and continue on E 63 St', miles: '0.01 miles', time: '0.19 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.75 mins' },
          { description: 'Turn right and continue on E 64 St', miles: '0.01 miles', time: '0.18 mins' },
          { description: 'Turn right and continue on 5 Av', miles: '0.14 miles', time: '2.69 mins' },
          { description: 'Turn right and continue on E 67 St', miles: '0.01 miles', time: '0.15 mins' },
          { description: 'Turn right and continue on 5 Av', miles: '0.04 miles', time: '0.80 mins' },
          { description: 'Turn right and continue on E 68 St', miles: '0.01 miles', time: '0.14 mins' },
          { description: 'Turn right and continue on 5 Av', miles: '0.04 miles', time: '0.78 mins' },
          { description: 'Turn right and continue on E 69 St', miles: '0.01 miles', time: '0.17 mins' },
          { description: 'Turn right and continue on 5 Av', miles: '0.04 miles', time: '0.79 mins' },
          { description: 'Turn right and continue on E 70 St', miles: '0.01 miles', time: '0.13 mins' },
          { description: 'Turn right and continue on 5 Av', miles: '0.04 miles', time: '0.83 mins' },
          { description: 'Turn right and continue on E 71 St', miles: '0.01 miles', time: '0.19 mins' },
          { description: 'Turn right and continue on 5 Av', miles: '0.14 miles', time: '2.72 mins' },
          { description: 'Turn right and continue on E 74 St', miles: '0.01 miles', time: '0.13 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.80 mins' },
          { description: 'Turn right and continue on E 75 St', miles: '0.01 miles', time: '0.16 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.80 mins' },
          { description: 'Turn right and continue on E 76 St', miles: '0.01 miles', time: '0.15 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.83 mins' },
          { description: 'Turn right and continue on E 77 St', miles: '0.01 miles', time: '0.15 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.81 mins' },
          { description: 'Turn right and continue on E 78 St', miles: '0.01 miles', time: '0.15 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.80 mins' },
          { description: 'Turn right and continue on 79 St Transverse', miles: '0.01 miles', time: '0.25 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.84 mins' },
          { description: 'Turn right and continue on E 80 St', miles: '0.01 miles', time: '0.19 mins' },
          { description: 'Turn right and continue on 5 Av', miles: '0.04 miles', time: '0.77 mins' },
          { description: 'Turn right and continue on E 81 St', miles: '0.01 miles', time: '0.19 mins' },
          { description: 'Turn right and continue on 5 Av', miles: '0.04 miles', time: '0.77 mins' },
          { description: 'Finish at Central Park Outer Loop New York, NY 10075', miles: '', time: '' }
        ]
      },
      {
        name: 'Central Park South to Lincoln Center',
        coordinates: [
          [40.765877, -73.97626],
          [40.765838648, -73.976297687],
          [40.766939578, -73.978963052],
          [40.767889542, -73.981202132],
          [40.768503441, -73.981442515],
          [40.76923642, -73.980922517],
          [40.769381591, -73.981081298],
          [40.769737297, -73.981933926],
          [40.770404689, -73.981939569],
          [40.7707411, -73.982334463],
          [40.771102779, -73.983258607],
          [40.771382034, -73.983327555],
          [40.772438, -73.983484],
          [40.772292629, -73.98267392]
        ],
        directions: [
          { description: 'Start at Central Park Outer Loop New York, NY 10105', miles: '', time: '' },
          { description: 'Turn left and continue on Central Park S', miles: '0.34 miles', time: '6.49 mins' },
          { description: 'Turn left and continue on C P 8 Av Appr', miles: '0.04 miles', time: '0.80 mins' },
          { description: 'Turn right and continue on Central Park W', miles: '0.06 miles', time: '1.10 mins' },
          { description: 'Continue on W 61 St', miles: '0.07 miles', time: '1.40 mins' },
          { description: 'Turn left and continue on Broadway', miles: '0.08 miles', time: '1.51 mins' },
          { description: 'Turn left and continue on W 62 St', miles: '0.08 miles', time: '1.51 mins' },
          { description: 'Turn left and continue on Columbus Av', miles: '0.01 miles', time: '0.28 mins' },
          { description: 'Finish at Josie Robertson Plaza New York, NY 10023', miles: '', time: '' }
        ]
      }
  ];


export const sociabilityRoutes = [
    {
      name: 'Grand Central Station to Carnegie Hall',
      coordinates: [
        [40.752328, -73.977543],
        [40.752232878, -73.977700549],
        [40.75245857, -73.978245609],
        [40.752524932, -73.978378197],
        [40.753186119, -73.977897752],
        [40.753683411, -73.97753967],
        [40.753794271, -73.977482644],
        [40.754326782, -73.977084492],
        [40.754432203, -73.977001128],
        [40.754952121, -73.976619408],
        [40.755051415, -73.976550956],
        [40.755599838, -73.976177169],
        [40.75589968, -73.976890407],
        [40.755984361, -73.9768362],
        [40.756069792, -73.977022159],
        [40.756676601, -73.978429119],
        [40.757283177, -73.97825088],
        [40.757385889, -73.978157102],
        [40.758003479, -73.977740702],
        [40.758492163, -73.978918746],
        [40.759103775, -73.978430913],
        [40.759654674, -73.978102915],
        [40.76056307, -73.979813555],
        [40.761046488, -73.97945184],
        [40.761775221, -73.979204637],
        [40.762877538, -73.981781304],
        [40.763478514, -73.981347107],
        [40.764226219, -73.980845334],
        [40.764861703, -73.980382564],
        [40.765368, -73.980085],
        [40.765412461, -73.980013801]
      ],
      directions: [
        { description: 'Start at Grand Central Branch New York, NY 10178', miles: '', time: '' },
        { description: 'Turn left and continue on E 42 St', miles: '0.01 miles', time: '0.18 mins' },
        { description: 'Turn right and continue on Vanderbilt Av', miles: '0.24 miles', time: '4.62 mins' },
        { description: 'Turn left and continue on E 47 St', miles: '0.05 miles', time: '0.94 mins' },
        { description: 'Turn right and continue on Madison Av', miles: '0.01 miles', time: '0.12 mins' },
        { description: 'Turn left and continue on E 47 St', miles: '0.11 miles', time: '2.12 mins' },
        { description: 'Turn left and continue on 5 Av', miles: '0.04 miles', time: '0.76 mins' },
        { description: 'Turn right and continue on W 48 St', miles: '0.01 miles', time: '0.24 mins' },
        { description: 'Turn right and continue on 5 Av', miles: '0.06 miles', time: '1.08 mins' },
        { description: 'Turn left and continue on W 49 St', miles: '0.08 miles', time: '1.56 mins' },
        { description: 'Turn right and continue on Rockefeller Plz', miles: '0.08 miles', time: '1.62 mins' },
        { description: 'Turn right and continue on W 51 St', miles: '0.12 miles', time: '2.36 mins' },
        { description: 'Turn left and continue on Ave of the Americas', miles: '0.01 miles', time: '0.19 mins' },
        { description: 'Continue on Av of the Americas', miles: '0.04 miles', time: '0.74 mins' },
        { description: 'Turn right and continue on W 52 St', miles: '0.01 miles', time: '0.28 mins' },
        { description: 'Turn left and continue on Ave of the Americas', miles: '0.01 miles', time: '0.16 mins' },
        { description: 'Turn right and continue on Av of the Americas', miles: '0.04 miles', time: '0.79 mins' },
        { description: 'Turn left and continue on W 53 St', miles: '0.18 miles', time: '3.42 mins' },
        { description: 'Continue on 7 Av', miles: '0.20 miles', time: '3.80 mins' },
        { description: 'Finish at 200 West 57th Street New York, NY 10019', miles: '', time: '' }
      ]
    },
    {
      name: 'Grand Central to Time Warner Building',
      coordinates: [
        [40.752328, -73.977543],
        [40.752232878, -73.977700549],
        [40.75245857, -73.978245609],
        [40.752524932, -73.978378197],
        [40.753186119, -73.977897752],
        [40.753683411, -73.97753967],
        [40.753794271, -73.977482644],
        [40.754326782, -73.977084492],
        [40.754432203, -73.977001128],
        [40.754952121, -73.976619408],
        [40.755051415, -73.976550956],
        [40.755599838, -73.976177169],
        [40.75589968, -73.976890407],
        [40.755984361, -73.9768362],
        [40.756069792, -73.977022159],
        [40.756676601, -73.978429119],
        [40.757283177, -73.97825088],
        [40.757385889, -73.978157102],
        [40.758003479, -73.977740702],
        [40.758535628, -73.977321332],
        [40.759253125, -73.976807548],
        [40.759747, -73.978013],
        [40.759764863, -73.977981863]
      ],
      directions: [
        { description: 'Start at Grand Central Branch New York, NY 10178', miles: '', time: '' },
        { description: 'Turn left and continue on E 42 St', miles: '0.01 miles', time: '0.18 mins' },
        { description: 'Turn right and continue on Vanderbilt Av', miles: '0.24 miles', time: '4.62 mins' },
        { description: 'Turn left and continue on E 47 St', miles: '0.05 miles', time: '0.94 mins' },
        { description: 'Turn right and continue on Madison Av', miles: '0.01 miles', time: '0.12 mins' },
        { description: 'Turn left and continue on E 47 St', miles: '0.11 miles', time: '2.12 mins' },
        { description: 'Turn left and continue on 5 Av', miles: '0.04 miles', time: '0.76 mins' },
        { description: 'Turn right and continue on W 48 St', miles: '0.01 miles', time: '0.24 mins' },
        { description: 'Turn right and continue on 5 Av', miles: '0.15 miles', time: '2.99 mins' },
        { description: 'Finish at West 51st Street New York, NY 10019', miles: '', time: '' }
      ]
    },
    {
        name: 'Grand Central to Morgan Library',
        coordinates: [
          [40.752328, -73.977543],
          [40.752232878, -73.977700549],
          [40.75245857, -73.978245609],
          [40.75285766, -73.979124917],
          [40.752150145, -73.979656481],
          [40.751522722, -73.980092078],
          [40.750911431, -73.980562664],
          [40.750287202, -73.981004579],
          [40.749673157, -73.981455221],
          [40.749331, -73.981662],
          [40.749340025, -73.981710232]
        ],
        directions: [
          { description: 'Start at Grand Central Branch New York, NY 10178', miles: '', time: '' },
          { description: 'Turn left and continue on E 42 St', miles: '0.06 miles', time: '1.18 mins' },
          { description: 'Continue on Madison Av', miles: '0.25 miles', time: '4.84 mins' },
          { description: 'Finish at 225 Madison Avenue New York, NY 10016', miles: '', time: '' }
        ]
      },
      {
        name: 'Union Square to Washington Square Park',
        coordinates: [
          [40.735281, -73.990794],
          [40.735236324, -73.991066812],
          [40.73509841, -73.991671981],
          [40.734558752, -73.992161468],
          [40.734602355, -73.992297576],
          [40.733995257, -73.992834536],
          [40.733375935, -73.993374425],
          [40.732799754, -73.993853155],
          [40.732721382, -73.993909371],
          [40.732241129, -73.994339906],
          [40.731677545, -73.994815227],
          [40.731280574, -73.995204749],
          [40.730945, -73.997358],
          [40.731248654, -73.996760026]
        ],
        directions: [
          { description: 'Start at George Washington New York, NY 10003', miles: '', time: '' },
          { description: 'Turn right and continue on Union Sq W', miles: '0.04 miles', time: '0.71 mins' },
          { description: 'Turn right and continue on University Pl', miles: '0.05 miles', time: '0.89 mins' },
          { description: 'Continue on E 13 St', miles: '0.01 miles', time: '0.17 mins' },
          { description: 'Turn right and continue on University Pl', miles: '0.32 miles', time: '6.18 mins' },
          { description: 'Finish at Washington Square Fountain New York, NY 10012', miles: '', time: '' }
        ]
      },
      {
        name: 'Washington Square Park to Chelsea Market',
        coordinates: [
          [40.730945, -73.997358],
          [40.731248654, -73.996760026],
          [40.732146893, -73.99858455],
          [40.732286939, -73.998614741],
          [40.732931801, -73.999928333],
          [40.733626326, -73.999420393],
          [40.734739019, -73.999912994],
          [40.736780072, -74.001129063],
          [40.737527811, -74.001587126],
          [40.738664708, -74.002229866],
          [40.739335371, -74.002671789],
          [40.739629769, -74.002450917],
          [40.740726115, -74.005068838],
          [40.741543904, -74.004778997],
          [40.742264, -74.006283]
        ],
        directions: [
          { description: 'Start at Washington Square Fountain New York, NY 10012', miles: '', time: '' },
          { description: 'Turn left and continue on Mac Dougal St', miles: '0.01 miles', time: '0.13 mins' },
          { description: 'Turn left and continue on Waverly Pl', miles: '0.09 miles', time: '1.79 mins' },
          { description: 'Turn left and continue on Av of the Americas', miles: '0.05 miles', time: '0.88 mins' },
          { description: 'Turn right and continue on Ave of the Americas', miles: '0.01 miles', time: '0.17 mins' },
          { description: 'Continue on Greenwich Av', miles: '0.03 miles', time: '0.53 mins' },
          { description: 'Turn left and continue on Christopher St', miles: '0.01 miles', time: '0.13 mins' },
          { description: 'Turn left and continue on Greenwich Av', miles: '0.38 miles', time: '7.37 mins' },
          { description: 'Turn right and continue on 8 Av', miles: '0.02 miles', time: '0.45 mins' },
          { description: 'Turn left and continue on W 14 St', miles: '0.18 miles', time: '3.47 mins' },
          { description: 'Continue on Hudson St', miles: '0.01 miles', time: '0.24 mins' },
          { description: 'Turn right and continue on 9 Av', miles: '0.04 miles', time: '0.83 mins' },
          { description: 'Continue on W 15 St', miles: '0.02 miles', time: '0.32 mins' },
          { description: 'Turn left and continue on 9 Ave', miles: '0.01 miles', time: '0.15 mins' },
          { description: 'Finish at 425 West 15th Street New York, NY 10011', miles: '', time: '' }
        ]
      },
      {
        name: 'Central Park South to Metropolitan Museum of Art',
        coordinates: [
          [40.765877, -73.97626],
          [40.765750764, -73.976114442],
          [40.764746318, -73.973727446],
          [40.76502011, -73.97316006],
          [40.764847491, -73.972768423],
          [40.765056347, -73.972542093],
          [40.765625057, -73.972147047],
          [40.76626256, -73.971688958],
          [40.766897161, -73.971240115],
          [40.767519654, -73.970799836],
          [40.768041119, -73.970431013],
          [40.7686497, -73.969951189],
          [40.769273762, -73.969465861],
          [40.769902262, -73.96900627],
          [40.770508112, -73.968566277],
          [40.771145259, -73.968103554],
          [40.771778098, -73.967643959],
          [40.772409293, -73.967185559],
          [40.773231546, -73.966582751],
          [40.773863695, -73.966125767],
          [40.774502905, -73.965663679],
          [40.775130277, -73.965210149],
          [40.775779115, -73.964741101],
          [40.776417264, -73.96427978],
          [40.77711534, -73.963779117],
          [40.777652394, -73.963347029],
          [40.778294476, -73.962896975],
          [40.779053, -73.96246]
        ],
        directions: [
          { description: 'Start at Central Park Outer Loop New York, NY 10105', miles: '', time: '' },
          { description: 'Turn left and continue on Central Park S', miles: '0.18 miles', time: '3.48 mins' },
          { description: 'Turn left and continue on Grand Army Plz', miles: '0.06 miles', time: '1.16 mins' },
          { description: 'Turn right and continue on 5 Av', miles: '0.05 miles', time: '1.07 mins' },
          { description: 'Turn right and continue on E 61 St', miles: '0.01 miles', time: '0.16 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.79 mins' },
          { description: 'Turn right and continue on E 62 St', miles: '0.01 miles', time: '0.17 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.76 mins' },
          { description: 'Turn right and continue on E 63 St', miles: '0.01 miles', time: '0.19 mins' },
          { description: 'Continue on 5 Av', miles: '0.04 miles', time: '0.75 mins' },
          { description: 'Turn right and continue on E 64 St', miles: '0.01 miles', time: '0.18 mins' },
          { description: 'Continue on 5 Av', miles: '0.14 miles', time: '2.69 mins' },
          { description: 'Turn right and continue on E 67 St', miles: '0.01 miles', time: '0.15 mins' },
          { description: 'Turn right and continue on 5 Av', miles: '0.04 miles', time: '0.80 mins' },
          { description: 'Finish at Central Park Outer Loop New York, NY 10075', miles: '', time: '' }
        ]
      },
      {
        name: 'Central Park South to Lincoln Center',
        coordinates: [
          [40.765877, -73.97626],
          [40.765838648, -73.976297687],
          [40.766939578, -73.978963052],
          [40.767889542, -73.981202132],
          [40.768503441, -73.981442515],
          [40.76923642, -73.980922517],
          [40.769381591, -73.981081298],
          [40.769737297, -73.981933926],
          [40.770404689, -73.981939569],
          [40.7707411, -73.982334463],
          [40.771102779, -73.983258607],
          [40.771382034, -73.983327555],
          [40.772438, -73.983484],
          [40.772292629, -73.98267392]
        ],
        directions: [
          { description: 'Start at Central Park Outer Loop New York, NY 10105', miles: '', time: '' },
          { description: 'Turn left and continue on Central Park S', miles: '0.34 miles', time: '6.49 mins' },
          { description: 'Turn left and continue on C P 8 Av Appr', miles: '0.04 miles', time: '0.80 mins' },
          { description: 'Turn right and continue on Central Park W', miles: '0.06 miles', time: '1.10 mins' },
          { description: 'Turn left and continue on W 61 St', miles: '0.07 miles', time: '1.40 mins' },
          { description: 'Turn left and continue on Broadway', miles: '0.09 miles', time: '1.71 mins' },
          { description: 'Turn left and continue on W 62 St', miles: '0.08 miles', time: '1.51 mins' },
          { description: 'Turn left and continue on Columbus Av', miles: '0.01 miles', time: '0.28 mins' },
          { description: 'Finish at Josie Robertson Plaza New York, NY 10023', miles: '', time: '' }
        ]
      }
  ];
  
export const getRouteListByCategory = (type) => {
    switch (type) {
      case 'Nature':
        return natureCategory;
      case 'Comfort':
        return comfortCategory;
      case 'Quiet':
        return quietCategory;
      case 'Sociability':
        return sociabilityRoutes;
      case 'Architecture':
        return architectureList;
      default:
        return [];
    }
  }
  
  