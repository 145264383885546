import { ReactComponent as Quiet } from './assets/noise.svg';
import { ReactComponent as Access } from './assets/access.svg';
import { ReactComponent as Architecture } from './assets/architecture.svg';
import { ReactComponent as Comfort } from './assets/comfort.svg';
import { ReactComponent as Activities } from './assets/activities.svg';
import { ReactComponent as Nature } from './assets/nature.svg';

import { ReactComponent as All } from './assets/all.svg';
import { ReactComponent as Food } from './assets/food.svg';
import { ReactComponent as Services } from './assets/services.svg';
import { ReactComponent as Shop } from './assets/shop.svg';
import { ReactComponent as Entertainment } from './assets/entertainment.svg';
import { ReactComponent as Favorite } from './assets/favorite.svg';

import CarouselImg1 from './assets/carousel/img-1.jpg';
import CarouselImg2 from './assets/carousel/img-2.png';
import CarouselImg3 from './assets/carousel/img-3.jpg';
import CarouselImg4 from './assets/carousel/img-4.png';
import CarouselImg5 from './assets/carousel/img-5.jpg';
import CarouselImg6 from './assets/carousel/img-6.jpg';

import CarouselPH1 from './assets/carousel/placeholder-1.jpg';
import CarouselPH2 from './assets/carousel/placeholder-2.jpg';
import CarouselPH3 from './assets/carousel/placeholder-3.jpg';
import CarouselPH4 from './assets/carousel/placeholder-4.jpg';
import CarouselPH5 from './assets/carousel/placeholder-5.jpg';
import CarouselPH6 from './assets/carousel/placeholder-6.jpg';

import Partner1 from './assets/landing-page/ricky.png';
import Partner4 from './assets/landing-page/sagamore.png';
import Partner2 from './assets/landing-page/ccny.png';
import Partner3 from './assets/landing-page/omega.png';

import Impact1 from './assets/landing-page/impact1.png';
import Impact2 from './assets/landing-page/impact2.png';
import Impact3 from './assets/landing-page/impact3.png';

import Tab1 from './assets/landing-page/Tab/tab1.png';
import Score1 from './assets/landing-page/Tab/scoring1.png';
import Score2 from './assets/landing-page/Tab/scoring2.png';
import Tab3 from './assets/landing-page/Tab/tab3.png';
import Tab4 from './assets/landing-page/Tab/tab4.jpg';
import Tab5 from './assets/landing-page/Tab/tab5.png';

import { ReactComponent as Analytics } from './assets/other-features/analytics.svg';
import { ReactComponent as HealthImpact } from './assets/other-features/health-impach.svg';
import { ReactComponent as CO2 } from './assets/other-features/co2.svg';
import { ReactComponent as Navigation } from './assets/other-features/navigation.svg';
import { ReactComponent as Filter } from './assets/other-features/filter.svg';

import Team1 from './assets/landing-page/team/Bernardita C.jpg';
import Team2 from './assets/landing-page/team/Amanda R.png';
import Team3 from './assets/landing-page/team/Fariz A.jpg';
import Team4 from './assets/landing-page/team/Oliver C.jpg';

import TA1 from './assets/landing-page/team/Team1/civ_lab_logo 3.svg';
import TA2 from './assets/landing-page/team/Team1/louis_berger_logo-modified 4.svg';
import TA3 from './assets/landing-page/team/Team1/national_science_foundation_logo-modified 3.svg';
import TA4 from './assets/landing-page/team/Team1/nycedc_logo-modified 4.svg';
import TA5 from './assets/landing-page/team/Team1/sunyesf_logo-modified 4.svg';
import TA6 from './assets/landing-page/team/Team1/tiqc_logo 4.svg';

import TB1 from './assets/landing-page/team/Team2/louis_berger_logo-modified 4.svg';
import TB2 from './assets/landing-page/team/Team2/nycedc_logo-modified 4.svg';
import TB3 from './assets/landing-page/team/Team2/sunyesf_logo-modified 4.svg';
import TB4 from './assets/landing-page/team/Team2/tiqc_logo 4.svg';

import TC1 from './assets/landing-page/team/Team3/sunyesf_logo-modified 5.svg';
import TC2 from './assets/landing-page/team/Team3/sunyesf_logo-modified 6.svg';
import TC3 from './assets/landing-page/team/Team3/tiqc_logo 5.svg';

import TD1 from './assets/landing-page/team/Team4/nycedc_logo-modified 5.svg';
import TD2 from './assets/landing-page/team/Team4/sunyesf_logo-modified 7.svg';

import Advisor1 from './assets/landing-page/advisors/Vilma L.png';
import Advisor2 from './assets/landing-page/advisors/David S.png';
import Advisor3 from './assets/landing-page/advisors/Ying Z.png';
import Advisor4 from './assets/landing-page/advisors/Myles S.png';
import Advisor5 from './assets/landing-page/advisors/Jhon A.png';

import { ReactComponent as ActiveGem1 } from './assets/mobile-active-scores/gem-1.svg';
import { ReactComponent as ActiveGem2 } from './assets/mobile-active-scores/gem-2.svg';
import { ReactComponent as ActiveGem3 } from './assets/mobile-active-scores/gem-3.svg';
import { ReactComponent as ActiveFeel1 } from './assets/mobile-active-scores/feel-1.svg';
import { ReactComponent as ActiveFeel2 } from './assets/mobile-active-scores/feel-2.svg';
import { ReactComponent as ActiveFeel3 } from './assets/mobile-active-scores/feel-3.svg';

const walkIndexFeatures = [
  {
    id: 'beautyn',
    name: 'Nature',
    color: '#1EF04C',
    svg: <Nature className='feature-icon' />,
    description: 'Trees and greenspace',
    type: 'Tree cover %',
  },
  {
    id: 'beautym',
    name: 'Architecture',
    color: '#FFC991',
    svg: <Architecture className='feature-icon' />,
    description: 'Pre-war, postwar and modern',
    type: 'Pre-war %',
  },
  {
    id: 'activities',
    name: 'Vibrance',
    color: '#F48426',
    svg: <Activities className='feature-icon' />,
    description: 'Mixed/commercial residential',
    type: 'Mixed use %',
  },
  {
    id: 'comfort',
    name: 'Comfort',
    color: '#F252AB',
    svg: <Comfort className='feature-icon' />,
    description: 'Seating and shading',
    type: 'Presence',
  },
  {
    id: 'noise',
    name: 'Quiet',
    color: '#F6CE26',
    svg: <Quiet className='feature-icon' />,
    description: 'Noise level',
    type: 'Noise presence %',
  },
];

const lifeStyleFeatures = [
  {
    id: 'All',
    name: 'All',
    color: '#A66626',
    svg: <All className='feature-icon all-icon' />,
  },
  {
    id: 'food',
    name: 'Food',
    color: '#F26F01',
    svg: <Food className='feature-icon' />,
    description: 'Restaurants, cafes, groceries',
  },
  {
    id: 'services',
    name: 'Services',
    color: '#FF77C8',
    svg: <Services className='feature-icon' />,
    description: 'Banks, laundry, beauty',
  },
  {
    id: 'shops',
    name: 'Shops',
    color: '#F5C500',
    svg: <Shop className='feature-icon' />,
    description: 'Clothing and bookstore',
  },
  {
    id: 'transit',
    name: 'Transit',
    color: '#3AA7F0',
    svg: <Access className='feature-icon' />,
    description: 'Bus, bike, train',
  },
  {
    id: 'leisure',
    name: 'Leisure',
    color: '#9A77CF',
    svg: <Entertainment className='feature-icon' />,
    description: 'Clubs, movies, fitness, museums',
  },
  {
    id: 'Favorites',
    name: 'Favorites',
    color: '#EB082A',
    svg: <Favorite className='feature-icon' />,
  },
];

const carouselImages = [
  CarouselImg1,
  CarouselImg2,
  CarouselImg3,
  CarouselImg4,
  CarouselImg5,
  CarouselImg6,
];

const carouselPlaceholders = [
  CarouselPH1,
  CarouselPH2,
  CarouselPH3,
  CarouselPH4,
  CarouselPH5,
  CarouselPH6,
];

const partnersData = [
  {
    name: 'Ricky Hadzovic',
    img: Partner1,
    description:
      'Pharetra hendrerit eget. Id ullamcorper nisi lacus iaculis nunc ullamcorper. Porttitor molestie fringilla venenatis tristique adipiscing urna neque praesent. Leo lobortis gravida.',
    link: 'https://rickyhadzovic.com',
  },
  {
    name: 'City Connections',
    img: Partner2,
    description:
      'Consectetur. Nibh ultrices nisi in pharetra hendrerit eget. Id ullamcorper nisi lacus iaculis nunc ullamcorper. Porttitor molestie fringilla venenatis tristique adipiscing urna.',
    link: 'https://cityconnections.com',
  },
  {
    name: 'Omega Global',
    img: Partner3,
    description:
      'Ultrices nisi in pharetra hendrerit eget. Id ullamcorper nisi lacus iaculis nunc ullamcorper. Porttitor molestie fringilla venenatis tristique adipiscing urna neque praesent.',
    link: 'https://omegaglobal.com',
  },
  {
    name: 'The Sagamore',
    img: Partner4,
    description:
      'Ultrices nisi in pharetra hendrerit eget. Id ullamcorper nisi lacus iaculis nunc ullamcorper. Porttitor molestie fringilla venenatis tristique adipiscing urna neque praesent.',
    link: 'https://thesagamore.com',
  },
];

const otherFeatures = [
  { name: 'Analytics', img: <Analytics /> },
  { name: 'Carbon Footprint', img: <CO2 /> },
  { name: 'Health Impact', img: <HealthImpact /> },
  { name: 'Ratings', img: 'path/to/ratings-icon.png' },
  { name: 'Navigation', img: <Navigation /> },
  { name: 'Advanced Filters', img: <Filter /> },
];

const impactData = [
  {
    img: Impact1,
    description:
      'The average premium in residential sales for realtors is approximately 24.60% now that approximately 78% of Americans are willing to pay more for walkable communities.',
  },
  {
    img: Impact2,
    description:
      'A 150-minute walk a week can greatly reduce the incidence of chronic illnesses saving the US health system greater than 708 dollars a year.',
  },
  {
    img: Impact3,
    description:
      'Countries in Europe like Spain and the Netherlands observe a 50% reduction in carbon emissions where people are encouraged to walk as much as possible and to drive as much as necessary.',
  },
];

const neighborhoodIntelTabs = [
  { name: 'Single Interface', img: Tab1 },
  { name: 'Scoring', img1: Score1, img2: Score2 },
  { name: 'Mapping', img: Tab3 },
  { name: 'Reporting', img: Tab4 },
  { name: 'Recommendation', img: Tab5 },
];

const neighborhoodSearchFeatures = [
  {
    name: 'Quick',
    description: 'I need a pub within a 5-minute walk from the property',
  },
  {
    name: 'Detailed',
    description: 'Map treelined sidewalks, restaurants, and transit',
  },
  { name: 'Combined', description: 'Overlay feel data on gems maps' },
  { name: 'Comparison', description: 'Show four neighborhoods at a time' },
];

const teamData = [
  {
    name: 'Bernardita Calinao, PhD',
    title: 'Founder and CEO',
    img: Team1,
    linkedIn: 'https://www.linkedin.com/in/bernarditacaliano',
    email: 'mailto:bernardita@example.com',
    affiliations: [TA1, TA2, TA3, TA4, TA5, TA6],
  },
  {
    name: 'Amanda A. Russo',
    title: 'Chief Operating Officer, COO',
    img: Team2,
    linkedIn: 'https://www.linkedin.com/in/amandarusso',
    email: 'mailto:amanda@example.com',
    affiliations: [TB1, TB2, TB3, TB4],
  },
  {
    name: 'Fariz Attamimi',
    title: 'Chief Technology Officer, CTO',
    img: Team3,
    linkedIn: 'https://www.linkedin.com/in/farizattamimi',
    email: 'mailto:fariz@example.com',
    affiliations: [TC1, TC2, TC3],
  },
  {
    name: 'Oliver Chartock',
    title: 'Chief Technology Officer, CTO',
    img: Team4,
    linkedIn: 'https://www.linkedin.com/in/oliverchartock',
    email: 'mailto:oliver@example.com',
    affiliations: [TD1, TD2],
  },
];

const advisorsData = [
  {
    name: 'Vilma Ussa Lopez',
    title: 'Real Estate Professional',
    img: Advisor1,
    linkedIn: 'https://www.linkedin.com/in/vilmaussa',
    email: 'mailto:vilma@example.com',
  },
  {
    name: 'David Schlamm',
    title: 'Corporate Partnership',
    img: Advisor2,
    linkedIn: 'https://www.linkedin.com/in/davidschlamm',
    email: 'mailto:david@example.com',
  },
  {
    name: 'Ying Zhou, PHD',
    title: 'Technology Expert',
    img: Advisor3,
    linkedIn: 'https://www.linkedin.com/in/yingzhou',
    email: 'mailto:ying@example.com',
  },
  {
    name: 'Myles Share',
    title: 'Finance Expert',
    img: Advisor4,
    linkedIn: 'https://www.linkedin.com/in/mylesshare',
    email: 'mailto:myles@example.com',
  },
  {
    name: 'John Alexander, CEPA®',
    title: 'Strategic Partnership',
    img: Advisor5,
    linkedIn: 'https://www.linkedin.com/in/jhonalexander',
    email: 'mailto:jhon@example.com',
  },
];

const surveyQuestions = [
  {
    question: "Why did you try out Walkspan?",
    options: [
      {
        isTextarea: false,
        label: "I saw the banner ad and clicked."
      },
      {
        isTextarea: false,
        label: "I am actively searching for a new residence and wanted to compare neighborhoods."
      },
      {
        isTextarea: false,
        label: "Wanted to compare my current neighborhood to other NYC neighborhoods"
      },
      {
        isTextarea: true,
        label: "Add your own comment"
      }
    ]
  },
  {
    question: "Did your search change your perceptions of the neighborhood or neighborhoods you were investigating?",
    options: [
      {
        isTextarea: false,
        label: "Yes"
      },
      {
        isTextarea: false,
        label: "No"
      },
      {
        isTextarea: true,
        label: "Add your own comment"
      }
    ]
  },
  {
    question: `Would you like Walkspan to create a personal profile using your criteria for 
      your ideal NYC neighborhood, including your borough(s) of choice, and recommend ideal 
      locations in those boroughs that meet your profile requirements?`,
    options: [
      {
        isTextarea: false,
        label: "Yes"
      },
      {
        isTextarea: false,
        label: "No"
      },
      {
        isTextarea: true,
        label: "Add your own comment"
      }
    ]
  },
  {
    question: `Other Web tools permit data searches to choose neighborhoods and search for amenities. 
      Based on your knowledge, on a scale of 0 to 5 with, with 5 being the highest, 
      how would you compare Walkspan to them?`,
    subQuestions: [
      "Walkspan permits an intuitive, graphic way to compare and contrast neighborhoods",
      "Walkspan's data analysis condenses data into easily understood, usable information",
      "Walkspan includes all the neighborhood features within viable walking distances of specific addresses"
    ],
    options: [1, 2, 3, 4, 5]
  },
  {
    question: `Would you recommend Walkspan? If Yes, who would you recommend it to?`,
    options: [
      {
        isTextarea: false,
        label: "Yes"
      },
      {
        isTextarea: false,
        label: "No"
      },
    ],
    requiresTextArea: true
  },
  {
    question: 'Do you have any additional comments?',
    isOptional: true
  }
];

const getMarkerCategoryImage = (type) => {
  switch (type) {
    case 'food':
      return <Food className='category-image' />;
    case 'shops':
      return <Shop className='category-image' />;
    case 'services':
      return <Services className='category-image' />;
    case 'transit':
      return <Access className='category-image' />;
    case 'leisure':
      return <Entertainment className='category-image' />;
    default:
      return <Food className='category-image' />;
  }
};

const recommendedFilters = [
  {
    title: 'Grocery',
    type: 'food',
    filters: [
      "Trader Joe's",
      'Whole Foods',
      'Specialty Foods',
      'Convenience',
      'Supermarket',
      'Bakery',
      'Alcohol',
    ]
  },
  {
    title: 'Restaurant',
    type: 'food',
    filters: [
      'Steak',
      'Burger',
      'barbecue',
      'salad',
      'Diner',
      'Italian',
      'German',
      'Spanish',
      'Swiss',
      'Japanese',
      'Chinese',
      'Korean',
      'Filipino',
      'Thai',
      'Dominican',
      'Colombian',
      'Brazilian',
      'Argentinian',
      'Cuban',
      'Mexican',
    ]
  },
  {
    title: 'Cafe',
    type: 'food',
    filters: [
      'Donut',
      'Naman',
      'Cafe',
      'Coffee Shop',
      'Boutique',
    ]
  },
  {
    title: 'Bars',
    type: 'food',
    filters: [
      'Sportsbar',
      'Wine Bar',
      'Champagne Bar',
      'Irish Bar',
      'Pub',
    ]
  },
  {
    title: 'Fast Foods',
    type: 'food',
    filters: [
      'Fast Food',
      'Ice Cream',
      'Chocolate',
      'frozen yogurt',
      'Pizza',
      'Delis',
      'Bagel',
    ]
  },
  {
    title: 'Education',
    type: 'services',
    filters: [
      'College',
      'Coworking Space',
      'Library',
      'School',
    ]
  },
  {
    title: 'Health',
    type: 'services',
    filters: [
      'Clinic',
      'hospital',
      'Urgent Care',
      'Dentist',
      'Fitness',
      'Massage',
      'Yoga',
      'optician',
      'nutrition supplements',
      'Pharmacy',
    ]
  },
  {
    title: 'Business',
    type: 'services',
    filters: [
      'Office Supplies',
      'Post Office',
      'Printing',
      'Legal',
    ]
  },
  {
    title: 'Bank',
    type: 'services',
    filters: [
      'Bank',
      'ATM',
    ]
  },
  {
    title: 'Police Station',
    type: 'services',
    filters: [
      'Police',
    ]
  },
  {
    title: 'Gas Station',
    type: 'services',
    filters: [
      'Charging',
      'Gas',
    ]
  },
  {
    title: 'Big Box',
    type: 'shops',
    filters: [
      'Target',
      'Walmart',
      'Marshalls',
    ]
  },
  {
    title: 'Beauty',
    type: 'shops',
    filters: [
      'hairdresser',
      'barber',
      'Nail',
      'Lashes',
      'cosmetics',
    ]
  },
  {
    title: 'Household',
    type: 'shops',
    filters: [
      'Garden',
      'Laundry',
      'Hardware',
      'Home Furnishings',
      'Lumber',
      'Pet Store',
    ]
  },
  {
    title: 'Chains',
    type: 'shops',
    filters: [
      'Zara',
      'Ann Taylor',
      'NorthFace',
      'Patagonia',
      'Warby Parker',
      'H&M',
    ]
  },
  {
    title: 'Cloths',
    type: 'shops',
    filters: [
      'Clothes',
    ]
  },
  {
    title: 'Electronics',
    type: 'shops',
    filters: [
      'Phone'
    ]
  },
  {
    title: 'Boutique',
    type: 'shops',
    filters: [
      'Boutique'
    ]
  },
  {
    title: 'Jewelry',
    type: 'shops',
    filters: [
      'Jewelry'
    ]
  },
  {
    title: 'Antique',
    type: 'shops',
    filters: [
      'Antique'
    ]
  },
  {
    title: 'Toys',
    type: 'shops',
    filters: [
      'Toys'
    ]
  },
  {
    title: 'Cannabis',
    type: 'shops',
    filters: [
      'Cannabis'
    ]
  },
];

const activeIcons = [
  {
    type: 'walkindex',
    icons: [
      ActiveFeel1,
      ActiveFeel2,
      ActiveFeel3,
    ]
  },
  {
    type: 'lifestyle',
    icons: [
      ActiveGem1,
      ActiveGem2,
      ActiveGem3,
    ]
  }
];

const walkindxID = {
  Nature: 'beautyn',
  Architecture: 'beautym',
  Vibrance: 'activities',
  Comfort: 'comfort',
  Quiet: 'noise',
};

export {
  walkIndexFeatures,
  lifeStyleFeatures,
  carouselImages,
  carouselPlaceholders,
  partnersData,
  otherFeatures,
  impactData,
  neighborhoodIntelTabs,
  neighborhoodSearchFeatures,
  teamData,
  advisorsData,
  surveyQuestions,
  getMarkerCategoryImage,
  recommendedFilters,
  activeIcons,
  walkindxID,
};
