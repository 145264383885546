import React from 'react';
import { formatGemNumber } from '../../helper.js';
import { hexToRgb } from '../../services.js';

const ProgressBar = ({ items, scores, itemClick }) => {
  const totalScore = Object.values(scores).reduce((acc, score) => acc + score, 0);

  // Calculate percentage width, handle case where totalScore is 0 and apply minimum width if needed
  const getPercentage = (value = 1) => {
    if (totalScore === 0) return 100 / items.length;
    const percentage = (value / totalScore) * 100;
    return percentage < 10 ? 10 : percentage; // Minimum width is 10%
  };

  return (
    <div className="bar-score-container">
      {/* Bar Container */}
      <div className="gems-bar">
        {items.map((item) => (
          <div
            key={item.id}
            className="gems-bar-container"
            style={{
              width: `${getPercentage(scores[item.id])}%`,
              '--icon-color': hexToRgb(item.color),
            }}
            onClick={() => itemClick(item.name)}
          >
            <div className="bar-section">
              <span className="bar-score">{totalScore === 0 ? 0 : scores[item.id] || 0}</span>
            </div>
            {item.svg}
            <span className="bar-label">{item.name}</span>
          </div>
        ))}
        <div className="total-score">
          <strong>{formatGemNumber(totalScore)}</strong> <span>Total</span>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
