import { useState, useEffect, useRef } from "react";
import FilterContainer from "../Filter";
import Show from "../../utility/Show";

import { getFeatureIcon, getTabColor } from "../../services";

import { ReactComponent as Toggle } from '../../assets/mobile-toggle.svg';
import { ReactComponent as Star } from '../../assets/star.svg';
import { ReactComponent as Close } from '../../assets/cancel-white.svg';
import { ReactComponent as CloseOutline } from '../../assets/cancel-outline.svg';
import { ReactComponent as Walk } from '../../assets/walk.svg';
import { ReactComponent as Filter } from '../../assets/filter-2.svg';
import { ReactComponent as Check } from '../../assets/check.svg';
import { ReactComponent as Add } from '../../assets/add.svg';
import { ReactComponent as Location } from '../../assets/location.svg';

import { Rating } from 'react-simple-star-rating';
import { distance } from '@turf/turf';

import { isObjectEmpty } from "../../services";

import { recommendedFilters } from "../../constants";

import MarkerList from "../Map/marker-list";

const MobileMarkersFilter = ({ state, dispatch, actions }) => {
  const [showMarkers, setShowMarkers] = useState(false);
  const {
    mapTab,
    selectedMap,
    currentRates,
    categoryFilters,
    showFilter,
    listOfMarkers,
    lifeStyleCompareMaps,
    showCompareLifeStyle,
    map,
    currentAddress,
    currentTab,
    compareLifeStyleAddress,
    isMobile,
    isLoading,
    selectedRecommendedFilters,
  } = state;

  const elementRef = useRef(null);
  const [translateY, setTranslateY] = useState(500);
  const [startY, setStartY] = useState(0);
  const [showMoreFilter, setShowMoreFilters] = useState(false);

  const handleTouchStart = (event) => {
    setStartY(event.touches[0].clientY);
  };

  const handleTouchMove = (event) => {
    const deltaY = event.touches[0].clientY - startY;
    setTranslateY(Math.max(0, deltaY)); // Ensure translateY is non-negative
    if (deltaY >= 350) {
      closeFilters();
    }
  };

  const isWalkIndex = currentTab === 'walkindex';

  useEffect(() => {
    if (showFilter) {
      setTranslateY(0);
    } else {
      setShowMarkers(false);
      setTranslateY(500);
      setShowMoreFilters(false);
    }
  }, [showFilter]);

  const hasRate = (index) => {
    return index + 1 <= Math.max(...currentRates);
  };

  const clearFilters = () => {
    if (
      !currentRates[0] &&
      (categoryFilters?.length === 1 &&
      categoryFilters[0] === '') &&
      selectedRecommendedFilters?.length < 1
    ) {
      return;
    }

    if (categoryFilters?.length > 1 || categoryFilters[0] !== '') {
      dispatch({
        type: 'RESET_CATEGORY',
        payload: [''],
      });
    }
    clearRates();
    if (selectedRecommendedFilters?.length > 0) {
      dispatch({ type: 'SET_SELECTED_RECOMMENDED_FILTERS', payload: [] });
    }
  };

  const clearRates = () => {
    if (currentRates[0] !== null) {
      dispatch({ type: 'SET_RATES', payload: [] });
    }
  }

  const redirectToMap = (marker) => {
    actions.sendDataToGoogleAnalytics(
      'checked_establishment',
      { establishment: marker.name }
    );
    const newPosition = new window.google.maps.LatLng(
      parseFloat(marker.lat),
      parseFloat(marker.lng)
    );
    const currentMap = showCompareLifeStyle
      ? lifeStyleCompareMaps[selectedMap]
      : map;
    currentMap.panTo(newPosition);
    currentMap.setZoom(currentMap.getZoom() + 2.5);

    dispatch({
      type: 'SET_CURRENT_MARKER',
      payload: { markerIndex: selectedMap, markerData: marker },
    });
    setShowMarkers(false);
    closeFilters();
  };

  const calculateDistance = (startPoint, endPoint) => {
    return distance(startPoint, endPoint, { units: 'miles' });
  };

  const renderFeatureMarkers = () => {
    return (
      <div className='feature-markers-wrapper establishment-wrapper' style={getTabColor(false, mapTab)}>
        <MarkerList markers={listOfMarkers?.[selectedMap]} setShowMarkers={setShowMarkers} />
      </div>
    )
  };


  const closeFilters = () => {
    dispatch({ type: 'SET_SHOW_FILTERS', payload: false });
    // clearFilters();
  };

  const filteredRecoFilters = recommendedFilters.filter((item) => {
    return item.type === mapTab?.toLocaleLowerCase();
  });

  const isRecoFilterSelected = (filter) => {
    return selectedRecommendedFilters.some((item) => item === filter);
  };

  const handleRecoFilters = (selectedFilter) => {
    let newFilters = [];
    if (isRecoFilterSelected(selectedFilter.title)) {
      newFilters = selectedRecommendedFilters.filter((filter) => {
        return filter !== selectedFilter.title;
      });
      dispatch({ type: 'SET_SELECTED_RECOMMENDED_FILTERS', payload: newFilters });
      dispatch({ type: 'REMOVE_CATEGORY', payload: selectedFilter.filters });
    } else {
      newFilters = [...selectedRecommendedFilters, selectedFilter.title];
      dispatch({ type: 'SET_SELECTED_RECOMMENDED_FILTERS', payload: newFilters });
      dispatch({ type: 'APPEND_CATEGORY', payload: selectedFilter.filters });
    }
  }

  return (
    <div
      className={`mobile-markers-filter-wrapper ${
        mapTab !== 'Favorites' &&
        !isWalkIndex &&
        showFilter &&
        !isObjectEmpty(compareLifeStyleAddress[selectedMap])
          ? 'open' : ''
      }`}
      ref={elementRef}
      style={{ transform: `translate(-50%, ${translateY}px)` }}
      onTouchStart={isMobile ? handleTouchStart : null}
      onTouchMove={isMobile ? handleTouchMove : null}
    >
      <div className="toggle-btn" onClick={() => closeFilters()}>
        <Toggle />
      </div>
      <div className="filter-markers-header">
        <div className="feater-wrapper" style={getTabColor(false, mapTab)}>
          {getFeatureIcon(false, mapTab)} 
          <Show>
            <Show.When isTrue={showMarkers}>
              Show all
            </Show.When>
            <Show.Else>
              {mapTab} : 
              <span onClick={() => setShowMarkers(true)}>
                {listOfMarkers[selectedMap]?.length}
              </span>
            </Show.Else>
          </Show>
        </div>
        <div className="other-controls">
          <Show>
            <Show.When isTrue={showMarkers}>
              <Close className="close-show-markers" onClick={() => setShowMarkers(false)}/>
            </Show.When>
            <Show.Else>
              <Show.When
                isTrue={
                  mapTab !== 'Transit' &&
                  mapTab !== 'Leisure' &&
                  mapTab !== 'All'
                }
              >
                <div onClick={() => setShowMoreFilters(!showMoreFilter)}>
                  <Filter /> {showMoreFilter ? 'recommended filter' : 'more filter'}
                </div>
              </Show.When>
              <div onClick={() => clearFilters()}>
                <Close /> clear
              </div>
            </Show.Else>
          </Show>
        </div>
      </div>
      <Show>
        <Show.When isTrue={showMarkers}>
          {renderFeatureMarkers()}
        </Show.When>
        <Show.Else>
          <Show.When isTrue={showMoreFilter || mapTab === 'All'}>
            <Show.When isTrue={mapTab !== 'Transit'}>
              <div className="rating-filter-wrapper">
                <span>Ratings</span>
                <div className='rating-buttons-wrapper'>
                  {[...Array(5)].map((_, index) => (
                    <Star
                      key={`rating-star-${index+1}`}
                      className={`${hasRate(index) && 'active-star'}`}
                      onClick={() => actions.setRatings(index + 1)}
                      // style={getStarColor(index)}
                    />
                  ))}
                  <CloseOutline className="clear-rating" onClick={() => clearRates()} />
                </div>
              </div>
            </Show.When>
          </Show.When>
          <Show>
            <Show.When
              isTrue={
                showMoreFilter ||
                mapTab === 'All' ||
                mapTab === 'Leisure' ||
                mapTab === 'Transit'
              }
            >
              <FilterContainer />
            </Show.When>
            <Show.Else>
              <div className="filter-container modern-filter-container">
                <div className="category-list-wrapper">
                  {filteredRecoFilters.map(item => (
                    <div
                      className={`filter-item ${
                          isRecoFilterSelected(item.title)
                            ? 'selected-filter-item'
                            : ''
                        }
                        ${
                          isLoading ? 'btn-loading' : ''
                        }
                      `}
                      style={getTabColor(false, mapTab)}
                      onClick={() => handleRecoFilters(item)}
                    >
                      {/* <span className='filter-item-icon'>
                        {isRecoFilterSelected(item.title) ? (
                          <Check />
                        ) : (
                          <Add />
                        )}
                      </span> */}
                      {item.title}
                    </div>
                  ))}
                </div>
              </div>
            </Show.Else>
          </Show>
        </Show.Else>
      </Show>
    </div>
  )
};

export default MobileMarkersFilter;