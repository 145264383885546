import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import './input.css';

const AddressInput = ({
  value,
  currentAddress,
  handleClick,
  properties,
  index,
  isClearable,
  placeholder = 'Enter an Address',
  isDisabled,
}) => {
  const [selectedAddress, setSelectedAddress] = useState(null);
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    fields: ['ALL'],
  };

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
     inputRef.current,
     options
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
     const place = await autoCompleteRef.current.getPlace();
     const payload = {
      property: place?.formatted_address,
      latitude: place?.geometry?.location?.lat(),
      longitude: place?.geometry?.location?.lng(),
    };
     handleClick(index, payload);
     setSelectedAddress(payload.property)
    });

    if (index === 0) {
      setSelectedAddress(currentAddress?.property)
    }
   }, [index]);

  const handleChange = (selected) => {
    handleClick(index, selected.value);
  };

  return (
    <>
      {/* <Select
        className='location-select'
        classNamePrefix='select'
        isClearable={isClearable}
        isSearchable={true}
        isDisabled={isDisabled}
        onChange={handleChange}
        placeholder={placeholder}
        name='color'
        options={properties}
        defaultValue={value || ''}
      /> */}
      <input
        className='address-search-input'
        value={value}
        ref={inputRef}
      />
    </>
  );
};

export default AddressInput;
