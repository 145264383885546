import React, { useContext } from 'react';
import { StoreContext } from '../../store';
import { distance } from '@turf/turf';
import { Rating } from 'react-simple-star-rating';
import { useSnackbar } from 'notistack';

import Show from "../../utility/Show";

import { ReactComponent as Gem } from '../../assets/modern-gem.svg';
import { ReactComponent as Location } from '../../assets/location-3.svg';
import { ReactComponent as Clock } from '../../assets/time-filled.svg';
import { ReactComponent as Favorite } from '../../assets/marker-popup/favorite.svg';
import { ReactComponent as Delete } from '../../assets/trash-filled.svg';

import { getEstablishmentColor, formatNumber, setLSData } from '../../services';
import { getMarkerCategoryImage } from '../../constants';

const MarkerList = React.memo(({ markers, isRemovable = false, setShowMarkers }) => {
  const  { state, dispatch } = useContext(StoreContext);
  const {
    map,
    currentMarker,
    currentAddress,
    favorites,
    keyPropertyName,
    isMobile,
    showDashboard,
  } = state;
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (marker) => {
    const newPosition = new window.google.maps.LatLng(
      parseFloat(marker.lat),
      parseFloat(marker.lng)
    );
    map.panTo(newPosition);
    // map.setZoom(map.getZoom() + 2.5);
    map.fetchMarkerDetails(marker)
    if (isMobile) {
      dispatch({
        type: 'SET_SHOW_ESTABLISHMENTS',
        payload: false,
      });

      if (setShowMarkers) {
        setShowMarkers(false);
      }
    }
  };

  const isMarkerSelected = (marker) => {
    if (!currentMarker[0]) return false;
    return (
      marker?.lat === currentMarker?.[0].lat &&
      marker?.lng === currentMarker?.[0].lng
    );
  };

  const getMarkerCategories = (marker) => {
    return marker?.category
      ? marker.category.split(';').map(category => {
        return (
          <div className='popup-category-item'>
            {category}
          </div>
        )
      })
      : '';
  };

  const calculateDistance = (startPoint, endPoint) => {
    return distance(startPoint, endPoint, { units: 'miles' });
  };

  const gemStyle = (marker) => {
    const style = {
      '--gem-bg': getEstablishmentColor(marker?.type),
    };
    return style;
  };

  const saveToFavorites = (item) => {
    const favoritesCopy = { ...favorites };
    const propertyFavorites = favorites[keyPropertyName];
    if (propertyFavorites?.length > 0) {
      propertyFavorites.push(item);
      favoritesCopy[keyPropertyName] = [...propertyFavorites];
    } else {
      favoritesCopy[keyPropertyName] = [item];
    }
    setLSData('favorites', favoritesCopy);
    dispatch({
      type: 'SET_FAVORITES',
      payload: favoritesCopy,
    });
  };

  const removeFromFavorites = (item) => {
    const favoritesCopy = { ...favorites };
    const updatedFavorites = favoritesCopy[keyPropertyName].filter(
      (favorite) => favorite.id !== item.id
    );
    favoritesCopy[keyPropertyName] = updatedFavorites;
    setLSData('favorites', favoritesCopy);
    dispatch({
      type: 'SET_FAVORITES',
      payload: favoritesCopy,
    });
  };

  const handleFavorite = (e, item) => {
    e.stopPropagation();
    if (favorites[keyPropertyName]?.some((fav) => fav.id === item.id)) {
      removeFromFavorites(item);
    } else {
      saveToFavorites(item);
      enqueueSnackbar('Added to your Favorites', {
        variant: 'info',
        preventDuplicate: true,
        autoHideDuration: 2000,
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      });
    }
  };

  const isFavoriteChecked = (item) => {
    return (
      favorites[getKeyName()]?.length &&
      favorites[getKeyName()]?.some((fav) => fav.id === item?.id)
    );
  };

  const getKeyName = () => {
    return currentAddress ? String(currentAddress?.property) : keyPropertyName;
  };

  const sortedMarkers = markers?.sort((a, b) => b.rating - a.rating);

  return (
    <div className='marker-list-wrapper'>
      <Show>
        <Show.When isTrue={markers?.length > 0}>
          {sortedMarkers?.map((marker, index) => (
            <div
              className={`marker-item ${
                isMarkerSelected(marker) ? 'selected' : ''
              }`}
              onClick={() => showDashboard ? null : handleClick(marker)}
            >
              <div className='marker-details-title'>
                <div className='marker-details-gem'>
                  <Gem className='marker-details-gem-icon' style={gemStyle(marker)} />
                  {getMarkerCategoryImage(marker?.type || marker?.Type)}
                </div>
                <div className='marker-details-name-category'>
                  <p className='marker-address-name'>
                    {marker?.name || marker?.Name}
                    <Show.When isTrue={marker?.category}>
                      <div className='popup-category-container'>
                        {getMarkerCategories(marker)}
                      </div>
                    </Show.When>
                  </p>
                </div>
                <Show>
                  <Show.When isTrue={!isRemovable}>
                  <Favorite
                    className={`add-to-favorites-icon ${
                      isFavoriteChecked(marker) && 'added-to-favorites'
                    }`}
                    onClick={(e) => handleFavorite(e, marker)}
                  />
                  </Show.When>
                  <Show.Else>
                    <Delete className='remove-icon' onClick={(e) => handleFavorite(e, marker)} />
                  </Show.Else>
                </Show>
              </div>
              <div className='marker-other-details'>
                <Show.When isTrue={marker?.address}>
                  <div>
                    <Location className='location-icon' />
                    <span>{marker?.address || marker?.Address}</span>
                  </div>
                </Show.When>
                <div>
                  {/* <Show.When isTrue={marker?.OpeningHours && inputValue !== ''}>
                    <Clock />
                    <span>{convertYelpTimeFormat(marker?.OpeningHours)}</span>
                  </Show.When> */}
               
                    <Show.When isTrue={marker.rating && marker.rating > 0}>
                      <Rating
                        initialValue={formatNumber(marker.rating)}
                        allowFraction
                        size={20}
                        readonly
                        fillColor="#ffffff"
                        emptyColor='#000000'
                        SVGstrokeColor="#d4d4d4"
                        SVGstorkeWidth="0.25px"
                      />
                      {formatNumber(marker?.rating || marker?.Rating)}
                    </Show.When>

                    <span className='distance'>
                      {calculateDistance(
                      [marker.lng, marker.lat],
                      [currentAddress?.longitude, currentAddress?.latitude]
                      ).toFixed(1)}{' '}
                      mi
                    </span>
                </div>
              </div>
            </div>
          ))}
        </Show.When>
        <Show.Else>
          <div className='marker-item no-result'>
            no result
          </div>
        </Show.Else>
      </Show>
    </div>
  )
});

export default MarkerList;