import { useState, useEffect, useCallback } from "react";
import { useParams } from 'react-router-dom';

import Show from "../../utility/Show";
import { WalkingDistanceButtons } from '../WalkingDistanceButtons/WalkingDistanceButtons';
import { WalkingLoader } from '../Loaders/index.jsx';
import SlidingButtons from './sliding-buttons';

import { GoogleMap, Circle, Marker } from '@react-google-maps/api';

import useGoogleMaps from '../../useGoogleMaps';
import { findColor, getArea, convertMarkers } from "../../services";
import { walkIndexFeatures, lifeStyleFeatures } from "../../constants";

import Cluster from '../../assets/cluster/cluster1.svg';
import FoodMarker from '../../assets/cluster/food.svg';
import ShopMarker from '../../assets/cluster/shops.svg';
import ServiceMarker from '../../assets/cluster/services.svg';
import TransitMarker from '../../assets/cluster/transit.svg';
import EntertainmentMarker from '../../assets/cluster/entertainment.svg';

import useGemMarkers from "../../hooks/useGemMarkers.js";

const Map = ({ state, dispatch, actions, fetchAddressData }) => {
  const {
    currentAddress,
    isGoogleMapsLoaded,
    currentDistance,
    currentWalkindxType,
    currentLifestyleType,
    currentPresence,
    isMapLoading,
    isLoading,
    isMobile,
    token,
    apiKey,
    userDetails,
  } = state;
  const { '*': encodedAddress } = useParams();
  const address = decodeURIComponent(encodedAddress);

  const [isMounted, setIsMounted] = useState(false);
  const [mapInstance, setMap] = useState(null);
  const [mapCenter ,setMapCenter] = useState(null);
  const [zoom, setZoom] = useState(16);
  const [originalMarkers, setOriginalMarkers] = useState([]);
  const [filteredMarkers, setFilteredMarkers] = useState([]);

  const isGoogleMapsReady = useGoogleMaps();

  const controller = new AbortController();
  const signal = controller.signal;

  const {
    data: gemMarkers,
    error: gemMarkersError,
    isLoading: gemMarkersLoading,
  } = useGemMarkers(
    currentAddress?.latitude,
    currentAddress?.longitude,
    'all',
    token,
    apiKey,
    getArea(currentDistance),
    signal
  );

  useEffect(() => {
    if (!gemMarkers) return;

    const markers = convertMarkers(gemMarkers)
    handleGemMarkers(markers);
    actions.handleFetchedMarkers(markers);
  }, [gemMarkers]);

  const handleGemMarkers = (markers) => {
    setOriginalMarkers(markers)
    setFilteredMarkers(markers);
  }

  useEffect(() => {
    setIsMounted(true);
    if (!currentAddress) {
      fetchAddressData(address);
    }
  }, []);

  useEffect(() => {
    if (!currentAddress) return;

    if (currentWalkindxType) {
      changeWalkindexType();
    }

    document.title = `${currentAddress.property} - Walkspan`;

    if (currentAddress) {
      setMapCenter({
        lat: parseFloat(currentAddress?.latitude),
        lng: parseFloat(currentAddress?.longitude),
      });
    }
  }, [currentAddress]);

  useEffect(() => {
    if (isGoogleMapsReady) {
      dispatch({
        type: 'SET_IS_GOOGLE_MAPS_LOADED',
        payload: isGoogleMapsReady,
      });
    }
  }, [isGoogleMapsReady]);

  useEffect(() => {
    if(!isMounted || !mapInstance) return;

    changeWalkindexType();
  }, [currentWalkindxType]);

  useEffect(() => {
    if (!isMounted) return;

    if (currentLifestyleType.length > 0) {
      filterMarkersByType();
    } else {
      setFilteredMarkers(originalMarkers);
    }
  }, [currentLifestyleType]);

  useEffect(() => {
    if (!currentAddress || !isMounted) return;

    const zoomValue = getZoomValue();
    setZoom(zoomValue);

    if (currentWalkindxType) {
      changeWalkindexType();
    }
  }, [currentDistance]);

  const desktopZoom = (distance) => {
    switch (distance) {
      case '1':
        return 15.5;
      case '2':
        return 14.5;
      case '3':
        return 14;
      default:
        return;
    }
  };

  const mobileZoom = (distance) => {
    switch (distance) {
      case '1':
        return 15;
      case '2':
        return 14;
      case '3':
        return 13;
      default:
        return;
    }
  };

  const getZoomValue = () => {
    return isMobile
      ? mobileZoom(currentDistance)
      : desktopZoom(currentDistance);
  };

  const getGeoserverLink = (layer, bbox, type, viewParams) => {
    let geoserver = 'geoserver/walkspan/wms?';
    geoserver += '&REQUEST=GetMap';
    geoserver += '&SERVICE=WMS';
    geoserver += '&VERSION=1.1.1';
    geoserver += '&LAYERS=walkspan:' + layer; //walkspan_circle
    geoserver += '&FORMAT=image/png';
    geoserver += '&BGCOLOR=0xFFFFFF';
    geoserver += '&TRANSPARENT=TRUE';
    geoserver += '&SRS=EPSG:' + '4326';
    geoserver += '&BBOX=' + bbox;
    geoserver += '&WIDTH=256';
    geoserver += '&HEIGHT=256';
    geoserver += '&STYLES=' + type;
    geoserver += '&VIEWPARAMS=' + viewParams;
    return geoserver;
  };

  const changeWalkindexType = () => {
    const currentMap = mapInstance;
    if (currentWalkindxType) {
      currentMap?.overlayMapTypes.clear();
      const radiusMap = {
        'quarter-mile': 25,
        'half-mile': 50,
        'one-mile': 100,
      };

      let viewParams = '_radius:' + (radiusMap[getArea(currentDistance)] || 10000);

      viewParams += `;_lat:${(currentAddress?.latitude * 10000).toFixed()};_lng:${Math.abs(
        currentAddress?.longitude * 10000
      ).toFixed()}`;

      const getFilter = (viewParams += currentPresence
        ? `;_walk_indx_metric:${
            currentWalkindxType === 'Quiet'
              ? 'noise'
              : currentWalkindxType === 'Vibrance'
              ? 'activities'
              : currentWalkindxType?.toLowerCase()
          };_score_filter:${currentPresence}`
        : '');

      const layer = new window.google.maps.ImageMapType({
        getTileUrl: (coord, zoom) => {
          const zfactor = Math.pow(2, zoom);
          const proj = currentMap.getProjection();

          // Get Long Lat coordinates
          const bot = proj.fromPointToLatLng(
            new window.google.maps.Point(
              ((coord.x + 1) * 256) / zfactor,
              ((coord.y + 1) * 256) / zfactor
            )
          );
          const top = proj.fromPointToLatLng(
            new window.google.maps.Point(
              (coord.x * 256) / zfactor,
              (coord.y * 256) / zfactor
            )
          );
          // Create the Bounding box string
          const bbox = `${top.lng()},${bot.lat()},${bot.lng()},${top.lat()}`;
          // Build WMS URL
          const geoserver = getGeoserverLink(
            'walkspan_circle',
            bbox,
            `${
              currentWalkindxType === 'Quiet'
                ? 'noise'
                : currentWalkindxType === 'Vibrance'
                ? 'activities'
                : currentWalkindxType?.toLowerCase()
            }WalkspanV3`,
            getFilter
          );
          const url =`${process.env.REACT_APP_API_URL}/geoserver/?geoserver=${encodeURIComponent(
            geoserver
          )}
          &email=${userDetails?.email}&api_key=${userDetails?.api_key}`;

          return url;
        },
        tileSize: new window.google.maps.Size(256, 256),
        maxZoom: 9,
        minZoom: 0,

        isPng: true,
        name: 'WalkspanLayer',
      });

      if (layer) {
        if (currentMap?.overlayMapTypes.length === 1) {
          currentMap?.overlayMapTypes.push(layer);
        } else {
          currentMap?.overlayMapTypes.setAt(1, layer);
        }
      }
    } else {
      currentMap?.overlayMapTypes.removeAt(1);
    }
  };

  const isInCurrentLifestyleType = (feature) => {
    return currentLifestyleType.some((type) => {
      return type?.toLowerCase() === feature.toLowerCase();
    });
  };

  const isFeatureSelected = (feature) => {
    return (feature === currentWalkindxType || isInCurrentLifestyleType(feature))
  };

  const handleFeelFeature = (feature) => {
    const payload = currentWalkindxType?.toLowerCase() === feature?.toLowerCase()
      ? null : feature;
    dispatch({ type: 'SET_CURRENT_WALKINDX_TYPE', payload: payload });
  };

  const handleGemFeature = (feature) => {
    const actionType = isInCurrentLifestyleType(feature)
      ? 'REMOVE_CURRENT_LIFESTYLE_TYPE'
      : 'SET_CURRENT_LIFESTYLE_TYPE';
    dispatch({ type: actionType, payload: feature });
  }

  const getMarkerIcon = (type) => {
    switch (type) {
      case 'food':
        return FoodMarker;
      case 'shops':
        return ShopMarker;
      case 'services':
        return ServiceMarker;
      case 'transit':
        return TransitMarker;
      case 'leisure':
        return EntertainmentMarker;
      default:
        return Cluster;
    }
  };

  const customMarkerIcon = (type) => {
    const getMarkerSize = filteredMarkers?.length > 20 ? [15, 20] : [20, 25];
    return {
      url: getMarkerIcon(type),
      scaledSize: new window.google.maps.Size(
        getMarkerSize[0],
        getMarkerSize[1]
      ),
    };
  };

  const filterMarkersByType = () => {
    const markers = originalMarkers?.filter((marker) => {
      return currentLifestyleType.some((type) => {
        return type.toLowerCase() === marker.type.toLowerCase()
      });
    });
    setFilteredMarkers(markers);
  };


  const handleZoomChanged = () => {
    if (mapInstance) {
      const newZoom = mapInstance.getZoom();
      setZoom(newZoom);
    }
  };

  const onLoad = useCallback(function callback(map1) {
    map1.setMapTypeId('satellite');
    setMap(map1);
    dispatch({ type: 'SET_CURRENT_WALKINDX_TYPE', payload: 'Nature' });
  }, []);

  return (
    <div className='location-container'>
      <h3>{currentAddress?.property || address}</h3>
      <div className="map-container" style={{
        width: '100%',
        height: isMobile ? '370px' : '302px',
        position: 'relative',
      }} >
        <Show.When isTrue={isGoogleMapsLoaded}>
          <GoogleMap
            mapContainerStyle={{ height: '100%', width: '100%' }}
            defaultCenter={mapCenter}
            zoom={zoom}
            center={mapCenter}
            onLoad={onLoad}
            onZoomChanged={handleZoomChanged}
            options={{
              zoomControl: false,
              fullscreenControl: false,
              streetViewControl: false,
              styles: [
                {
                  featureType: 'all',
                  elementType: 'all',
                },
                {
                  featureType: 'poi',
                  stylers: [{ visibility: 'off' }],
                },
                {
                  featureType: 'transit',
                  elementType: 'labels',
                  stylers: [{ visibility: 'off' }],
                },
                {
                  featureType: 'water',
                  elementType: 'labels',
                  stylers: [{ visibility: 'off' }],
                },
                {
                  featureType: 'administrative',
                  elementType: 'labels.text.fill',
                  stylers: [
                    {
                      visibility: 'off',
                    },
                  ],
                },
                {
                  featureType: 'landscape',
                  elementType: 'geometry',
                  stylers: [
                    { visibility: 'on' },
                    { hue: '#007bff' },
                    { saturation: -20 },
                    { lightness: -10 },
                  ],
                },
              ],
            }}
          >
            <WalkingDistanceButtons showTitle={false} isDark={true} />
            <Show.When isTrue={currentDistance >= 3}>
              <Circle
                center={mapCenter}
                radius={1609.34}
                options={{
                  strokeColor: findColor('lifestyle', 'All'),
                  strokeOpacity: 1,
                  strokeWeight: 3,
                  fillColor: 'transparent',
                }}
              />
            </Show.When>

            <Show.When isTrue={currentDistance >= 2}>
              <Circle
                center={mapCenter}
                radius={804.68}
                options={{
                  strokeColor: findColor('lifestyle', 'All'),
                  strokeOpacity: 1,
                  strokeWeight: 3,
                  fillColor: 'transparent',
                }}
              />
            </Show.When>

            <Show.When isTrue={currentDistance >= 1}>
              <Circle
                center={mapCenter}
                radius={402.34}
                options={{
                  strokeColor: findColor('lifestyle', 'All'),
                  strokeOpacity: 1,
                  strokeWeight: 3,
                  fillColor: 'transparent',
                }}
              />
            </Show.When>

            <Show.When
              isTrue={
                !isMapLoading[0] &&
                filteredMarkers?.length > 0 &&
                typeof filteredMarkers !== 'string'
              }
            >
              {filteredMarkers?.map((marker, index) => (
                <Marker
                  id={`cluster-marker-${index}`}
                  key={`cluster-marker-${index}`}
                  position={{
                    lat: parseFloat(marker.lat),
                    lng: parseFloat(marker.lng),
                  }}
                  icon={customMarkerIcon(marker.type)}
                ></Marker>
              ))}
            </Show.When>
            <Show.When isTrue={isLoading || isMapLoading[0] || gemMarkersLoading}>
              <WalkingLoader />
            </Show.When>
          </GoogleMap>
        </Show.When>
      </div>
      <div className='controls'>
        <SlidingButtons
          className='feel-btns'
          isLoading={isLoading || isMapLoading[0]}
          isMobile={isMobile}
          features={walkIndexFeatures}
          onButtonClick={handleFeelFeature}
          isFeatureSelected={isFeatureSelected}
        />
        <SlidingButtons
          className='gem-btns'
          isLoading={isLoading || isMapLoading[0]}
          isMobile={isMobile}
          features={lifeStyleFeatures.slice(1, -1)}
          onButtonClick={handleGemFeature}
          isFeatureSelected={isFeatureSelected}
        />
      </div>
    </div>
  )
};

export default Map;