import React, { createContext, useEffect, useReducer, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import convert from 'color-convert';
import {
  getRatings,
  getLifeStyleEssentials,
  login,
  logout,
  getNeighborhoodScore,
  getLifeStyleScores,
  fetchPlaceId,
  fetchPlaceDetails,
  getPlaceByLatLng,
  getProfilebyEmail,
  forgotPassword,
  confirmForgotPassword,
  signup,
  resendSignup,
  confirmSignup,
  verifyAPIKey,
  fetchGoogleRating,
} from './api.js';
import propertiesData from './data/data.json';
import searchPropertiesData from './data/properties.json';
import { getArea, saveAddressToRecentlyViewed } from './services.js';
import useGoogleMaps from './useGoogleMaps.js';
import { sign } from 'chart.js/helpers';

import {
  setLSData,
  getLSData,
  setCookieValue,
  getCookieValue,
  useAutoLogout,
  convertMarkers,
} from './services.js';
import { recommendedFilters } from './constants.js';
import { type } from '@testing-library/user-event/dist/type/index.js';

// Define initial state and reducer function
const initialState = {
  isGoogleMapsLoaded: false,
  map: null,
  isMapLoaded: null,
  currentMarker: Array(4).fill(null),
  showModal: false,
  isMobileSidebarOpen: false,
  isMenuOpen: false,
  isMobile: false,
  listOfProperties: propertiesData.filter((item) => item.type === 'Sale'),
  selectedProperty: propertiesData[0],
  searchPropertiesData: searchPropertiesData.streeteasy.properties.property,
  currentTab: 'lifestyle',
  mapTab: 'All',
  originalMarkers: [],
  listOfMarkers: [],
  walkIndexList: [],
  currentDistance: '1',
  zoom: 14,
  mapType: 'satellite',
  currentPresence: '',
  currentRates: [],
  categoryFilters: [''],
  city: '',
  placename: '',
  showWalkIndexCompare: false,
  showCompareLifeStyle: false,
  lifeStyleScores: Array(4).fill({}),
  walkindexScores: Array(4).fill({}),
  token: '',
  compareAddress: Array(4).fill({}),
  compareLifeStyleAddress: Array(4).fill({}),
  scoreProperties: Array(4).fill(0),
  showEstablishments: false,
  showFilter: false,
  showRatingFilter: false,
  showDashboard: true,
  urlParams: false,
  currentMap: null,
  favorites: [],
  isDrawerCollapse: false,
  showFeedbackForm: false,
  isLoading: false,
  lifeStyleCompareMaps: Array(4).fill({}),
  keyPropertyName: String(propertiesData[1].property),
  currentWalkindxType: null,
  currentLifestyleType: [],
  isiPhone: false,
  currentCompareMap: { index: 0, visibleForm: false },
  compareMapsSliderIndex: 0,
  showMapStreetView: Array(4).fill(false),
  isStandalone: false,
  currentAddress: null,
  currentCity: '',
  isDesktopSidebarOpen: false,
  showSearchAddress: true,
  branding: '#000000',
  isPlugin: false,
  isFullScreenMap: false,
  isEstablishmentSearchOpen: false,
  establishmentSearchResult: {},
  currentView: 'Modern',
  enableChangeAddress: false,
  showTutorial: false,
  enableTutorial: false,
  showProperties: false,
  savedAddresses: [],
  showCombineSearch: false,
  selectedMap: 0,
  quickSearchMarkers: {
    markers: [],
    isLoading: true,
  },
  previousPage: [],
  isSmallerScreen: false,
  isMapLoading: Array(4).fill(false),
  reachedMaxLimit: false,
  isAddressInNYC: false,
  isDisclaimerOpen: false,
  errorMessage: '',
  userDetails: {},
  FPSubmitted: false,
  confirmFPSubmitted: false,
  signupSubmitted: false,
  confirmSignupSubmitted: false,
  apiKey: null,
  isInTrialMode: false,
  showScoreGuide: false,
  showMapMobileControls: false,
  showComparePopup: false,
  recentlyViewedAddresses: [],
  showPrivacyPolicy: false,
  clientName: null,
  isServerError: false,
  placeCardDetails: Array(4).fill(null),
  isDarkMode: true,
  showFavoriteList: false,
  isGoogleMode: false,
  showRecentHistoy: false,
  showCompareConfirmExit: false,
  selectedRecommendedFilters: [],
  showMapQS: false,
  showMarkerList: false,
  showAPIKeyScreen: false,
  isEmbedMode: false,
  setCenter: false,
  signupEmail: null,
  forgotPasswordEmail: null,
  showLegend: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_IS_GOOGLE_MAPS_LOADED':
      return { ...state, isGoogleMapsLoaded: action.payload };

    case 'SET_MAP_INSTANCE':
      return { ...state, map: action.payload };

    case 'SET_COMPARE_MAPS':
      const mapIndex = action.payload.index;
      const mapObject = action.payload.object;
      const updatedCompareMaps = [...state.lifeStyleCompareMaps];
      updatedCompareMaps[mapIndex] = mapObject;
      return { ...state, lifeStyleCompareMaps: updatedCompareMaps };

    case 'SET_CURRENT_MARKER':
      const { markerIndex, markerData } = action.payload;
      const newCurrentMarker = [...state.currentMarker];
      newCurrentMarker[markerIndex] = markerData;
      return { ...state, currentMarker: newCurrentMarker };
    case 'GET_PROPERTY_BY_COORDINATES':
      const { latitude, longitude } = action.payload;

      // Find property based on criteria
      const foundProperty = state.listOfProperties.find(
        (item) => item.latitude === latitude && item.longitude === longitude
      );

      return {
        ...state,
        selectedProperty: foundProperty || state.listOfProperties[0],
      };

    case 'GET_PROPERTY_BY_NAME':
      const propertyName = action.payload;
      const property = state.listOfProperties.find((prop) =>
        prop.property.toLowerCase().includes(propertyName.toLowerCase())
      );

      const getFinalProperty =
        property?.length > 1
          ? property[0]
          : property || state.listOfProperties[0];

      return {
        ...state,
        selectedProperty: getFinalProperty,
      };
    case 'SET_CURRENT_TAB':
      return {
        ...state,
        currentTab: action.payload,
        mapTab: action.payload === 'walkindex' ? 'Nature' : 'All',
      };
    case 'SET_PROPERTY_LIST':
      return {
        ...state,
        listOfProperties: action.payload,
        selectedProperty: action.payload[0],
      };
    case 'SET_MOBILE_SIDEBAR':
      return { ...state, isMobileSidebarOpen: action.payload };
    case 'SET_SHOW_LEGEND':
      return { ...state, showLegend: action.payload };
    case 'SET_DESKTOP_SIDEBAR':
      return { ...state, isDesktopSidebarOpen: action.payload };
    case 'SET_MENU_SIDEBAR':
      return { ...state, isMenuOpen: action.payload };
    case 'SET_IS_MOBILE':
      return { ...state, isMobile: action.payload };
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_DRAWER':
      return { ...state, isDrawerCollapse: action.payload };
    case 'SET_COMPARE_VISIBLE':
      return { ...state, showWalkIndexCompare: action.payload };
    case 'SET_COMPARE_LIFESTYLE':
      return { ...state, showCompareLifeStyle: action.payload };
    case 'SET_SHOW_ESTABLISHMENTS':
      return { ...state, showEstablishments: action.payload };
    case 'SET_SHOW_FILTERS':
      return { ...state, showFilter: action.payload };
    case 'SET_SHOW_RATING_FILTER':
      return { ...state, showRatingFilter: action.payload };
    case 'SET_LIST_OF_MARKERS':
      const { markersIndex, markersList } = action.payload;

      const updatedListOfMarkers = [...state.listOfMarkers];

      updatedListOfMarkers[markersIndex] = markersList;
      return { ...state, listOfMarkers: updatedListOfMarkers };
    case 'SET_LIST_OF_ORIGINAL_MARKERS':
      return { ...state, originalMarkers: action.payload };
    case 'SET_CURRENT_DISTANCE':
      return { ...state, currentDistance: action.payload };
    case 'SET_CURRENT_ZOOM':
      return { ...state, zoom: action.payload };
    case 'SET_CENTER':
      return { ...state, setCenter: !state.setCenter };
    case 'SET_CURRENT_MAPTYPE':
      return { ...state, mapType: action.payload };
    case 'SET_WALKINDEX':
      return { ...state, walkIndexList: action.payload };
    case 'SET_CURRENT_PRESENCE':
      return { ...state, currentPresence: action.payload };
    case 'SET_LIFESTYLE_SCORES':
      const { scoreIndex, scoreData } = action.payload;

      const newLifeStyleScores = [...state.lifeStyleScores];

      newLifeStyleScores[scoreIndex] = scoreData;

      return {
        ...state,
        lifeStyleScores: newLifeStyleScores,
        isLoading: false,
      };
    case 'SET_WALKINDEX_SCORES':
      const { walkindxScoreData, walkindxScoreIndex } = action.payload;

      const newWalkindexScores = [...state.walkindexScores];

      newWalkindexScores[walkindxScoreIndex] = walkindxScoreData;

      return {
        ...state,
        walkindexScores: newWalkindexScores,
        isLoading: false,
      };
    case 'SET_CITY':
      return { ...state, city: action.payload };
    case 'SET_MAP':
      return { ...state, currentMap: action.payload };
    case 'SET_RATES':
      return { ...state, currentRates: action.payload };

    case 'SEARCH_CATEGORY':
      const newCategoryFilters = [...state.categoryFilters];
      newCategoryFilters[0] = action.payload;

      return {
        ...state,
        categoryFilters: newCategoryFilters,
      };
    case 'APPEND_CATEGORY':
      return {
        ...state,
        categoryFilters: [...state.categoryFilters, ...action.payload],
      };
    case 'REMOVE_CATEGORY':
      return {
        ...state,
        categoryFilters: state.categoryFilters.filter(
          (value) => !action.payload.includes(value)
        ),
      };
    case 'RESET_RATE':
      return {
        ...state,
        currentRates: action.payload,
      };
    case 'RESET_CATEGORY':
      return {
        ...state,
        categoryFilters: action.payload,
      };
    case 'SET_PLACENAME':
      return { ...state, placename: action.payload };
    case 'SET_PARAMS':
      return { ...state, urlParams: action.payload };
    case 'SET_MAP_TAB':
      return { ...state, mapTab: action.payload };
    case 'SET_SHOW_MODAL':
      return { ...state, showModal: action.payload };
    case 'SET_SHOW_DASHBOARD':
      return { ...state, showDashboard: action.payload };
    case 'SET_TOKEN':
      return { ...state, token: action.payload };
    case 'SET_API_KEY':
      return { ...state, apiKey: action.payload };

    case 'UPDATE_LIFESTYLE_COMPARE_ADDRESS':
      const { item, address } = action.payload;

      // Create a copy of the compareAddress array
      const compareAddress = [...state.compareLifeStyleAddress];

      // Set the object at the desired index
      compareAddress[item] = address;

      // Return the updated state
      return {
        ...state,
        compareLifeStyleAddress: compareAddress,
      };

    case 'RESET_LIFESTYLE_COMPARE_ADDRESS':
      const newArray = Array(4).fill({});
      newArray[0] = action.payload;
      return { ...state, compareLifeStyleAddress: newArray };

    case 'RESET_LIFESTYLE_COMPARE_MAPS':
      const newlifeStyleCompareMaps = Array(4).fill({});
      return { ...state, lifeStyleCompareMaps: newlifeStyleCompareMaps };

    case 'UPDATE_COMPARE_ADDRESS':
      const { index, object } = action.payload;

      const updatedCompareAddress = [...state.compareAddress];

      updatedCompareAddress[index] = object;

      return {
        ...state,
        compareAddress: updatedCompareAddress,
      };

    case 'RESET_COMPARE_ADDRESS':
      const emptyArray = Array(4).fill({});
      emptyArray[0] = action.payload;
      return { ...state, compareAddress: emptyArray };

    case 'UPDATE_COMPARE':
      const { number, length } = action.payload;

      const addressList = [...state.scoreProperties];

      addressList[number] = length;

      return {
        ...state,
        scoreProperties: addressList,
      };
    case 'SET_FAVORITES':
      return { ...state, favorites: action.payload };

    case 'SET_SHOW_FEEDBACK_FORM':
      return { ...state, showFeedbackForm: action.payload };

    case 'SET_KEY_PROPERTY_NAME':
      return { ...state, keyPropertyName: action.payload };

    case 'SET_CURRENT_WALKINDX_TYPE':
      return { ...state, currentWalkindxType: action.payload };

    case 'SET_CURRENT_LIFESTYLE_TYPE':
      const newCurrentLifestyleType = [
        ...state.currentLifestyleType,
        action.payload,
      ];
      return { ...state, currentLifestyleType: newCurrentLifestyleType };

    case 'REMOVE_CURRENT_LIFESTYLE_TYPE':
      const filteredCurrentLifestyleType = state.currentLifestyleType.filter(
        (type) => type !== action.payload
      );

      return { ...state, currentLifestyleType: filteredCurrentLifestyleType };

    case 'RESET_CURRENT_LIFESTYLE_TYPE':
      return { ...state, currentLifestyleType: [] };

    case 'SET_IS_IPHONE':
      return { ...state, isiPhone: action.payload };

    case 'SET_CURRENT_COMPARE_MAP':
      return { ...state, currentCompareMap: action.payload };

    case 'SET_COMPARE_MAPS_SLIDER_INDEX':
      return { ...state, compareMapsSliderIndex: action.payload };

    case 'SET_SHOW_MAP_STREET_VIEW':
      const { svIndex, svValue } = action.payload;
      const updatedShowMapStreetView = [...state.showMapStreetView];

      updatedShowMapStreetView[svIndex] = svValue;
      return { ...state, showMapStreetView: updatedShowMapStreetView };

    case 'SET_IS_STANDALONE':
      return { ...state, isStandalone: action.payload };

    case 'SET_CURRENT_ADDRESS':
      return { ...state, currentAddress: action.payload };

    case 'SET_CURRENT_CITY':
      return { ...state, currentCity: action.payload };
    case 'SET_SHOW_SEARCH_ADDRESS':
      return { ...state, showSearchAddress: action.payload };
    case 'SET_BRANDING':
      return { ...state, branding: action.payload };
    case 'SET_IS_PLUGIN':
      return { ...state, isPlugin: action.payload };
    case 'SET_IS_FULL_SCREEN_MAP':
      return { ...state, isFullScreenMap: action.payload };
    case 'SET_IS_ESTABLISHMENT_SEARCH_OPEN':
      return { ...state, isEstablishmentSearchOpen: action.payload };
    case 'SET_ESTABLISHMENT_SEARCH_RESULT':
      return { ...state, establishmentSearchResult: action.payload };
    case 'SET_CURRENT_VIEW':
      return { ...state, currentView: action.payload };
    case 'SET_ENABLE_CHANGE_ADDRESS':
      return { ...state, enableChangeAddress: action.payload };
    case 'SET_SHOW_TUTORIAL':
      return { ...state, showTutorial: action.payload };
    case 'SET_ENABLE_TUTORIAL':
      return { ...state, enableTutorial: action.payload };
    case 'SET_SHOW_PROPERTIES':
      return { ...state, showProperties: action.payload };
    case 'SET_SAVED_ADDRESSES':
      return { ...state, savedAddresses: action.payload };
    case 'SET_SHOW_COMBINE_SEARCH':
      return { ...state, showCombineSearch: action.payload };
    case 'SET_SELECTED_MAP':
      return { ...state, selectedMap: action.payload };
    case 'SET_QUICK_SEARCH_MARKERS':
      return { ...state, quickSearchMarkers: action.payload };
    case 'SET_PREVIOUS_PAGE':
      const newPreviousPage = [...state.previousPage, action.payload];
      return { ...state, previousPage: newPreviousPage };
    case 'FILTER_PREVIOUS_PAGE':
      return { ...state, previousPage: action.payload };
    case 'SET_IS_SMALLER_SCREEN':
      return { ...state, isSmallerScreen: action.payload };
    case 'SET_IS_MAP_LOADING':
      const { loadingIndex, loadingValue } = action.payload;

      const updatedIsMapLoading = [...state.isMapLoading];

      updatedIsMapLoading[loadingIndex] = loadingValue;
      return { ...state, isMapLoading: updatedIsMapLoading };
    case 'SET_REACHED_MAX_LIMIT':
      return { ...state, reachedMaxLimit: action.payload };
    case 'SET_IS_ADDRESS_IN_NYC':
      return { ...state, isAddressInNYC: action.payload };
    case 'SET_IS_DISCLAIMER_OPEN':
      return { ...state, isDisclaimerOpen: action.payload };
    case 'SET_ERROR_MESSAGE':
      return { ...state, errorMessage: action.payload };
    case 'SET_USER_DETAILS':
      return { ...state, userDetails: action.payload };
    case 'SET_FORGOT_PASSWORD_SUBMITTED':
      return { ...state, FPSubmitted: action.payload };
    case 'SET_CONFIRM_FORGOT_PASSWORD_SUBMITTED':
      return { ...state, confirmFPSubmitted: action.payload };
    case 'SET_SIGNUP_SUBMITTED':
      return { ...state, signupSubmitted: action.payload };
    case 'SET_CONFIRM_SIGNUP_SUBMITTED':
      return { ...state, confirmSignupSubmitted: action.payload };
    case 'SET_IS_IN_TRIAL_MODE':
      return { ...state, isInTrialMode: action.payload };
    case 'SET_SHOW_SCORE_GUIDE':
      return { ...state, showScoreGuide: action.payload };
    case 'SET_SHOW_MAP_MOBILE_CONTROLS':
      return { ...state, showMapMobileControls: action.payload };
    case 'SET_SHOW_COMPARE_POPUP':
      return { ...state, showComparePopup: action.payload };
    case 'SET_RECENTLY_VIEWED_ADDRESSES':
      return { ...state, recentlyViewedAddresses: action.payload };
    case 'SET_SHOW_PRIVACY_POLICY':
      return { ...state, showPrivacyPolicy: action.payload };
    case 'SET_CLIENT_NAME':
      return { ...state, clientName: action.payload };
    case 'SET_IS_SERVER_ERROR':
      return { ...state, isServerError: action.payload };
    case 'SET_PLACE_CARD_DETAILS':
      const { placeIndex, placeData } = action.payload;
      const newPlaceCard = [...state.placeCardDetails];
      newPlaceCard[placeIndex] = placeData;
      return { ...state, placeCardDetails: newPlaceCard };
    case 'SET_IS_DARK_MODE':
      return { ...state, isDarkMode: action.payload };
    case 'SET_SHOW_FAVORITE_LIST':
      return { ...state, showFavoriteList: action.payload };
    case 'SET_IS_GOOGLE_MODE':
      return { ...state, isGoogleMode: action.payload };
    case 'SET_SHOW_RECENT_HISTORY':
      return { ...state, showRecentHistoy: action.payload };
    case 'SET_SHOW_COMPARE_CONFIRM_EXIT':
      return { ...state, showCompareConfirmExit: action.payload };
    case 'SET_SELECTED_RECOMMENDED_FILTERS':
      return { ...state, selectedRecommendedFilters: action.payload };
    case 'SET_SHOW_MAP_QS':
      return { ...state, showMapQS: action.payload };
    case 'SET_SHOW_MARKER_LIST':
      return { ...state, showMarkerList: action.payload };
    case 'SET_SHOW_API_KEY_SCREEN':
      return { ...state, showAPIKeyScreen: action.payload };
      case 'SET_EMBED_MODE':
        return { ...state, isEmbedMode: action.payload };
    case 'SET_SIGNUP_EMAIL':
      return { ...state, signupEmail: action.payload };
    case 'SET_FORGOT_PASSWORD_EMAIL':
      return { ...state, forgotPasswordEmail: action.payload };
    default:
      return state;
  }
};

// Create the store context
export const StoreContext = createContext();
export const useStore = () => useContext(StoreContext);

// Create the store provider component
export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  const fullURL = window.location.href;
  const queryString = fullURL?.split('?')[1];
  const searchParams = queryString
    ? new URLSearchParams(queryString?.replace(/#/g, '%23'))
    : new URLSearchParams(window.location.search);
  const isGoogleMapsReady = useGoogleMaps();
  const location = useLocation();

  // const store = useStore();

  const {
    token,
    currentDistance,
    map,
    showCompareLifeStyle,
    compareLifeStyleAddress,
    lifeStyleCompareMaps,
    searchPropertiesData,
    currentTab,
    currentAddress,
    branding,
    isFullScreenMap,
    isEstablishmentSearchOpen,
    currentView,
    previousPage,
    isMapLoading,
    apiKey,
    errorMessage,
    isInTrialMode,
    isMobile,
    clientName,
    showDashboard,
    userDetails,
    recentlyViewedAddresses,
  } = state;

  useEffect(() => {
    if (isGoogleMapsReady) {
      dispatch({
        type: 'SET_IS_GOOGLE_MAPS_LOADED',
        payload: isGoogleMapsReady,
      });
    }
  }, [isGoogleMapsReady]);

  const isInFullAccessSites = () => {
    const sites = [
      'rickyhgroup.com',
      'omegarealestateglobal.com',
      'ccrny.com',
      'localhost',
      '192.168.254.101',
    ];
    const currentURL = window.location.hostname;
    return sites.includes(currentURL);
  };

  const handleLoading = (index=0, value) => {
    if (showDashboard) {
      dispatch({ type: 'SET_LOADING', payload: value });
    } else {
      dispatch({
        type: 'SET_IS_MAP_LOADING',
        payload: { loadingIndex: index, loadingValue: value },
      });
    }
  };

  const setAPIKey = () => {
    // const isUnlimited = searchParams.get('isUnlimited');
    const isGoogleAds = searchParams.get('isGoogleAds');

    let payload = '';
    if (isGoogleAds === 'true') {
      payload = '4B94311833CC53166A3ED242E5AD7';
      dispatch({ type: 'SET_IS_GOOGLE_MODE', payload: true });
    } else {
      payload = 'ED7BE647FE3863A21C224876254A4';
    }

    dispatch({ type: 'SET_API_KEY', payload: payload });
    // const selectedKey = isUnlimited === 'true' || isInFullAccessSites()
    // ? 'ED7BE647FE3863A21C224876254A4'
    // : '4B94311833CC53166A3ED242E5AD7';
  };

  const handleLogin = async (data) => {
    dispatch({ type: 'SET_LOADING', payload: true });

    const params = new URLSearchParams(window.location.search);
    const userEmail = params?.get('email');
    const apiKey = params?.get('api_key');

    if(apiKey || userEmail) {
      const user = await getProfilebyEmail(userEmail);
      if (user) {
        const respApi = user?.['api_key'];
        dispatch({ type: 'SET_USER_DETAILS', payload: user });
        setLSData('user-token', apiKey); // Use API key as a "token" in this case

        if (!respApi) {
          dispatch({ type: 'SET_SHOW_API_KEY_SCREEN', payload: true });
        } else {
          setLSData('user-api-key', apiKey);
        }

        if (!location.pathname.startsWith('/address/')) {
          navigate('/');
        }

        // dispatch({ type: 'SET_LOADING', payload: false });
      } else {
        dispatch({ type: 'SET_ERROR_MESSAGE', payload: 'Failed to fetch user profile using API key.' });
      }

    } else {
      try {
        const response = await login(data);

        if (response.status !== 200) {
          // Login failed, handle error
          dispatch({ type: 'SET_ERROR_MESSAGE', payload: response?.detail || 'Login failed.' });
        } else {
          // Login successful
          const userToken = response.data?.token;
          const userApiKey = response.data?.api_key;

          // Set token and API key if available
          if (userToken) {
            dispatch({ type: 'SET_TOKEN', payload: userToken });
            setLSData('user-token', userToken);
          }

          if (userApiKey) {
            setLSData('user-api-key', userApiKey);
          } else {
            dispatch({ type: 'SET_SHOW_API_KEY_SCREEN', payload: true });
          }

          // Response data contains the user details already no need to call the api again
          dispatch({ type: 'SET_USER_DETAILS', payload: response.data });

          // Redirect to home if not on address page
          if (!location.pathname.startsWith('/address/')) {
            navigate('/');
          }

          // let userEmail = response.data?.email || new URLSearchParams(window.location.search).get('email');
          // // Check for missing email and set error if not found
          // if (!userEmail) {
          //   dispatch({ type: 'SET_ERROR_MESSAGE', payload: 'Email not found in response or URL.' });
          //   dispatch({ type: 'SET_LOADING', payload: false });
          //   return;
          // }

          // Fetch user profile and set user details
          // const user = await getProfilebyEmail(userEmail);
        }
      } catch (error) {
        console.error('Error during login:', error);
      } finally {
        dispatch({ type: 'SET_LOADING', payload: false });
      }
    }
  };

  const handleLogout = async () => {
    const response = await logout();
    if (response.Success) {
      dispatch({ type: 'SET_TOKEN', payload: '' });
      dispatch({ type: 'SET_USER_DETAILS', payload: {} });
      setLSData('user-token', null);
      setLSData('user-details', null);
      navigate('/login');
    }
  };

  const handleAPIKey = async (data) => {
    const params = new URLSearchParams(window.location.search);
    const userEmail = params.get('email');
    const email = userDetails?.email;
    data['email'] = userEmail || email;
    const response = await verifyAPIKey(token, data);
    if (response.status === 200) {
      dispatch({ type: 'SET_SHOW_API_KEY_SCREEN', payload: false });
      setLSData('user-api-key', data.api_key);
    } else {
      dispatch({ type: 'SET_ERROR_MESSAGE', payload: response.data.detail });
    }
  }

  const handleGoogleMarkerRating = async (index, data) => {
    try {
      handleLoading(index, true);
      const email = userDetails?.email;
      const response = await fetchGoogleRating(data, email);
      return response;
    } catch (error) {
      console.log(error);
    } finally {
      handleLoading(index, false);
    }
  };

  const resetLogoutTimer = useAutoLogout(
    30 * 60 * 1000,
    handleLogout,
    isInTrialMode
  );

  const handleForgotPassword = async (data) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    const response = await forgotPassword(data);

    const isSubmitted = response.status === 201;
    dispatch({
      type: 'SET_FORGOT_PASSWORD_SUBMITTED',
      payload: isSubmitted,
    });

    if (response.status !== 201) {
      dispatch({
        type: 'SET_ERROR_MESSAGE',
        payload: response?.data?.non_field_errors?.[0] || 'Invalid email address'
      });
    }

    dispatch({ type: 'SET_LOADING', payload: false });
  };

  const handleConfirmForgotPassword = async (data) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    const response = await confirmForgotPassword(data);

    const isSubmitted = response.status === 200;
    dispatch({
      type: 'SET_CONFIRM_FORGOT_PASSWORD_SUBMITTED',
      payload: isSubmitted,
    });

    if (!isSubmitted) {
      dispatch({
        type: 'SET_ERROR_MESSAGE',
        payload: response.data?.non_field_errors?.[0] || 'Invalid reset link.'
      });
    }

    dispatch({ type: 'SET_LOADING', payload: false });
  };

  const handleSignup = async (data) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    const response = await signup(data);

    const isSubmitted = response.status === 200;
    dispatch({
      type: 'SET_SIGNUP_SUBMITTED',
      payload: isSubmitted,
    });

    if (!isSubmitted) {
      dispatch({ type: 'SET_ERROR_MESSAGE', payload: response.Error });
    }

    dispatch({ type: 'SET_LOADING', payload: false });
  };

  const handleResendSignup = async (data) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    const response = await resendSignup(data);

    const isSubmitted = response.Success;
    dispatch({
      type: 'SET_SIGNUP_SUBMITTED',
      payload: isSubmitted,
    });

    if (!isSubmitted) {
      dispatch({ type: 'SET_ERROR_MESSAGE', payload: response.Error });
    }

    dispatch({ type: 'SET_LOADING', payload: false });
  };

  const handleConfirmSignup = async (data) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    const response = await confirmSignup(data);

    const isSubmitted = response.status === 200;
    dispatch({
      type: 'SET_CONFIRM_SIGNUP_SUBMITTED',
      payload: isSubmitted,
    });

    if (!isSubmitted) {
      dispatch({ type: 'SET_ERROR_MESSAGE', payload: response.Error });
    }

    dispatch({ type: 'SET_LOADING', payload: false });
  };

  const elem = document.documentElement;
  const requestFullScreen =
    elem?.requestFullscreen ||
    elem?.mozRequestFullScreen ||
    elem?.webkitRequestFullscreen ||
    elem?.msRequestFullscreen;

  const exitFullScreen =
    document?.exitFullscreen ||
    document?.mozCancelFullScreen ||
    document?.webkitExitFullscreen ||
    document?.msExitFullscreen;

  const toggleFullScreenMap = () => {
    const isFullScreen =
      document?.fullscreenElement ||
      document?.mozFullScreenElement ||
      document?.webkitFullscreenElement ||
      document?.msFullscreenElement ||
      document?.webkitCurrentFullScreenElement;

    if (!isFullScreen) {
      requestFullScreen?.call(elem);
      sendDataToGoogleAnalytics('used_fullscreen', {});
    } else {
      exitFullScreen.call(document);
    }
    dispatch({ type: 'SET_IS_FULL_SCREEN_MAP', payload: !isFullScreenMap });
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isFullScreenMap && event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isFullScreenMap]);

  const getFavoriteMarkers = () => {
    const favorites = getLSData('favorites');
    dispatch({
      type: 'SET_FAVORITES',
      payload: favorites ? favorites : [],
    });
  };

  const getSavedAddresses = () => {
    const addresses = getLSData('addresses');
    dispatch({
      type: 'SET_SAVED_ADDRESSES',
      payload: addresses ? addresses : [],
    });
  };

  const getRecentlyViewed = () => {
    const addresses = getLSData('recenlty-viewed');
    dispatch({
      type: 'SET_RECENTLY_VIEWED_ADDRESSES',
      payload: addresses ? addresses : [],
    });
  }

  const fetchWalkIndex = async () => {
    const markers = await getNeighborhoodScore('New York', 'NY', '', userDetails?.email);

    dispatch({ type: 'SET_WALKINDEX', payload: markers });
  };

  const setMapCenter = () => {
    if (map && !showCompareLifeStyle) {
      const { latitude, longitude } = currentAddress;
      const newPosition = new window.google.maps.LatLng(
        parseFloat(latitude),
        parseFloat(longitude)
      );

      map.panTo(newPosition);
    } else {
      dispatch({
        type: 'SET_CENTER',
        payload: true
      });
     
      // lifeStyleCompareMaps.forEach((compareMap, index) => {
      //   if (Object.keys(compareMap).length !== 0) {
      //     const property = compareLifeStyleAddress[index];
      //     const { latitude, longitude } = property;
      //     const newPosition = new window.google.maps.LatLng(
      //       parseFloat(latitude),
      //       parseFloat(longitude)
      //     );
      //     console.log("MAP", compareMap)
      //     if (compareMap) {
      //       compareMap?.panTo(newPosition);
      //     }   
      //   }
      // });
    }
  };

  const getAddressImage = () => {
    const hasData = searchPropertiesData.filter((searchProperty) => {
      const address = searchProperty.location.address.toLowerCase();
      const apartment = searchProperty.location.apartment.toLowerCase();
      return currentAddress?.property
        .toLowerCase()
        .includes(address + ' #' + apartment);
    });

    return hasData.length > 0 ? hasData[0].media.photo[0]?._url : false;
  };

  const fetchLifeStyleScores = async (
    address,
    index,
    signal,
    shouldReturn=false,
  ) => {
    handleLoading(index, true);
    try {
      const { latitude, longitude } = address || currentAddress;
      let scores = await getLifeStyleScores(
        latitude,
        longitude,
        getArea(currentDistance),
        userDetails?.email,
        signal,
      );

      if (scores?.error === "50x") {
        dispatch({ type: 'SET_IS_SERVER_ERROR', payload: true });
        handleLoading(index, false);
      }

      if (shouldReturn) return scores;

      const payload = {
        scoreData: scores,
        scoreIndex: index || 0,
      };
      dispatch({ type: 'SET_LIFESTYLE_SCORES', payload: payload });
    } catch (error) {
      console.log(error);
    } finally {
      handleLoading(index, false);
    }
  };

  const fetchWalkIndexRatings = async (
    address,
    index = 0,
    signal,
    shouldReturn=false,
  ) => {
    handleLoading(index, true);
    try {
      const { latitude, longitude } = address || currentAddress;
      let scores = await getRatings(
        latitude,
        longitude,
        getArea(currentDistance),
        userDetails?.email,
        signal,
      );

      if (scores?.error === "50x") {
        dispatch({ type: 'SET_IS_SERVER_ERROR', payload: true });
        handleLoading(index, false);
      }

      const isGoogleAds = searchParams.get('isGoogleAds') === 'true';

      if (scores) {
        if (!scores.Success && !Array.isArray(scores) && isGoogleAds) {
          if (scores.Error === 'Exceeded access limit') {
            dispatch({ type: 'SET_REACHED_MAX_LIMIT', payload: true });
          } else if (scores.Error === 'Unauthorized') {
            dispatch({ type: 'SET_TOKEN', payload: '' });
            dispatch({ type: 'SET_USER_DETAILS', payload: {} });
            navigate('/login');
          }
        } else {
          if (shouldReturn) return scores;

          const payload = {
            walkindxScoreData: scores[0],
            walkindxScoreIndex: index || 0,
          };

          dispatch({ type: 'SET_WALKINDEX_SCORES', payload: payload });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleLoading(index, false);
    }
  };

  const fetchSingleWalkIndex = async (latitude, longitude) => {
    let scores = await getRatings(
      latitude,
      longitude,
      getArea(currentDistance),
      userDetails?.email,
    );

    const isGoogleAds = searchParams.get('isGoogleAds') === 'true';

    if (scores) {
      if (!scores.Success && !Array.isArray(scores) && isGoogleAds) {
        if (scores.Error === 'Exceeded access limit') {
          dispatch({ type: 'SET_REACHED_MAX_LIMIT', payload: true });
        } else if (scores.Error === 'Unauthorized') {
          dispatch({ type: 'SET_TOKEN', payload: '' });
          dispatch({ type: 'SET_USER_DETAILS', payload: {} });
          navigate('/login');
        }
      } else {
        return scores[0];
      }
    }
  };

  // Fetch address data
  const fetchAddressData = async (address, checkLocation=true, shouldReturn=false) => {
    try {
      const addressDetails = await fetchPlaceId(address, token);

      if (checkLocation) {
        console.log("aDDRESS", addressDetails)
        isWithinCity(addressDetails?.address_components);
        isInNYC(addressDetails);
      }

      // const placeDetails = await fetchPlaceDetails(addressDetails.place_id, token);
      if (addressDetails && Object.keys(addressDetails).length > 0) {
        const propertyName = !isInLocalData(addressDetails?.formatted_address)
          ? addressDetails?.formatted_address
          : removeZipAndCountry(
              addressDetails?.formatted_address
            )?.toUpperCase();
        const payload = {
          placeID: addressDetails?.place_id,
          property: propertyName,
          latitude: addressDetails?.geometry?.location?.lat,
          longitude: addressDetails?.geometry?.location?.lng,
          sublocality: getSublocality(addressDetails),
          neighborhood: getNeighborhood(addressDetails),
          postal: getPostalCode(addressDetails),
          shortName: addressDetails?.formatted_address?.split(',')[0].trim(),
          city: getCityShortName(addressDetails),
          photos: [],
        };

        saveAddressToRecentlyViewed(payload, recentlyViewedAddresses, dispatch);

        if (shouldReturn) return payload;

        dispatch({
          type: 'SET_CURRENT_ADDRESS',
          payload: payload,
        });

        dispatch({ type: 'SET_MAP_TAB', payload: currentTab === 'walkindex' ? 'Nature' : 'All' });
        dispatch({ type: 'SET_SHOW_MARKER_LIST', payload: false });
        dispatch({ type: 'SET_SHOW_MAP_QS', payload: false });
        dispatch({ type: 'SET_SHOW_FILTERS', payload: false });

        if (!showCompareLifeStyle) {
          dispatch({
            type: 'UPDATE_COMPARE_ADDRESS',
            payload: { index: 0, object: payload },
          });
          dispatch({
            type: 'UPDATE_LIFESTYLE_COMPARE_ADDRESS',
            payload: { item: 0, address: payload },
          });
        }
      }
      sendDataToGoogleAnalytics(`page_view`, {
        address_name: address
      });
      sendDataToGoogleAnalytics('address_visits', {
        address_name: address
      });
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const fetchAddressByLatLng = async (lat, lng) => {
    const address = await getPlaceByLatLng(lat, lng);

    isWithinCity(address?.address_components);

    const payload = {
      placeID: address?.place_id,
      property: address?.formatted_address,
      latitude: address?.geometry?.location?.lat,
      longitude: address?.geometry?.location?.lng,
      neighborhood: getNeighborhood(address),
      photos: [],
    };

    dispatch({
      type: 'SET_CURRENT_ADDRESS',
      payload: payload,
    });

    if (!showCompareLifeStyle) {
      dispatch({
        type: 'UPDATE_COMPARE_ADDRESS',
        payload: { index: 0, object: payload },
      });
      dispatch({
        type: 'UPDATE_LIFESTYLE_COMPARE_ADDRESS',
        payload: { item: 0, address: payload },
      });
    }

    dispatch({
      type: 'SET_SHOW_SEARCH_ADDRESS',
      payload: false,
    });
  };

  function getSublocality(response) {
    if (response) {
      for (let component of response.address_components) {
        if (component.types.includes('sublocality')) {
          return component.long_name;
        }
      }
    }
    return null;
  }

  const getNeighborhood = (response) => {
    if (response) {
      for (let component of response.address_components) {
        if (
          component.types.includes('neighborhood') ||
          component.types.includes('political')
        ) {
          return component.long_name;
        }
      }
    }
    return null;
  };

  const getPostalCode = (response) => {
    if (!response || !response.address_components) {
      return null;
    }

    const postalCodeComponent = response.address_components.find(
      component => component.types.includes('postal_code')
    );

    return postalCodeComponent ? postalCodeComponent.long_name : null;
  };

  const getCityShortName = (placeDetails) => {
    if (!placeDetails.address_components) {
      return null;
    }

    for (const component of placeDetails.address_components) {
      if (component.types.includes('administrative_area_level_1')) {
        return component.short_name;
      }
    }
    return null;
  }

  const removeZipAndCountry = (address) => {
    // Split the address by commas
    let addressParts = address.split(',');
    // Remove the last two elements (ZIP code and country)
    addressParts.splice(addressParts.length - 2, 2);
    // Join the remaining parts back into a string
    let modifiedAddress = addressParts.join(',');
    // Remove New York, NY, and USA from the string
    modifiedAddress = modifiedAddress.replace(/,\s*(New York|NY|USA)/g, '');
    // Trim to remove extra spaces at the beginning or end
    return modifiedAddress.trim();
  };

  const isInLocalData = (address) => {
    return (
      propertiesData.filter((item) => item.property?.includes(address))
        ?.length > 0
    );
  };

  const isWithinCity = (addressComponents) => {
    const cityList = [
      'New York',
      'Washington',
      'Chicago',
      'Seattle',
      'Boston',
      'Los Angeles',
      'Austin',
      'Philadelphia',
      'Sioux Falls',
      'Massachusetts',
    ];
  
    const isCityFound = addressComponents.some((component) => {
      if (
        component.types?.includes('locality') ||
        component.types?.includes('administrative_area_level_1')
      ) {
        const isInCityList = cityList.includes(component.long_name);
        dispatch({ type: 'SET_IS_STANDALONE', payload: !isInCityList });
        return isInCityList;
      }
      return false;
    });
  
    if (!isCityFound) {
      dispatch({ type: 'SET_IS_STANDALONE', payload: true });
    }
  };

  const isInNYC = (data) => {
    const inNYC = data.address_components.some((component) => {
      return (
        (component.types.includes('locality') &&
          component.long_name === 'New York') ||
        (component.types.includes('administrative_area_level_1') &&
          component.long_name === 'New York' &&
          data.address_components.some(
            (comp) =>
              comp.types.includes('country') &&
              comp.long_name === 'United States'
          ))
      );
    });

    if (inNYC) {
      dispatch({ type: 'SET_IS_ADDRESS_IN_NYC', payload: true });
      if (!showCompareLifeStyle) {
        dispatch({ type: 'SET_CURRENT_DISTANCE', payload: '1' });
      }
    } else {
      dispatch({ type: 'SET_CURRENT_DISTANCE', payload: '3' });
      dispatch({ type: 'SET_IS_ADDRESS_IN_NYC', payload: false });
    }
  };

  // Fetch data when the component mounts
  useEffect(() => {
    const isGoogle = searchParams.get('is_google');
    const isFullScreen = searchParams.get('is_full_screen') || true;
    const longitude = searchParams.get('longitude');
    const latitude = searchParams.get('latitude');
    let address = searchParams.get('address');
    const type = searchParams.get('type') || 'Sale';
    const brandingColor = searchParams.get('branding');
    const plugin = searchParams.get('is_plugin');
    const font = searchParams.get('font');
    const view = searchParams.get('view');
    const addressChange = searchParams.get('address_change');
    const tutorial = searchParams.get('enable_tutorial');
    const client = searchParams.get('suffix');
    const city = searchParams.get('city');

    // if (client && !address) {
    //   navigate('/address-not-found');
    //   return;
    // }

    if (city) {
      dispatch({ type: 'SET_CURRENT_CITY', payload: city });
    }

    if (address) {
      // dispatch({ type: 'GET_PROPERTY_BY_NAME', payload: address });
      if (
        !location.pathname.includes('/plugin') ||
        location.pathname.includes('/plugin/')
      ) {
        handleLogin();
        navigate(`/plugin/?address=${address}`);
      }
      dispatch({ type: 'SET_SHOW_SEARCH_ADDRESS', payload: false });

      fetchAddressData(address);
    } else if (location.pathname.includes('/plugin')) {
      handleLogin();
      // dispatch({ type: 'SET_SHOW_SEARCH_ADDRESS', payload: true });
      // fetchAddressData('157 W 57TH ST #36C');
    }

    const signupEmail = getLSData('signup-email');
    if (signupEmail) {
      dispatch({ type: 'SET_SIGNUP_EMAIL', payload: signupEmail });
    }

    const fpEmail = getLSData('fp-email');
    if (fpEmail) {
      dispatch({ type: 'SET_FORGOT_PASSWORD_EMAIL', payload: fpEmail });
    }

    setAPIKey();

    // handleLogin();

    // const isLoggedIn = getCookieValue('isLoggedIn');
    // if (isLoggedIn) {
    //   const userToken = getLSData('user-token');
    //   dispatch({ type: 'SET_TOKEN', payload: userToken });
    //   const details = getLSData('user-details');
    //   if (details) {
    //     dispatch({ type: 'SET_USER_DETAILS', payload: details });
    //   }
    // } else {
    //   if (plugin === 'true' || isInFullAccessSites()) {
    //     handleLogin();
    //   } else {
    //     localStorage.removeItem('user-token');
    //     localStorage.removeItem('user-details');

    //     if (location.pathname.includes('/plugin')) {
    //       navigate('/login');
    //     } else {
    //       navigate('/');
    //     }
    //   }
    // }

    try {
      const isEmbedded = window.self !== window.top;
      dispatch({ type: 'SET_EMBED_MODE', payload: isEmbedded });
    } catch (e) {
      dispatch({ type: 'SET_EMBED_MODE', payload: true });
    }

    if (location.pathname.startsWith('/address/')) {
      dispatch({ type: 'SET_SHOW_DASHBOARD', payload: false });
    }

    if (plugin === 'true') {
      dispatch({ type: 'SET_IS_PLUGIN', payload: true });
    }

    if (client) {
      dispatch({ type: 'SET_CLIENT_NAME', payload: client });
    }

    if (font) {
      getFontFamily(font);
    }

    if (view) {
      dispatch({
        type: 'SET_CURRENT_VIEW',
        payload: view,
      });
    }

    if (addressChange) {
      dispatch({
        type: 'SET_ENABLE_CHANGE_ADDRESS',
        payload: addressChange,
      });
    }

    if (tutorial) {
      dispatch({
        type: 'SET_ENABLE_TUTORIAL',
        payload: tutorial,
      });
    }

    if (isDeviceiPhone()) {
      dispatch({ type: 'SET_IS_IPHONE', payload: true });
    }

    const filteredProperties = propertiesData.filter(
      (item) => item.type === type
    );

    dispatch({
      type: 'SET_PROPERTY_LIST',
      payload: filteredProperties,
    });

    dispatch({
      type: 'SET_PARAMS',
      payload: { isFullScreen, isGoogle, longitude, latitude, address },
    });

    if (isFullScreen) {
      dispatch({ type: 'SET_SHOW_MODAL', payload: true });
    }

    if (brandingColor) {
      const hexWithoutHashRegex = /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})\b$/;
      const formattedBranding = hexWithoutHashRegex.test(brandingColor)
        ? `#${brandingColor}`
        : brandingColor;

      dispatch({ type: 'SET_BRANDING', payload: formattedBranding });
    }

    getFavoriteMarkers();
    if (currentView === 'Modern') {
      getSavedAddresses();
      getRecentlyViewed();
    }
    dispatch({
      type: 'UPDATE_LIFESTYLE_COMPARE_ADDRESS',
      payload: { item: 0, address: currentAddress },
    });

    const handleFullScreenChange = () => {
      const payload =
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement ||
        document.webkitCurrentFullScreenElement; // Safari and iOS;
      dispatch({ type: 'SET_IS_FULL_SCREEN_MAP', payload: payload });
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('mozfullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
    document.addEventListener('msfullscreenchange', handleFullScreenChange);

    const handleResize = () => {
      const innerWidth = window.innerWidth;
      const isMobileNow = innerWidth < 768;
      dispatch({ type: 'SET_IS_MOBILE', payload: isMobileNow });

      const isSmallScreen = innerWidth < 1280 && innerWidth > 768;
      dispatch({ type: 'SET_IS_SMALLER_SCREEN', payload: isSmallScreen });
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener(
        'mozfullscreenchange',
        handleFullScreenChange
      );
      document.removeEventListener(
        'webkitfullscreenchange',
        handleFullScreenChange
      );
      document.removeEventListener(
        'msfullscreenchange',
        handleFullScreenChange
      );

      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (currentAddress) {
      // const controller = new AbortController();
      // const signal = controller.signal;
      if (!isMobile && !location.pathname.startsWith('/address/')) {
        fetchQuickSearchMarkers(currentAddress);
      }
      document.title = `${currentAddress.property} - Walkspan`;

      // return () => {
      //   controller.abort(); // Cleanup: abort the fetch request
      // };
    }
  }, [currentAddress]);

  const handleFetchScores = async (payload, index) => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (currentTab === 'lifestyle') {
      await fetchLifeStyleScores(payload, index, signal);
    } else if (currentTab === 'walkindex') {
      await fetchWalkIndexRatings(payload, index, signal);
    } 

    return () => {
      controller.abort();
    };
  };

  const handleInitialFetchScores = async (payload, index) => {
    const controller = new AbortController();
    const signal = controller.signal;

    await fetchLifeStyleScores(payload, index, signal);
    await fetchWalkIndexRatings(payload, index, signal);

    return () => {
      controller.abort();
    };
  };

  useEffect(() => {
    if (
      currentAddress &&
      showDashboard &&
      !location.pathname.startsWith('/address/')
    ) {
      handleInitialFetchScores();
    }
  }, [currentDistance, currentAddress, currentTab]);

  useEffect(() => {
    if (isMobile) {
      dispatch({ type: 'SET_SHOW_DASHBOARD', payload: false });
    }
  }, [isMobile]);

  const getQuickSearchResult = (markers, searchText) => {
    const filteredMarkers = markers?.filter((place) => {
      const placeCategory = place.category;
      const placeName = place.name?.toLowerCase();
      const category = placeCategory ? placeCategory.toLowerCase() : 'others';

      return searchText !== '' && (
        category.includes(searchText?.toLowerCase()) ||
        placeName.includes(searchText?.toLowerCase())
      );
    });
    if (isEstablishmentSearchOpen && filteredMarkers.length) {
      dispatch({
        type: 'SET_ESTABLISHMENT_SEARCH_RESULT',
        payload: { searchText: searchText, result: filteredMarkers.length },
      });
    }
    return filteredMarkers;
  };

  const handleFetchedMarkers = (markers, index=0) => {
    try {
      if (markers?.error === "50x") {
        dispatch({ type: 'SET_IS_SERVER_ERROR', payload: true });
      }

      if (markers) {
        if (!markers.Success && !Array.isArray(markers) && typeof markers === 'object') {
          if (markers.Error === 'Exceeded access limit') {
            dispatch({ type: 'SET_REACHED_MAX_LIMIT', payload: true });
          }
          else if (markers.Error === 'Unauthorized') {
            dispatch({ type: 'SET_TOKEN', payload: '' });
            dispatch({ type: 'SET_USER_DETAILS', payload: {} });
            navigate('/login');
          }
        } else {
          const data = convertMarkers(markers);

          const getLength = data ? data.length : 0;
          if (currentAddress !== null) {
            dispatch({
              type: 'UPDATE_COMPARE',
              payload: { number: index, length: getLength },
            });
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  };

  const fetchQuickSearchMarkers = async (address) => {
    let payload = {
      markers: [],
      isLoading: true,
    };
    dispatch({ type: 'SET_QUICK_SEARCH_MARKERS', payload: payload });
    let markers = await getLifeStyleEssentials(
      address.latitude,
      address.longitude,
      'all',
      'one-mile',
      userDetails?.email,
    );
    const isGoogleAds = searchParams.get('isGoogleAds') === 'true';
    if (markers) {
      if (!markers.Success && !Array.isArray(markers) && isGoogleAds) {
        if (markers.Error === 'Exceeded access limit') {
          dispatch({ type: 'SET_REACHED_MAX_LIMIT', payload: true });
        } else if (markers.Error === 'Unauthorized') {
          dispatch({ type: 'SET_TOKEN', payload: '' });
          dispatch({ type: 'SET_USER_DETAILS', payload: {} });
          navigate('/login');
        }
      } else {
        const data = convertMarkers(markers);
        payload = {
          markers: data,
          isLoading: false,
        };
        dispatch({ type: 'SET_QUICK_SEARCH_MARKERS', payload: payload });
      }
    }
  };

  const isWholeWordMatch = (text, searchWord) => {
    const regex = new RegExp(`\\b${searchWord}\\b`, 'i');
    return regex.test(text);
  };

  const isDeviceiPhone = () => {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  };

  const getFontFamily = (fontStyle) => {
    const fontUrl = `https://fonts.googleapis.com/css?family=${fontStyle}`;
    const link = document.createElement('link');
    link.href = fontUrl;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
    document.documentElement.style.setProperty('--font-family', fontStyle);
  };

  const getBrandingColor = () => {
    const style = {
      '--color': branding !== null ? branding : '#000000',
    };
    return style;
  };

  const getLightBrandingColor = (lightnessIncrease) => {
    // Convert hex to hsl
    let hslColor = convert.hex.hsl(branding);

    // Increase lightness
    hslColor[2] += lightnessIncrease;
    hslColor[2] = Math.max(0, Math.min(hslColor[2], 100));

    // Convert hsl back to hex
    const lighterHex = convert.hsl.hex(hslColor);

    const style = {
      '--light-color': `#${lighterHex}`,
    };
    return style;
  };

  const getSuffix = () => {
    const suffix = searchParams.get('suffix');
    const finalClient = suffix || clientName;
    return finalClient ? '_' + finalClient : '';
  };

  const setRatings = (number) => {
    // let newArray = currentRates;
    // if (currentRates.includes(number)) {
    //   newArray = currentRates.filter(rate => rate !== number);
    //   dispatch({ type: 'SET_RATES', payload: newArray });
    // } else {
    //   newArray = [...currentRates, number];
    //   dispatch({ type: 'SET_RATES', payload: newArray });
    // }
    dispatch({ type: 'SET_RATES', payload: [number] });

    sendDataToGoogleAnalytics('used_rating_filter', { rating: number });
  };

  const filterPreviousPage = () => {
    const page = previousPage[previousPage.length - 1];
    if (page === 1) {
      dispatch({ type: 'SET_SHOW_DASHBOARD', payload: true });
      dispatch({ type: 'SET_DESKTOP_SIDEBAR', payload: false });
      dispatch({ type: 'SET_COMPARE_LIFESTYLE', payload: false });
    } else if (page === 2) {
      dispatch({ type: 'SET_SHOW_DASHBOARD', payload: false });
      dispatch({ type: 'SET_COMPARE_LIFESTYLE', payload: false });
    } else {
      dispatch({ type: 'SET_SHOW_DASHBOARD', payload: false });
      dispatch({ type: 'SET_COMPARE_LIFESTYLE', payload: true });
    }
    const filteredPreviousPage =
      previousPage.length > 1 ? previousPage.slice(0, -1) : [];

    dispatch({
      type: 'FILTER_PREVIOUS_PAGE',
      payload: filteredPreviousPage,
    });
  };

  const areMapsLoading = () => {
    return isMapLoading.some((loading) => loading === true);
  };

  const clearErrorMessage = () => {
    if (errorMessage !== '') {
      dispatch({ type: 'SET_ERROR_MESSAGE', payload: '' });
    }
  };

  const sendDataToGoogleAnalytics = (eventName, params) => {
    window.gtag('event', eventName + getSuffix(), params);
  };

  const actions = {
    handleLogin,
    handleLogout,
    fetchLifeStyleScores,
    fetchWalkIndexRatings,
    fetchSingleWalkIndex,
    setMapCenter,
    getAddressImage,
    fetchAddressData,
    getNeighborhood,
    getBrandingColor,
    getLightBrandingColor,
    toggleFullScreenMap,
    getQuickSearchResult,
    isWholeWordMatch,
    sendDataToGoogleAnalytics,
    setRatings,
    filterPreviousPage,
    isInLocalData,
    areMapsLoading,
    fetchQuickSearchMarkers,
    isDeviceiPhone,
    fetchAddressByLatLng,
    handleForgotPassword,
    handleConfirmForgotPassword,
    handleSignup,
    handleResendSignup,
    handleConfirmSignup,
    clearErrorMessage,
    isInFullAccessSites,
    handleFetchedMarkers,
    getSavedAddresses,
    getRecentlyViewed,
    handleFetchScores,
    handleAPIKey,
    handleGoogleMarkerRating,
  };

  return (
    <StoreContext.Provider value={{ state, dispatch, actions, navigate }}>
      {children}
    </StoreContext.Provider>
  );
};
