import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import Header from './components/LandingHeader';
import HeaderMobile from './components/LandingHeaderMobile';
import Footer from './components/Footer';

const MainLayout = ({ children }) => {
  const location = useLocation();
  const pathsWithHeaderFooter = [
    '/home',
    '/platform',
    '/contact-us',
    '/about-us',
    '/address/*'
  ];
  const showHeaderFooter = pathsWithHeaderFooter.some(path => matchPath(path, location.pathname));

  return (
    <div className='main-layout'>
      {showHeaderFooter && <Header />}
      {showHeaderFooter && <HeaderMobile />}
      {children}
      {showHeaderFooter && <Footer />}
    </div>
  );
};

export default MainLayout;
