import { useState, useEffect } from "react";
import { Rating } from 'react-simple-star-rating';

import Show from "../../utility/Show";

import { ReactComponent as Close } from '../../assets/cancel-white.svg';
import { ReactComponent as Gem } from '../../assets/modern-gem.svg';
import { ReactComponent as Food } from '../../assets/food.svg';
import { ReactComponent as Services } from '../../assets/services.svg';
import { ReactComponent as Shop } from '../../assets/shop.svg';
import { ReactComponent as Entertainment } from '../../assets/entertainment.svg';
import { ReactComponent as Access } from '../../assets/access.svg';
import { ReactComponent as Yelp } from '../../assets/yelp.svg';
import { ReactComponent as Clock } from '../../assets/marker-popup/time.svg';
import { ReactComponent as Location } from '../../assets/walking-distance.svg';
import { ReactComponent as Share } from '../../assets/marker-popup/share.svg';
import { ReactComponent as Copy } from '../../assets/copy.svg';

import PlaceHolder from '../../assets/thumbnail.jpeg'

import { getEstablishmentColor } from "../../services";
import { fetchPlaceDetails } from "../../api";

const PlaceCard = ({ placeCardDetails, currentMarker, actions, dispatch, index }) => {
  const [data, setData] = useState(null);

  const markerCategories = currentMarker[index]?.category
      ? currentMarker[index].category.split(';')
      : '';

  useEffect(() => {
    if (placeCardDetails) {
      fetchPlaceDetails(placeCardDetails.place_id).then((details) => {
        setData(details)
      });
    }
  }, [placeCardDetails]);

  const gemStyle = () => {
    const style = {
      '--gem-bg': getEstablishmentColor(currentMarker[index].type),
    };
    return style;
  };

  const getCategoryImage = (type) => {
    switch (type) {
      case 'food':
        return <Food className='category-image' />;
      case 'shops':
        return <Shop className='category-image' />;
      case 'services':
        return <Services className='category-image' />;
      case 'transit':
        return <Access className='category-image' />;
      case 'leisure':
        return <Entertainment className='category-image' />;
      default:
        return <Food className='category-image' />;
    }
  };

  const convertTo12HourFormat = (time) => {
    const hours = parseInt(time?.substring(0, 2), 10);
    const minutes = parseInt(time?.substring(2), 10);

    let meridiem = 'AM';
    let adjustedHours = hours;

    if (hours >= 12) {
      meridiem = 'PM';
      adjustedHours = hours === 12 ? 12 : hours - 12;
    }
    return `${adjustedHours}:${minutes
      .toString()
      .padStart(2, '0')} ${meridiem}`;
  };

  const isOpen24Hours = (opening_hours) => {
    return opening_hours.periods.some((period) => {
      return (
        period.open &&
        period.open.time === '0000' &&
        period.close &&
        period.close.time === '2359'
      );
    });
  };

  const formatNumber = (value) => {
    if (value === null) {
      return '0.0';
    }

    const isWholeNumber = Number.isInteger(value);
    return isWholeNumber ? `${value}.0` : `${value}`;
  };

  const closePopup = () => {
    dispatch({
      type: 'SET_PLACE_CARD_DETAILS',
      payload: { placeIndex: index, placeData: null },
    });
    setData(null);
  };

  const styleColor = actions.getBrandingColor();

  return (
    <div className="place-card-wrapper">
      <Close className="close-icon" onClick={() => closePopup()}/>
      <div className="card">
        <img src={data?.photos?.[0]?.getUrl() ?? PlaceHolder} alt="" />
        <div className="details">
          <div className='marker-details-title'>
            <div className='marker-details-gem'>
              <Gem className='marker-details-gem-icon' style={gemStyle()} />
              {getCategoryImage(currentMarker[index].type)}
            </div>
            <div className='marker-details-name-category'>
              <div className='marker-address-name'>
                {currentMarker[index]?.name || data?.name}
              </div>
              {markerCategories && (
                <div className='popup-category-container'>
                  {markerCategories.map((item) => (
                    <div className={`popup-category-item sharp-border`}>
                      {item}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className='marker-details-item'>
            {data?.formatted_address && (
              <>
                <Location className='marker-details-icon' />
                <span>{data?.formatted_address}</span>
              </>
            )}
          </div>
          <>
            <div className='marker-details-item'>
              {data?.opening_hours && (
                <>
                  <Clock className='marker-details-icon' />
                  {isOpen24Hours(data?.current_opening_hours) ? (
                    'Open 24 hours'
                  ) : (
                    <>
                      {convertTo12HourFormat(
                        data?.current_opening_hours?.periods[0]?.open?.time
                      )}
                      {' - '}
                      {convertTo12HourFormat(
                        data?.current_opening_hours?.periods[0]?.close?.time
                      )}
                    </>
                  )}
                </>
              )}
              <div className='marker-rating-container'>
                <Show>
                  <Show.When isTrue={currentMarker[index].rating === null}>
                    <Rating iconsCount={1} size={20} readonly />
                    Unavailable
                  </Show.When>
                  <Show.Else>
                    <Rating
                      initialValue={currentMarker[index].rating}
                      allowFraction
                      size={20}
                      readonly
                      fillColor={styleColor['--color']}
                    />
                    {formatNumber(currentMarker[index].rating)}
                  </Show.Else>
                </Show>
                <Yelp alt='Powered by Yelp' />
              </div>
            </div>
          </>

        </div>
      </div>
      <div className="share-buttons">
        <div><Share /> Share</div>
        <div><Copy /> Copy Link</div>
      </div>
    </div>
  )
};

export default PlaceCard;