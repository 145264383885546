import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const LazyImage = ({ src, placeholder, alt, ...props }) => {
  return (
    <LazyLoadImage
      src={src}
      placeholderSrc={placeholder}
      alt={alt}
      {...props}
      effect="blur" // Optional: Add blur effect while loading
    />
  );
};

export default LazyImage;
