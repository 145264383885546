import { useState, useEffect, useContext, useRef } from 'react';
import { StoreContext } from '../../store';
import { walkIndexFeatures, lifeStyleFeatures } from '../../constants';
import { getLifeStyleScore, scoreCircleStyle } from '../../services';

import Show from '../../utility/Show';
import { ScoreIcon } from "../../utility/ScoreIcon";
import MiniMap from '../Map/minimap';

export const MobileDashboard = () => {
  const { state, dispatch, actions } = useContext(StoreContext);
  const {
    token,
    currentTab,
    currentAddress,
    currentDistance,
    walkindexScores,
    lifeStyleScores,
    isMobile,
    isLoading,
    isStandalone,
    showDashboard,
    currentView,
  } = state;

  const [overallScore, setOverallScore] = useState(0);

  const isModern = currentView === 'Modern';
  const isWalkIndex = currentTab === 'walkindex';

  useEffect(() => {
    const filteredGemFeatures = lifeStyleFeatures.slice(0, -1);
    const getFeatures = isWalkIndex
      ? walkIndexFeatures.filter((item) => walkindexScores[0][item.id] >= 1)
      : filteredGemFeatures.filter(
          (item) => lifeStyleEssentialScores()[item.id] >= 1
        );

    if (getFeatures.length) {
      setOverallScore(getOverallScore());
    }
  }, [walkindexScores, lifeStyleScores]);

  const getOverallScore = () => {
    if (isWalkIndex) {
      const { activities, beautyM, beautyN, comfort, noise } =
        walkindexScores[0];

      const scores = [
        parseFloat(activities),
        parseFloat(beautyM),
        parseFloat(beautyN),
        parseFloat(comfort),
        parseFloat(noise),
      ];

      const counts = [0, 0, 0];
      scores.forEach((score) => {
        const roundedScore = Math.round(score);
        counts[roundedScore - 1]++;
      });

      if (counts[2] >= 1) return 3;
      if (counts[1] >= 1) return 2;
      return 1;
    } else {
      const scores = Object.values(lifeStyleEssentialScores());

      const counts = [0, 0, 0];
      scores.forEach((score) => {
        const roundedScore = Math.round(score);
        counts[roundedScore - 1]++;
      });

      if (counts[2] >= 1) return 3;
      if (counts[1] >= 1) return 2;
      return 1;
    }
  };

  const getArea = () => {
    switch (currentDistance) {
      case '1':
        return 'quarter-mile';
      case '2':
        return 'half-mile';
      case '3':
        return 'one-mile';
      default:
        return;
    }
  };

  const lifeStyleEssentialScores = () => {
    if (!lifeStyleScores) return {};

    const categories = ['Food', 'Services', 'Shops', 'Transit', 'Leisure'];
    const essentialScores = {};

    categories.forEach((category) => {
      const type = category === 'Leisure' ? 'leisure' : category.toLowerCase();
      essentialScores[category] = getLifeStyleScore(
        lifeStyleScores[0][type],
        currentDistance,
        isStandalone
      );
    });

    return essentialScores;
  };

  const getTotalGems = () => {
    return Object.values(lifeStyleScores[0]).reduce(
      (acc, count) => acc + count,
      0
    );
  };

  const renderCustomButtons = () => {
    const listBtns = isWalkIndex
      ? walkIndexFeatures
      : lifeStyleFeatures.slice(0, -1);

    const scores = isWalkIndex
      ? walkindexScores[0]
      : lifeStyleEssentialScores();

    if (!scores) return;

    if (listBtns.length) {
      return listBtns.map((item) => {
        const btnColor = {
          '--type-color': item.color,
        };

        const selectedScore = item.id === 'All'
          ? (getTotalGems() === 0 ? 1 : getTotalGems())
          : Math.round(scores[item.id]);

        return (
          <button
            data-tooltip-id={`custom-button-${item.name}`}
            key={`custom-button-${item.name}`}
            className={`dashboard-type-btn is-btn-active
              ${isMobile && 'is-mobile-btn'}
              ${isLoading ? 'btn-loading' : ''}
              ${showDashboard && 'default-cursor'}
              ${isModern && 'modern-dashboard-type-btn'}
            `}
            style={btnColor}
          >
            {/* {currentView === 'Classic' ?
              item.svg :
              <div className='type-btn-dot'></div>
            } */}
            <div
              className={`custom-button-icon ${
                isModern && 'modern-custom-button-icon'
              } ${
                isModern && !isWalkIndex && 'modern-lifestyle-custom-button'
              }`}
            >
              <Show.When isTrue={isModern}>
                <ScoreIcon
                  height='40px'
                  width='40px'
                  withBg={true}
                  score={selectedScore.toString()}
                  content={
                    <div className='modern-dashboard-type-btn'>
                      {item.svg}
                    </div>
                  }
                />
                {/* <Show>
                  <Show.When isTrue={isWalkIndex}>
                    <div
                      className='icon-score-cirlce'
                      data-score-value={selectedScore.toString()}
                      style={scoreCircleStyle(selectedScore)}
                    ></div>
                  </Show.When>

                  <Show.Else>
                    <div
                      className='score-diamond'
                      data-score-value={selectedScore.toString()}
                    >
                      <ModernGem />
                    </div>
                  </Show.Else>
                </Show> */}
              </Show.When>
            </div>
            <div className='btn-name'>
              {item.name}
              <Show.When isTrue={!isWalkIndex}>
                <span className='gem-count'>
                  <Show>
                    <Show.When isTrue={item.id === 'All'}>
                      {` ${getTotalGems() || '0'}`}
                    </Show.When>
                    <Show.Else>
                      {` ${
                        lifeStyleScores[0][item.name.toLowerCase()] || '0'
                      }`}
                    </Show.Else>
                  </Show>
                 </span>
              </Show.When>
            </div>

            <Show.When isTrue={!isModern}>
              <span> {selectedScore}</span>
            </Show.When>

            {/* <Tooltip
              className='custom-btn-popup'
              id={`custom-button-${item.name}`}
              place='bottom'
            >
              {getAttributeDescription(item.name)}
            </Tooltip> */}
          </button>
        );
      });
    }
  };

  return (
    <div className='mobile-dashboard-cotainer'>
      <div className='mobile-dashboard-content'>
        <MiniMap
          property={currentAddress}
          state={state}
          dispatch={dispatch}
          actions={actions}
        />
      </div>
    </div>
  );
};
