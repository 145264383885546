import './index.css';

const ConfirmExit = ({ component, handleExit }) => {
  return (
    <div className='confirm-exit-popup'>
      <div className='card'>
        <h2>Exit?</h2>
        <div className='message'>
          Are you sure you want to exit{component ? ` ${component}` : ''} ?
        </div>
        <div className='buttons'>
          <button onClick={() => handleExit(true)}>Yes</button>
          <button onClick={() => handleExit(false)}>No</button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmExit;