import { ReactComponent as Info } from '../assets/info-outline.svg';
import './index.css';

const FormError = ({ error, textColor='#f29e9d' }) => {

  const errorStyle = {
    '--error-text-color': textColor,
  };

  return (
    <div className='form-error-message' style={errorStyle}>
      <Info /> {error}
    </div>
  );
};

export default FormError;