import { useState, useRef, useContext } from 'react';
import './index.css';
import { StoreContext } from '../../store';

import emailjs from '@emailjs/browser';

import Show from '../../utility/Show';
import FormError from '../../utility/FormError';
import Survey from './survey';

import { ReactComponent as WalkspanLogo } from '../../assets/walkspan-logo-2.svg';
import { ReactComponent as Info } from '../../assets/alert-icon.svg';


import { isValidEmail } from '../../services';

const ReachedLimit = () => {
  const { state, dispatch, actions, navigate } = useContext(StoreContext);
  const { errorMessage, isMobile } = state;

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);
  const [email, setEmail] = useState('');
  const [surveyError, setSurveyError] = useState('');
  const formRef = useRef();

  const sendEmail = (event) => {
    event.preventDefault();
    actions.clearErrorMessage();
    const email = formRef?.current[0]?.value;

    if (email === '') {
      dispatch({
        type: 'SET_ERROR_MESSAGE',
        payload: 'Email is required.',
      });
      return;
    }

    if (!isValidEmail(email)) {
      dispatch({
        type: 'SET_ERROR_MESSAGE',
        payload: 'Please enter a valid email.',
      });
      return;
    }

    emailjs
      .sendForm(
        'service_tdyc8vd',
        'template_npenggs',
        formRef.current,
        'NqktzMSE3ylSjOrfR'
      )
      .then(
        (result) => {
          setIsSubmitted(true);
        },
        (error) => {
          setIsSubmitted(false);
        }
      );
  };

  const handleSurvey = (value) => {
    // if (value && !isSubmitted) {
    //   setSurveyError('Submit your email first')
    //   return;
    // }
    // if (surveyError) {
    //   setSurveyError('');
    // }
    setShowSurvey(value);
  };

  const openPrivacyPolicy = () => {
    dispatch({ type: 'SET_SHOW_PRIVACY_POLICY', payload: true });
  };

  return (
    <div className={`reached-limit-container ${
        isMobile && isSubmitted ? "vertically-centered" : ""
      }`}
    >
      <Show>
        <Show.When isTrue={showSurvey}>
          <Survey
            navigate={navigate}
            actions={actions}
            handleSurvey={handleSurvey}
          />
        </Show.When>
        <Show.Else>
          <div className='reached-limit-card max-w-screen-lg'>
            <WalkspanLogo className='logo' />
            <div className='reached-limit-header'>
              <Show>
                <Show.When isTrue={isSubmitted}>
                  <h1 className='title'>Thank you for your request!</h1>
                  <span>Our team will reach out to you shortly.</span>
                </Show.When>
                <Show.Else>
                  <h1 className='title'>You've reached your search limit</h1>
                  <span>To continue using our platform, please contact us!</span>
                </Show.Else>
              </Show>
            </div>

            <Show.When isTrue={!isSubmitted}>
              <form ref={formRef} onSubmit={(e)=> sendEmail(e)}>
                <input
                  type='text'
                  name='from_email'
                  placeholder='Enter your Email'
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Show.When isTrue={errorMessage && !isSubmitted}>
                  <FormError error={errorMessage} textColor='#f14c4c' />
                </Show.When>
                <input type='hidden' name='from_name' value='Walkspan user' />
                <button type='submit'>Send it now</button>
              </form>
            </Show.When>
          </div>
          <div className='reached-limit-survey-msg max-w-screen-lg'>
            <div className='message'>
              <h4 className='title'>Your feedback is valuable to us!</h4>
              <span>
                Complete our short survey to enhance your journey and ensure a 
                smoother experience the next time you use our platform!
              </span>
              <div className='privacy-policy-btn' onClick={() => openPrivacyPolicy()}>
                <Info /> Privacy Policy
              </div>
            </div>
            <div>
              <Show.When isTrue={surveyError && (email === '' || !isValidEmail(email))}>
                <FormError error={surveyError} textColor='#f14c4c' />
              </Show.When>
              <button className='start-survey-btn' onClick={() => handleSurvey(true)}>
                Start Survey
              </button>
            </div>
          </div>
        </Show.Else>
      </Show>
    </div>
  );
};

export default ReachedLimit;