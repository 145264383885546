import Slider from 'react-slick';

const SlidingButtons = ({
  className,
  isLoading,
  isMobile,
  features,
  onButtonClick,
  isFeatureSelected,
}) => {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: isMobile ? 3 : 2,
    slidesToScroll: isMobile ? 3 : 2,
    swipeToSlide: false,
    className: className,
  };

  const handleButtonClick = (feature) => {
    onButtonClick(feature);
  };

  const isSelected = (feature) => {
    return isFeatureSelected(feature);
  };

  return (
    <Slider {...settings}>
      {features.map((feature, index) => {
        const btnColor = {
          '--type-color': feature.color,
        };

        return (
          <div
            key={`feature-btn-${feature.name}`}
            className={`feature-btn ${
              isSelected(feature.name) ? 'selected' : ''
            } ${
              isLoading ? 'btn-loading' : ''
            }`}
            onClick={isLoading ? null : () => handleButtonClick(feature.name)}
          >
            <div style={btnColor}>{feature.svg}</div>
            {feature.name}
          </div>
        )
      })}
    </Slider>
  )
};

export default SlidingButtons;