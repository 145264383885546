import React from 'react';
import './index.css';

import BG1 from '../../assets/landing-page/about-us-bg.jpg';
import BG1PH from '../../assets/landing-page/about-us-bg-ph.jpg';
import BG2 from '../../assets/landing-page/what-we-do.png';

import LinkedInWhite from '../../assets/landing-page/linkedin-white.svg';
import GmailWhite from '../../assets/landing-page/gmail-white.svg';

import LinkedIn from '../../assets/landing-page/linkedin.svg';
import Gmail from '../../assets/landing-page/gmail.svg';

import { teamData, advisorsData } from '../../constants';

import LazyImage from '../../utility/LazyImage';

const About = () => {
  return (
    <div className='About'>
      <section className='who-we-are'>
        <LazyImage src={BG1} placeholder={BG1PH} alt='' />
        <div className='content max-w-screen-lg'>
          <h1 className='landing-title'>Who We Are</h1>
          <ul className='who-we-are-content'>
            <li className='who-we-are-item'>
              <p>
                We are a data and technology company dedicated to accelerating
                public realm transformation through ai-driven metrics.
              </p>
            </li>
            <li className='who-we-are-item'>
              <p>
                We hold a unique value system and mission to shape the public
                realm of tomorrow. We are passionate about elevating
                neighborhood as the next important thing.
              </p>
            </li>
            <li className='who-we-are-item'>
              <p>
                We are impact entrepreneurs, an interdisciplinary team in the
                technology, location intelligence, data, and environmental
                fields.
              </p>
            </li>
          </ul>
        </div>
        <LazyImage src={BG2} placeholder={BG2} alt='' />
        <section className='what-we-do max-w-screen-lg'>
          <h1 className='landing-title'>What We Do</h1>
          <p>
            Walkspan reveals neighborhood character and quality through its
            neighborhood intelligence platform. A platform that seamlessly
            integrates with real estate search engines to help buyers and
            renters find homes in walkable neighborhoods. Buying homes where
            lifestyle essentials are within walking distance improves quality of
            life and brings sustainable impacts.
          </p>
        </section>
      </section>

      <section className='meet-our-team'>
        <h1 className='landing-title'>Meet Our Team</h1>
        <p className='quote'>
          We have <b>35 years</b> of combined experience in urban environment,
          data management and location intelligence.
        </p>
        <div className='team-list'>
          {teamData.map((member, index) => (
            <div key={index} className='team-item'>
              <img src={member.img} alt={member.name} />
              <h3>{member.name}</h3>
              <p>{member.title}</p>
              <div className='team-links'>
                <a
                  href={member.linkedIn}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src={LinkedIn} alt='LinkedIn' />
                </a>
                <a href={member.email}>
                  <img src={Gmail} alt='Email' />
                </a>
              </div>
              <div className='team-affiliations'>
                {member.affiliations.map((logo, logoIndex) => (
                  <img key={logoIndex} src={logo} alt='Affiliation' />
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
      <section className='meet-our-advisors'>
        <h1 className='landing-title'>Meet Our Advisors</h1>
        <p className='quote'>
          We have a <b>network of professionals</b> in real estate technology
          and marketing to create synergies and share ideas on walkability.
        </p>
        <div className='advisors-list'>
          {advisorsData.map((advisor, index) => (
            <div key={index} className='advisor-item'>
              <img src={advisor.img} alt={advisor.name} />
              <h3>{advisor.name}</h3>
              <p>{advisor.title}</p>
              <div className='advisor-links'>
                <a
                  href={advisor.linkedIn}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src={LinkedInWhite} alt='LinkedIn' />
                </a>
                <a href={advisor.email}>
                  <img src={GmailWhite} alt='Email' />
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default About;
