import React, { useContext } from 'react';
import './index.css';

import { ReactComponent as Back } from '../../assets/back-btn.svg';
import { ReactComponent as Food } from '../../assets/food.svg';
import { ReactComponent as Services } from '../../assets/services.svg';
import { ReactComponent as Shop } from '../../assets/shop-white.svg';
import { ReactComponent as Entertainment } from '../../assets/entertainment.svg';
import { ReactComponent as Access } from '../../assets/access.svg';
import { ReactComponent as CloseIcon } from '../../assets/cancel-white.svg';

import { StoreContext } from '../../store';

import { Rating } from 'react-simple-star-rating';
import { distance } from '@turf/turf';
import StreetViewMap from '../Map/streetmap';
import { findColor } from '../../services';

import Show from '../../utility/Show';
import MarkerList from '../Map/marker-list';

const Establishments = ({ index = 0 }) => {
  const { state, dispatch, actions } = useContext(StoreContext);

  const {
    listOfMarkers,
    showCompareLifeStyle,
    lifeStyleCompareMaps,
    map,
    currentAddress,
    isDesktopSidebarOpen,
    isSmallerScreen,
  } = state;

  const calculateDistance = (startPoint, endPoint) => {
    return distance(startPoint, endPoint, { units: 'miles' });
  };

  const toggleShowEstablishments = () => {
    dispatch({
      type: 'SET_SHOW_ESTABLISHMENTS',
      payload: false,
    });
  };

  const getImage = (type) => {
    switch (type) {
      case 'food':
        return <Food />;
      case 'shops':
        return <Shop />;
      case 'services':
        return <Services />;
      case 'transit':
        return <Access />;
      case 'leisure':
        return <Entertainment />;
      default:
        return <Food />;
    }
  };

  const sortedEstablishments = listOfMarkers[index].sort(
    (a, b) => b.rating - a.rating
  );

  const getColor = (type) => {
    const getType = type === 'entertainment-fitness' ? 'leisure' : type;
    return findColor('lifestyle', getType);
  };

  const redirectToMap = (marker) => {
    actions.sendDataToGoogleAnalytics(
      'checked_establishment',
      { establishment: marker.name }
    );
    const newPosition = new window.google.maps.LatLng(
      parseFloat(marker.lat),
      parseFloat(marker.lng)
    );
    const selectedMap = showCompareLifeStyle
      ? lifeStyleCompareMaps[index]
      : map;
    selectedMap.panTo(newPosition);
    selectedMap.setZoom(selectedMap.getZoom() + 2.5);
    dispatch({
      type: 'SET_SHOW_ESTABLISHMENTS',
      payload: false,
    });
    dispatch({
      type: 'SET_CURRENT_MARKER',
      payload: {markerIndex: index, markerData: marker},
    });
  };

  const styleColor = actions.getBrandingColor();

  return (
    <div className='establishment-wrapper'>
      <div className='city-bg'></div>
      <div className='establishment-header'>
        <h3>ESTABLISHMENTS</h3>
        <CloseIcon onClick={() => toggleShowEstablishments()}/>
      </div>

      <div className={`list-establishments-container
          ${
            isDesktopSidebarOpen &&
            isSmallerScreen &&
            'small-list-establishments-container'
          }
        `}
      >
        <MarkerList markers={sortedEstablishments} />
      </div>
    </div>
  );
};

export default Establishments;
