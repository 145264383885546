import './index.css';
import MapTypeLegends from '../LegendLines/index.jsx';

const NeighborhoodTabLegendPopup = () => {
  return (
    <div className="neighbohood-tab-popup">
      <div className="score-wrapper">
        <MapTypeLegends version={2}/>
      </div>
    </div>
  )
};

export default NeighborhoodTabLegendPopup;