import { Children } from "react";

export const Show = ({ children }) => {
  let when = null;
  let otherwise = null;

  Children.forEach(children, child => {
    if (!child.props.isTrue) {
      otherwise = child;
    } else if (!when && child.props.isTrue === true) {
      when = child;
    }
  });

  return when || otherwise || null;
};

Show.When = ({ isTrue, children }) => (isTrue ? children : null);
Show.Else = ({ children }) => children;

export default Show;