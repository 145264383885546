import axios from 'axios';
import { getLSData, setLSData } from "./services";


// Function to get API key from URL parameters
const getApiKeyFromUrl = () => {
  const params = new URLSearchParams(window.location.search);
  const apiKey = params.get('api_key');

  if (apiKey) {
    // If api_key is found in the URL, store it in localStorage for future use
    setLSData('user-api-key', apiKey);
  }

  return apiKey;
};

// Create an instance of Axios
const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// request interceptor
httpClient.interceptors.request.use(
  (config) => {
    const token = getLSData('user-token'); // token from localStorage
    const apiKeyFromUrl = getApiKeyFromUrl(); // Try to get API key from URL
    const apiKey = apiKeyFromUrl || getLSData('user-api-key') || 'e2b5c60361c00d586b5bf12e2e8b36b14a52f04b84c1d3db8261ec235cdb416c'; // Fallback to localStorage if not in URL

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    if (apiKey) {
      config.headers['X-API-Key'] = apiKey;
    }

    return config;
  },
  (error) => {
    console.log(error)
    // return Promise.reject(error);
  }
);

export default httpClient;