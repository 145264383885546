import React, { useState, useContext, useEffect } from 'react';
import './index.css';
import { ReactComponent as Check } from '../../assets/check.svg';
import { ReactComponent as Add } from '../../assets/add.svg';
import { ReactComponent as Dropdown } from '../../assets/dropdown.svg';
import { StoreContext } from '../../store';

import { getTabColor } from '../../services';

import Show from '../../utility/Show';

const FilterContainer = ({ title, image, categories, isRecommended = false }) => {
  const { state, dispatch, actions } = useContext(StoreContext);
  const {
    categoryFilters,
    currentView,
    isLoading,
    mapTab,
    isMobile,
    showFilter,
  } = state;

  const [isOpen, setOpen] = useState(false);
  const [parentCategories, setParentCategories] = useState([]);

  const isModern = currentView === 'Modern';

  const isCategoryExists = (categories) => {
    return categories.some((item) => categoryFilters.includes(item));
  };

  const isParentCategorySelected = (category) => {
    return parentCategories.includes(category);
  };

  const setParentFilters = (category) => {
    const categories = category.list.map((item) => item.value);
    if (isParentCategorySelected(category.name)) {
      const updatedParents = parentCategories.filter(
        (parent) => parent !== category.name
      );
      if (updatedParents.length) {
        handleChildFilters(categories);
      } else {
        // Reset category filters if updatedParents is empty
        dispatch({ type: 'RESET_CATEGORY', payload: [''] });
      }
      setParentCategories(updatedParents);
    } else {
      handleChildFilters(categories);
      setParentCategories([...parentCategories, category.name]);
    }
  };

  const handleChildFilters = (child) => {
    actions.sendDataToGoogleAnalytics('filter_btn_clicks', {
      filter_btn_name: child.name,
    });
    let childCategories = [];
    childCategories = child.length ? child : [child.value];

    if (isCategoryExists(childCategories)) {
      dispatch({ type: 'REMOVE_CATEGORY', payload: childCategories });
    } else {
      dispatch({ type: 'APPEND_CATEGORY', payload: childCategories });
    }
  };

  const setFilters = (category) => {
    const categories = category.map((item) => item.value);

    if (isCategoryExists(category)) {
      dispatch({ type: 'REMOVE_CATEGORY', payload: categories });
    } else {
      dispatch({ type: 'APPEND_CATEGORY', payload: categories });
    }
  };

  const isSelected = (category) => {
    return categoryFilters.includes(category);
  };

  const getLength = () => {
    return categories.filter((item) => categoryFilters.includes(item.value))
      .length;
  };

  useEffect(() => {
    if (!showFilter) {
      setOpen(false);
    } else {
      if (
        (mapTab === 'Transit' ||
        mapTab === 'Leisure')
      ) {
        setOpen(true);
      }
    }
  }, [showFilter]);

  const btnColor = (isParent) => {
    const style = {
      '--button-color': isParent ? '#347cf3' : '#f1592a',
    };
    return style;
  };

  const combinedStyle = {
    ...btnColor(false),
    ...getTabColor(false, mapTab)
  };

  return (
    <div
      className={`filter-item-container ${
        isModern && 'modern-filter-item-container'
      }`}
    >
      <Show.When isTrue={mapTab !== 'Transit' && mapTab !== 'Leisure' && title}>
        <div className='filter-header' onClick={() => setOpen(!isOpen)}>
          {/* <img src={image} alt={title} /> */}
          <span> {title} </span>
          {getLength() > 0 && <div className='total-filter'> {getLength()} </div>}
          <span
            className={`filter-icon ${isOpen && isModern && 'close-filter-item'}`}
          >
            {isModern ? <Dropdown /> : isOpen ? '-' : '+'}
          </span>
        </div>
      </Show.When>

      {isOpen && (
        <div className='category-list-wrapper'>
          {categories.length
            ? categories.map((item) => {
                return item.list ? (
                  isOpen &&
                    item.list.map((category) => {
                      return (
                        <div
                          className={`filter-item ${
                            categoryFilters?.includes(category.value) &&
                            'selected-filter-item'
                          }
                          ${isLoading && 'btn-loading'}
                          `}
                          style={combinedStyle}
                          onClick={() => handleChildFilters(category)}
                        >
                          {category.name}
                        </div>
                      );
                    })
                ) : (
                  <div
                    className={`category-item ${
                      isSelected(item.value) ? 'is-category-selected' : ''
                    }`}
                  >
                    {item.name}
                  </div>
                );
              })
            : 'No Categories'}
          {/* Add empty divs to fill the last row */}
          {categories.length % 3 !== 0 &&
            Array(3 - (categories.length % 3))
              .fill()
              .map((_, index) => (
                <div key={`empty-${index}`} className="filter-item empty-filter-item"></div>
              ))}
        </div>
      )}
      {isModern && <hr className='line-break'></hr>}
    </div>
  );
};

export default FilterContainer;
