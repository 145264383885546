import { useRef, useState, useEffect } from 'react';
import './index.css';
import { ReactComponent as WalkspanLogo } from '../../assets/walkspan-logo-2.svg';
import { ReactComponent as Check } from '../../assets/survey/check-circle.svg';

import Show from '../../utility/Show';
import FormError from '../../utility/FormError';

import { surveyQuestions } from '../../constants';

const Survey = ({ navigate, actions, handleSurvey }) => {
  const questionRefs = useRef([]);
  const componentRef = useRef(null);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [textareaValues, setTextareaValues] = useState({});
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const letters = ['A', 'B', 'C', 'D', 'E', 'F'];

  const scrollToTop = () => {
    if (componentRef.current) {
      componentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleOptionChange = (qIndex, oIndex, isTextarea, subQIndex = null) => {
    if (!isTextarea) {
      if (subQIndex !== null) {
        setSelectedAnswers({
          ...selectedAnswers,
          [`${qIndex}-${subQIndex}`]: oIndex
        });
      } else {
        setSelectedAnswers({
          ...selectedAnswers,
          [qIndex]: oIndex
        });
      }

      if (!(qIndex === 4 && oIndex === 0) && qIndex < questionRefs.current.length - 1) {
        questionRefs.current[qIndex + 1].scrollIntoView({ behavior: 'smooth' });
      }

      setErrorMessages({
        ...errorMessages,
        [qIndex]: ''
      });
    } else {
      setSelectedAnswers({
        ...selectedAnswers,
        [qIndex]: oIndex
      });

      setErrorMessages({
        ...errorMessages,
        [qIndex]: ''
      });
    }
  };

  const handleSubmit = () => {
    let allAnswered = true;
    const newErrorMessages = {};
    let firstUnansweredQuestion = null;

    surveyQuestions.forEach((question, qIndex) => {
      if (!question.isOptional) {
        if (question.subQuestions) {
          question.subQuestions.forEach((_, subQIndex) => {
            const selectedOption = selectedAnswers[`${qIndex}-${subQIndex}`];
            if (selectedOption === undefined) {
              newErrorMessages[`${qIndex}-${subQIndex}`] = 'Please answer this sub-question';
              if (!firstUnansweredQuestion) {
                firstUnansweredQuestion = questionRefs.current[qIndex];
              }
              allAnswered = false;
            }
          });
        } else {
          const selectedOption = selectedAnswers[qIndex];
          if (selectedOption === undefined) {
            newErrorMessages[qIndex] = 'Please answer this question';
            if (!firstUnansweredQuestion) {
              firstUnansweredQuestion = questionRefs.current[qIndex];
            }
            allAnswered = false;
          } else if (surveyQuestions[qIndex].requiresTextArea && selectedOption === 0 && !textareaValues[qIndex]) {
            // 0 is the index of "Yes"
            newErrorMessages[qIndex] = 'Please provide an answer for this question';
            if (!firstUnansweredQuestion) {
              firstUnansweredQuestion = questionRefs.current[qIndex];
            }
            allAnswered = false;
          } else if (surveyQuestions[qIndex].options[selectedOption]?.isTextarea && !textareaValues[qIndex]) {
            newErrorMessages[qIndex] = 'Please provide an answer for this question';
            if (!firstUnansweredQuestion) {
              firstUnansweredQuestion = questionRefs.current[qIndex];
            }
            allAnswered = false;
          }
        }
      }
    });

    setErrorMessages(newErrorMessages);

    // Scroll to the first unanswered question if any
    if (firstUnansweredQuestion) {
      firstUnansweredQuestion.scrollIntoView({ behavior: 'smooth' });
    }

    if (allAnswered) {
      console.log(selectedAnswers);
      setIsSubmitted(true);
    }
  };

  const handleTextareaChange = (qIndex, event) => {
    const value = event.target.value;
    setTextareaValues({
      ...textareaValues,
      [qIndex]: value
    });

    setSelectedAnswers({
      ...selectedAnswers,
      [qIndex]: value
    });

    if (value.trim() !== '') {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [qIndex]: ''
      }));
    }
  };

  const handleSubQuestionChange = (qIndex, subQIndex, oIndex) => {
    const key = `${qIndex}-${subQIndex}`;
    setSelectedAnswers((prevAnswers) => {
      if (prevAnswers[key] === oIndex) {
        const newAnswers = { ...prevAnswers };
        delete newAnswers[key];
        return newAnswers;
      } else {
        return {
          ...prevAnswers,
          [key]: oIndex
        };
      }
    });

    setErrorMessages({
      ...errorMessages,
      [key]: ''
    });
  };

  const isSubOptionSelected = (qIndex, subQIndex, oIndex) => {
    return selectedAnswers[`${qIndex}-${subQIndex}`] === oIndex;
  };

  const gotoContactUs = () => {
    navigate('/contact-us');
    handleSurvey(false);
    setIsSubmitted(false);
  };

  const handleLogout = async () => {
    await actions.handleLogout();
    handleSurvey(false);
    setIsSubmitted(false);
  };

  const renderSurvey = () => {
    return (
      <>
        <div className="survey-header max-w-screen-lg" ref={componentRef}>
          <div className='message'>
            <h1>Thank you for trying out Walkspan</h1>
            <span>
              Your responses to this short survey will help us make Walkspan even better for your next visit and others.
            </span>
          </div>
          <WalkspanLogo className='logo' />
        </div>
        <div className='limit-survey-questions max-w-screen-lg'>
          {surveyQuestions.map((item, qIndex) => {
            return (
              <div
                className={`question-container ${
                  item.isOptional !== undefined ? 'additional-comments' : ''
                } ${
                  item.requiresTextArea !== undefined ? 'recommend-to' : ''
                }`}
                key={qIndex}
                ref={(el) => (questionRefs.current[qIndex] = el)}
              >
                <div className='question'>{qIndex + 1}. {item.question}</div>
                <div className='options-wrapper'>
                  <Show>
                    <Show.When isTrue={item.subQuestions !== undefined}>
                      {item.subQuestions?.map((subQuestion, subQIndex) => (
                        <div key={subQIndex}>
                          <div className='sub-question'>{subQuestion}</div>
                          <div className='sub-options'>
                            {item.options.map((option, oIndex) => (
                              <div
                                className={`rating ${
                                  isSubOptionSelected(qIndex, subQIndex, oIndex) ? 'selected' : ''
                                }`}
                                onClick={() => handleSubQuestionChange(qIndex, subQIndex, oIndex)}
                                key={`sub-options-${oIndex}`}
                              >
                                {option}
                              </div>
                            ))}
                          </div>
                          <Show.When isTrue={errorMessages[`${qIndex}-${subQIndex}`]}>
                            <FormError error={errorMessages[`${qIndex}-${subQIndex}`]} textColor='#f14c4c' />
                          </Show.When>
                        </div>
                      ))}
                    </Show.When>
                    <Show.Else>
                      <Show>
                        <Show.When isTrue={item.isOptional !== undefined}>
                          <div className='option'>
                            <textarea
                              name={`question${qIndex + 1}-textarea`}
                              id={`question${qIndex + 1}-textarea`}
                              rows='4'
                              placeholder='Add your comment here'
                              value={textareaValues[qIndex] || ''}
                              onChange={(e) => handleTextareaChange(qIndex, e)}
                            ></textarea>
                          </div>
                        </Show.When>
                        <Show.Else>
                          {item.options?.map((option, oIndex) => (
                            <div className='option' key={oIndex}>
                              <input
                                type="radio"
                                id={`question${qIndex + 1}-option${oIndex + 1}`}
                                name={`question${qIndex + 1}`}
                                value={option.label}
                                onChange={() => handleOptionChange(qIndex, oIndex, option.isTextarea)}
                              />
                              <Show>
                                <Show.When isTrue={option.isTextarea}>
                                  <textarea
                                    name={`question${qIndex + 1}-textarea${oIndex + 1}`}
                                    id={`question${qIndex + 1}-textarea${oIndex + 1}`}
                                    rows='4'
                                    placeholder={option.label}
                                    value={textareaValues[qIndex] || ''}
                                    onChange={(e) => handleTextareaChange(qIndex, e)}
                                  ></textarea>
                                </Show.When>
                                <Show.Else>
                                  <label htmlFor={`question${qIndex + 1}-option${oIndex + 1}`}>
                                    {letters[oIndex]}. <span>{option.label}</span>
                                  </label>
                                </Show.Else>
                              </Show>
                            </div>
                          ))}
                          <Show.When isTrue={item.requiresTextArea !== undefined}>
                            <div className='option' key={`question-${qIndex}-textarea`}>
                              <textarea
                                name={`question${qIndex + 1}-textarea`}
                                id={`question${qIndex + 1}-textarea`}
                                rows='4'
                                placeholder='I would recommend it to ...'
                                value={textareaValues[qIndex] || ''}
                                onChange={(e) => handleTextareaChange(qIndex, e)}
                              ></textarea>
                            </div>
                          </Show.When>
                        </Show.Else>
                      </Show>
                    </Show.Else>
                  </Show>
                </div>
                <Show.When isTrue={errorMessages[qIndex]}>
                  <FormError error={errorMessages[qIndex]} textColor='#f14c4c' />
                </Show.When>
              </div>
            )
          })}
          <button
            className='complete-survey-btn'
            onClick={() => handleSubmit(true)}
          >
            Complete Survey
          </button>
        </div>
      </>
    )
  };

  const renderThankYouMsg = () => {
    return (
      <div className='survey-ty-container max-w-screen-lg'>
        <WalkspanLogo className='logo' />
        <h1>Thank you for completing our survey!</h1>
        <Check />
        <div className='message'>
          Your responses to this short survey will help us make Walkspan even better for your next visit and others.
        </div>
        <div className='buttons'>
          <button onClick={() => gotoContactUs()}>Contact Us</button>
          <button onClick={() => handleLogout()}>Log out</button>
        </div>
      </div>
    )
  };

  return (
    <div className="survey-container max-w-screen-lg">
      <Show>
        <Show.When isTrue={isSubmitted}>
          {renderThankYouMsg()}
        </Show.When>
        <Show.Else>
          {renderSurvey()}
        </Show.Else>
      </Show>
    </div>
  )
};


export default Survey;