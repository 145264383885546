import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import Signup from './components/Signup';
import Home from './components/Home';
import About from './components/About';
import Platform from './components/Platform';
import Contact from './components/Contact';
import AddressScore from './components/AddressScore';
import reportWebVitals from './reportWebVitals';
import { StoreProvider } from './store';
import { SnackbarProvider } from 'notistack';
import MainLayout from './MainLayout';
import NotFound from './components/NotFound';
import AddressNotFound from './components/AddressNotFound';
import Plugin from './components/Plugin';
import ConfirmSignup from './components/Signup/confirm-signup';
import ResetPassword from './components/ForgotPassword/reset-password';
import { QueryClient, QueryClientProvider } from 'react-query';
import Navigation from './components/Navigation';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <StoreProvider>
      <SnackbarProvider>
        <QueryClientProvider client={queryClient}>
          <MainLayout>
            <Routes>
              <Route path='/login' element={<Login />} />
              <Route path='/forgot-password' element={<ForgotPassword />} />
              <Route path='/signup' element={<Signup />} />
              <Route path='/home' element={<Home />} />
              <Route path='/about-us' element={<About />} />
              <Route path='/platform' element={<Platform />} />
              <Route path='/contact-us' element={<Contact />} />
              <Route path='/' element={<App />} />
              <Route path='/plugin' element={<App />} />
              <Route path='/address/*' element={<AddressScore />} />
              <Route path='*' element={<NotFound />} />
              <Route path='/address-not-found' element={<AddressNotFound />} />
              <Route path='/summary' element={<Plugin />} />
              <Route path='/navigation' element={<Navigation />} />
              <Route path='/activate/:signupCode' element={<ConfirmSignup />} />
              <Route path='/reset-password/:resetPassCode' element={<ResetPassword />} />
            </Routes>
          </MainLayout>
        </QueryClientProvider>
      </SnackbarProvider>
    </StoreProvider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
