import React, { useContext } from 'react';
import { StoreContext } from '../../store';
import './index.css';
import Walking from '../../assets/walking.gif';

export const WalkingLoader = () => {
  const { state } = useContext(StoreContext);
  const { showCompareLifeStyle } = state;

  return (
    <div
      className={`loader-container ${
        showCompareLifeStyle && 'compare-loader-container'
      }
      `}
    >
      <img src={Walking} />
      <img src={Walking} />
      <img src={Walking} />
    </div>
  );
};


export const CircularLoader = ({ size }) => {
  const getStyle = () => {
    const style = {
      '--loader-size': size || '50px',
    };
    return style;
  };

  return (
    <div className='circular-loader' style={getStyle()}>
    </div>
  )
}
