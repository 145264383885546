// useGoogleMapsLoader.js
import { useState, useEffect } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';


const useGoogleMaps = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const libraries = ['places'];

  const { isLoaded: googleMapsLoaded } = useJsApiLoader({
    version: 'single-map',
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAmcGjLChegfkj9yJZBMKhlk18ckavo3HI',
    libraries: libraries,
  });

  useEffect(() => {
    if (googleMapsLoaded) {
      setIsLoaded(true);
    }
  }, [googleMapsLoaded]);

  return isLoaded;
};

export default useGoogleMaps;