import { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { StoreContext } from '../../store';
import './index.css';
import { ReactComponent as WalkspanLogo } from '../../assets/walkspan-logo-2.svg';

import Show from '../../utility/Show';

const ConfirmSignup = () => {
  const { signupCode } = useParams();

  const { state, dispatch, actions, navigate } = useContext(StoreContext);
  const { errorMessage, signupSubmitted, confirmSignupSubmitted } = state;

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      actions.clearErrorMessage();
      confirmSignupSubmit();
    }
  }, [isMounted]);

  const gotoLogin = () => {
    actions.clearErrorMessage();

    if (signupSubmitted) {
      dispatch({
        type: 'SET_SIGNUP_SUBMITTED',
        payload: false,
      });
    }

    if (confirmSignupSubmitted) {
      dispatch({
        type: 'SET_CONFIRM_SIGNUP_SUBMITTED',
        payload: false,
      });
    }

    navigate('/login');
  };

  const confirmSignupSubmit = async () => {
    const payload = {
      code: signupCode,
    };

    await actions.handleConfirmSignup(payload);
  };

  return (
    <div className="signup-container">
      <div className='signup-overlay'></div>
      <div className='signup-card'>
        <WalkspanLogo className='walkspan-logo'/>
        <div className='title'>Confirm Signup</div>

        <Show>
          <Show.When isTrue={confirmSignupSubmitted}>
            <div>
              Congratulations! Your account has been successfully registered. You can now log in using your credentials
            </div>
          </Show.When>
          <Show.Else>
            <div>
              You're account is already activated.
            </div>
          </Show.Else>
        </Show>
        <div className='signup-buttons'>
          <button className='login-button' onClick={() => gotoLogin()}>
            Login
          </button>
        </div>
      </div>
    </div>
  )
};

export default ConfirmSignup;