import React, { useState, useContext, useRef, useEffect } from 'react';
import { StoreContext } from '../../store';
import './index.css';
import { ReactComponent as Property } from '../../assets/architecture.svg';
import Clear from '../../assets/cancel-icon.svg';
import { ReactComponent as Close } from '../../assets/cancel-white.svg';
import SearchInput from '../Search';

import Show from '../../utility/Show';

const CompareMaps = ({
  currentProperty,
  currentView,
  properties,
  handleClick,
  index,
  showListProperties,
  showCompareLifeStyle,
}) => {

  const isModern = currentView === 'Modern';

  const filteredProperties = () => {
    return properties.filter(property => !property.label.toLowerCase().includes(currentProperty.toLowerCase()))
  };

  return (
    <div className={`property-list-wrapper ${
      showCompareLifeStyle ? 'compare-property-list-wrapper' : ''}`
    }>
      <div
        className='overlay-property'
        onClick={() => showListProperties(false)}
      ></div>

      <div className={`property-search-container ${
        showCompareLifeStyle ? 'compare-property-search-container' : ''
        } ${isModern && 'modern-property-search-container'}`
      }>
        <Show.When isTrue={isModern}>
          <div className='modern-property-search-header'>
            <h2>Change Address</h2>
            <Close onClick={() => showListProperties(false)}/>
          </div>
        </Show.When>
        <SearchInput
          currentProperty={currentProperty}
          currentView={currentView}
          properties={filteredProperties()}
          handleClick={handleClick}
          showListProperties={showListProperties}
          index={index}
          isFocus={true}
        />
      </div>
    </div>
  );
};

export default CompareMaps;
