import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import './index.css';

import { ReactComponent as Logo } from '../../assets/walkspan-logo-2.svg';
import { ReactComponent as LinkedIn } from '../../assets/social-icons/linkedin-black.svg';
import { ReactComponent as Instragram } from '../../assets/social-icons/instagram-icon.svg';
import { ReactComponent as Twitter } from '../../assets/social-icons/twitterx-icon.svg';

import { StoreContext } from '../../store';

import Show from '../../utility/Show';

const Header = () => {
  const { state, navigate } = useContext(StoreContext);
  const { isMobile } = state;

  const location = useLocation();

  const navLinks = [
    { path: '/home', label: 'Home' },
    { path: '/about-us', label: 'About Us' },
    { path: '/platform', label: 'Platform' },
    // { path: '/media', label: 'Media' },
    { path: '/contact-us', label: 'Contact Us' },
  ];

  const isActive = (path) => location.pathname === path;

  const handleLinkClick = (link) => {
    navigate(link.path);
    document.title = `${link.label} - Walkspan`;
  };

  return (
    <div className='landing-header'>
      <Logo className='walkspan-logo' onClick={() => navigate('/home')} />
      <div className='landing-header-buttons'>
        {navLinks.map((link) => (
          <div
            key={link.path}
            onClick={() => handleLinkClick(link)}
            className={isActive(link.path) ? 'active-header' : ''}
          >
            {link.label}
          </div>
        ))}
      </div>
      <Show.When isTrue={!isMobile}>
        <div>
          {/* <button onClick={() => navigate('/login')} className='login-btn'>
            login
          </button> */}
          <div className='landing-header-socials'>
            <a target='_blank' href='https://www.instagram.com/walkspan_nyc/'>
              <Instragram />
            </a>
            <a
              target='_blank'
              href='https://www.linkedin.com/company/walkspan-inc'
            >
              <LinkedIn />
            </a>
            <a target='_blank' href='https://twitter.com/walkspan_walks'>
              <Twitter />
            </a>
          </div>
        </div>
      </Show.When>
    </div>
  );
};

export default Header;
