import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import './index.css';
import '../../styles/animation.css';

// import CompareMaps from './compare';
import MapSingle from './single-map';
import { EstablishmentSearch } from '../EstablishmentSearch';
import WalkChart from '../Dashboard';
import DashboardSimplified from '../DashboardSimplified';
import { MobileDashboard } from '../Dashboard/mobile';
import { AddressSearch } from '../AddressSearch';
import { FeedbackForm } from '../FeedbackForm';
import MobileScoreContainer from './mobile-score-container';
import Show from '../../utility/Show';
import { ScoreIcon } from "../../utility/ScoreIcon";
import MyFavorites from '../MyFavorites';
import RecentHistory from '../RecentHistory';
import { Autocomplete } from '../../utility/Autocomplete';
import NeighborhoodTabPopup from '../NeighborhoodTabPopup';
import NeighborhoodTabLegendPopup from '../TabLegendPopup';
import MapQuickSearch from '../EstablishmentSearch/map-quick-search';
import FilterContainer from '../Filter';
import MarkerList from './marker-list';
import CompareMaps from '../CompareMaps';
import CompareMapsMobile from './compare';

import { StoreContext } from '../../store';
import { Tooltip } from 'react-tooltip';

import { ReactComponent as Back } from '../../assets/back-btn.svg';
import { ReactComponent as QuickSearch } from '../../assets/quick-search.svg';
import { ReactComponent as Filter } from '../../assets/filter-2.svg';
import { ReactComponent as MileIcon } from '../../assets/mile-icon.svg';
import { ReactComponent as Action } from '../../assets/add-circle.svg';
import { ReactComponent as Minus } from '../../assets/minus-circle.svg';
import { ReactComponent as Favorite } from '../../assets/marker-popup/favorite.svg';
import { ReactComponent as RemoveIcon } from '../../assets/remove-circle.svg';
import { ReactComponent as Burger } from '../../assets/burger-menu.svg';
import { ReactComponent as Close } from '../../assets/close-icon.svg';

import {
  getAttributeDescription,
  setLSData,
  getLSData,
  lifeStyleFeaturesScores,
  isObjectEmpty,
  getFeatureIcon,
  findColor,
  saveAddressToFavorites,
  getSoloFeelScore
} from '../../services';
import { walkIndexFeatures, lifeStyleFeatures } from '../../constants';
import MapControls from './MapControls';

const MapContainer = () => {
  const { state, dispatch, actions, navigate } = useContext(StoreContext);

  const {
    currentTab,
    showCompareLifeStyle,
    mapTab,
    listOfMarkers,
    listOfProperties,
    showWalkIndexCompare,
    showWalkIndexSummary,
    showDashboard,
    favorites,
    lifeStyleScores,
    isMobile,
    isMenuOpen,
    isLoading,
    currentDistance,
    compareLifeStyleAddress,
    keyPropertyName,
    isiPhone,
    currentCompareMap,
    compareMapsSliderIndex,
    currentAddress,
    isStandalone,
    isDesktopSidebarOpen,
    showFilter,
    isEstablishmentSearchOpen,
    establishmentSearchResult,
    isFullScreenMap,
    currentView,
    isPlugin,
    showSearchAddress,
    enableTutorial,
    selectedMap,
    previousPage,
    categoryFilters,
    currentRates,
    isAddressInNYC,
    quickSearchMarkers,
    showFeedbackForm,
    showMapMobileControls,
    walkindexScores,
    currentMarker,
    showFavoriteList,
    showMapStreetView,
    showRecentHistoy,
    selectedRecommendedFilters,
    isMobileSidebarOpen,
    showMapQS,
    showMarkerList,
    savedAddresses,
    clientName,
    isEmbedMode,
    showLegend,
  } = state;

  const { enqueueSnackbar } = useSnackbar();

  const [allMarkers, setAllMarkers] = useState([]);

  const featuresContainerRef = useRef(null);

  const isWalkIndex = currentTab === 'walkindex';
  const isModern = currentView === 'Modern';
  const distanceData = ['0.25', '0.5', '1.0'];
  const timeData = ['5 min', '10 min', '20 min'];

  const setMapType = (type, index) => {
    if (isMobile) {
      handleButtonScroll(index);
      if (selectedRecommendedFilters?.length > 0) {
        dispatch({ type: 'SET_SELECTED_RECOMMENDED_FILTERS', payload: [] });
      }
      if (isEstablishmentSearchOpen) {
        dispatch({ type: 'SET_IS_ESTABLISHMENT_SEARCH_OPEN', payload: false });
      }
    }
    if (!isLoading) {
      actions.sendDataToGoogleAnalytics('map_tab_btn_clicks', {
        map_btn_name: type + '_Click_' + currentAddress?.property,
      });

      if (currentMarker[selectedMap] && isMobile) {
        dispatch({
          type: 'SET_CURRENT_MARKER',
          payload: { markerIndex: selectedMap, markerData: null },
        });
      }
      dispatch({ type: 'SET_CURRENT_PRESENCE', payload: '' });
      if (currentRates.length !== 0 && currentRates[0] !== '') {
        dispatch({
          type: 'RESET_RATE',
          payload: [],
        });
      }
      if (categoryFilters?.length > 1 || categoryFilters[0] !== '') {
        dispatch({
          type: 'RESET_CATEGORY',
          payload: [''],
        });
      }
      dispatch({ type: 'SET_MAP_TAB', payload: type });
      closeMarkerList()
      dispatch({ type: 'SET_SHOW_MAP_QS', payload: false });
      actions.setMapCenter();
      // actions.setDefaultZoom();

      // if (!showCompareLifeStyle) {
      //   const showFilterPayload = !isWalkIndex && type !== 'All' ? true : false;
      //   dispatch({ type: 'SET_SHOW_FILTERS', payload: showFilterPayload });
      // }
    }
  };

  const getControllers =
    currentTab === 'walkindex'
      ? walkIndexFeatures
      : // : showCompareLifeStyle
        // ? lifeStyleEssentials.filter((item) => item.name !== 'Favorites')
        lifeStyleFeatures;

  const getLength = (name) => {
    if (!lifeStyleScores[0]) return;

    if (name === 'Favorites') {
      return favorites[keyPropertyName]?.length
        ? favorites[keyPropertyName]?.length
        : isMobile
        ? 0
        : '';
    } else if (name === 'All') {
      return Object.values(lifeStyleScores[0]).reduce(
        (acc, count) => acc + count,
        0
      );
    } else {
      const getName = name === 'Leisure' ? 'leisure' : name.toLowerCase();
      return lifeStyleScores ? lifeStyleScores[0][getName] : '';
    }
  };

  const handleButtonScroll = (buttonIndex) => {
    const container = featuresContainerRef.current;
    if (!container) return;

    const button = container.children[buttonIndex];
    const buttonRect = button.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    // Calculate the center of the button
    const buttonCenter = buttonRect.left + buttonRect.width / 2;

    // Calculate the center of the container
    const containerCenter = containerRect.left + containerRect.width / 2;

    // Calculate the scroll offset to center the button
    const scrollOffset = buttonCenter - containerCenter;

    // Smoothly scroll to the calculated position
    container.scrollTo({
      left: container.scrollLeft + scrollOffset,
      behavior: 'smooth',
    });
  };

  const renderCustomButtons = () => {
    return getControllers.map((item, index) => {
      const btnColor = {
        backgroundColor: item.name === mapTab && item.color,
        '--type-color': item.color,
      };

      const scores = isWalkIndex
        ? walkindexScores[selectedMap]
        : lifeStyleFeaturesScores(lifeStyleScores, selectedMap, currentDistance, isStandalone);

      const selectedScore = scores?.[item.id] !== undefined ? isWalkIndex ? getSoloFeelScore(scores[item.id]) : Math.round(scores[item.id]) : 1;

      return (
        <div key={`feature-${item.name}`}>
          <button
            data-tooltip-id={`custom-button-${item.name}`}
            className={`map-btn capsule-map-btn ${
              isLoading ? 'btn-loading' : ''
            } ${
              isStandalone ? 'small-map-btn' : ''
            } ${
              isModern ? 'modern-map-btn' : ''
            } ${
              item.name === mapTab ? 'is-btn-active active-capsule-map-btn' : ''
            }`}
            style={btnColor}
            onClick={isLoading ? null : () => setMapType(item.name, index)}
          >
            <Show>
              <Show.When isTrue={item.name !== 'All' && item.name !== 'Favorites' && !showCompareLifeStyle}>
                <ScoreIcon
                  height='20px'
                  width='20px'
                  withBg={false}
                  score={selectedScore.toString()}
                  content={
                    <div className='feature-icon-wrapper'>
                      {item.svg}
                    </div>
                  }
                />
              </Show.When>
              <Show.Else>
                <div className={`feature-icon-wrapper ${showCompareLifeStyle ? 'compare' : ''}`}>
                  {item.svg}
                </div>
              </Show.Else>
            </Show>
            <div className='btn-name'> {item.name} </div>

            {/* {!showCompareLifeStyle && currentTab === 'lifestyle' && (
              <span className='total-establishments'>
                {' '}
                {getLength(item.name)}{' '}
              </span>
            )} */}

            {/* Tooltip */}
            {isMobile ? (
              <Tooltip
                className='custom-btn-popup'
                anchorSelect="[name^='map-btn-wrapper-']"
                place='right'
                content={getAttributeDescription(item.name)}
              >
                {' '}
                {getAttributeDescription(item.name)}
              </Tooltip>
            ) : (
              <Tooltip
                className='custom-btn-popup'
                id={`custom-button-${item.name}`}
                place='bottom'
              >
                {' '}
                {getAttributeDescription(item.name)}
              </Tooltip>
            )}
          </button>
        </div>
      );
    });
  };

  const properties = listOfProperties.map((item) => {
    return {
      label: item.property,
      value: item,
    };
  });

  const openCompareExit = () => {
    dispatch({ type: 'SET_SHOW_COMPARE_CONFIRM_EXIT', payload: true });
  };

  const handleBack = () => {
    if (isFullScreenMap || isMobile) {
      actions.toggleFullScreenMap();
    }
    if (showCompareLifeStyle) {
      dispatch({
        type: 'RESET_LIFESTYLE_COMPARE_ADDRESS',
        payload: currentAddress,
      });
    }

    closeMarkerList()

    if (showCompareLifeStyle) {
      dispatch({
        type: 'SET_COMPARE_LIFESTYLE',
        payload: false,
      });

      if (!isModern) {
        dispatch({
          type: 'RESET_LIFESTYLE_COMPARE_ADDRESS',
          payload: currentAddress,
        });

        dispatch({ type: 'RESET_LIFESTYLE_COMPARE_MAPS' });
      } else {
        if (previousPage?.length > 0) {
          actions.filterPreviousPage();
        }
      }

      dispatch({
        type: 'SET_COMPARE_LIFESTYLE',
        payload: false,
      });

      const mapTabPayload = isWalkIndex ? 'Nature' : 'All';
      dispatch({ type: 'SET_MAP_TAB', payload: mapTabPayload }); 
    } else {
      dispatch({ type: 'SET_SHOW_DASHBOARD', payload: true });
    }

    dispatch({
      type: 'SET_SHOW_FILTERS',
      payload: false,
    });
    dispatch({
      type: 'RESET_CATEGORY',
      payload: [''],
    });
    dispatch({
      type: 'SET_SHOW_ESTABLISHMENTS',
      payload: false,
    });
    dispatch({ type: 'SET_CURRENT_WALKINDX_TYPE', payload: null });
    dispatch({ type: 'RESET_CURRENT_LIFESTYLE_TYPE' });
    dispatch({ type: 'SET_MENU_SIDEBAR', payload: false });
    dispatch({ type: 'SET_DESKTOP_SIDEBAR', payload: false });

    if (isEstablishmentSearchOpen) {
      const distance = isAddressInNYC ? '1' : '3';
      dispatch({ type: 'SET_CURRENT_DISTANCE', payload: distance });
      dispatch({ type: 'SET_IS_ESTABLISHMENT_SEARCH_OPEN', payload: false });
      dispatch({ type: 'RESET_CATEGORY', payload: [''] });
      dispatch({
        type: 'SET_ESTABLISHMENT_SEARCH_RESULT',
        payload: {},
      });
    }

    if (showMapQS) {
      dispatch({ type: 'SET_SHOW_MAP_QS', payload: false });
      dispatch({ type: 'SEARCH_CATEGORY', payload: '' });
    }
  };

  const toggleSideMenu = () => {
    dispatch({ type: 'SET_MENU_SIDEBAR', payload: !isMenuOpen });
  };

  const filteredProperties = () => {
    return properties.filter((unselected) => {
      return !compareLifeStyleAddress.some((selected) =>
        selected?.property
          ?.toLowerCase()
          .includes(unselected.label?.toLowerCase())
      );
    });
  };

  const redirectToMap = () => {
    if (isModern) {
      if (previousPage?.length > 0) {
        dispatch({
          type: 'SET_CURRENT_MARKER',
          payload: { markerIndex: 0, markerData: null },
        });
        const mapTabPayload = isWalkIndex ? 'Nature' : 'All';
        dispatch({ type: 'SET_MAP_TAB', payload: mapTabPayload });
        actions.filterPreviousPage();
      } else {
        dispatch({ type: 'SET_COMPARE_LIFESTYLE', payload: false });
      }
    }

    if (isFullScreenMap) actions.toggleFullScreenMap();
    // dispatch({ type: 'SET_SHOW_COMBINE_SEARCH', payload: true });
    // dispatch({ type: 'SET_SHOW_DASHBOARD', payload: false });
    // toggleMobileSidebar();
  };

  const useOutsideClick = (callback) => {
    const ref = useRef();

    useEffect(() => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };

      document.addEventListener('click', handleClick);

      return () => {
        document.removeEventListener('click', handleClick);
      };
    }, [ref]);

    return ref;
  };

  const handleClickOutside = () => {
    dispatch({ type: 'SET_MENU_SIDEBAR', payload: false });
  };

  const ref = useOutsideClick(handleClickOutside);

  const isFBFormVisible = () => {
    return (
      currentCompareMap.index === compareMapsSliderIndex &&
      currentCompareMap.visibleForm
    );
  };

  useEffect(() => {
    // need to update logic
    if (isMobile) {
      dispatch({ type: 'SET_MENU_SIDEBAR', payload: true });
    }

    const isGetStarted = getLSData('isGetStarted');

    if ((isGetStarted && isGetStarted === 0) || isPlugin || !enableTutorial) {
      dispatch({ type: 'SET_SHOW_TUTORIAL', payload: false });
    } else {
      dispatch({ type: 'SET_SHOW_TUTORIAL', payload: true });
      setLSData('isGetStarted', 1);
    }
  }, []);

  const handleMobileBack = () => {
    if (showDashboard) {
      dispatch({ type: 'SET_SHOW_SEARCH_ADDRESS', payload: true });
    } else {
      if (previousPage?.length > 0) {
        actions.filterPreviousPage();
      } else {
        handleBack();
      }
    }
  };

  useEffect(() => {
    if (quickSearchMarkers?.markers?.length > 0) {
      setAllMarkers(quickSearchMarkers.markers);
    }
  }, [quickSearchMarkers]);

  const toggleDesktopSidebar = () => {
    dispatch({ type: 'SET_DESKTOP_SIDEBAR', payload: !isDesktopSidebarOpen });
    if (isDesktopSidebarOpen && showFilter) {
      dispatch({
        type: 'SET_SHOW_FILTERS',
        payload: !showFilter,
      });
    }
  };

  const showQuickSearchResult = () => {
    return (
      <div className='quick-search-results'>
        <p>
          Search results for <b>'{establishmentSearchResult?.searchText}'</b>:
          {isMobile ? <br /> : ' '}
          {establishmentSearchResult?.result} match
          {establishmentSearchResult?.result > 1 && 'es'} found
        </p>
      </div>
    );
  };

  const openTutorial = () => {
    dispatch({ type: 'SET_SHOW_TUTORIAL', payload: true });
  };

  const openFeedbackForm = () => {
    dispatch({
      type: 'SET_SHOW_FEEDBACK_FORM',
      payload: true
    });
    handleMobileMapControls();
  };

  const handleMobileMapControls = () => {
    dispatch({
      type: 'SET_SHOW_MAP_MOBILE_CONTROLS',
      payload: !showMapMobileControls
    });
  };

  const handleNeighborhoodTab = (tab) => {
    dispatch({ type: 'SET_CURRENT_TAB', payload: tab });
  };

  const handleWalkingDistance = (distance) => {
    if (isEstablishmentSearchOpen) {
      dispatch({ type: 'SET_IS_ESTABLISHMENT_SEARCH_OPEN', payload: false });
    }

    // if (categoryFilters?.length > 1 || categoryFilters[0] !== '') {
    //   dispatch({
    //     type: 'RESET_CATEGORY',
    //     payload: [''],
    //   });
    // }
    dispatch({ type: 'SET_CURRENT_DISTANCE', payload: distance.toString() })
  };

  const openQuickSearch = () => {
    if (isWalkIndex) {
      dispatch({ type: 'SET_CURRENT_TAB', payload: 'lifestyle' });
    }

    if (mapTab !== 'All') {
      dispatch({ type: 'SET_MAP_TAB', payload: 'All' });
    }

    if (showFilter) {
      dispatch({ type: 'SET_SHOW_FILTERS', payload: false });
    }

    if (categoryFilters?.length > 1 || categoryFilters[0] !== '') {
      dispatch({
        type: 'RESET_CATEGORY',
        payload: [''],
      });
    }

    if (currentMarker[selectedMap]) {
      dispatch({
        type: 'SET_CURRENT_MARKER',
        payload: { markerIndex: selectedMap, markerData: null },
      });
    }

    if (currentRates?.length > 0) {
      dispatch({ type: 'SET_RATES', payload: [] });
    }

    dispatch({ type: 'SET_IS_ESTABLISHMENT_SEARCH_OPEN', payload: true });
  };

  const openFilter = () => {
    if (isEstablishmentSearchOpen) {
      dispatch({ type: 'SET_IS_ESTABLISHMENT_SEARCH_OPEN', payload: false });
    }
    if (currentMarker[selectedMap]) {
      dispatch({
        type: 'SET_CURRENT_MARKER',
        payload: { markerIndex: selectedMap, markerData: null },
      });
    }

    dispatch({ type: 'SET_SHOW_FILTERS', payload: true });
  }

  const handleCompareAddress = (index) => {
    dispatch({
      type: 'SET_COMPARE_MAPS_SLIDER_INDEX',
      payload: index
    });
    dispatch({ type: 'SET_SELECTED_MAP', payload: index });
  }

  const getMapHeight = () => {
    if ((isMobile || isiPhone) && !showSearchAddress) {
      return 'calc(100dvh - 72px)';
    } else {
      return '100%';
    }
  };

  const isPropertyEmpty = () => {
    return showCompareLifeStyle
      ? isObjectEmpty(compareLifeStyleAddress?.[selectedMap] ?? {})
      : isObjectEmpty(currentAddress);
  };

  const getAddressButtonsLength = () => {
    return compareLifeStyleAddress.filter(address => !isObjectEmpty(address))?.length;
  };

  const handleComparePopup = () => {
    if (showFilter) {
      dispatch({ type: 'SET_SHOW_FILTERS', payload: false });
    }

    if (showMapQS) {
      dispatch({ type: 'SET_SHOW_MAP_QS', payload: false });
    }

    if (currentMarker[selectedMap]) {
      dispatch({
        type: 'SET_CURRENT_MARKER',
        payload: { markerIndex: 0, markerData: null },
      });
    }

    if (isMobile) {
      dispatch({
        type: 'SET_SHOW_COMPARE_POPUP',
        payload: true
      });
    } else {
      dispatch({
        type: 'SET_COMPARE_LIFESTYLE',
        payload: true,
      });
    }
    dispatch({ type: 'SET_PREVIOUS_PAGE', payload: 2 });
  };

  const handleMobileSidebar = () => {
    dispatch({ type: 'SET_MOBILE_SIDEBAR', payload: !isMobileSidebarOpen });

    if (showFilter) {
      dispatch({ type: 'SET_SHOW_FILTERS', payload: false });
    }

    if (currentMarker[selectedMap]) {
      dispatch({
        type: 'SET_CURRENT_MARKER',
        payload: { markerIndex: 0, markerData: null },
      });
    }
  };

  const closeMarkerList = () => {
    if (showMarkerList) {
      dispatch({ type: 'SET_SHOW_MARKER_LIST', payload: false });
    }
  };

  const isAddressSaved = () => {
    return savedAddresses?.some(
      (address) => address?.property === currentAddress?.property
    );
  };

  const handleSaveAddressToFavorites = () => {
    saveAddressToFavorites(
      isAddressSaved() ? 'remove' : 'save',
      currentAddress,
      savedAddresses,
      dispatch,
      enqueueSnackbar,
    );
  };

  const markerListIconStyle = {
    '---marker-list-icon-color': findColor(currentTab, mapTab),
  };

  return (
    <div className={`maps-container ${isEmbedMode || clientName ? 'embed-mode' : ''}`} style={{ height: getMapHeight(), width: '100%' }}>
      <Show.When isTrue={showFeedbackForm && isMobile}>
        <FeedbackForm
          state={state}
          dispatch={dispatch}
          actions={actions}
          currentView={currentView}
        />
      </Show.When>
      <Show.When
        isTrue={
          !showWalkIndexSummary && !showDashboard && !showWalkIndexCompare
        }
      >
        <Show.When isTrue={!showSearchAddress && !showMapStreetView[selectedMap]}>
          <div className={`map-controls ${
              showCompareLifeStyle ? 'compare-map-controls' : ''
            }`}
          >
            <Show.When isTrue={isMobile}>
              <Show.When isTrue={!isObjectEmpty(compareLifeStyleAddress[selectedMap])}>
                <div className='neighborhood-switch'>
                  <div
                    className={`${!isWalkIndex ? 'active' : ''}`}
                    onClick={() => handleNeighborhoodTab('lifestyle')}
                  >
                    Neighborhood Gems
                  </div>
                  <div
                    className={`${isWalkIndex ? 'active' : ''}`}
                    onClick={() => handleNeighborhoodTab('walkindex')}
                  >
                    Neighborhood Feel
                  </div>
                </div>
              </Show.When>
            </Show.When>
            <div
              className={`map-top-controls ${
                showCompareLifeStyle ? 'compare-map-top-controls' : ''
              }`}
            >
              <Show.When isTrue={!isMobile}>
                <div>
                  <Burger onClick={() => handleMobileSidebar()}/>
                  <Show.When isTrue={showCompareLifeStyle}>
                    <div className='compare-address-title-container'>
                      <div className='compare-address-title'>
                        COMPARE ADDRESS
                      </div>
                      <RemoveIcon className='remove-icon' data-tooltip-id="back-tooltip" onClick={openCompareExit}/>
                    </div>
                  </Show.When>
                  <Show.When isTrue={!showCompareLifeStyle}>
                    <Autocomplete
                      className='map-autocomplete'
                      placeholder='Type an address here'
                      value={currentAddress?.property}
                      // index={index}
                      // onClick={handleClick}
                      // onClear={handleClear}
                    />
                    <div className="map-top-icons">
                      {/* <RemoveIcon data-tooltip-id="back-tooltip" onClick={handleBack}/> */}
                      <Back className="map-top-back" data-tooltip-id="back-tooltip" onClick={handleBack}/>
                      <Favorite
                        className={`add-to-favorites-icon ${
                          isAddressSaved() ? 'added-to-favorites' : ''
                        }`}
                        data-tooltip-id="favorites-tooltip"
                        onClick={() => handleSaveAddressToFavorites()}
                      />
                      <Show.When isTrue={!isEstablishmentSearchOpen}>
                        <Action className='show-compare-icon' data-tooltip-id="compare-tooltip" onClick={() => handleComparePopup()} />
                      </Show.When> 
                    </div>
                   
                  </Show.When>
                </div>
                <Tooltip id='back-tooltip' className='custom-btn-popup' place='top'>Go Back</Tooltip>
                <Tooltip id='favorites-tooltip' className='custom-btn-popup' place='top'>Add to Favorites</Tooltip>
                <Tooltip id='compare-tooltip' className='custom-btn-popup' place='top'>Compare Addresses</Tooltip>
              </Show.When>
              <Show.When isTrue={!isEstablishmentSearchOpen || isMobile}>
                <Show.When isTrue={!isPropertyEmpty() && !actions.areMapsLoading()}>
                  <div ref={featuresContainerRef} className='feature-buttons'>
                    {renderCustomButtons()}
                  </div>
                </Show.When>
                {/* <Show.When isTrue={!isMobile}>
                  <div
                    data-tooltip-id={`toggle-desktop-sidebar`}
                    className={`desktop-collapse-btn ${
                      !isDesktopSidebarOpen && 'open-desktop-sidebar'
                    } ${isModern && 'modern-desktop-collapse-btn'}
                    `}
                    style={actions.getBrandingColor()}
                    onClick={toggleDesktopSidebar}
                  >
                    <ModernCollapse />
                  </div>
                </Show.When> */}
              </Show.When>

              {/* <Show.When isTrue={!isMobile && (isEstablishmentSearchOpen || !isModern)}>
                <div
                  className={`map-back-btn ${
                    isEstablishmentSearchOpen && 'establishment-back-btn'
                  }`}
                  onClick={handleBack}
                >
                  <Back />
                </div>
              </Show.When> */}
              <Tooltip
                className='custom-btn-popup'
                id={`toggle-desktop-sidebar`}
                place='right'
              >
                {isDesktopSidebarOpen ? 'Hide' : 'Show'} Sidebar
              </Tooltip>
              <Show.When isTrue={!isMobile}>
                <div className='gradient-bg'></div>
              </Show.When>
            </div>
            {/* quick search and walking distance */}
            <Show.When isTrue={!isPropertyEmpty()}>
              <div className='filter-wd'>
                <Show.When isTrue={!isWalkIndex && isMobile}>
                  <div className='filter-btns'>
                    <Show.When isTrue={!showCompareLifeStyle}>
                      <div
                        className={`quick-search-btn ${
                          isEstablishmentSearchOpen ? 'open' : ''
                        } ${
                          isLoading ? 'btn-loading' : ''
                        }`}
                        onClick={() => openQuickSearch()}
                      >
                        <QuickSearch />
                      </div>
                    </Show.When>
                    <Show.When isTrue={mapTab !== 'Favorites' && isMobile}>
                      <div
                        className={`filter-btn ${
                          showFilter ? 'open' : ''
                        } ${
                          isLoading ? 'btn-loading' : ''
                        }`}
                        onClick={() => openFilter()}
                      >
                        <Filter />
                      </div>
                    </Show.When>
                  </div>
                </Show.When>
                <Show.When isTrue={!isMobile}>
                  <div className='map-popups-wrapper'>
                    <Show>
                      <Show.When isTrue={showCompareLifeStyle}>
                        <CompareMaps state={state} dispatch={dispatch} actions={actions}  navigate={navigate} />
                      </Show.When>
                      <Show.Else>
                        <Show.When
                          isTrue={
                            currentAddress &&
                            !showMapQS &&
                            !showMarkerList &&
                            !showFavoriteList &&
                            !showRecentHistoy &&
                            !isMobile
                          }
                        >
                          <NeighborhoodTabPopup />
                        </Show.When>
                        <Show.When
                          isTrue={
                            currentAddress &&
                            !showMapQS &&
                            !showMarkerList &&
                            !showFavoriteList &&
                            !showRecentHistoy &&
                            !isMobile && showLegend
                          }
                        >
                          <NeighborhoodTabLegendPopup />
                        </Show.When>

                        <Show.When isTrue={showMapQS && !showMarkerList && !isMobile}>
                          <MapQuickSearch />
                        </Show.When>
                        <Show.When isTrue={showFilter && !showMarkerList && !isMobile && !isWalkIndex}>
                          <div className='map-filter'>
                            <FilterContainer />
                          </div>
                        </Show.When>
                        <Show.When isTrue={showMarkerList && !isMobile}>
                          <div className='map-marker-list' style={markerListIconStyle}>
                            <div className='map-marker-list-header'>
                              {getFeatureIcon(false, mapTab)}
                              <div>All {listOfMarkers[0]?.length} list</div>
                              <Close className='close-icon' onClick={() => closeMarkerList()}/>
                            </div>
                            <MarkerList markers={listOfMarkers[0]} />
                          </div>
                        </Show.When>
                        <Show.When isTrue={showFavoriteList}>
                          <MyFavorites state={state} dispatch={dispatch} actions={actions} />
                        </Show.When>
                        <Show.When isTrue={showRecentHistoy}>
                          <RecentHistory state={state} dispatch={dispatch} actions={actions} />
                        </Show.When>
                      </Show.Else>
                    </Show>
                  </div>
                </Show.When>
                <div className='mobile-walking-distance-wrapper'>
                  {distanceData.map((distance, index) => {
                    return (
                      <div>
                        <div
                          className={`distance-btn ${
                            (parseInt(currentDistance) === index + 1) ? 'active' : ''
                          } ${
                            isLoading ? 'btn-loading' : ''
                          }`}
                          onClick={isLoading ? null : () => handleWalkingDistance(index+1)}
                        >
                          {distance}
                          <MileIcon />
                        </div>
                        <Show.When isTrue={!isMobile}>
                          <div className='apx-time'>≈ {timeData[index]}</div>
                        </Show.When>
                      </div>
                    )
                  })}
                </div>
                <Show.When isTrue={showCompareLifeStyle}>
                  <MapControls />
                </Show.When> 
              </div>
            </Show.When>
          </div>
        </Show.When>
        <Show.When isTrue={!showSearchAddress}>
          <Show>
            <Show.When isTrue={showCompareLifeStyle}>
              <CompareMapsMobile state={state} dispatch={dispatch} properties={filteredProperties()} />
            </Show.When>

            <Show.Else>
              <MapSingle
                property={currentAddress?.property}
                longitude={currentAddress?.longitude}
                latitude={currentAddress?.latitude}
                listOfMarkers={listOfMarkers || []}
                properties={properties}
                state={state}
                dispatch={dispatch}
                actions={actions}
                navigate={navigate}
                mapHeight={'100%'}
              />
            </Show.Else>
          </Show>
        </Show.When>

        {/* <Show.When isTrue={isMobile}>
          <div className='mobile-map-header'>
            <Show.When isTrue={isEstablishmentSearchOpen}>
              {showQuickSearchResult()}
            </Show.When>
          </div>
        </Show.When> */}
      </Show.When>
      
      <Show.When isTrue={isEstablishmentSearchOpen && isMobile}>
        <EstablishmentSearch
          state={state}
          dispatch={dispatch}
          actions={actions}
          allMarkers={allMarkers}
          isMobile={isMobile}
          currentView={currentView}
          showDashboard={showDashboard}
        />
      </Show.When>

      <Show.When isTrue={showFavoriteList && isMobile}>
        <MyFavorites state={state} dispatch={dispatch} actions={actions} />
      </Show.When>

      <Show.When isTrue={showRecentHistoy && isMobile}>
        <RecentHistory state={state} dispatch={dispatch} actions={actions} />
      </Show.When>

      <Show.When isTrue={showDashboard && !showSearchAddress}>
        <Show>
          <Show.When isTrue={isMobile && isModern}>
            <MobileDashboard />
          </Show.When>

          <Show.Else>
            {/* <WalkChart /> */}
            <DashboardSimplified />
          </Show.Else>
        </Show>
      </Show.When>

      <Show.When isTrue={showSearchAddress}>
        <AddressSearch
          dispatch={dispatch}
          actions={actions}
          state={state}
          openTutorial={openTutorial}
        />
      </Show.When>
      {/* <Show.When isTrue={isMobile && !showSearchAddress && isModern}>
         <MapMobileFooter />
      </Show.When> */}
      <Show.When isTrue={showCompareLifeStyle && !currentMarker[selectedMap] && isMobile}>
        <div className='address-btns'>
          <div>
            {[...Array(getAddressButtonsLength())].map((_, index) => (
              <div
              className={`${selectedMap === index ? 'current-map' : ''}`}
                onClick={() => handleCompareAddress(index)}
              >
                Address {index + 1}
              </div>
            ))}
          </div>
          <Show>
            <Show.When isTrue={getAddressButtonsLength() === 4}>
              <Minus onClick={() => handleComparePopup()} />
            </Show.When>
            <Show.Else>
            <Action className='add' onClick={() => handleComparePopup()} />
            </Show.Else>
          </Show>
        </div>
      </Show.When>
      <Show.When
        isTrue={
          !isEstablishmentSearchOpen &&
          !showDashboard &&
          !showSearchAddress &&
          isMobile &&
          !isObjectEmpty(compareLifeStyleAddress[selectedMap]) &&
          !currentMarker[selectedMap] &&
          !actions.areMapsLoading()
        }
      >
        <MobileScoreContainer />
      </Show.When>
    </div>
  );
};

export default MapContainer;
