import React, { useEffect, useState } from 'react';
import { GoogleMap, StreetViewPanorama } from '@react-google-maps/api';

const StreetViewMap = ({ coordinates, height="180px" }) => {
  const [streetViewPanoramaOptions, setStreetViewPanoramaOptions] = useState({
    disableDefaultUI: true,
    fullscreenControl: true,
    enableCloseButton: false,
  });

  useEffect(() => {
    const streetViewService = new window.google.maps.StreetViewService();

    streetViewService.getPanorama(
      { location: { lat: coordinates[0], lng: coordinates[1] } },
      (data, status) => {
        if (status === window.google.maps.StreetViewStatus.OK) {
          setStreetViewPanoramaOptions({
            position: data.location.latLng,
            pov: { heading: 0, pitch: 0 },
            visible: true,
          });
        }
      }
    );
  }, [coordinates]);

  return (
    <div style={{ height:height, width: '100%' }}>
      <GoogleMap
        mapContainerStyle={{ height: '100%', width: '100%' }}
        zoom={14}
      >
        {streetViewPanoramaOptions && (
          <StreetViewPanorama options={streetViewPanoramaOptions} />
        )}
      </GoogleMap>
    </div>
  );
};

export default StreetViewMap;
