import React, { useContext, useState, useEffect } from 'react';
import './Modal.css';
import MapContainer from '../Map';
import WalkIndexCompare from '../WalkIndexCompare';
import { StoreContext } from '../../store';
import Sidebar from '../Sidebar/';
import Show from '../../utility/Show';
import ReachedLimit from '../ReachedLimit';
import MapMobileHeader from '../Map/map-mobile-header';
import FilterContainer from '../Filter';
import { Autocomplete } from '../../utility/Autocomplete';
import { GetStarted } from '../GetStarted';
import Establishments from '../Establishments';
import PrivacyPolicy from '../PrivacyPolicy';
import ServerError from '../../utility/ServerError';
import MobileSidebar from '../Sidebar/mobile-sidebar';
import MobileMarkersFilter from './mobile-markers-filters';
import ConfirmExit from '../../utility/ConfirmExit';
import APIKeyScreen from '../APIKeyScreen';

import { ReactComponent as Star } from '../../assets/star.svg';
import { ReactComponent as CloseSqaure } from '../../assets/close-square.svg';
import PlaceCard from '../Map/place-card';

import { findColor, setLSData } from '../../services';

export const Modal = () => {
  const { state, dispatch, actions } = useContext(StoreContext);

  // Access the state variables
  const {
    showWalkIndexCompare,
    showModal,
    urlParams,
    showDashboard,
    isiPhone,
    isStandalone,
    isDesktopSidebarOpen,
    isPlugin,
    isFullScreenMap,
    showCompareLifeStyle,
    isEstablishmentSearchOpen,
    showSearchAddress,
    currentView,
    reachedMaxLimit,
    isMobile,
    showFilter,
    currentRates,
    currentTab,
    currentLifestyleType,
    mapTab,
    showRatingFilter,
    showCombineSearch,
    compareLifeStyleAddress,
    showComparePopup,
    showTutorial,
    showScoreGuide,
    isDisclaimerOpen,
    showEstablishments,
    selectedMap,
    showPrivacyPolicy,
    currentMarker,
    placeCardDetails,
    showCompareConfirmExit,
    showAPIKeyScreen,
  } = state;

  const isWalkIndex = currentTab === 'walkindex';
  const isModern = currentView === 'Modern';

  const toggleModal = () => {
    dispatch({ type: 'SET_SHOW_MODAL', payload: !showModal });
  };

  // if (showModal) {
  //   document.body.classList.add('active-modal');
  // } else {
  //   document.body.classList.remove('active-modal');
  // }

  const isFullScreen = urlParams.isFullScreen;

  useEffect(() => {
    if (isStandalone) {
      dispatch({ type: 'SET_CURRENT_TAB', payload: 'lifestyle' });
    }
  }, [isStandalone]);

  const getStreetViewBackBtnMarginTop = () => {
    const style = {
      '--svmt': showCompareLifeStyle ? '13em' : '1.5em',
    };
    return style;
  };

  const hasRate = (index) => {
    return index + 1 <= Math.max(...currentRates);
  };

  const getStarColor = (index) => {
    const feature = isWalkIndex ? currentLifestyleType : mapTab;
    const starStyle = {
      '--star-color':
        hasRate(index) && feature && feature !== 'All'
          ? findColor('lifestyle', feature)
          : '#fff',
    };
    return starStyle;
  };

  const handleRatingFilter = () => {
    dispatch({
      type: 'SET_SHOW_RATING_FILTER',
      payload: !showRatingFilter
    });
  };

  const ratingFilterPopup = () => {
    return (
      <div className='rating-filter-container'>
        <div className='rating-filter-header'>
        <div onClick={() => actions.setRatings('')}>clear all</div>
        <div className='title'>Rating</div>
        <CloseSqaure onClick={() => handleRatingFilter()}/>
        </div>
        <div className='rating-buttons-container'>
          {[...Array(5)].map((_, index) => (
            <Star
              key={`rating-star-${index+1}`}
              className={`${hasRate(index) && 'active-star'}`}
              onClick={() => actions.setRatings(index + 1)}
              style={getStarColor(index)}
            />
          ))}
          <div>
            {currentRates.length ? Math.max(...currentRates) : 0}/5
          </div>
        </div>
      </div>
    )
  };

  const modernMobileMapFilter = () => {
    return (
      <div className='modern-mobile-map-filter'>
        <Show.When isTrue={showFilter}>
          <FilterContainer />
        </Show.When>
        <Show.When isTrue={showRatingFilter}>
          {ratingFilterPopup()}
        </Show.When>
      </div>
    )
  };

  const closeComparePopup = () => {
    dispatch({
      type: 'SET_SHOW_COMPARE_POPUP',
      payload: false
    });
  };

  const handleClick = async (payload, index) => {
    dispatch({
      type: 'UPDATE_LIFESTYLE_COMPARE_ADDRESS',
      payload: { item: index, address: payload },
    });
    await actions.fetchLifeStyleScores(payload, index);
    await actions.fetchWalkIndexRatings(payload, index);
  };

  const gotoCompareAddress = () => {
    dispatch({ type: 'RESET_CATEGORY', payload: [''] });
    dispatch({ type: 'SET_IS_ESTABLISHMENT_SEARCH_OPEN', payload: false });
    dispatch({
      type: 'SET_COMPARE_LIFESTYLE',
      payload: true,
    });
    dispatch({ type: 'SET_SELECTED_MAP', payload: 0 });
    closeComparePopup();
  };

  const handleClear = (index) => {
    dispatch({
      type: 'UPDATE_LIFESTYLE_COMPARE_ADDRESS',
      payload: { item: index, address: {} },
    });
    dispatch({
      type: 'SET_COMPARE_MAPS',
      payload: { index, object: {} }
    });
    if (index !== 0) {
      dispatch({
        type: 'UPDATE_COMPARE',
        payload: { number: index, length: 0 },
      });
      dispatch({
        type: 'SET_WALKINDEX_SCORES',
        payload: {
          walkindxScoreData: {},
          walkindxScoreIndex: index,
        }
      });
    }
    dispatch({
      type: 'SET_COMPARE_MAPS_SLIDER_INDEX',
      payload: 0
    });
    dispatch({ type: 'SET_SELECTED_MAP', payload: 0 });
  };

  const renderComparePopup = () => {
    return (
      <div className='compare-address-popup'>
        <div className='property-locations-container'>
          <p>Enter address to compare</p>
          <CloseSqaure className='close-icon' onClick={() => closeComparePopup()}/>
          <hr />
          {compareLifeStyleAddress.map((item, index) => {
            return (
              <div
                key={`comapre-address-${index+1}`}
                className={`property-location-item ${
                  isModern && 'modern-property-location-item'
                }`}
              >
                <div>A{index + 1}</div>
                <Autocomplete
                  className='lifestyle-search-address-input'
                  placeholder={`Enter Address ${index + 1}`}
                  value={item?.property || ''}
                  index={index}
                  onClick={handleClick}
                  onClear={handleClear}
                  isClearable={true}
                />
              </div>
            );
          })}
          <button onClick={() => gotoCompareAddress()}>COMPARE ADDRESS</button>
        </div>
      </div>
    )
  };

  const handleCompareComfirnExit = (value) => {
    dispatch({ type: 'SET_SHOW_COMPARE_CONFIRM_EXIT', payload: false });
    if (value) {
      dispatch({ type: 'SET_COMPARE_LIFESTYLE', payload: false  });
      dispatch({ type: 'SET_SELECTED_MAP', payload: 0  });
    }
  };

  const handleGetStarted = (value) => {
    if (isDisclaimerOpen) {
      dispatch({ type: 'SET_IS_DISCLAIMER_OPEN', payload: false });
    }

    if (showScoreGuide) {
      dispatch({ type: 'SET_SHOW_SCORE_GUIDE', payload: false });
    }
    dispatch({ type: 'SET_SHOW_TUTORIAL', payload: value });
    setLSData('isGetStarted', 0);
  };

  return (
    <>
      <div
        className={`modal ${isiPhone ? 'iphone-content' : ''} ${isModern &&
          isiPhone &&
          'modern-modal-content'
        }`}
        style={getStreetViewBackBtnMarginTop()}
      >
        <div
          onClick={toggleModal}
          className={`overlay ${isPlugin && 'no-background'}`}
        ></div>
        <div
          className={`modal-content
            ${isFullScreen ? 'modal-fullscreen' : ''}
          `}
        >
          <Show.When isTrue={placeCardDetails[selectedMap] !== null}>
            <PlaceCard
              placeCardDetails={placeCardDetails[selectedMap]}
              currentMarker={currentMarker}
              actions={actions}
              dispatch={dispatch}
              index={selectedMap}
            />
          </Show.When>
          <Show.When isTrue={showCompareConfirmExit}>
            <ConfirmExit component={'Compare Addresses'} handleExit={handleCompareComfirnExit} />
          </Show.When>
          <Show.When isTrue={false}>
            <ServerError />
          </Show.When>
          <Show.When isTrue={reachedMaxLimit}>
            <ReachedLimit />
          </Show.When>
          <Show.When isTrue={showAPIKeyScreen}>
            <APIKeyScreen state={state} actions={actions} />
          </Show.When>
          <Show.When isTrue={showPrivacyPolicy}>
            <PrivacyPolicy />
          </Show.When>
          <Show.When isTrue={showEstablishments}>
            <Establishments index={selectedMap} />
          </Show.When>
          <MobileSidebar state={state} dispatch={dispatch} actions={actions} />
          {showWalkIndexCompare && <WalkIndexCompare />}

          {/* <Show.When isTrue={!showDashboard && !isEstablishmentSearchOpen}>
            <Sidebar />
          </Show.When> */}

          <div
            className={`modal-map
              ${
                isFullScreen &&
                isDesktopSidebarOpen &&
                !isEstablishmentSearchOpen &&
                !showDashboard &&
                'modal-with-sidebar'
              }
              ${
                (!isDesktopSidebarOpen ||
                  isEstablishmentSearchOpen ||
                  showSearchAddress) &&
                'modal-content-fs'
              }
              ${isiPhone && 'iphone-modal-map'}
              ${
                isModern &&
                showCompareLifeStyle &&
                'modern-compare-modal'
              }
              ${
                isModern &&
                isiPhone &&
                showCompareLifeStyle &&
                'modern-iphone-compare-modal'
              }
              ${isPlugin && 'no-background'}
              ${showSearchAddress && 'show-address-container'}
              ${
                isMobile &&
                isModern &&
                (showCombineSearch ||
                showCompareLifeStyle) &&
                'modern-mobile-modal'
              }
            `}
          >
            <Show.When isTrue={isModern && isMobile && !showSearchAddress}>
              <MapMobileHeader />
            </Show.When>
            <MapContainer />
            <Show.When isTrue={isMobile && !showDashboard && !showSearchAddress}>
              <MobileMarkersFilter state={state} dispatch={dispatch} actions={actions} />
            </Show.When>
            <Show.When isTrue={showComparePopup}>
              {renderComparePopup()}
            </Show.When>
            <Show.When isTrue={showTutorial}>
              <GetStarted setShowTutorial={handleGetStarted} version={isDisclaimerOpen ? 1 : 2} />
            </Show.When>
          </div>
        </div>
      </div>
    </>
  );
};
