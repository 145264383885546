export const formatGemNumber = (number) => {
    if (!number) {
        return 0;
    }

    if (number >= 1000) {
        return `${(number / 1000).toFixed(1).replace(/\.0$/, '')}k`;
    }
    return number;
}

export const normalizeAddress = (address) => {
    if (!address) return "";
  
    return address
      .toLowerCase()
      .replace(/\bst\b/g, "street")
      .replace(/\bave\b/g, "avenue")
      .replace(/\brd\b/g, "road")
      .replace(/\bdr\b/g, "drive")
      .replace(/\bblvd\b/g, "boulevard")
      .split(",")[0]  // Take only the main part before the first comma
      .trim();
  };