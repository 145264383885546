import { ReactComponent as Logo } from '../assets/walkspan-logo-2.svg';

const GatewayTimeout = () => {

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className='server-error-container'>
      <Logo />
      <h1>Oops!</h1>
      <div>Something went wrong. Please try again later.</div>
      <button onClick={() => handleRefresh()}>Refresh</button>
    </div>
  )
};

export default GatewayTimeout;