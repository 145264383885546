import { useState, useContext, useRef } from "react";
import { StoreContext } from "../../store";
import './index.css';

import { isValidEmail, setLSData } from "../../services";

import Show from "../../utility/Show";
import FormError from "../../utility/FormError";
import { CircularLoader } from "../Loaders";

import { ReactComponent as WalkspanLogo } from '../../assets/walkspan-logo-2.svg';

const ForgotPassword = () => {
  const { state, dispatch, actions, navigate } = useContext(StoreContext);
  const {
    isLoading,
    errorMessage,
    FPSubmitted,
    confirmFPSubmitted,
    forgotPasswordEmail,
  } = state;

  const [showConfirmFP, setConfirmFP] = useState(false);
  const formRef = useRef();

  const gotoLogin = () => {
    actions.clearErrorMessage();

    if (FPSubmitted) {
      dispatch({
        type: 'SET_FORGOT_PASSWORD_SUBMITTED',
        payload: false,
      });
    }

    if (confirmFPSubmitted) {
      dispatch({
        type: 'SET_CONFIRM_FORGOT_PASSWORD_SUBMITTED',
        payload: false,
      });
    }

    if (showConfirmFP) {
      setConfirmFP(false);
    }

    navigate('/login');
  };

  const renderForm = () => {
    return (
      <Show>
        <Show.When isTrue={FPSubmitted}>
          <div>
            A confirmation link has been sent to your email address. 
            Please check your inbox to verify your account.
          </div>
          <div className="no-code-msg">
            Didn't receive a code? <u onClick={() => resendCode()}>Resend It</u>
          </div>
        </Show.When>
        <Show.Else>
          <div className="input-container">
            <label className="input-label" for="email">Email</label>
            <input
              type="text"
              className="input-field"
              id="email"
              name="email"
              placeholder="Type your email"
            />
          </div>
        </Show.Else>
      </Show>
    )
  };

  const forgotPasswordSubmit = () => {
    const email = formRef.current[0].value;

    if (email.trim() === '' || !isValidEmail(email)) {
      dispatch({
        type: 'SET_ERROR_MESSAGE',
        payload: 'Make sure a valid, existing user email is provided'
      });
      return;
    }

    const payload = { email: email }
    actions.handleForgotPassword(payload);
    dispatch({ type: 'SET_FORGOT_PASSWORD_EMAIL', payload: email });
    setLSData('fp-email', email);
  };

  const resendCode = () => {
    actions.handleForgotPassword({ email: forgotPasswordEmail });
  };

  const getButtonFunction = () => {
    actions.clearErrorMessage();
    if (FPSubmitted) {
      navigate('/reset-password/allow');
    } else {
      forgotPasswordSubmit();
    }
  };

  const renderTitle = () => {
    return (
      <Show>
        <Show.When isTrue={FPSubmitted}>
          <div className='title'>Email Sent Successfully!</div>
        </Show.When>
        <Show.Else>
          <div className='title'>Forgot your password?</div>
        </Show.Else>
      </Show>
    )
  };

  const getButtonText = () => {
    if (!FPSubmitted || showConfirmFP) {
      return 'Submit';
    } else {
      return 'Reset Password';
    }
  }

  return (
    <div className="login-container">
      <div className='login-overlay'></div>
      <div className='login-card'>
        <WalkspanLogo className='walkspan-logo'/>
        {renderTitle()}
        <form ref={formRef}>
          {renderForm()}
          <Show.When isTrue={errorMessage}>
            <FormError error={errorMessage} />
          </Show.When>
        </form>
        <Show.When isTrue={!FPSubmitted}>
          <div className='login-buttons'>
            <Show.When isTrue={!confirmFPSubmitted}>
              <button
                className='submit-button'
                onClick={() => getButtonFunction()}
              >
                <Show>
                  <Show.When isTrue={isLoading}>
                    <CircularLoader size={'10px'} />
                  </Show.When>
                  <Show.Else>
                    {getButtonText()}
                  </Show.Else>
                </Show>
              </button>
            </Show.When>
            <Show.When
              isTrue={
                (!FPSubmitted && !showConfirmFP) || confirmFPSubmitted
              }
            >
              <div
                className='back-to-login'
                onClick={() => gotoLogin()}
              >
                Back to Login
              </div>
            </Show.When>
          </div>
        </Show.When>
      </div>
    </div>
  )
};

export default ForgotPassword;