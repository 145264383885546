import { useContext, useRef } from "react";
import { useParams } from 'react-router-dom';
import { StoreContext } from "../../store";
import './index.css';

import { setLSData, isValidPassword } from "../../services";

import Show from "../../utility/Show";
import VerificationCodeInput from "../../utility/VerificationCodeInput";
import FormError from "../../utility/FormError";
import { CircularLoader } from "../Loaders";

import { ReactComponent as WalkspanLogo } from '../../assets/walkspan-logo-2.svg';

const ResetPassword = () => {
  const { resetPassCode } = useParams();
  const { state, dispatch, actions, navigate } = useContext(StoreContext);
  const {
    isLoading,
    errorMessage,
    confirmFPSubmitted,
    forgotPasswordEmail,
  } = state;
  const formRef = useRef();

  const codeUsed = errorMessage === 'Invalid reset link.';

  const renderTitle = () => {
    return (
      <Show>
        <Show.When isTrue={confirmFPSubmitted}>
          <div className='title'>Your password has been reset</div>
        </Show.When>
        <Show.Else>
          <div className='title'>Reset your password</div>
        </Show.Else>
      </Show>
    )
  };

  const renderForm = () => {
    return (
      <Show>
        <Show.When isTrue={confirmFPSubmitted}>
          <div>
            Your password has been successfully updated. You can now log in using your new password.
          </div>
        </Show.When>
        <Show.Else>
          <div className="input-container">
            <label className="input-label" for="password">Password</label>
            <input
              type="password"
              className="input-field"
              id="password"
              name="password"
              placeholder="Type your password"
            />
          </div>
          <div className="input-container">
            <label className="input-label" for="password">Confirm Password</label>
            <input
              type="password"
              className="input-field"
              id="confirm-password"
              name="confirm-password"
              placeholder="Re-type the same password"
            />
          </div>
        </Show.Else>
      </Show>
    )
  };

  const confirmFPSubmit = () => {
    const password = formRef?.current[0]?.value;
    const confirmPassword = formRef.current[1]?.value;

    if (!password || !confirmPassword) {
      dispatch({
        type: 'SET_ERROR_MESSAGE',
        payload: 'Please enter both password and confirm password.'
      });
      return;
    }

    if (password !== confirmPassword) {
      dispatch({
        type: 'SET_ERROR_MESSAGE',
        payload: 'Passwords do not match.'
      });
      return;
    }

    const passwordErrors = isValidPassword(password);
    if (passwordErrors.length !== 0) {
      dispatch({
        type: 'SET_ERROR_MESSAGE',
        payload: passwordErrors.join('\n').toString(),
      });
      return;
    }

    const payload = {
      code: resetPassCode,
      email: forgotPasswordEmail,
      new_password: password,
      confirm_password: confirmPassword,
    }
    actions.handleConfirmForgotPassword(payload);

    dispatch({ type: 'SET_FORGOT_PASSWORD_EMAIL', payload: null });
    setLSData('fp-email', null);
  }

  const getButtonFunction = () => {
    actions.clearErrorMessage();
    if (codeUsed) {
      navigate('/forgot-password');
    } else {
      confirmFPSubmit();
    }
  };

  const gotoLogin = () => {
    actions.clearErrorMessage();

    if (confirmFPSubmitted) {
      dispatch({
        type: 'SET_CONFIRM_FORGOT_PASSWORD_SUBMITTED',
        payload: false,
      });
    }

    navigate('/login');
  };

  return (
    <div className="fp-container">
      <div className='fp-overlay'></div>
      <div className='fp-card'>
        <WalkspanLogo className='walkspan-logo'/>
        {renderTitle()}
        <Show>
          <Show.When isTrue={codeUsed}>
            <div>
              Oops! It looks like this code has already been used. Please try submitting your email again.
            </div>
          </Show.When>
          <Show.Else>
            <form ref={formRef}>
              {renderForm()}
              <Show.When isTrue={errorMessage}>
                <FormError error={errorMessage} />
              </Show.When>
            </form>
          </Show.Else>
        </Show>
        <div className='fp-buttons'>
          <Show.When isTrue={!confirmFPSubmitted}>
            <button className='submit-button' onClick={() => getButtonFunction()}>
              <Show>
                <Show.When isTrue={isLoading}>
                  <CircularLoader size={'10px'} />
                </Show.When>
                <Show.When isTrue={codeUsed}>
                  Forgot Password
                </Show.When>
                <Show.Else>
                  Reset Password
                </Show.Else>
              </Show>
            </button>
          </Show.When>
          <Show.When isTrue={confirmFPSubmitted}>
            <div className='back-to-login' onClick={() => gotoLogin()}>
              Back to Login
            </div>
          </Show.When>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword;