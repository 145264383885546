import React, { useContext, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { StoreContext } from '../../store';
import './ToggleSwitch.css';
import Show from '../../utility/Show';

export const ToggleSwitch = ({ fullWidth = true }) => {
  const { state, dispatch, actions } = useContext(StoreContext);
  const {
    currentTab,
    isMobileSidebarOpen,
    isMobile,
    showDashboard,
    currentView,
    showCompareLifeStyle,
    isAddressInNYC,
    currentLifestyleType,
    currentWalkindxType,
  } = state;

  const isModern = currentView === 'Modern';

  const setCurrentTab = (tab) => {
    dispatch({ type: 'SET_CURRENT_TAB', payload: tab });
    const distance = isAddressInNYC ? '1' : '3';
    dispatch({ type: 'SET_CURRENT_DISTANCE', payload: distance });

    // if (showCompareLifeStyle) {
    //   dispatch({
    //     type: 'SET_MAP_TAB',
    //     payload: tab === 'walkindex' ? 'Nature' : 'Food',
    //   });
    // }
  };

  const handleSwitchTab = (tab) => {
    actions.sendDataToGoogleAnalytics('switch_btn_clicks', {
      switch_btn_name: tab,
    });

    if (currentLifestyleType.length > 0) {
      dispatch({ type: 'RESET_CURRENT_LIFESTYLE_TYPE' });
    }

    if (tab === 'walkindex') {
      if (!isModern) {
        dispatch({
          type: 'SET_COMPARE_LIFESTYLE',
          payload: false,
        });
      } else {
      }
    } else {
      dispatch({
        type: 'RESET_RATE',
        payload: [],
      });

      if (showCompareLifeStyle) {
        dispatch({
          type: 'SET_SHOW_COMBINE_SEARCH',
          payload: true,
        });
      }
    }

    if (isModern) {
      // dispatch({ type: 'SET_CURRENT_WALKINDX_TYPE', payload: null });
      dispatch({ type: 'RESET_CURRENT_LIFESTYLE_TYPE' });
    } else {
      dispatch({ type: 'SET_CURRENT_WALKINDX_TYPE', payload: null });
    }

    if (!showDashboard) {
      actions.setMapCenter();
    }

    setCurrentTab(tab);
    dispatch({
      type: 'SET_SHOW_FILTERS',
      payload: false,
    });
    dispatch({
      type: 'SET_SHOW_ESTABLISHMENTS',
      payload: false,
    });

    if (isMobileSidebarOpen) {
      dispatch({ type: 'SET_MOBILE_SIDEBAR', payload: !isMobileSidebarOpen });
    }
  };

  return (
    <div
      className={`toggle-switch-container
          ${isMobile ? 'toggle-switch-container-mobile ' : ''}
          ${isModern && 'modern-toggle-switch-container'}`}
    >
      <button
        data-tooltip-id='lifestyle'
        onClick={() => handleSwitchTab('lifestyle')}
        className={`switch-btn
          ${currentTab === 'lifestyle' ? 'active-tab' : ''}
          ${!showDashboard ? 'smaller-font-size' : ''}
        `}
      >
        Neighborhood Gems
      </button>
      <button
        data-tooltip-id='walkindex'
        onClick={() => handleSwitchTab('walkindex')}
        className={`switch-btn
          ${currentTab === 'walkindex' ? 'active-tab' : ''}
          ${!showDashboard ? 'smaller-font-size' : ''}
        `}
      >
        Neighborhood Feel
      </button>
      <Show.When isTrue={!isMobile}>
        <Tooltip className='dashboard-popup' id='walkindex' place='bottom'>
          An indicator of the quality of the walk within the immediate
          surrounding of the property. It is a corridor assessment capped at a
          quarter-mile, half-mile and one-mile radii. The higher the score of
          the circle, the better the corridors for walking.
        </Tooltip>
        <Tooltip className='dashboard-popup' id='lifestyle' place='bottom'>
          Are elements in the neighborhood that enhance the quality of life
          such as: a nearby cafes, bakeries, restaurants, shops, urgent care
          centers, gyms, liquor stores, and groceries.
        </Tooltip>
      </Show.When>
    </div>
  );
};
