import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './index.css';

const SkeletonContainer = ({
  className,
  count,
  size,
  width = '100%',
  isCircle = false,
  baseColor = '#ebebeb',
  gap = '10px',
}) => {
  const length = Array.from({ length: count }, (_, index) => index);

  // Calculate grid template columns based on width
  const columnWidth = typeof width === 'string' && width.includes('%')
    ? `calc(${width} - ${gap})`
    : `${width}`;

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fit, minmax(${columnWidth}, 1fr))`, // Items adapt to the container size
    gap, // Space between items
  };

  return (
    <div className="skeleton-main-container" style={gridStyle}>
      {length.map((item, index) => (
        <Skeleton
          className={className}
          count={1}
          height={size}
          width="100%" // Make the Skeleton fill its parent grid item
          circle={isCircle}
          key={`skeleton-${index}`}
          baseColor={baseColor}
        />
      ))}
    </div>
  );
};

export default SkeletonContainer;
