import { useContext } from 'react';
import { StoreContext } from '../../store';
import { useSnackbar } from 'notistack';

import { ReactComponent as Location } from '../../assets/location-3.svg';
import { ReactComponent as Delete } from '../../assets/trash-filled.svg';

import Show from '../../utility/Show';

import { saveAddressToFavorites } from '../../services';

const AddressList = ({ addresses }) => {
  const { state, dispatch, actions } = useContext(StoreContext);
  const { savedAddresses, showFavoriteList } = state;
  const { enqueueSnackbar } = useSnackbar();

  const handleAddressClick = (address) => {
    actions.fetchAddressData(address);
    dispatch({ type: 'SET_SHOW_FAVORITE_LIST', payload: false });
  };

  const removeAddress = (e, address) => {
    e.stopPropagation();
    saveAddressToFavorites(
      'remove',
      address,
      savedAddresses,
      dispatch,
      enqueueSnackbar,
    );
  };

  return (
    <div className="address-list-wrapper">
      {addresses.map((item, index) => (
        <div className='address-item' onClick={() => handleAddressClick(item.property)}>
          <div className='address-details'>
            <div>
              <Location />
              {item.shortName}
            </div>
            <Show.When isTrue={showFavoriteList}>
              <Delete className='delete-icon' onClick={(e) => removeAddress(e, item)} />
            </Show.When>
          </div>
          <div className='other-details'>
            {item?.neighborhood}, {item?.city} {item?.postal}
          </div>
        </div>
      ))}
    </div>
  )
};

export default AddressList;