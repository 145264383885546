import { useState, useRef, useEffect, useCallback } from 'react';
import { ReactComponent as Search } from '../../assets/search.svg';
import { ReactComponent as Close } from '../../assets/cancel-white.svg';
import './index.css';

import { debounce } from '../../services';

import Show from '../../utility/Show';
import MarkerList from '../Map/marker-list';

export const EstablishmentSearch = ({
  state,
  dispatch,
  actions,
  setShowFilterSearch,
  allMarkers,
  isMobile,
  currentView,
  showDashboard,
}) => {
  const { listOfMarkers, currentMarker, showEstablishments } = state;
  const [searchText, setSearchText] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const inputRef = useRef(null);
  const cardRef = useRef(null);

  const isModern = currentView === 'Modern';

  const closeFilterSearch = () => {
    if (showDashboard && !isMobile) {
      setShowFilterSearch(false);
    }
    dispatch({ type: 'SET_IS_ESTABLISHMENT_SEARCH_OPEN', payload: false });
    dispatch({
      type: 'SET_LIST_OF_MARKERS',
      payload: { markersIndex: 0, markersList: [] },
    });

    if (isMobile) {
      dispatch({ type: 'RESET_CATEGORY', payload: [''] });
      dispatch({
        type: 'SET_ESTABLISHMENT_SEARCH_RESULT',
        payload: {},
      });
    } else {
      dispatch({ type: 'SET_CURRENT_DISTANCE', payload: '1' });
    }
  }

  useEffect(() => {
    inputRef.current.focus();
    if (!isMobile) {
      const handleOutsideClick = (event) => {
        if (cardRef.current && !cardRef.current.contains(event.target)) {
          closeFilterSearch();
        }
      };

      document.addEventListener('mousedown', handleOutsideClick);

      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }
  }, []);

  const handleFilterChange = (value) => {
    setSearchText(value);
    if (isMobile) {
      dispatch({ type: 'SEARCH_CATEGORY', payload: value });
    } else {
      debouncedFilterMarkers(value);
      dispatch({ type: 'SEARCH_CATEGORY', payload: value });
    }
  };

  const debouncedFilterMarkers = useCallback(
    debounce((value) => {
      actions.getQuickSearchResult(allMarkers, value);

      const filteredMarkers = allMarkers?.filter((place) => {
        const placeCategory = place.category;
        const placeName = place.name?.toLowerCase();
        const category = placeCategory ? placeCategory.toLowerCase() : 'others';

        return (
          value !== '' &&
          (category.includes(value?.toLowerCase()) || placeName.includes(value?.toLowerCase()))
        );
      });

      setSearchResult(filteredMarkers && filteredMarkers.length ? filteredMarkers : []);
    }, 500),
    [allMarkers]
  );

  const redirectToMap = () => {
    actions.sendDataToGoogleAnalytics(
      'quick_search',
      {
        quick_search_value: searchText,
      }
    );
    dispatch({ type: 'SET_SHOW_MAP_QS', payload: true });
    dispatch({ type: 'SEARCH_CATEGORY', payload: searchText });
    dispatch({ type: 'SET_SHOW_DASHBOARD', payload: false });
    dispatch({ type: 'SET_CURRENT_DISTANCE', payload: '3' });
    dispatch({ type: 'SET_CURRENT_TAB', payload: 'lifestyle' });
    dispatch({
      type: 'SET_LIST_OF_MARKERS',
      payload: { markersIndex: 0, markersList: searchResult },
    });
    dispatch({ type: 'SET_IS_ESTABLISHMENT_SEARCH_OPEN', payload: true });
  };

  const getSelectedMarkers = () => {
    if (isMobile) {
      return listOfMarkers[0];
    } else {
      return searchResult;
    }
  };

  const openEstablishments = () => {
    dispatch({ type: 'SET_SHOW_ESTABLISHMENTS', payload: true });
  }

  return (
    <div
      className={`establisment-search-container ${
        (currentMarker?.[0] !== null ||
        showEstablishments) &&
        isMobile &&
        'hide'
      }`}
      style={actions.getBrandingColor()}
    >
      <div
        className={`establisment-search-card ${
          isModern && 'modern-establisment-search-card'
        }`}
        ref={cardRef}
      >
        <Show.When isTrue={isModern}>
          <div className='modern-establisment-search-header'>
            <h2>Quick Search</h2>
            <Close onClick={() => closeFilterSearch()} />
          </div>
        </Show.When>
        <div className='search-input-container'>
          <Search />
          <input
            ref={inputRef}
            type='text'
            value={searchText}
            onChange={(e) => handleFilterChange(e.target.value)}
            placeholder='Search for an establishment'
          />
        </div>
        <Show.When isTrue={searchText && getSelectedMarkers()?.length === 0}>
          <div className='no-result-wrapper'>
            <p>
              No results found for <b>{searchText}</b>.
            </p>
          </div>
        </Show.When>
        <Show.When isTrue={searchText && getSelectedMarkers()?.length !== 0}>
          <div className='result-wrapper'>
            <MarkerList markers={searchResult} />
            <div>
              <p>{getSelectedMarkers()?.length} gem{getSelectedMarkers()?.length > 1 && 's'} found</p>
              <Show>
                <Show.When isTrue={isMobile}>
                  <div className='view-result' onClick={() => openEstablishments()}>view result</div>
                </Show.When>
                <Show.Else>
                  <button id='quick-search-btn' onClick={() => redirectToMap()}>
                    View Map
                  </button>
                </Show.Else>
              </Show>
            </div>
          </div>
        </Show.When>
      </div>
    </div>
  )
}