import { useState, useContext, useRef } from 'react';
import { StoreContext } from '../../store';
import './index.css';

import { ReactComponent as WalkspanLogo } from '../../assets/walkspan-logo-2.svg';

import Show from '../../utility/Show';
import { CircularLoader } from '../Loaders';
import FormError from '../../utility/FormError';

import { isValidEmail, isValidPassword, setLSData } from '../../services';

const Signup = () => {
  const { state, dispatch, actions, navigate } = useContext(StoreContext);
  const {
    isLoading,
    errorMessage,
    signupSubmitted,
    confirmSignupSubmitted,
    signupEmail,
  } = state;

  const [code, setCode] = useState('');
  const formRef = useRef();

  const gotoLogin = () => {
    actions.clearErrorMessage();
    if (signupSubmitted) {
      dispatch({
        type: 'SET_SIGNUP_SUBMITTED',
        payload: false,
      });
    }

    if(code !== '') {
      setCode('');
    }

    navigate('/login');
  };

  const renderSignupForm = () => {
    return (
      <Show>
        <Show.When isTrue={signupSubmitted}>
        <div>
            An email containing a verification code has been sent to your email address.
            Please check your inbox and follow the instructions to confirm your account.
          </div>
          <div className="no-code-msg">
            Didn't receive a code? <u onClick={() => resendCode()}>Resend It</u>
          </div>
        </Show.When>
        <Show.Else>
          <div className="input-container">
            <div className='name-container'>
              <div>
                <label className="input-label" for="first_name">First Name</label>
                <input
                  type="text"
                  className="input-field"
                  id="first_name"
                  placeholder="First Name"
                />
              </div>
              <div>
                <label className="input-label" for="last_name">Last Name</label>
                <input
                  type="text"
                  className="input-field"
                  id="last_name"
                  placeholder="Last Name"
                />
              </div>
            </div>
          </div>
          <div className="input-container">
            <label className="input-label" for="email">Email</label>
            <input
              type="text"
              className="input-field"
              id="email"
              placeholder="Type your email"
            />
          </div>
          <div className="input-container">
            <label className="input-label" for="password">Password</label>
            <input
              type="password"
              className="input-field"
              id="password"
              placeholder="Type your password"
            />
          </div>
        </Show.Else>
      </Show>
    )
  };

  const signupSubmit = () => {
    const firstName = formRef.current[0].value;
    const lastName = formRef.current[1].value;
    const email = formRef.current[2].value;
    const password = formRef.current[3].value;

    if (!firstName || !lastName || !email || !password) {
      dispatch({
        type: 'SET_ERROR_MESSAGE',
        payload: 'Please fill in all fields.'
      });
      return false;
    }

    if (!isValidEmail(email)) {
      dispatch({
        type: 'SET_ERROR_MESSAGE',
        payload: 'Please enter a valid email address.'
      });
      return false;
    }

    const passwordErrors = isValidPassword(password);
    if (passwordErrors.length !== 0) {
      dispatch({
        type: 'SET_ERROR_MESSAGE',
        payload: passwordErrors.join('\n').toString(),
      });
      return;
    }

    const payload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
    }
    actions.handleSignup(payload);
    dispatch({ type: 'SET_SIGNUP_EMAIL', payload: email });
    setLSData('signup-email', email);
    if (signupSubmitted) {
      dispatch({
        type: 'SET_SIGNUP_SUBMITTED',
        payload: false,
      });
    }
    // setEmail(email);
  };

  const resendCode = () => {
    actions.handleResendSignup({ email: signupEmail });
  };

  const renderTitle = () => {
    return (
      <Show>
        <Show.When isTrue={signupSubmitted}>
          <div className='title'>Email Sent Successfully!</div>
        </Show.When>
        <Show.Else>
          <div className='title'>Create a new account</div>
        </Show.Else>
      </Show>
    )
  };

  const getButtonFunction = () => {
    actions.clearErrorMessage();
    if (signupSubmitted) {
      navigate('/activate/token');
    } else {
      signupSubmit();
    }
  };

  const getButtonText = () => {
    if (!signupSubmitted) {
      return 'Register';
    } else {
      return 'Confirm Signup';
    }
  };

  return (
    <div className="signup-container">
      <div className='signup-overlay'></div>
      <div className='signup-card'>
        <WalkspanLogo className='walkspan-logo'/>
        {renderTitle()}

        <form ref={formRef}>
          {renderSignupForm()}
          <Show.When isTrue={errorMessage}>
            <FormError error={errorMessage} />
          </Show.When>
        </form>
        <div className='signup-buttons'>
          <Show.When isTrue={!signupSubmitted}>
            <button
              className='submit-button'
              onClick={isLoading ? null : () => getButtonFunction()}
            >
              <Show>
                <Show.When isTrue={isLoading}>
                  <CircularLoader size={'10px'} />
                </Show.When>
                <Show.Else>
                  {getButtonText()}
                </Show.Else>
              </Show>
            </button>
          </Show.When>
          <Show.When isTrue={!signupSubmitted || confirmSignupSubmitted}>
            <button
              className='login-button'
              onClick={() => gotoLogin()}
            >
              <Show>
                <Show.When isTrue={confirmSignupSubmitted}>
                  Login
                </Show.When>
                <Show.Else>
                  Go Back
                </Show.Else>
              </Show>
            </button>
          </Show.When>
        </div>
      </div>
    </div>
  )
};


export default Signup;