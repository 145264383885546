import { useState, useContext } from 'react';
import { StoreContext } from '../../store';
import './index.css';

import GetScore from './get-score';
import Favorites from './favorites';
import Show from '../../utility/Show';
import { GetStarted } from '../GetStarted';

const AddressScore = () => {
  const { state, dispatch, actions } = useContext(StoreContext);
  const { showScoreGuide, token } = state;
  const [tab, setTab] = useState('score');

  const handleTabChange = (value) => {
    if (tab !== value) {
      setTab(value);
    }
  };

  const isSelected = (value) => {
    return tab === value;
  };

  const handleGetStarted = (value) => {
    if (showScoreGuide) {
      dispatch({ type: 'SET_SHOW_SCORE_GUIDE', payload: false });
    }
  };

  const fetchAddressData = async (address) => {
    if (!token) {
      actions.handleLogin();
    }
    await actions.fetchAddressData(address, false);
  };

  return (
    <div className='address-score-container'>
      <Show.When isTrue={showScoreGuide}>
        <GetStarted
          key='address-score-score-guide'
          setShowTutorial={handleGetStarted}
        />
      </Show.When>
      <div className='address-score-header'>
        <div className='tabs max-w-screen-lg'>
          <div
            className={`${isSelected('score') ? 'selected' : ''}`}
            onClick={() => handleTabChange('score')}
          >
            Get Score
          </div>
          <div
            className={`${isSelected('favorites') ? 'selected' : ''}`}
            onClick={() => handleTabChange('favorites')}
          >
            My favorite
          </div>
          {/* <div>Add to your site</div> */}
        </div>
      </div>
      <Show>
        <Show.When isTrue={isSelected('score')}>
          <GetScore fetchAddressData={fetchAddressData} />
        </Show.When>
        <Show.When isTrue={isSelected('favorites')}>
          <Favorites
            handleTabChange={handleTabChange}
            fetchAddressData={fetchAddressData}
          />
        </Show.When>
      </Show>
    </div>
  );
};

export default AddressScore;