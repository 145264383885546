import React, { useContext, useEffect, useState } from 'react';

import { StoreContext } from '../../store';
import './index.css';
import { WalkingDistance } from '../Walking Distance/WalkingDistance';

import AddressItem from './AddressItem';

import { ReactComponent as Quiet } from '../../assets/noise.svg';
import { ReactComponent as Access } from '../../assets/access.svg';
import { ReactComponent as Architecture } from '../../assets/architecture.svg';
import { ReactComponent as Comfort } from '../../assets/comfort.svg';
import { ReactComponent as Activities } from '../../assets/activities.svg';
import { ReactComponent as Nature } from '../../assets/nature.svg';

import { ReactComponent as High } from '../../assets/score/high.svg';
import { ReactComponent as Moderate } from '../../assets/score/moderate.svg';
import { ReactComponent as Low } from '../../assets/score/low.svg';
import { ReactComponent as Back } from '../../assets/back-btn.svg';
import { ReactComponent as Arrow } from '../../assets/landing-page/back-button.svg';

import Slider from 'react-slick';

import { WalkingDistanceButtons } from '../WalkingDistanceButtons/WalkingDistanceButtons';

const WalkIndexCompare = () => {
  const { dispatch, actions, state } = useContext(StoreContext);
  const {
    listOfProperties,
    compareAddress,
    isMobile,
    currentAddress,
    currentView,
    showCompareLifeStyle,
  } = state;

  const [innerWidth, setInnerWidth] = useState(false);
  const isModern = currentView === 'Modern';

  const indexType = [
    {
      id: 'BeautyN',
      name: 'Nature',
      color: '#1EF04C',
      image: <Nature />,
    },
    {
      id: 'BeautyM',
      name: 'Architecture',
      color: '#FFC991',
      image: <Architecture />,
    },
    {
      id: 'Activities',
      name: 'Activities',
      color: '#F48426',
      image: <Activities />,
    },
    {
      id: 'Comfort',
      name: 'Comfort',
      color: '#F252AB',
      image: <Comfort />,
    },
    {
      id: 'Noise',
      name: 'Quiet',
      color: '#F6CE26',
      image: <Quiet />,
    },
  ];

  const branding = actions.getBrandingColor();

  const getTypeColor = (type) => {
    const color = indexType.find((itype) => {
      if (type) {
        return itype.id?.toLocaleLowerCase() === type?.toLocaleLowerCase()
      }
    });
    return color ? color.color : branding['--color'];
  };

  const renderLegend = () => {
    return (
      <div className={`compare-legend ${isModern && 'modern-compare-legend'}`}>
        <span className='legend-item'>
          <Low /> <span>1</span>
        </span>
        <span className='legend-item'>
          <Moderate /> <span>2</span>
        </span>
        <span className='legend-item'>
          <High /> <span>3</span>
        </span>
      </div>
    )
  }

  const renderIndexType = () => {
    return (
      <div>
        <h5 className='compare-title'>Score Legend</h5>
        {renderLegend()}
        {indexType.map((item) => {
          const typColor = {
            '--type-color': getTypeColor(item.id),
          };
          return (
            <div
              className='compare-type-container'
              style={typColor}
            >
              {item.image}
              <span> {item.name}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const handleBack = () => {
    dispatch({ type: 'SET_COMPARE_VISIBLE', payload: false });
    dispatch({ type: 'SET_SHOW_DASHBOARD', payload: true });
    if (!isModern) {
      dispatch({ type: 'RESET_COMPARE_ADDRESS', payload: currentAddress });
    }
    dispatch({ type: 'SET_DESKTOP_SIDEBAR', payload: false });
  };

  useEffect(() => {
    if (isMobile) {
      dispatch({ type: 'SET_MOBILE_SIDEBAR', payload: false });
    }

    const handleResize1 = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize1);

    handleResize1();

    return () => {
      window.removeEventListener('resize', handleResize1);
    };
  }, [innerWidth]);

  const getCount = () => {
    return innerWidth > 1280 ? 4 : innerWidth > 768 ? 2 : 1;
  };

  const redirectToGemCompare = () => {
    dispatch({ type: 'SET_CURRENT_TAB', payload: 'lifestyle' });
    dispatch({ type: 'SET_COMPARE_VISIBLE', payload: false });
    dispatch({ type: 'RESET_COMPARE_ADDRESS', payload: currentAddress });
    dispatch({ type: 'SET_DESKTOP_SIDEBAR', payload: true });
    dispatch({ type: 'SET_SHOW_DASHBOARD', payload: false });
    dispatch({ type: 'SET_COMPARE_LIFESTYLE', payload: true });
  };

  const properties = listOfProperties.map((item) => {
    return {
      label: item.property,
      value: item,
    };
  });

  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: getCount(),
    slidesToScroll: getCount(),
    className: 'slider-container',
  };

  const renderSlider = (
    <Slider {...settings}>
      {compareAddress.map((item, index) => {
        return (
          <AddressItem
            item={item}
            index={index}
            properties={properties}
            getTypeColor={getTypeColor}
          />
        );
      })}
    </Slider>
  );

  return (
    <div className='compare-container' style={actions.getBrandingColor()}>
      <div className='compare-header'>
        <div className='back-button' onClick={() => handleBack()}>
          <Back />
          BACK
        </div>
        {/* <div
          className='gem-compare-btn'
          onClick={() => redirectToGemCompare()}
        >
          GEM COMPARE
          <Arrow />
        </div> */}
      </div>

      <div className='walk-index-container'>
        <div className='location-item'>
          <div className='walkindx-compare-walking-distance'>
            <WalkingDistanceButtons
              color='#0F5671'
              key='compare-walking-distance-btn'
            />
          </div>

          {renderIndexType()}
        </div>

        {renderSlider}

        {/* {renderSlider} */}
      </div>

      <div className='walk-index-compare-mobile'>{renderSlider}</div>
    </div>
  );
};

export default WalkIndexCompare;
