import { useQuery, useQueryClient } from 'react-query';
import { useContext, useState } from 'react';
import { StoreContext } from '../store';
import axios from 'axios';
import { login } from '../api';
import httpClient from '../interceptors';

const walkspan_api = process.env.REACT_APP_API_URL;
const getLifeStyleEssentials = async (
  lat,
  lng,
  type,
  area = 'quarter-mile',
  email,
) => {
  try {
    const getType = type === 'leisure' ? 'entertainment-fitness' : type;

    const url = `${walkspan_api}/lifestyle-essentials/?areatype=${area}&lat=${lat}&lng=${lng}&type=${getType}&email=${email}`;
    const response = await httpClient.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    if (error.response) {
      if (error.response.status === 401) {
        return error.response.data;
      }
    } else {
      console.error('An error occurred:', error.message);
      return { error: '50x' }; // General 50x error indicator
    }
  }
};

const useGemMarkers = (lat, lng, type, token, apiKey, area, signal) => {
  const queryClient = useQueryClient();
  const { dispatch, state } = useContext(StoreContext);
  const { userDetails } = state;
  const [isRefreshingToken, setIsRefreshingToken] = useState(false);

  return useQuery(
    ['lifestyleEssentials', lat, lng, type, area],
    async () => {
      const data = await getLifeStyleEssentials(lat, lng, type, area, userDetails?.email);
      if (data.Error === 'Unauthorized' && !isRefreshingToken) {
        setIsRefreshingToken(true);
        const newToken = await login();
        dispatch({ type: 'SET_TOKEN', payload: newToken });
        setIsRefreshingToken(false);

        const newData = await getLifeStyleEssentials(lat, lng, type, newToken, apiKey, area, signal);
        queryClient.setQueryData(
          ['lifestyleEssentials', lat, lng, type, area],
          newData
        );
        return newData;
      }
      return data;
    },
    {
      staleTime: 60000, // 1 minute
      cacheTime: 300000, // 5 minutes
      enabled: !!lat && !!lng && !!type, // Only run if these are available
    }
  );
};

export default useGemMarkers;
