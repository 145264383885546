import "./index.css";
import { useState, useEffect } from 'react';
import { ReactComponent as Minus } from '../../assets/minus-circle.svg';
import { ReactComponent as Favorite } from '../../assets/marker-popup/favorite.svg';
import { ReactComponent as LegendScore1 } from '../../assets/legend-score-1.svg';
import { ReactComponent as LegendScore2 } from '../../assets/legend-score-2.svg';
import { ReactComponent as LegendScore3 } from '../../assets/legend-score-3.svg';
import { ReactComponent as ActiveGem1 } from '../../assets/mobile-active-scores/gem-1.svg';
import { ReactComponent as ActiveGem2 } from '../../assets/mobile-active-scores/gem-2.svg';
import { ReactComponent as ActiveGem3 } from '../../assets/mobile-active-scores/gem-3.svg';
import { ReactComponent as ActiveFeel1 } from '../../assets/mobile-active-scores/feel-1.svg';
import { ReactComponent as ActiveFeel2 } from '../../assets/mobile-active-scores/feel-2.svg';
import { ReactComponent as ActiveFeel3 } from '../../assets/mobile-active-scores/feel-3.svg';

import Show from "../../utility/Show";
import MapContainer from "../Map/single-map";
import { Autocomplete } from "../../utility/Autocomplete";
import { ScoreIcon } from "../../utility/ScoreIcon";

import { isObjectEmpty, lifeStyleFeaturesScores, getOverallFeelScore, getOverallGemScore, saveAddressToFavorites, getSoloFeelScore } from "../../services";
import { walkIndexFeatures, lifeStyleFeatures } from "../../constants";
import FilterContainer from '../Filter';
import { useSnackbar } from 'notistack';
import MapTypeLegends from "../LegendLines";

const CompareMaps = ({ state, dispatch, actions, navigate }) => {
  const {
    currentTab,
    compareLifeStyleAddress,
    currentAddress,
    listOfMarkers,
    listOfProperties,
    walkindexScores,
    lifeStyleScores,
    scoreProperties,
    currentDistance,
    isStandalone,
    currentView,
    mapTab,
    currentWalkindxType,
    showCompareLifeStyle,
    currentLifestyleType,
    showFilter,
    savedAddresses,
  } = state;

  const { enqueueSnackbar } = useSnackbar();
  const [enableCombineSearch, setEnableCombineSearch] = useState(false);
  const isWalkIndex = currentTab === 'walkindex';
  const isModern = currentView === 'Modern';

  const legend = [
    { label: 'Meh', value: 1 },
    { label: 'Yeah', value: 2 },
    { label: 'Wow', value: 3 },
  ];

  const getTabColor = () => {
    const selectedFeatures = isWalkIndex
      ? walkIndexFeatures
      : lifeStyleFeatures;
    const selectedColor = selectedFeatures
      .filter(
        (feature) =>
          feature.name.toLocaleLowerCase() === mapTab.toLocaleLowerCase()
      )
      .map((feature) => feature.color)[0];

    return { '--tab-color': selectedColor };
  };

  const tabs = [
    { label: 'Neighborhood Gems', value: 'lifestyle' },
    { label: 'Neighborhood Feel', value: 'walkindex' },
  ];

  const setCurrentTab = (tab) => {
    dispatch({ type: 'SET_CURRENT_TAB', payload: tab });
    // const distance = isAddressInNYC ? '1' : '3';
    // dispatch({ type: 'SET_CURRENT_DISTANCE', payload: distance });
    resetCombinedSearch();
  };

  const resetCombinedSearch = () => {
    dispatch({ type: 'SET_CURRENT_WALKINDX_TYPE', payload: null });
    dispatch({ type: 'RESET_CURRENT_LIFESTYLE_TYPE' });
    dispatch({ type: 'SET_SHOW_COMBINE_SEARCH', payload: false });
    dispatch({ type: 'SET_SHOW_FILTERS', payload: false });
  };

  const isTabActive = (tab) => {
    return currentTab === tab;
  };

  const isAddressEmpty = (address) => {
    return isObjectEmpty(address);
  };

  const handleAddressClick = async (payload, index) => {
    dispatch({
      type: 'UPDATE_LIFESTYLE_COMPARE_ADDRESS',
      payload: { item: index, address: payload },
    });
    await actions.fetchLifeStyleScores(payload, index);
    await actions.fetchWalkIndexRatings(payload, index);
  };

  const handleRemoveAddress = (index) => {
    dispatch({
      type: 'UPDATE_LIFESTYLE_COMPARE_ADDRESS',
      payload: { item: index, address: {} },
    });
    dispatch({
      type: 'SET_COMPARE_MAPS',
      payload: { index, object: {} }
    });
    if (index !== 0) {
      dispatch({
        type: 'UPDATE_COMPARE',
        payload: { number: index, length: 0 },
      });
      dispatch({
        type: 'SET_WALKINDEX_SCORES',
        payload: {
          walkindxScoreData: {},
          walkindxScoreIndex: index,
        }
      });
    }
  };

  const properties = listOfProperties.map((item) => {
    return {
      label: item.property,
      value: item,
    };
  });

  const getActiveIcon = (score) => {
    const gemIcons = [ActiveGem1, ActiveGem2, ActiveGem3];
    const feelIcons = [ActiveFeel1, ActiveFeel2, ActiveFeel3];

    const icons = isWalkIndex ? feelIcons : gemIcons;
    const IconComponent = icons[score - 1] || null; // Ensure score is within range (1-3)

    return IconComponent ? <IconComponent className="overall-icon" /> : null;
  };

  const renderFeatures = (index) => {
    const filteredGemFeatures = lifeStyleFeatures.slice(1, lifeStyleFeatures.length - 1);
    const features = isWalkIndex ? walkIndexFeatures : filteredGemFeatures

    const scores = isWalkIndex
      ? walkindexScores[index]
      : lifeStyleFeaturesScores(
        lifeStyleScores,
        index,
        currentDistance,
        isStandalone
      );

    if (features.length) {
      return features.map((feature) => {
        const featureColor = {
          '--feature-color': feature.color,
        };

        return (
          <div className="score-item" style={featureColor}>
            <ScoreIcon
              height='30px'
              width='30px'
              withBg={false}
              score={isWalkIndex ? getSoloFeelScore(scores[feature.id]) : Math.round(scores[feature.id]) || 1}
              content={feature.svg}
            />
            <span className="score-label">
              {feature.name}
            </span>
          </div>
        );
      });
    }
  };

  const overallScore = (index) => {
    return isWalkIndex
      ? getOverallFeelScore(walkindexScores[index])
      : getOverallGemScore(lifeStyleScores[index], currentDistance, isStandalone);
  }

  const renderScores = (index) => {
    return (
      <div className="scores-wrapper">
        <div className="score-item">
          <div className={`overall-score-wrapper ${!isWalkIndex ? 'gem' : ''}`}>
            {getActiveIcon(overallScore(index))}
            <span>{overallScore(index)}</span>
          </div>
          <span className="score-label">
            {isWalkIndex ? 'Feel' : 'Gem'} Score
          </span>
        </div>
        {renderFeatures(index)}
      </div>
    )
  };

  const getEstablishmentType = () => {
    if (mapTab === 'All') {
      return 'Gems';
    } else if (mapTab === 'Food') {
      return 'Restaurants';
    } else if (mapTab === 'Transit' || mapTab === 'Leisure') {
      return mapTab + 's';
    } else {
      return mapTab;
    }
  };

  const renderLegends = () => {
    return (
      <div
        className={`neighborhood-score-legend-container ${
          isModern && 'modern-neighborhood-score-legend-container'
        }`}
      >
        {legend.map((item, index) => {
          return (
            <div
              className='neighborhood-legend-label-container'
              key={`neighborhood-legend-${index}`}
            >
              <div
                className={`small-score-icon ${
                  !isWalkIndex && !isModern && 'small-score-lifestyle'
                } ${isModern && 'modern-small-score-icon'} ${
                  isModern && !isWalkIndex && 'modern-small-score-lifestyle'
                }`}
              >
                <ScoreIcon
                  height='23px'
                  width='auto'
                  withBg={true}
                  fontSize="12px"
                  score={item.value?.toString()}
                  content={item.value?.toString()}
                />
              </div>
              <p>{item.label}</p>
            </div>
          );
        })}
      </div>
    );
  };

  const getFeelLineLegend = (score) => {
    const lines = [LegendScore1, LegendScore2, LegendScore3];

    const IconComponent = lines[score - 1] || null; // Ensure score is within range (1-3)

    return IconComponent ? <IconComponent /> : null;
  }

  const filteredAddress = compareLifeStyleAddress.filter(place => Object.keys(place).length !== 0)

  const gridClass = filteredAddress.length === 2
  ? 'two-items'
  : filteredAddress.length === 3
  ? 'three-items'
  : filteredAddress.length === 4
  ? 'four-items'
  : '';

  const renderMapTypeLegends = () => {
    return (
      <div className='map-type-legends'>
        <div
          className='map-type-legend'
          data-border-style={'1'}
          style={getTabColor()}
        >
          <Show.When isTrue={isWalkIndex}>
            {getFeelLineLegend(1)}
          </Show.When>
          {`< 50% ${getEstablishmentType()}`}
        </div>
        <div
          className='map-type-legend'
          data-border-style={'2'}
          style={getTabColor()}
        >
          <Show.When isTrue={isWalkIndex}>
            {getFeelLineLegend(2)}
          </Show.When>
          {`50% ${getEstablishmentType()}`}
        </div>
        <div
          className='map-type-legend'
          data-border-style={'3'}
          style={getTabColor()}
        >
          <Show.When isTrue={isWalkIndex}>
            {getFeelLineLegend(3)}
          </Show.When>
          {`> 50% ${getEstablishmentType()}`}
        </div>
      </div>
    );
  };

  const isInCurrentLifestyleType = (feature) => {
    return currentLifestyleType.some((type) => {
      return type?.toLowerCase() === feature.toLowerCase();
    });
  };

  const getSelectedFeatures = (selected) => {
    const filteredLifestyle = lifeStyleFeatures.slice(
      1,
      lifeStyleFeatures.length - 1
    );
    return isWalkIndex ? filteredLifestyle : walkIndexFeatures
  };

  const isFeatureSelected = (feature) => {
    return (feature === currentWalkindxType || isInCurrentLifestyleType(feature))
      ? true
      : feature === mapTab && !showCompareLifeStyle && !isWalkIndex;
  };


  const renderCombineSearch = () => {
    return (
      <div className='modern-combine-search-container'>
        <div className='combine-search-btns'>
          {getSelectedFeatures().map((feature) => {
            const getName =
              feature.name === 'Leisure'
                ? 'leisure'
                : feature.name.toLowerCase();
            return (
              <div
                key={`combine-search-btn-${feature.name}`}
                className={`combine-search-btn ${
                  isFeatureSelected(feature.name) ? 'selected' : ''
                }`}
                onClick={() => combineSearchFunction(feature.name)}
                style={{ '--icon-color': feature.color }}
              >
                {feature.svg}
                <div>
                  {feature.name}
                  <Show.When isTrue={!showCompareLifeStyle && !isWalkIndex}>
                    <div className='gem-count'>
                      {lifeStyleScores[0][getName] || '0'}
                    </div>
                  </Show.When>
                </div>
              </div>
            );
          })}
        </div>
        {/* <Show.When isTrue={!isStandalone}>
          <div className='toggle-walkindx-container'>
            Combine with neighborhood {isWalkIndex ? 'gems' : 'feel'}
            <div
              className='toggle-button'
              onClick={() => toggleCombineSearch()}
            >
              <input type='checkbox' checked={enableCombineSearch} readOnly />
              <span
                className={`combine-search-slider`}
              ></span>
            </div>
          </div>
        </Show.When>
        <Show.When isTrue={enableCombineSearch && !isStandalone}>
          <div className='combine-search-btns'>
            {getSelectedFeatures(false).map((feature) => {
              return (
                <div
                  className={`combine-search-btn ${
                    isFeatureSelected(feature.name) ? 'selected' : ''
                  }${actions.areMapsLoading() ? 'btn-loading' : ''}`}
                  onClick={
                    actions.areMapsLoading()
                      ? null
                      : () => combineSearchFunction(false, feature.name)
                  }
                  style={{ '--icon-color': feature.color }}
                >
                  {feature.svg}
                  {feature.name}
                </div>
              );
            })}
          </div>
        </Show.When> */}
      </div>
    );
  };

  const toggleCombineSearch = () => {
    if (enableCombineSearch) {
      setEnableCombineSearch(false);
      if (isWalkIndex) {
        dispatch({ type: 'RESET_CURRENT_LIFESTYLE_TYPE' });
      } else {
        dispatch({ type: 'SET_CURRENT_WALKINDX_TYPE', payload: null });
      }
    } else {
      setEnableCombineSearch(true);
    }
  };

  const combineSearchFunction = (type) => {
    let actionType;
    let payload;

    if (!isWalkIndex) {
      actionType = 'SET_CURRENT_WALKINDX_TYPE';
      payload = type === currentWalkindxType ? null : type;
    } else {
      actionType = isInCurrentLifestyleType(type)
        ? 'REMOVE_CURRENT_LIFESTYLE_TYPE'
        : 'SET_CURRENT_LIFESTYLE_TYPE';
      payload = type;
    }


    dispatch({ type: actionType, payload });
  };

  useEffect(() => {
    setEnableCombineSearch(false);
    resetCombinedSearch()
  }, [showCompareLifeStyle]);

  const isAddressSaved = (currentAddress) => {
    return savedAddresses?.some(
      (address) => address?.property === currentAddress?.property
    );
  };

  const handleSaveAddressToFavorites = (address) => {
    saveAddressToFavorites(
      isAddressSaved(address) ? 'remove' : 'save',
      address,
      savedAddresses,
      dispatch,
      enqueueSnackbar,
    );
  };

  return (
    <div className="compare-maps-container">
      <div className="compare-address-list">
        <Show.When isTrue={!showFilter}>
          <Show.When isTrue={!isStandalone}>
            <div className="neighbohood-tab-popup">
              <div className="tabs-wrapper">
                {tabs.map((tab) => (
                  <div
                    key={tab.value}
                    className={`tab-item ${
                      isTabActive(tab.value) ? 'selected' : ''
                    }`}
                    onClick={() => setCurrentTab(tab.value)}
                  >
                    {tab.label}
                  </div>
                ))}
              </div>
            </div>
          </Show.When>
          <div className="address-list">
            {compareLifeStyleAddress.map((address, addressIndex) => (
              <div key={addressIndex} className={`compare-address-item ${
                isAddressEmpty(address) ? 'empty-address-item' : ''
              }`}>
                <div className="address-item-header">
                  <span>
                    Address {addressIndex + 1}
                    <Show.When isTrue={isAddressEmpty(address)}>
                      <div>---</div>
                    </Show.When>
                  </span>
                  <Show.When isTrue={!isAddressEmpty(address) && addressIndex !== 0}>
                    <Minus
                      className="remove-address-icon"
                      onClick={() => handleRemoveAddress(addressIndex)}
                    />
                  </Show.When>
                </div>
                <Show>
                  <Show.When isTrue={isAddressEmpty(address)}>
                    <Autocomplete
                      className='compare-address-input'
                      placeholder={`Type an address`}
                      index={addressIndex}
                      onClick={handleAddressClick}
                    />
                  </Show.When>
                </Show>
                <div className="address-wrapper">
                  <span>{address.property}</span>
                  <Show.When isTrue={!isAddressEmpty(address)}>
                    <Favorite
                        className={`add-to-favorites-icon ${
                          isAddressSaved(address) ? 'added-to-favorites' : ''
                        }`}
                        onClick={() => handleSaveAddressToFavorites(address)}
                      />
                  </Show.When>
                </div>
                <Show.When isTrue={!isAddressEmpty(address)}>
                  {renderScores(addressIndex)}
                </Show.When>
              </div>
            ))}
          </div>
        </Show.When>
        <Show.When isTrue={showFilter}>
          <FilterContainer />
        </Show.When>
        {/* {renderCombineSearch()} */}
        <div className='version-2-legends'>
          <MapTypeLegends />
        </div>
      </div>
      <div className={`maps-wrapper ${gridClass}`}>
      {filteredAddress.map((item, index) => {
            return (
              <MapContainer
                key={index}
                className='map-item'
                isSearchable
                index={index}
                property={item?.property}
                longitude={item?.longitude}
                latitude={item?.latitude}
                neighborhood={item?.neighborhood}
                properties={properties}
                state={state}
                dispatch={dispatch}
                actions={actions}
              />
            );
          })}

      </div>
    </div>
  )
}

export default CompareMaps;