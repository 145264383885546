import React from 'react';
import './index.css';

import { ReactComponent as HouseSearch } from '../../assets/house_searching.svg';

const AddressNotFound = () => {
  return (
    <div className="address-not-found-container">
      <HouseSearch />
      <h2 className="address-not-found-title">Address Not Found</h2>
      <p className="address-not-found-message">We couldn't find the property you're looking for.</p>
      <p className="address-not-found-contact">Please contact <strong>+1 212-627-7800</strong> for assistance.</p>
    </div>
  );
};

export default AddressNotFound;
