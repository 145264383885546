import { useState, useEffect, useRef } from 'react';
import { Rating } from 'react-simple-star-rating';
import { getEstablishmentColor } from '../../services';

import { ReactComponent as Clock } from '../../assets/marker-popup/time.svg';
import { ReactComponent as Location } from '../../assets/walking-distance.svg';
import { ReactComponent as Favorite } from '../../assets/marker-popup/favorite.svg';
import { ReactComponent as Progress } from '../../assets/marker-popup/progress.svg';
import { ReactComponent as Footsteps } from '../../assets/marker-popup/footsteps.svg';
import { ReactComponent as Fire } from '../../assets/marker-popup/fire.svg';
import { ReactComponent as CO2 } from '../../assets/marker-popup/co2.svg';
import { ReactComponent as DownArrow } from '../../assets/marker-popup/down-arrow.svg';
import { ReactComponent as Info } from '../../assets/marker-popup/info.svg';
import { ReactComponent as Distance } from '../../assets/marker-popup/distance.svg';
import { ReactComponent as MobileDistance } from '../../assets/marker-popup/mobile-distance.svg';
import { ReactComponent as Toggle } from '../../assets/mobile-toggle.svg';
import { ReactComponent as Share } from '../../assets/marker-popup/share.svg';

import { ReactComponent as Walk } from '../../assets/walk.svg';
import { ReactComponent as Gem } from '../../assets/modern-gem.svg';
import { ReactComponent as Cancel } from '../../assets/marker-popup/close.svg';

import { ReactComponent as Food } from '../../assets/food.svg';
import { ReactComponent as Services } from '../../assets/services.svg';
import { ReactComponent as Shop } from '../../assets/shop.svg';
import { ReactComponent as Entertainment } from '../../assets/entertainment.svg';
import { ReactComponent as Access } from '../../assets/access.svg';
import { ReactComponent as Yelp } from '../../assets/yelp.svg';

import Skeleton from '../Skeleton';
import { Tooltip } from 'react-tooltip';

import Show from '../../utility/Show';

export const MarkerDetails = ({
  index,
  marker,
  categories,
  currentMarker,
  currentAddress,
  currentView,
  isLoading,
  isMobile,
  directionDetails,
  actions,
  dispatch,
  handleFavorite,
  setCurrentMarker,
  setMarkerDetails,
  isFavoriteChecked,
  calculateDistance,
}) => {
  const [markerDetailsTab, setMarkerDetailsTab] = useState('metrics');
  const [distance, setDistance] = useState(null);
  const [duration, setDuration] = useState(null);
  const [steps, setSteps] = useState(null);
  const [calories, setCalories] = useState(null);
  const [co2, setCO2] = useState(null);
  const [currentStatContainer, setCurrentStatContainer] = useState(0);
  const elementRef = useRef(null);
  const [translateY, setTranslateY] = useState(300);
  const [startY, setStartY] = useState(0);

  const handleTouchStart = (event) => {
    event.preventDefault();
    setStartY(event.touches[0].clientY);
  };

  const handleTouchMove = (event) => {
    const deltaY = event.touches[0].clientY - startY;
    setTranslateY(Math.max(0, deltaY)); // Ensure translateY is non-negative
    if (deltaY >= 150) {
      closeMarkerPopup();
    }
  };

  const isModern = currentView === 'Modern';
  const skeletonWidth = isMobile? 320 : 400;

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentStatContainer(index),
    // className: 'slider-container',
  };

  const statTotal = [
    { label: 'distance', total: 1609.34 }, // in meters
    { label: 'steps', total: 2000 },
    { label: 'duration', total: 1200 },
    { label: 'calories', total: 100 },
    { label: 'co2', total: 0.882 }, // 400g in lb
  ];

  const getStyleVariable = (type, value) => {
    const selectedStat = statTotal.filter((stat) => stat.label === type)[0];
    const percent = value / 2;
    const style = {
      '--percent': percent,
      '--total': selectedStat?.total,
    };
    return style;
  };

  useEffect(() => {
    if (currentMarker) {
      setTranslateY(0);
    }
  }, [currentMarker]);

  useEffect(() => {
    if (directionDetails) {
      const totalTravelTime = directionDetails.routes[0].legs[0].duration;
      setDuration(totalTravelTime);

      const distance = directionDetails.routes[0].legs[0].distance.value;
      const totalSteps = Math.round(distance / 0.765); // meters/step
      setSteps(totalSteps);

      const totalCalories = Math.round(totalSteps * 0.04); // kcal/step
      setCalories(totalCalories);

      setCO2(getCO2Saved(distance).toFixed(1));
    } else {
      setDuration(null);
      setSteps(null);
      setCalories(null);
      setCO2(null);
    }
  }, [directionDetails]);

  const getCO2Saved = (meters) => {
    const miles = meters / 1609; // convert meters to miles
    setDistance(miles.toFixed(1));
    const co2EmissionsPerMile = 400; // grams/mile according to EPA
    const totalCO2Emissions = co2EmissionsPerMile * miles; // total CO2 emissions in grams
    const co2Saved = totalCO2Emissions / 453.6; // convert grams to metric tons
    return co2Saved; // convert to pounds
  };

  const convertTo12HourFormat = (time) => {
    const hours = parseInt(time?.substring(0, 2), 10);
    const minutes = parseInt(time?.substring(2), 10);

    let meridiem = 'AM';
    let adjustedHours = hours;

    if (hours >= 12) {
      meridiem = 'PM';
      adjustedHours = hours === 12 ? 12 : hours - 12;
    }
    return `${adjustedHours}:${minutes
      .toString()
      .padStart(2, '0')} ${meridiem}`;
  };

  const isOpen24Hours = (opening_hours) => {
    return opening_hours.periods.some((period) => {
      return (
        period.open &&
        period.open.time === '0000' &&
        period.close &&
        period.close.time === '2359'
      );
    });
  };

  const formatNumber = (value) => {
    if (value === null) {
      return '0.0';
    }

    const isWholeNumber = Number.isInteger(value);
    return isWholeNumber ? `${value}.0` : `${value}`;
  };

  const closeMarkerPopup = () => {
    setCurrentMarker(null);
    // setMarkerDetails(null);
    setDuration(null);
    if (isMobile) {
      setTranslateY(300);
    }
  };

  const getCategoryImage = (type) => {
    switch (type) {
      case 'food':
        return <Food className='category-image' />;
      case 'shops':
        return <Shop className='category-image' />;
      case 'services':
        return <Services className='category-image' />;
      case 'transit':
        return <Access className='category-image' />;
      case 'leisure':
        return <Entertainment className='category-image' />;
      default:
        return <Food className='category-image' />;
    }
  };

  const showPlaceCard = () => {
    dispatch({
      type: 'SET_PLACE_CARD_DETAILS',
      payload: { placeIndex: index, placeData: marker },
    });
  };

  const styleColor = actions.getBrandingColor();

  const renderPopupHeader = () => {
    return (
      <div className='marker-details-header'>
        <Show>
          <Show.When isTrue={isLoading}>
            <Skeleton count={1} size={30} width={skeletonWidth} />
            <Skeleton count={1} size={30} width={skeletonWidth} />
            <Skeleton count={1} size={30} width={skeletonWidth} />
          </Show.When>
          <Show.Else>
            <div className='marker-details-title'>
              <div className='marker-details-gem'>
                <Gem className='marker-details-gem-icon' style={gemStyle()} />
                {getCategoryImage(currentMarker?.type)}
              </div>
              <div className='marker-details-name-category'>
                <p className='marker-address-name'>
                  {currentMarker?.name || marker?.name}
                  {categories && (
                  <div className='popup-category-container'>
                    {categories.map((item) => (
                      <div
                        className={`popup-category-item ${
                          isModern && 'sharp-border'
                        }`}
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                )}
                </p>

              </div>
              <Show.When isTrue={!isLoading}>
                <div className='buttons'>
                  <Show.When isTrue={isMobile}>
                    <Share onClick={() => showPlaceCard()} />
                  </Show.When>
                  <Favorite
                    className={`add-to-favorites-icon ${
                      isFavoriteChecked(currentMarker) && 'added-to-favorites'
                    }`}
                    onClick={() => handleFavorite(currentMarker)}
                  />
                </div>
              </Show.When>
            </div>
            <div className='marker-details-item'>
              {marker?.formatted_address && (
                <>
                  <Location className='marker-details-icon' />
                  <span>{marker?.formatted_address}</span>
                </>
              )}
            </div>
            <>
              <div className='marker-details-item'>
                {marker?.opening_hours && (
                  <>
                    <Clock className='marker-details-icon' />
                    {isOpen24Hours(marker?.current_opening_hours) ? (
                      'Open 24 hours'
                    ) : (
                      <>
                        {convertTo12HourFormat(
                          marker?.current_opening_hours?.periods[0]?.open?.time
                        )}
                        {' - '}
                        {convertTo12HourFormat(
                          marker?.current_opening_hours?.periods[0]?.close?.time
                        )}
                      </>
                    )}
                  </>
                )}
                <div className='marker-rating-container'>

                    <Show.When isTrue={currentMarker?.rating}>
                      <Rating
                        initialValue={currentMarker?.rating}
                        allowFraction
                        size={20}
                        readonly
                        fillColor={styleColor['--color']}
                      />
                      {formatNumber(currentMarker?.rating)}
                      <Yelp alt='Powered by Yelp' />
                    </Show.When>
                </div>
              </div>
            </>
          </Show.Else>
        </Show>
      </div>
    );
  };

  const renderReviews = () => {
    return (
      <div className='marker-reviews-container'>
        {isLoading ? (
          <>
            <Skeleton count={1} size={70} width={skeletonWidth} />
          </>
        ) : (
          <>
            {marker?.reviews && (
              <div className='review-container-title'>Reviews:</div>
            )}
            <div className='marker-reviews'>
              {marker?.reviews ? (
                marker?.reviews.map((review) => (
                  <div className='review-item'>
                    <div className='review-header'>
                      <div>
                        <div className='reviewer-img'>
                          <img
                            src={review.profile_photo_url}
                            referrerPolicy='no-referrer'
                          />
                        </div>
                        <div className='reviewer-details'>
                          <div className='reviewer-name'>
                            {review.author_name}
                          </div>
                          <div className='review-date'>
                            {review.relative_time_description}
                          </div>
                        </div>
                      </div>
                      {/* <Rating
                        initialValue={review?.rating}
                        allowFraction
                        size={15}
                        readonly
                        fillColor='#979797'
                      /> */}
                    </div>
                    {review.text && (
                      <div className='review-content'>{`“${review.text}”`}</div>
                    )}
                  </div>
                ))
              ) : (
                <div className='no-reviews'>No reviews yet</div>
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  const renderMarkerStats = () => {
    return (
      <div
        className={`marker-stats-container ${
          currentStatContainer === 0 ? 'hide-prev-arrow' : 'hide-next-arrow'
        }`}
      >
        <Show>
          <Show.When isTrue={isLoading}>
            <Skeleton count={1} size={80} width={skeletonWidth} />
          </Show.When>
          <Show.Else>
            <div className='stats-container'>
              <div className='marker-stat-item'>
                <div className='marker-stat-progress'>
                  <Progress />
                  <div
                    className='stat-progress-value'
                    style={getStyleVariable(
                      'distance',
                      directionDetails?.routes[0]?.legs[0]?.distance?.value
                    )}
                  >
                    <Progress />
                  </div>
                  <div className='marker-stat-value'>
                    {distance} mi
                    <Show.When isTrue={!isMobile}>
                      <div className='max-distance'>1 mi</div>
                    </Show.When>
                  </div>
                </div>
                <div className='marker-stat-label'>
                  <Show>
                    <Show.When isTrue={isMobile}>
                      <MobileDistance className='marker-details-icon' />
                    </Show.When>
                    <Show.Else>
                      <Distance className='marker-details-icon' />
                    </Show.Else>
                  </Show>
                  distance
                </div>
              </div>
              <div className='marker-stat-item'>
                <div className='marker-stat-progress'>
                  <Progress />
                  <div
                    className='stat-progress-value'
                    style={getStyleVariable('steps', steps)}
                  >
                    <Progress />
                  </div>
                  <div className='marker-stat-value'>
                    {steps}
                    <Show.When isTrue={!isMobile}>
                      <div className='max-distance'>2000</div>
                    </Show.When>
                  </div>
                </div>
                <div className='marker-stat-label'>
                  <Footsteps className='marker-details-icon' />
                  steps
                </div>
              </div>
              <div className='marker-stat-item'>
                <div className='marker-stat-progress'>
                  <Progress />
                  <div
                    className='stat-progress-value'
                    style={getStyleVariable('duration', duration?.value)}
                  >
                    <Progress />
                  </div>
                  <div className='marker-stat-value'>
                    {duration?.text}
                    <Show.When isTrue={!isMobile}>
                      <div className='max-distance'>20 mins</div>
                    </Show.When>
                  </div>
                </div>
                <div className='marker-stat-label'>
                  <Clock className='marker-details-icon' />
                  duration
                </div>
              </div>

              <div className='marker-stat-item'>
                <div className='marker-stat-progress'>
                  <Progress />
                  <div
                    className='stat-progress-value'
                    style={getStyleVariable('calories', calories)}
                  >
                    <Progress />
                  </div>
                  <div className='marker-stat-value'>
                    ≈ {calories} cal
                    <Show.When isTrue={!isMobile}>
                      <div className='max-distance'>100 cal</div>
                    </Show.When>
                  </div>
                </div>
                <div className='marker-stat-label'>
                  <Fire className='marker-details-icon' />
                  calories
                </div>
              </div>
              <div className='marker-stat-item'>
                <div className='marker-stat-progress'>
                  <Progress />
                  <div
                    className='stat-progress-value'
                    style={getStyleVariable('co2', co2)}
                  >
                    <Progress />
                  </div>
                  <div className='marker-stat-value'>
                    = {co2} lbs
                    <Show.When isTrue={!isMobile}>
                      <div className='max-distance'>0.9 lbs</div>
                    </Show.When>
                  </div>
                </div>
                <div className='marker-stat-label'>
                  <CO2 className='marker-details-icon' />
                  <div>
                    CO<span className='subset-text'>2</span>
                    <DownArrow className='marker-details-icon co2-reduced-icon' />
                    <div className='marker-stat-label'>
                      <Info className="info-icon" data-tooltip-id={`co2-button`} />
                      <Tooltip
                        className='custom-btn-popup'
                        id={`co2-button`}
                        place='bottom'
                      >
                        <span>
                          Calculation based on{' '}
                          <span>
                            <a
                              href='https://www.epa.gov/greenvehicles/greenhouse-gas-emissions-typical-passenger-vehicle'
                              target='_blank'
                              rel='noreferrer'
                            >
                              {' '}
                              epa.gov
                            </a>
                          </span>
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Show.Else>
        </Show>
      </div>
    );
  };

  const gemStyle = () => {
    const style = {
      '--gem-bg': getEstablishmentColor(currentMarker?.type),
    };
    return style;
  };

  return (
    <div
      className={`marker-details-card ${
        currentMarker ? 'open' : ''
      }`}
      ref={elementRef}
      style={{ transform: `translateY(${translateY}px)` }}
      onTouchStart={isMobile ? handleTouchStart : null}
      onTouchMove={isMobile ? handleTouchMove : null}
    >
      <Show>
        <Show.When isTrue={isMobile}>
          <Toggle
            className='marker-details-toggle'
            onClick={() => closeMarkerPopup()}
            alt='Popup Close'
          />
        </Show.When>
        <Show.Else>
          <Cancel
            className='marker-details-close'
            onClick={() => closeMarkerPopup()}
            alt='Popup Close'
          />
        </Show.Else>
      </Show>
      {renderPopupHeader()}
      <div className='marker-details-tabs'>
        <button
          className={`${
            markerDetailsTab === 'metrics' && 'selected-marker-details-tab'
          }`}
          onClick={() => setMarkerDetailsTab('metrics')}
        >
          Metrics
        </button>
        <button
          className={`${
            markerDetailsTab === 'reviews' && 'selected-marker-details-tab'
          }`}
          onClick={() => setMarkerDetailsTab('reviews')}
        >
          Reviews
        </button>
      </div>
      <Show>
        <Show.When isTrue={markerDetailsTab === 'metrics'}>
          {renderMarkerStats()}
        </Show.When>
        <Show.Else>
          {renderReviews()}
        </Show.Else>
      </Show>
    </div>
  );
};
