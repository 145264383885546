import './App.css';
import './fonts/GothamMedium.ttf';
import './fonts/GothamLight.ttf';
import React, { useContext, useState } from 'react';
import { Modal } from './components/Modal/Modal';
import { Rating } from 'react-simple-star-rating';
import { StoreContext } from './store';
import MiniMap from './components/Map/minimap';

function App() {
  const [rating, setRating] = useState(0);

  const { state, actions } = useContext(StoreContext);

  const { showModal, isPlugin } = state;


  return (
    <div className='App' style={actions.getBrandingColor()}>
      <div className='WalkingExperience'>
        {isPlugin && <div className='white-bg'></div>}
        <Modal />
      </div>
    </div>
  );
}

export default App;
