import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as WalkspanLogo } from '../../assets/walkspan-logo-2.svg';

const NotFound = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <div>
        <WalkspanLogo />
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you are looking for does not exist.</p>
        <Link to='/'>
          <button>Go to Home</button>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
