import { useState, useEffect, useContext } from "react";
import { useSnackbar } from 'notistack';
import { StoreContext } from "../../store";

import Show from '../../utility/Show';
import { ScoreIcon } from "../../utility/ScoreIcon";
import MapTypeLegends from "../LegendLines";

import { ReactComponent as ActiveGem1 } from '../../assets/mobile-active-scores/gem-1.svg';
import { ReactComponent as ActiveGem2 } from '../../assets/mobile-active-scores/gem-2.svg';
import { ReactComponent as ActiveGem3 } from '../../assets/mobile-active-scores/gem-3.svg';
import { ReactComponent as ActiveFeel1 } from '../../assets/mobile-active-scores/feel-1.svg';
import { ReactComponent as ActiveFeel2 } from '../../assets/mobile-active-scores/feel-2.svg';
import { ReactComponent as ActiveFeel3 } from '../../assets/mobile-active-scores/feel-3.svg';
import { ReactComponent as Active } from '../../assets/active-icon.svg';

import {
  getLifeStyleScore,
  getTotalGems,
  getWalkIndexWording,
  saveAddressToFavorites,
  getFeatureIcon,
  getTabColor,
  getTypeDescription,
  getLimit,
  getLifeStyleWording,
  isObjectEmpty,
  lifeStyleFeaturesScores,
} from '../../services';

import { walkIndexFeatures, lifeStyleFeatures, walkindxID } from "../../constants";

const MobileScoreContainer = () => {
  const { state, dispatch } = useContext(StoreContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    currentTab,
    walkindexScores,
    lifeStyleScores,
    currentDistance,
    isStandalone,
    currentAddress,
    savedAddresses,
    currentWalkindxType,
    currentLifestyleType,
    listOfMarkers,
    selectedMap,
    isMapLoading,
    mapTab,
    scoreProperties,
    isMobile,
    showCompareLifeStyle,
    compareLifeStyleAddress,
    isLoading,
    categoryFilters,
    currentRates,
  } = state;

  const [isPropertyEmpty, setIsPropertyEmpty] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const isWalkIndex = currentTab === 'walkindex';

  const gemFeaturesScores = lifeStyleFeaturesScores(
    lifeStyleScores,
    selectedMap,
    currentDistance,
    isStandalone,
  );

  const scoreLegend = [
    { score: 3, label: 'Wow' },
    { score: 2, label: 'Yeah' },
    { score: 1, label: 'Meh' }
  ];

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (compareLifeStyleAddress[0] !== null) {
      const isEmpty =
      isObjectEmpty(compareLifeStyleAddress[selectedMap]) &&
      typeof compareLifeStyleAddress[selectedMap] === 'object';
      setIsPropertyEmpty(isEmpty);
    }
  }, [compareLifeStyleAddress]);

  useEffect(() => {
    if (compareLifeStyleAddress[0] !== null) {
      const isEmpty =
      isObjectEmpty(compareLifeStyleAddress[selectedMap]) &&
      typeof compareLifeStyleAddress[selectedMap] === 'object';
      setIsPropertyEmpty(isEmpty);
    }
  }, [selectedMap]);

  useEffect(() => {
    if (!isMounted) return;

    dispatch({ type: 'SET_CURRENT_WALKINDX_TYPE', payload: null });
    dispatch({ type: 'RESET_CURRENT_LIFESTYLE_TYPE' });
    dispatch({ type: 'SET_SHOW_FILTERS', payload: false });
    dispatch({ type: 'RESET_CATEGORY', payload: [''] });
    dispatch({ type: 'SET_RATES', payload: [] });
  }, [currentTab]);

  const getOverallScore = () => {
    if (isWalkIndex) {
      if (!walkindexScores[selectedMap]) return 1;
      
      const { activities, beautyM, beautyN, comfort, noise } =
        walkindexScores[selectedMap];

      const scores = [
        parseFloat(activities),
        parseFloat(beautyM),
        parseFloat(beautyN),
        parseFloat(comfort),
        parseFloat(noise),
      ];

      const counts = [0, 0, 0];
      scores.forEach((score) => {
        const roundedScore = Math.round(score);
        counts[roundedScore - 1]++;
      });

      if (counts[2] >= 1) return 3;
      if (counts[1] >= 1) return 2;
      return 1;
    } else {
      if (!lifeStyleScores[selectedMap]) return 1;
      const scores = Object.values(gemFeaturesScores);

      const counts = [0, 0, 0];
      scores.forEach((score) => {
        const roundedScore = Math.round(score);
        counts[roundedScore - 1]++;
      });

      if (counts[2] >= 1) return 3;
      if (counts[1] >= 1) return 2;
      return 1;
    }
  };

  const handleSaveAddressToFavorites = () => {
    saveAddressToFavorites(
      isAddressSaved() ? 'remove' : 'save',
      currentAddress,
      savedAddresses,
      dispatch,
      enqueueSnackbar,
    );
  };

  const isAddressSaved = () => {
    return savedAddresses.some(
      (address) => address?.property === currentAddress?.property
    );
  };

  const showEstablishments = () => {
    dispatch({
      type: 'SET_SHOW_ESTABLISHMENTS',
      payload: true,
    });
  };

  const hasType = (selected) => {
    return selected
      ? isWalkIndex
        ? (currentWalkindxType !== null || mapTab !== 'All')
        : currentLifestyleType?.length > 0 && currentLifestyleType?.length < 2
      : isWalkIndex
      ? currentLifestyleType?.length > 0 && currentLifestyleType?.length < 2
      : currentWalkindxType !== null;
  };

  const hasData = () => {
    if (isWalkIndex) {
      return getFinalNeigborhoodScore() !== 0;
    } else {
      return !isObjectEmpty(lifeStyleScores[selectedMap]) ||
        listOfMarkers[selectedMap]?.length > 0;
    }
  };

  const renderModernWalkindxScore = () => {
    if (!walkindexScores[selectedMap]) return;
    let feature = currentWalkindxType || mapTab;
    feature = feature === 'Vibrance' ? 'Activities' : feature;
    return (
      <div className='lifestyle-search-score-container'>
        <div className='custom-button-icon modern-custom-button-icon'>
          <ScoreIcon
            width='40px'
            height='40px'
            withBg={false}
            score={getFeatureScore()}
            content={getFeatureIcon(true, feature)}
          />
        </div>
        <div
          className='tab-score-desciption'
          data-border-style={Math.round(
            walkindexScores[selectedMap][walkindxID[feature]]
          ).toString()}
          style={getTabColor(true, feature)}
        >
          {getTypeDescription(
            'walkindex',
            feature?.toLowerCase(),
            Math.round(
              parseFloat(walkindexScores?.[selectedMap]?.[walkindxID?.[feature]])
            ) || 1,
            currentDistance,
            isStandalone,
            isMobile
          )}
        </div>
      </div>
    );
  };

  const renderModernLifestyleScore = () => {
    const feature = currentLifestyleType[0];
    return (
      <div className='lifestyle-search-score-container'>
        <div className='custom-button-icon modern-custom-button-icon'>
          <ScoreIcon
            width='40px'
            height='40px'
            withBg={false}
            score={getFeatureScore()}
            content={getFeatureIcon(false, feature)}
          />
        </div>
        <div
          className='tab-score-desciption'
          data-border-style={Math.round(
            gemFeaturesScores?.[feature]
          ).toString()}
          style={getTabColor(false, feature)}
        >
          <b>{listOfMarkers?.[selectedMap]?.length}</b>{' '}
          {!feature?.endsWith('s') && scoreProperties[selectedMap] > 1
            ? feature + 's'
            : feature}
        </div>
      </div>
    );
  };

  const renderFinalScore = () => {
    return isWalkIndex ? renderModernWalkindxScore() : renderModernLifestyleScore();
  };

  const getFeatureScore = () => {
    // if (!walkindexScores[selectedMap] ||!lifeStyleScores[selectedMap]) return 1;

    if (isWalkIndex) {
      let feature = (mapTab !== 'All' ? mapTab : '') || currentWalkindxType;
      feature = feature === 'Vibrance' ? 'Activities' : feature;
      return Math.round(
        walkindexScores?.[selectedMap]?.[walkindxID?.[feature]]
      ) || 1;
    } else {
      const type = mapTab === 'Leisure' ? 'leisure' : mapTab.toLowerCase();
      return getLifeStyleScore(
        lifeStyleScores?.[selectedMap]?.[type?.toLowerCase()],
        currentDistance,
        isStandalone
      ) || 1;
    }
  };

  const getFinalNeigborhoodScore = () => {
    return mapTab === 'All' ? getOverallScore() : getFeatureScore();
  };

  const getOverallScoreLabel = () => {
    if (isWalkIndex) {
      return 'Feel Score';
    } else {
      return `${(mapTab == 'All' || mapTab == 'Favorites') ? 'Gem' : mapTab} Score`;
    }
  };

  const getOverallScoreWording = () => {
    const gemFeature =
      currentLifestyleType[0] === 'Leisure'
        ? 'leisure'
        : currentLifestyleType[0]?.toLowerCase();
    if (mapTab !== 'All') {
      if (isWalkIndex) {
        return getWalkIndexWording(getFinalNeigborhoodScore());
      } else {
        return (
          <span>
            {getLifeStyleWording(scoreProperties[selectedMap], currentDistance)}
          </span>
        );
      }
    } else {
      if (isWalkIndex) {
        return getWalkIndexWording(getFinalNeigborhoodScore());
      } else {
        return getLifeStyleWording(
          lifeStyleScores[selectedMap][gemFeature],
          currentDistance
        );
      }
    }
  };

  const filteredLifestyleFeatures = () => {
    const features = lifeStyleFeatures.slice(1, lifeStyleFeatures.length - 1);

    if (
      currentLifestyleType?.length > 1 ||
      (isWalkIndex && currentLifestyleType?.length > 0)
    ) {
      return features.filter((feature) => {
        return currentLifestyleType.includes(feature.name);
      });
    } else {
      return features;
    }
  };

  const renderCustomButtons = (selected) => {
    if (!walkindexScores[selectedMap] || !lifeStyleScores[selectedMap]) return;
    let listBtns = [];
    if (currentLifestyleType.length > 1) {
      listBtns = filteredLifestyleFeatures();
    } else {
      listBtns = selected
        ? isWalkIndex
          ? walkIndexFeatures
          : filteredLifestyleFeatures()
        : isWalkIndex
        ? filteredLifestyleFeatures()
        : walkIndexFeatures;
    }

    let scores = [];
    if (currentLifestyleType.length > 1) {
      scores = gemFeaturesScores;
    } else {
      scores = selected
        ? isWalkIndex
          ? walkindexScores[selectedMap]
          : gemFeaturesScores
        : isWalkIndex
        ? gemFeaturesScores
        : walkindexScores[selectedMap];
    }

    if (listBtns.length) {
      return listBtns.map((item) => {
        const btnColor = {
          '--type-color': item.color,
        };

        const getName =
          item.name === 'Leisure' ? 'leisure' : item.name.toLowerCase();

        return (
          <button
            data-tooltip-id={`custom-button-${item.name}`}
            key={`custom-button-${item.name}`}
            className={`dashboard-type-btn is-btn-active modern-dashboard-type-btn
              ${isMobile && 'is-mobile-btn'}
              ${isMapLoading[selectedMap] ? 'btn-loading' : ''}
            `}
            style={btnColor}
          >
            <div
              className={`custom-button-icon modern-custom-button-icon
              ${
                (!isWalkIndex ||
                  !selected ||
                  currentLifestyleType.length > 1) &&
                'modern-lifestyle-custom-button'
              }`}
            >
              <ScoreIcon
                height='25px'
                width='25px'
                withBg={true}
                score={Math.round(scores[item.id]).toString()}
                content={item.svg}
              />
            </div>
            <div className='btn-name'>
              {item.name}
              <Show.When
                isTrue={!isWalkIndex || currentLifestyleType.length > 0}
              >
                <span className='gem-count'>{` ${
                  lifeStyleScores[selectedMap][getName] || '0'
                }`}</span>
              </Show.When>
            </div>
          </button>
        );
      });
    }
  };

  const getTabColor = () => {
    const selectedFeatures = isWalkIndex
      ? walkIndexFeatures
      :  lifeStyleFeatures.slice(1, -1);
    const selectedColor = selectedFeatures
      .filter(
        (feature) =>
          feature.name.toLocaleLowerCase() === mapTab.toLocaleLowerCase()
      )
      .map((feature) => feature.color)?.[0];

    return { '--tab-color': selectedColor || '#fff' };
  };

  const getTabLabel = () => {
    if (mapTab === 'All') {
      return `Gem${getTotalGems(lifeStyleScores[selectedMap]) > 1 ? 's' : ''}`;
    } else {
      return mapTab;
    }
  };

  const getActiveIcon = (score) => {
    const gemIcons = [ActiveGem1, ActiveGem2, ActiveGem3];
    const feelIcons = [ActiveFeel1, ActiveFeel2, ActiveFeel3];

    const icons = isWalkIndex ? feelIcons : gemIcons;
    const IconComponent = icons[score - 1] || null; // Ensure score is within range (1-3)

    return IconComponent ? <IconComponent /> : null;
  };

  const isFilterActive =
    categoryFilters.length > 1 ||
    categoryFilters[0] !== '' ||
    (currentRates.length !== 0 && currentRates[0] !== '');

  return (
    <div className='mobile-map-score-controls'>
      {/* <Show.When isTrue={showCompareLifeStyle}>
        <div
          className={`slider-arrow-container
            ${selectedMap === 0 && 'align-right'}
            ${selectedMap === 3 && 'align-left'}
          `}
        >
          <Show.When isTrue={selectedMap !== 0}>
            <div
              className="previous-btn"
              onClick={() => handleSlideButtonClick(selectedMap - 1)}
            >
              <Arrow />
              A{selectedMap}
            </div>
          </Show.When>
          <Show.When isTrue={selectedMap !== 3}>
            <div
              className="next-btn"
              onClick={() => handleSlideButtonClick(selectedMap + 1)}
            >
              A{selectedMap + 2}
              <Arrow />
            </div>
          </Show.When>
        </div>
      </Show.When> */}
      <Show>
        <Show.When isTrue={true}>
          <Show>
            <Show.When isTrue={currentLifestyleType.length > 1 && !isWalkIndex}>
              <div
                className={`type-scores ${
                  !isWalkIndex && 'gem-type-scores'
                }`}
              >
                {renderCustomButtons(true)}
              </div>
            </Show.When>
            <Show.Else>
              <div className="score-legend-wrapper">
                <MapTypeLegends />
              </div>
            </Show.Else>
          </Show>
        </Show.When>
        <Show.Else>
          <Show.When isTrue={!isPropertyEmpty}>
            <Show>
              <Show.When isTrue={isMapLoading[selectedMap] || isLoading}>
                <div className="loading">Loading...</div>
              </Show.When>
              <Show.Else>
                <div className="no-data">No Data Available</div>
              </Show.Else>
            </Show>
          </Show.When>
        </Show.Else>
      </Show>
      <Show>
        <Show.When isTrue={isWalkIndex}>
          <div className="selected-feel-feature" style={getTabColor(true, mapTab)}>
          <ScoreIcon
            width='25px'
            height='25px'
            withBg={false}
            score={getFeatureScore()}
            content={
              <div className="feel-feature-icon">{getFeatureIcon(true, mapTab)}</div> 
            }
          />
          <div className="tab-score-desciption">
            {getTypeDescription(
              currentTab.toLowerCase(),
              mapTab.toLowerCase(),
              Math.round(
                parseFloat(
                  walkindexScores?.[selectedMap]?.[walkindxID?.[mapTab]]
                )
              ) || 1,
              currentDistance,
              isStandalone,
              isMobile
            )}
          </div>
          </div>
        </Show.When>
        <Show.Else>
          <div className="total-gems" style={getTabColor()}>
            <div className="gem-count"  onClick={() => showEstablishments()}>
              {listOfMarkers[selectedMap]?.length}
            </div>
            <div className="tab-score-desciption">
              <Show.When isTrue={isFilterActive && !isWalkIndex}>
                <Active />{' '}
              </Show.When>
              {getTabLabel()}
            </div>
          </div>
        </Show.Else>
      </Show>
      {/* <Show.When isTrue={currentLifestyleType.length < 2}>
        <div className={`popup-buttons ${
            isPropertyEmpty && 'no-address'
          }`}
        >
          <Show.When isTrue={!isWalkIndex && showCompareLifeStyle && hasData()}>
            <div onClick={() => showEstablishments()}>
              <div className="total-gems">
                {listOfMarkers[selectedMap]?.length}
              </div>
              Establishment
            </div>
          </Show.When>
          <Show.When isTrue={!showDashboard && !showCompareLifeStyle}>
            <div onClick={() => openCompareAddress()}>
              <Grid className="grid-icon" />
              Compare Address
            </div>
          </Show.When>
          <Show>
            <Show.When isTrue={showDashboard}>
              <Show>
                <Show.When isTrue={quickSearchMarkers.isLoading}>
                  <Skeleton count={1} size={34} width={66} baseColor='#a9a9a9' />
                </Show.When>
                <Show.Else>
                  <div onClick={() => openQuickSearch()}>
                    <Search />
                    Quick Search
                  </div>
                </Show.Else>
              </Show>
              <div onClick={() => openTutorial()}>
                <Info />
                Tutorials
              </div>
            </Show.When>
            <Show.Else>
              <Show.When isTrue={!showCompareLifeStyle}>
                <div onClick={() => handleSaveAddressToFavorites()}>
                  <Favorite className={`${isAddressSaved() && 'saved-address'}`} />
                  {isAddressSaved() ? 'Remove from' : 'Add to'} Favorites
                </div>
              </Show.When>
            </Show.Else>
          </Show>
        </div>
      </Show.When> */}
    </div>
  )
};

export default MobileScoreContainer;