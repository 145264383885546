import { useState, useRef } from 'react';
import './index.css';

import Show from '../../utility/Show';
import FormError from '../../utility/FormError';

import { ReactComponent as WalkspanLogo } from '../../assets/walkspan-logo-2.svg';

const APIKeyScreen = ({ state, actions }) => {
  const { userDetails, errorMessage } = state;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const formRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const formDataObj = Object.fromEntries(formData.entries());
    const payload = {
      api_key: formDataObj['api-key'],
    };
    try {
      actions.handleAPIKey(payload);
      setIsSubmitted(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitted(false);
    }
  }

  return (
    <div className="api-key-screen-container">
      <div className="api-key-card">
        <WalkspanLogo />
        <div>To continue using our platform, please enter the API Key</div>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Show.When isTrue={errorMessage && !isSubmitted}>
            <FormError error={errorMessage} textColor='#f14c4c' />
          </Show.When>
          <input
            type='text'
            name='api-key'
            placeholder='Enter API Key'
            // onChange={(e) => setEmail(e.target.value)}
          />
          <button type='submit'>Send it now</button>
        </form>
      </div>
    </div>
  )
}

export default APIKeyScreen;