import React, { useState, useEffect, useContext } from 'react';
import { StoreContext } from '../../store';
import { GoogleMap, Marker, Polyline } from '@react-google-maps/api';
import useGoogleMaps from '../../useGoogleMaps';
import './index.css';
import { lightMapTheme, } from '../../services';
import { getRouteListByCategory } from './data1'; // Updated import
import { walkIndexFeatures } from '../../constants';
import { Tooltip } from 'react-tooltip';
import { getAttributeDescription } from '../../services';
import { FaBars } from 'react-icons/fa'; // For hamburger menu icon

const GoogleMapComponent = () => {
  const { state, dispatch } = useContext(StoreContext);
  const isGoogleMapsReady = useGoogleMaps();

  const [selectedCategory, setSelectedCategory] = useState('Nature');
  const [selectedRoute, setSelectedRoute] = useState(0);
  const [firstMarkerIcon, setFirstMarkerIcon] = useState(null);
  const [lastMarkerIcon, setLastMarkerIcon] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // For toggling the sidebar

  // Retrieve the list of routes based on the selected category
  const routeData = getRouteListByCategory(selectedCategory);

  // Default center in case of invalid or missing coordinates
  const defaultCenter = {
    lat: 40.730610, // Example fallback latitude (New York City)
    lng: -73.935242 // Example fallback longitude
  };

  // Set marker icons after Google Maps is ready
  useEffect(() => {
    if (isGoogleMapsReady && window.google && window.google.maps) {
      setFirstMarkerIcon({
        url: 'https://maps.google.com/mapfiles/kml/paddle/grn-stars.png',
        scaledSize: new window.google.maps.Size(50, 50),
      });

      setLastMarkerIcon({
        url: 'https://maps.google.com/mapfiles/kml/paddle/red-stars.png',
        scaledSize: new window.google.maps.Size(50, 50),
      });
    }
  }, [isGoogleMapsReady]);

  // Dispatch to set Google Maps ready state in context
  useEffect(() => {
    if (isGoogleMapsReady) {
      dispatch({
        type: 'SET_IS_GOOGLE_MAPS_LOADED',
        payload: isGoogleMapsReady,
      });
    }
  }, [isGoogleMapsReady, dispatch]);

  // Function to handle category selection change
  const handleCategoryChange = (name) => {
    setSelectedCategory(name);
    setSelectedRoute(0); // Reset to the first route in the new category
  };

  // Function to handle route selection change
  const handleRouteChange = (e) => {
    setSelectedRoute(Number(e.target.value));
  };

  // Validate the coordinates and ensure they are valid numbers
  const validateCoordinates = (coordinates) => {
    return (
      Array.isArray(coordinates) &&
      coordinates.length === 2 &&
      typeof coordinates[0] === 'number' &&
      typeof coordinates[1] === 'number' &&
      !isNaN(coordinates[0]) &&
      !isNaN(coordinates[1])
    );
  };

  // Get the selected route's coordinates and validate them
  const selectedCoordinates = routeData[selectedRoute]?.coordinates || [];
  const selectedDirections = routeData[selectedRoute]?.directions || []; // Get the directions for the selected route

  // Find the first valid coordinate in the selected route
  const getValidCenter = (coordinates) => {
    const validCoord = coordinates.find(coord => validateCoordinates(coord));
    return validCoord ? { lat: validCoord[0], lng: validCoord[1] } : null;
  };

  // Set the map center to the first valid coordinate or fallback to default
  const center = getValidCenter(selectedCoordinates) || defaultCenter;

  // Toggle sidebar visibility
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const renderCustomButtons = () => {
    return walkIndexFeatures.map((item, index) => {
      const btnColor = {
        backgroundColor: item.name === selectedCategory && item.color,
        '--type-color': item.color,
      };
      return (
        <div key={`feature-${item.name}`}>
          <button
            data-tooltip-id={`custom-button-${item.name}`}
            className={`map-btn capsule-map-btn modern-map-btn ${
              item.name === selectedCategory ? 'is-btn-active active-capsule-map-btn' : ''
            }`}
            style={btnColor}
            onClick={() => handleCategoryChange(item.name)}
          >
            <div className='feature-icon-wrapper'>
              {item.svg}
            </div>
            <div className='btn-name'> {item.name} </div>

            <Tooltip
              className='custom-btn-popup'
              id={`custom-button-${item.name}`}
              place='bottom'
            >
              {' '}
              {getAttributeDescription(item.name)}
            </Tooltip>
          </button>
        </div>
      );
    });
  };

  // Render the directions in the sidebar
  const renderDirections = () => {
    return (
      <div className="directions">
        <h3>Directions</h3>
        <ul>
          {selectedDirections.map((step, index) => (
            <li key={index}>
              <p>{step.description}</p>
              {step.miles && <p><strong>Miles:</strong> {step.miles}</p>}
              {step.time && <p><strong>Time:</strong> {step.time}</p>}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="map-container">
      {/* Hamburger menu for collapsing/expanding the sidebar */}
      <div className="hamburger-menu" onClick={toggleSidebar}>
        <FaBars size={24} />
      </div>

      <div className="floating-header">
        <div className='feature-buttons'>
          {renderCustomButtons()}
        </div>
      </div>

      {/* Sidebar for category, route selection, and directions */}
      <div className={`sidebar ${isSidebarOpen ? 'open' : 'collapsed'}`}>
        {/* Route selector */}
        <select onChange={handleRouteChange} value={selectedRoute}>
          {routeData.map((route, index) => (
            <option key={index} value={index}>
              {route.name}
            </option>
          ))}
        </select>

        {/* Render directions */}
        {renderDirections()}
      </div>


      {/* Google Map */}
      {isGoogleMapsReady && (
        <GoogleMap
          mapContainerClassName="google-map"
          center={center}
          zoom={14}
          options={{
            minZoom: 14,
            zoomControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            styles: lightMapTheme,
            keyboardShortcuts: false,
          }}
        >
          {/* First marker with custom icon */}
          {firstMarkerIcon && validateCoordinates(selectedCoordinates[0]) && (
            <Marker
              position={{ lat: selectedCoordinates[0][0], lng: selectedCoordinates[0][1] }}
              icon={firstMarkerIcon}
            />
          )}

          {/* Middle markers with default icon */}
          {selectedCoordinates.slice(1, selectedCoordinates.length - 1).map((coord, index) => (
            validateCoordinates(coord) && (
              <Marker
                key={index + 1}
                position={{ lat: coord[0], lng: coord[1] }}
              />
            )
          ))}

          {/* Last marker with custom icon */}
          {lastMarkerIcon && validateCoordinates(selectedCoordinates[selectedCoordinates.length - 1]) && (
            <Marker
              position={{
                lat: selectedCoordinates[selectedCoordinates.length - 1][0],
                lng: selectedCoordinates[selectedCoordinates.length - 1][1],
              }}
              icon={lastMarkerIcon}
            />
          )}

          {/* Polyline with custom dash pattern */}
          <Polyline
            path={selectedCoordinates.map(coord => ({ lat: coord[0], lng: coord[1] }))}
            options={{
              strokeColor: "#FF0000",
              strokeOpacity: 1.0,
              strokeWeight: 10,
              strokePattern: [
                { type: "line", length: 10 }, // Length of solid dash
                { type: "gap", length: 10 }   // Length of gap between dashes
              ],
            }}
          />
        </GoogleMap>
      )}
    </div>
  );
};

export default GoogleMapComponent;
